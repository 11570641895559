import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
  HostListener,
  NgZone,
  HostBinding,
} from '@angular/core';
import { SidememberListSelectionModel } from '../shared/models/sidemember-list-selection.model';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { List } from 'linqts';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IndexlistEntry } from '../shared/models/indexlist-entry';
import { SidememberMinimapComponent } from '../sidemember-minimap/sidemember-minimap.component';
import resizeObserverPolyfill from 'resize-observer-polyfill';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { Subscription, BehaviorSubject, Subject, Observable } from 'rxjs';
import { TotalPicturePrintingService } from '../total-picture/total-picture-printing.service';
import { DataSource } from '../shared/models/grapeservice/datasource';
import { SelectDataSourceComponent } from '../select-datasource/select-datasource.component';
import { SidememberListVariantCodeSearchComponent } from '../sidemember-list-variantcode-search/sidemember-list-variantcode-search.component';
import { tsXLXS } from 'ts-xlsx-export';
import { SidememberListFilterComponent } from '../sidemember-list-filter/sidemember-list-filter.component';
import { debounceTime } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { GrapeService } from '../shared/services/grape.service';
import { Sidemember } from '../shared/models/grapeservice/sidemember';
import { Keyboard } from '../rendering/input/type/keyboard';
import { SidememberSelectService } from '../shared/services/sidemember-select.service';
import { isDate, isNumber } from '../shared/logic/type-utils';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { ModalResult, ModalType } from '../shared/models/enums';
import { LocaleService } from '../shared/services/locale.service';
enum SidememberListState {
  Visible,
  Hidden,
}

// TODO: Split up into smaller files
@Component({
  selector: 'app-sidemember-list',
  templateUrl: './sidemember-list.component.html',
  styleUrls: ['./sidemember-list.component.scss'],
})
export class SidememberListComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'd-flex flex-row flex-grow-1';

  @ViewChild('panelBody') panelBody: ElementRef;
  @ViewChild('indexlistHeader') indexlistHeader: ElementRef;
  @ViewChild('indexlistBody') indexlistBody: ElementRef;
  @ViewChild('ContextMenuComponent')
  sidememberContextMenu: ContextMenuComponent;
  @ViewChild('InfiniteScrollDirective')
  infiniteScroll: InfiniteScrollDirective;
  @ViewChild('minimap')
  sidememberMinimap: SidememberMinimapComponent;
  @ViewChild('variantCodeSearchDefs')
  variantCodeSearchDefs: SidememberListVariantCodeSearchComponent;
  @ViewChild('sidememberList') sidememberList: ElementRef;
  @ViewChild('dataSource')
  dataSourceComponent: SelectDataSourceComponent;
  @ViewChild('sidememberListFilter')
  sidememberListFilterComponent: SidememberListFilterComponent;
  @ViewChild('hScroll') hScroll: ElementRef;

  @Output('printTotalPicture') totalPicturePrint: EventEmitter<
    [IndexlistEntry[], string]
  > = new EventEmitter();

  public indexlistEntries: IndexlistEntry[] = [];
  public sidememberSelection: IndexlistEntry[] = [];
  public typeText?: string = null;
  public currentSource: string;
  public inDefineFilterMode = false;
  public searchIsFilterActive = false;
  public pagerPage = 1;
  public numPages = 1;
  public collectionSize = 0;
  public indexlistPageEntries: IndexlistEntry[] = [];
  public settings: UserSettingsService;
  public grpData: GrapeData;
  public hfStatusFilter$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public chassisNumberFilter$: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );

  public indexlistColumns = [
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.CHASSIS_NO'),
      value: 'chassisNumber',
      class: 'w-250',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.IPOID'),
      value: 'ipoId',
      class: 'w-200',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.VER'),
      value: 'version',
      class: 'w-125',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.CIID'),
      value: 'ciId',
      class: 'w-200',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.HF'),
      value: 'hfStatus',
      class: 'w-125',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.CU'),
      value: 'cusName',
      class: 'w-300',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.PLANNED_START'),
      value: 'assemblyDate',
      class: 'w-400',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.PROD_INDIVIDUAL'),
      value: 'prodIndividual',
      class: 'w-300',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.SYNC_TIME'),
      value: 'syncTime',
      class: 'w-250',
    },
    {
      name: this.translate.stream('SIDEMEMBER_LIST.COLUMNS.SUPPLIER'),
      value: 'supplierName',
      class: 'w-400-overflow-hidden',
    },
  ];

  public selectedDataSource: string;
  private selectSidemember$: Subject<IndexlistEntry> = new Subject();
  private subscriptions: Subscription[] = [];
  private sortField = 'chassisNumber';
  private sortOrder = 'desc';
  private listState = SidememberListState.Visible;
  grapeUserAccess: boolean;

  constructor(
   
    public totalPicturePrinting: TotalPicturePrintingService,
    private userSettings: UserSettingsService,
    private paginationConfig: NgbPaginationConfig,
    public grapeDataService: GrapeDataService,
    private fileSaver: FileSaverService,
    private grapeService: GrapeService,
    private ngZone: NgZone,
    private sidememberSelectService: SidememberSelectService,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService,
    private localeService: LocaleService
  ) {
    paginationConfig.pageSize = 25;
    paginationConfig.ellipses = true;
    paginationConfig.directionLinks = true;
    paginationConfig.boundaryLinks = true;
    paginationConfig.maxSize = 30;

    this.collectionSize = 0;
    this.settings = this.userSettings;
    this.settings.indexlist.load();
    this.settings.variantCodesSearch.load();
    this.selectedDataSource = this.settings.indexlist.dataSource;
    this.searchIsFilterActive = this.settings.variantCodesSearch.isFilterActive;

    this.sortField = this.settings.indexlist.sortColumn;
    this.sortOrder = this.settings.indexlist.sortDesc ? 'desc' : 'asc';

    this.subscribeToData();
  }

  get isSidememberSelectionNotExist() {
    return this.sidememberSelection.length === 0;
  }

  get isloadingPreviewErrorOccured() {
    return this.grapeDataService.errorPreview.value;
  }

  get isTotalPictureDisabled(): boolean {
    return (
      this.sidememberSelection.length !== 0 &&
      this.totalPicturePrinting.isProcessing
    );
  }

  get isFilterEnabled(): boolean {
    return (
      this.settings.variantCodesSearch.variantCodeSearchDefinitions.length > 0
    );
  }

  get filterDefExists(): boolean {
    return (
      this.variantCodeSearchDefs.filterDefinitionList.touched ||
      this.variantCodeSearchDefs.filterDefinitionList.dirty
    );
  }

  get gotMultiSelection(): boolean {
    return this.sidememberSelection.length > 1;
  }
  

  get gotSingleSelection(): boolean {
    return this.sidememberSelection.length === 1;
  }

  get gotDoubleSelection(): boolean {
    return this.sidememberSelection.length === 2;
  }

  get showPreview(): boolean {
    return (
      this.indexlistEntries.length > 0 &&
      this.sidememberSelection.length > 0 &&
      this.gotSingleSelection &&
      !this.isLoadingPreview
    );
  }

  get isLoadingPreview(): boolean {
    return (
      this.grpData &&
      this.grpData.Data &&
      this.grpData.Data.loadingSidememberPreview
    );
  }

  get isOpenEnabled(): boolean {
    return this.gotSingleSelection || this.gotDoubleSelection;
  }

  get filterToggleTitle(): Observable<string> {
    return this.settings.variantCodesSearch.variantCodeSearchDefinitions
      .length > 0
      ? this.translate.stream('SIDEMEMBER_LIST.VAR_CODE_SEARCH_TOGGLE')
      : this.translate.stream('NO_FILTER');
  }

  get showTableHeader(): boolean {
    return this.numPages > 0 && this.isDataSourceSelected();
  }

  get pagerText(): string {
    return this.showTableHeader
      ? this.translate.instant('PAGINATION.PAGE') +
          ` ${this.pagerPage} ` +
          this.translate.instant('PAGINATION.OF') +
          ` ${this.numPages}, ${this.collectionSize} ` +
          this.translate.instant('PAGINATION.ROWS')
      : this.translate.instant('SIDEMEMBER_LIST.NO_SIDEMEMBERS');
  }

  ngOnInit() {
    this.indexlistEntries = [];
    this.deselectSidemember();
    this.grpData = null;
  }
  
 

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscribeToUiEvents();
    });

    this.observeResize();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  @HostListener('window:resize')
  public onHorizontalScroll(): void {
    this.sidememberList.nativeElement.style.width = `${
      this.hScroll.nativeElement.clientWidth +
      Math.floor(this.hScroll.nativeElement.scrollLeft || 0)
    }px`;
  }

  public printTotalPicture(): void {
    if (this.isTotalPictureDisabled) return;
    this.totalPicturePrint.emit([
      this.sidememberSelection,
      this.selectedDataSource,
    ]);
  }

  public errText(): any {
    return this.grpData.Data.loadError.error;
  }

  public isOpenSidememberError(): boolean {
    if (this.grpData === null) {
      return false;
    }
    return this.grpData.Data.loadError.forIndexList;
  }

  public isLoading(): boolean {
    return (
      this.isDataSourceSelected() &&
      (this.grpData === null || this.grpData.Data.loadingIndexList)
    );
  }

  public isDataSourceSelected() {
    return this.selectedDataSource !== null;
  }

  public isLoadingTypeText(): boolean {
    return this.grpData === null || this.grpData.Data.loadingTypeText;
  }

  public selectFirstListItem(): void {
    if (this.indexlistEntries.length > 0) {
      this.sidememberSelection.push(this.indexlistEntries[0]);
    }
  }

  public trackByEntryIndex(index: number): number {
    return index;
  }

  public activeSort(column: string, descending: boolean): boolean {
    return (
      this.settings.indexlist.sortColumn === column &&
      this.settings.indexlist.sortDesc === descending
    );
  }

  public selected(entry: IndexlistEntry): boolean {
    if (!entry) return;
    const foundEntry = this.sidememberSelection.find((x) => {
      return x.ipoId === entry.ipoId && x.version === entry.version;
    });
    return foundEntry !== undefined;
  }

  public openSidemember(): void {
    if (this.isloadingPreviewErrorOccured) {
      return;
    }
    if (
      !this.isOpenEnabled ||
      !this.indexlistEntries ||
      this.indexlistEntries.length === 0
    ) {
      return;
    }

    if (this.sidememberSelection.length === 0 || !this.sidememberSelection[0]) {
      return;
    }

    this.sidememberSelectService.openSidemember();
  }

  public indexlistClick(
    event: MouseEvent,
    entry: IndexlistEntry,
    index: number,
    rightClick = false
  ): void {
    if (this.selectedItemRightClicked(rightClick, entry)) {
      return;
    }
    if (!event.ctrlKey && !event.shiftKey) {
      this.sidememberSelection = [];
    }

    if (event.shiftKey) {
      document.getSelection().removeAllRanges();
      this.selectMultipleSidemembers(entry);
    }
    this.addToSelection(entry);

    this.sidememberSelectService.clearSidememberCompareSelection();

    if (this.sidememberSelection.length === 1) {
      this.selectSidemember(this.sidememberSelection[0]);
    }
    if (this.sidememberSelection.length === 2) {
      // After sorting order of selection changes hence passing entry for second selection directly
      this.selectSidemember(entry, true);
    }
  }

  private selectedItemRightClicked(rightClick: boolean, entry: IndexlistEntry) {
    return rightClick && this.sidememberSelection.indexOf(entry) >= 0;
  }

  public setFullSize(ipoId: string): void {
    this.settings.indexlist.fullSize = !this.settings.indexlist.fullSize;
    this.settings.indexlist.save();
    this.grapeDataService.setVariantCodes(ipoId, this.selectedDataSource);
    // Wait angular rendering before resizing list
    setTimeout(() => {
      this.onHorizontalScroll();
    }, 100);
  }

  public gotoFirstPage(): void {
    this.pagerPage = 1;
    this.changePage();
    this.selectFirstItemOfPage();
    this.resetSidememberListScroll();
  }

  public gotoLastPage(): void {
    this.pagerPage = this.numPages;
    this.changePage();
    this.selectFirstItemOfPage();
    this.resetSidememberListScroll();
  }

  private resetSidememberListScroll(): void {
    this.sidememberList.nativeElement.scrollTop = 0;
    this.hScroll.nativeElement.scrollLeft = 0;
  }

  public changePage(): void {
    const numToSkip = (this.pagerPage - 1) * this.paginationConfig.pageSize;
    const lastPageCount = this.collectionSize % this.paginationConfig.pageSize;

    this.numPages = Math.ceil(
      this.collectionSize / this.paginationConfig.pageSize
    );

    this.indexlistPageEntries = [];

    if (this.pagerPage === this.numPages && lastPageCount !== 0) {
      for (let i = numToSkip; i < numToSkip + lastPageCount; i += 1) {
        this.indexlistPageEntries.push(this.indexlistEntries[i]);
      }
    } else {
      if (this.indexlistEntries.length > 0) {
        for (
          let i = numToSkip;
          i < numToSkip + this.paginationConfig.pageSize;
          i += 1
        ) {
          this.indexlistPageEntries.push(this.indexlistEntries[i]);
        }
      }
    }
  }

  public filterListOfChassis() {
    this.indexlistEntries = [];
    this.indexlistEntries = this.grpData.Data.IndexList.entries.filter(
      (item: IndexlistEntry) => {
        let selectedChassisArray = this.chassisNumberFilter$.value.split(",").map(x => x.toLowerCase());
        return (
          this.hfStatusFilter$.value.includes(item.hfStatus) &&
          (this.chassisNumberFilter$.value?.length === 0
            ? true
            : selectedChassisArray.includes(item.chassisNumber?.toLowerCase())
            
            )
        );
      }
    );
    this.sort();
    this.collectionSize = this.indexlistEntries.length;

    if (this.collectionSize === 0) {
      this.deselectSidemember();
    }

    const index = this.indexlistEntries.findIndex(
      (x) =>
        x.ipoId === this.sidememberSelection[0]?.ipoId &&
        x.version === this.sidememberSelection[0]?.version
    );

    this.pagerPage =
      index > 0 ? Math.ceil((index + 1) / this.paginationConfig.pageSize) : 1;
      if (index === -1) {
        this.selectFirstItemOfPage();
      }
    this.changePage();
    this.ensureSelectedRowVisible();
  }

  public applyFilter() {
    const checkList = this.chassisNumberFilter$?.value?.split(",");
    if (checkList?.length > 1) {
      this.filterListOfChassis();
    }
    else {
      this.indexlistEntries = [];
      this.indexlistEntries = this.grpData.Data.IndexList.entries.filter(
        (item: IndexlistEntry) => {
          return (
            this.hfStatusFilter$.value?.includes(item.hfStatus) &&
            (this.chassisNumberFilter$?.value?.length === 0
              ? true
              : item.chassisNumber?.toLowerCase()
                  .includes(this.chassisNumberFilter$.value.toLowerCase()))
          );
        }
      );
  
      this.sort();
      this.collectionSize = this.indexlistEntries.length;
  
      if (this.collectionSize === 0) {
        this.deselectSidemember();
      }
  
      const index = this.indexlistEntries.findIndex(
        (x) =>
          x.ipoId === this.sidememberSelection[0]?.ipoId &&
          x.version === this.sidememberSelection[0]?.version
      );
  
      this.pagerPage =
        index > 0 ? Math.ceil((index + 1) / this.paginationConfig.pageSize) : 1;
        if (index === -1) {
          this.selectFirstItemOfPage();
        }
      this.changePage();
      this.ensureSelectedRowVisible();
    }
    
  }

  public sortBy(fieldToSortBy: string): void {
    if (this.sortField !== fieldToSortBy) {
      this.sortOrder = 'asc';
      this.sortField = fieldToSortBy;
    } else {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    this.settings.indexlist.sortColumn = fieldToSortBy;
    this.settings.indexlist.sortDesc = this.sortOrder === 'desc';
    this.settings.indexlist.save();

    this.sort().then(() => {
      this.pagerPage = 1;
      this.changePage();
      this.selectFirstItemOfPage();
      this.ensureSelectedRowVisible();
    });
  }

  public setFilterState(): void {
    if (!this.isFilterEnabled) return;
    this.searchIsFilterActive = !this.searchIsFilterActive;
    this.settings.variantCodesSearch.isFilterActive = this.searchIsFilterActive;
    this.settings.variantCodesSearch.save();
    this.grapeDataService.setIndexList(this.selectedDataSource);
  }

  public selectDataSource(dataSource: DataSource): void {
    this.grapeDataService.resetErrors();
    this.indexlistEntries = [];
    this.selectedDataSource = dataSource.DataSourceName;
    this.grapeDataService.setIndexList(this.selectedDataSource);
    this.resetSidememberListScroll();
    this.selectFirstIndexlistEntry();
  }

  public exportToExcel() {
    const translate = (key) => this.translate.instant(`SIDEMEMBER_LIST.COLUMNS.${key}`);
    const exportableEntries = this.indexlistEntries.map((entry) => ({
        [translate('IPOID')]: entry.ipoId,
        [translate('VER')]: entry.version,
        [translate('CIID')]: entry.ciId,
        [translate('HF')]: entry.hfStatus,
        [translate('CU')]: entry.cusName,
        [translate('PLANNED_START')]: entry.assemblyDate.toLocaleString(this.localeService.locale),
        [translate('CHASSIS_NO')]: entry.chassisNumber,
        [translate('PROD_INDIVIDUAL')]: entry.prodIndividual,
        [translate('SYNC_TIME')]: new Date(entry.syncTime).toLocaleDateString(this.localeService.locale),
        [translate('SUPPLIER')]: entry.supplierName,
    }));
    tsXLXS()
      .exportAsExcelFile(exportableEntries)
      .saveAsExcelFile('GrapeIndexList');
  }

  public refreshList(): void {
    this.grapeDataService.setIndexList(this.selectedDataSource);
    this.resetSidememberListScroll();
  }

  public async validateFilterDefinitionsClosing() {
    if (!this.variantCodeSearchDefs.filterList.isValid()) {
      await this.showValidateModalOnClose();
    } else if (this.filterDefExists) {
      const result: ModalResult = await this.modalDialogService.show(
        this.translate.instant('SAVE_CONFIRMATION'),
        ModalType.YesNoCancel
      );
      switch (result) {
        case ModalResult.Yes:
          this.variantCodesSearch();
          this.closeFilterDefinitions();
          break;
        case ModalResult.No:
          this.closeFilterDefinitions();
          break;
        default:
          return;
      }
    }
    if (
      this.variantCodeSearchDefs.filterList.filterDefinitions.length === 0 ||
      !this.filterDefExists
    ) {
      this.closeFilterDefinitions();
    }
  }

  public closeFilterDefinitions(): void {
    this.inDefineFilterMode = false;
  }

  //#region Modal
  async showValidateModalOnClose() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_ERROR'),
      ModalType.YesCancel
    );
    if (result === ModalResult.Yes) {
      this.variantCodesSearch();
      this.closeFilterDefinitions();
    } else {
      return;
    }
  }

  async showActivationFilterModalOnSave() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_CONFIRMATION'),
      ModalType.YesNo
    );
    if (result === ModalResult.Yes) {
      this.searchIsFilterActive = true;
      this.settings.variantCodesSearch.isFilterActive = this.searchIsFilterActive;
    }
  }
  //#endregion Modal

  public async save() {
    if (
      !this.variantCodeSearchDefs.filterList.isValid() ||
      (!this.filterDefExists &&
        this.variantCodeSearchDefs.filterList.filterDefinitions.length === 0)
    ) {
      return;
    }

    const showPopup =
      !this.settings.variantCodesSearch.isFilterActive &&
      this.variantCodeSearchDefs.filterList.filterDefinitions.length > 0;

    if (showPopup) {
      await this.showActivationFilterModalOnSave();
    }
    this.variantCodesSearch();
    this.closeFilterDefinitions();
  }

  private variantCodesSearch() {
    if (!this.variantCodeSearchDefs.filterList.isValid()) return;
    this.variantCodeSearchDefs.save();

    if (this.settings.variantCodesSearch.isFilterActive) {
      this.refreshList();
    }
    if (
      this.variantCodeSearchDefs.filterList.filterDefinitionList.length === 0
    ) {
      this.searchIsFilterActive = false;
      this.settings.variantCodesSearch.isFilterActive = this.searchIsFilterActive;
      this.settings.variantCodesSearch.save();
    }
  }

  public showFilterDefinitions(): void {
    this.inDefineFilterMode = true;
  }

  public exportSelectedSidemember(): void {
    if (!this.sidememberSelection) return;

    for (const entry of this.sidememberSelection) {
      this.grapeService
        .getSidememberData(
          this.selectedDataSource,
          entry.ipoId,
          String(entry.version)
        )
        .subscribe(
          (data: Sidemember) => {
            const jsonData = JSON.stringify(data, null, 4);
            this.fileSaver.saveText(
              jsonData,
              `${entry.ipoId}_${entry.version}_${entry.ciId}.json`
            );
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  private subscribeToData(): void {
    this.subscriptions.push(
      this.grapeDataService.data.subscribe((data: GrapeData) => {
        if (data.ChangedObject === GrapeObjectType.IndexList) {
          this.grpData = data;
          this.applyFilter();
          this.selectLastOpenedSidemember();
        }
        if (data.ChangedObject === GrapeObjectType.TypeText) {
          this.grpData = data;
          this.typeText = data.Data.loadError.forTypeText
            ? this.translate.instant('SIDEMEMBER_LIST.ERROR_LOADING_TYPETEXT')
            : data.Data.TypeText;
        }
      }),
      this.selectSidemember$
        .pipe(debounceTime(300))
        .subscribe((entry: IndexlistEntry) => {
          this.grapeDataService.setTypeText(
            entry.ipoId,
            this.selectedDataSource
          );

          this.grapeDataService.setSidememberPreview(
            this.selectedDataSource,
            entry.ipoId,
            entry.version.toString()
          );

          if (!this.settings.indexlist.fullSize) {
            this.grapeDataService.setVariantCodes(
              entry.ipoId,
              this.selectedDataSource
            );
          }
        })
    );
  }

  private selectLastOpenedSidemember() {
    this.selectLastOpenedFile();
    this.changePage();
    this.ensureSelectedRowVisible();
  }

  private subscribeToUiEvents(): void {
    this.subscriptions.push(
      this.sidememberListFilterComponent.hfStatusChanged.subscribe(
        (hf: any) => {
          this.hfStatusFilter$.next(hf);
          this.applyFilter();
        }
      ),
      this.sidememberListFilterComponent.chassisNumberChanged.subscribe(
        (ch: any) => {
          this.chassisNumberFilter$.next(ch);
          this.applyFilter();
        }
      )
    );

    this.hfStatusFilter$.next(this.settings.indexlist.hfStatus);
    this.chassisNumberFilter$.next(this.settings.indexlist.chassisSearchNumber);
  }

  private selectLastOpenedFile(): void {
    this.sidememberSelection = [];

    const lastOpenedEntry = this.getLastOpenedFile();

    if (!lastOpenedEntry && this.indexlistEntries.length === 0) return;

    if (!lastOpenedEntry) {
      this.pagerPage = 1;
      this.selectFirstIndexlistEntry();
      return;
    }

    const index = this.indexlistEntries.findIndex(
      (x) =>
        x.ipoId === lastOpenedEntry.ipoId &&
        x.version === lastOpenedEntry.version
    );

    this.pagerPage =
      index > 0 ? Math.ceil((index + 1) / this.paginationConfig.pageSize) : 1;

    let entry: IndexlistEntry;

    if (index !== -1) {
      entry = this.indexlistEntries[index];
    } else {
      entry = this.indexlistEntries[0];
    }

    if (!entry) return;

    this.addToSelection(entry);
    this.selectSidemember(entry);
  }

  private selectFirstIndexlistEntry(): void {
    if (this.indexlistEntries.length === 0) return;
    this.addToSelection(this.indexlistEntries[0]);
    this.selectSidemember(this.indexlistEntries[0]);
  }

  private getLastOpenedFile(): IndexlistEntry {
    const lastOpenedFiles = new List(this.settings.indexlist.lastOpenedFiles);
    const lastOpenedFile = lastOpenedFiles.FirstOrDefault(
      (ds) => ds.dataSource === this.settings.indexlist.dataSource
    );
    if (!lastOpenedFile) return null;

    if (this.sidememberSelection.length > 0 && this.sidememberSelection[0]) {
      return this.sidememberSelection[0];
    }
    const showLastOpenedFile =
      lastOpenedFile &&
      (this.sidememberSelection.length === 0 || !this.sidememberSelection[0]);
    return showLastOpenedFile ? lastOpenedFile.entry : null;
  }

  private setLastOpenedFile(): void {
    this.settings.indexlist.lastOpenedFiles = [];
    this.sidememberSelection.forEach((item: IndexlistEntry) => {
      const selection: SidememberListSelectionModel = new SidememberListSelectionModel(
        this.settings.indexlist.dataSource,
        item
      );
      this.settings.indexlist.lastOpenedFiles.push(selection);
    });
    this.settings.indexlist.save();
  }

  private addToSelection(
    entry: IndexlistEntry,
    deselectDuplicate = true
  ): void {
    if (!entry) return;

    if (this.sidememberSelection.length === 0) {
      this.sidememberSelection.push(entry);
      return;
    }

    const foundIndex = this.sidememberSelection.findIndex(
      (x) => x.ipoId === entry.ipoId && x.version === entry.version
    );

    if (foundIndex !== -1 && deselectDuplicate) {
      this.sidememberSelection.splice(foundIndex, 1);
      return;
    }

    if (foundIndex === -1) {
      this.sidememberSelection.push(entry);
    }
    this.sidememberSelection = this.sidememberSelection.sort((a, b) =>
      this.sortFn(a, b)
    );

  }

  private selectSidemember(
    entry?: IndexlistEntry,
    forComparison = false
  ): void {
    if (!this.indexlistEntries || this.indexlistEntries.length === 0) {
      return;
    }

    if (!entry) return;

    // Store selected SM info to use in Sidemember Select Service
    const selection: SidememberListSelectionModel = new SidememberListSelectionModel(
      this.selectedDataSource,
      entry
    );

    if (!forComparison) {
      this.selectSidemember$.next(entry);
      this.sidememberSelectService.sidememberSelectionPreview = selection;
      this.sidememberSelectService.sidememberCompareSelection = null;
    } else {
      this.sidememberSelectService.sidememberCompareSelection = selection;
    }
  }

  private selectMultipleSidemembers(entry: IndexlistEntry): void {
    const lastSelection =
      this.sidememberSelection.length !== 0
        ? this.sidememberSelection[this.sidememberSelection.length - 1]
        : null;
    const lastSelectionIndex = lastSelection
      ? this.indexlistEntries.indexOf(lastSelection)
      : null;

    const selectionIndex = this.indexlistEntries.indexOf(entry);
    if (lastSelectionIndex !== null) {
      const min = Math.min(selectionIndex, lastSelectionIndex) + 1;
      const max = Math.max(selectionIndex, lastSelectionIndex) - 1;

      for (let index = min; index <= max; index += 1) {
        const sidemember = this.indexlistEntries[index];
        if (sidemember) this.addToSelection(sidemember, false);
      }
    }
  }

  private deselectSidemember(): void {
    this.sidememberSelection = [];
    this.sidememberSelectService.clearSidememberSelection();
    this.typeText = null;
  }

  private observeResize(): void {
    const resizeObserver = new resizeObserverPolyfill(() => {});

    resizeObserver.observe(this.panelBody.nativeElement);
  }

  private async sort() {
    this.indexlistEntries.sort((a, b) => this.sortFn(a, b));
  }

  private sortFn(a, b): number {
    const order = this.sortOrder === 'desc' ? -1 : 1;

    const valA = a[this.sortField] || '';
    const valB = b[this.sortField] || '';

    if (valA === valB) return 0;

    if (isNumber(valA) && isNumber(valB)) {
      return Number(valA) > Number(valB) ? order : -order;
    }

    if (isDate(valA) && isDate(valB)) {
      return new Date(valA) > new Date(valB) ? order : -order;
    }

    return valA.toString().toUpperCase() > valB.toString().toUpperCase()
      ? order
      : -order;
  }

  public goPrevPage(selectionIndex?: number): void {
    if (!this.isItFirstPage()) {
      this.gotoPage(this.pagerPage - 1);
      this.selectFirstItemOfPage();
      this.resetSidememberListScroll();
    }
  }

  public goNextPage(selectionIndex?: number): void {
    if (!this.isItLastPage()) {
      this.gotoPage(this.pagerPage + 1);
      this.selectFirstItemOfPage();
      this.resetSidememberListScroll();
    }
  }

  private getIndexListEntryIndex(entry: IndexlistEntry): number {
    const arrayIndex = this.indexlistEntries.findIndex(
      (x) => x.ipoId === entry.ipoId && x.version === entry.version
    );
    return arrayIndex;
  }

  private gotoPage(newPageNo: number): void {
    this.pagerPage = newPageNo;
    this.changePage();
  }

  private selectEntryByIndex(index: number): void {
    const entry = this.indexlistEntries[index];

    if (!entry) return;

    this.sidememberSelection = [];

    this.addToSelection(entry);
    this.selectSidemember(entry);
  }

  private selectFirstItemOfPage(): void {
    const index = (this.pagerPage - 1) * this.paginationConfig.pageSize;
    this.selectEntryByIndex(index);
    this.ensureSelectedRowVisible();
  }

  private selectLastItemOfPage(): void {
    const index = Math.min(
      (this.pagerPage - 1) * this.paginationConfig.pageSize +
        this.paginationConfig.pageSize -
        1,
      this.indexlistEntries.length - 1
    );
    this.selectEntryByIndex(index);
    this.ensureSelectedRowVisible();
  }

  private isItFirstItemOfPage(index: number): boolean {
    return index % this.paginationConfig.pageSize === 0;
  }

  private isItLastItemOfPage(index: number): boolean {
    return (
      index % this.paginationConfig.pageSize ===
        this.paginationConfig.pageSize - 1 ||
      index === this.indexlistEntries.length - 1
    );
  }

  private isItLastPage(): boolean {
    return this.pagerPage === this.numPages;
  }

  private isItFirstPage(): boolean {
    return this.pagerPage === 1;
  }

  private selectSidememberByKeyboard(
    event: KeyboardEvent,
    direction: Keyboard
  ): void {
    const isMultiSelection =
      event.ctrlKey || event.shiftKey || this.sidememberSelection.length === 0;

    if (isMultiSelection) return;

    const index = this.getIndexListEntryIndex(this.sidememberSelection[0]);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const newIndex = direction === Keyboard.UP ? prevIndex : nextIndex;

    if (
      (this.isItLastItemOfPage(index) &&
        this.isItLastPage() &&
        direction === Keyboard.DOWN) ||
      (this.isItFirstItemOfPage(index) &&
        this.isItFirstPage() &&
        direction === Keyboard.UP)
    ) {
      return;
    }

    if (direction === Keyboard.DOWN && this.isItLastItemOfPage(index)) {
      this.goNextPage();
      this.selectFirstItemOfPage();
      return;
    }

    if (direction === Keyboard.UP && this.isItFirstItemOfPage(index)) {
      this.goPrevPage();
      this.selectLastItemOfPage();
      return;
    }

    this.sidememberSelection = [];

    const entry = this.indexlistEntries[newIndex];

    this.addToSelection(entry);
    this.selectSidemember(entry);
    this.ensureSelectedRowVisible();
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (
      !this.sidememberList.nativeElement.offsetParent ||
      this.listState === SidememberListState.Hidden
    ) {
      return;
    }

    switch (event.keyCode) {
      case Keyboard.ENTER:
        if (!this.isloadingPreviewErrorOccured) {
          this.openSidemember();
        }
        break;
      case Keyboard.UP:
        this.selectSidememberByKeyboard(event, Keyboard.UP);
        event.preventDefault();
        break;
      case Keyboard.DOWN:
        this.selectSidememberByKeyboard(event, Keyboard.DOWN);
        event.preventDefault();
        break;
      case Keyboard.LEFT:
        this.goPrevPage();
        event.preventDefault();
        break;
      case Keyboard.RIGHT:
        this.goNextPage();
        event.preventDefault();
        break;
      case Keyboard.PAGE_UP:
        this.selectFirstItemOfPage();
        event.preventDefault();
        break;
      case Keyboard.PAGE_DOWN:
        this.selectLastItemOfPage();
        event.preventDefault();
        break;
      case Keyboard.HOME:
        this.gotoFirstPage();
        event.preventDefault();
        break;
      case Keyboard.END:
        this.gotoLastPage();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  private ensureSelectedRowVisible(): void {
    if (this.sidememberSelection.length !== 1) return;
    this.ensureEntryVisible(this.sidememberSelection[0]);
  }

  private ensureEntryVisible(entry: IndexlistEntry): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const index = this.indexlistPageEntries.indexOf(entry);
        const row = this.sidememberList.nativeElement.querySelectorAll('tr')[
          index
        ];

        if (!row) return;

        const rowTop = row.offsetTop;
        const rowBottom = rowTop + row.clientHeight;

        const scrollTop = this.sidememberList.nativeElement.scrollTop;

        const scrollBottom =
          this.sidememberList.nativeElement.scrollTop +
          this.sidememberList.nativeElement.clientHeight;

        let newScrollTop = this.sidememberList.nativeElement.scrollTop;

        if (rowTop <= scrollTop) {
          newScrollTop -= Math.abs(rowTop - scrollTop);
        } else if (rowBottom >= scrollBottom) {
          newScrollTop += Math.abs(rowBottom - scrollBottom);
        }

        this.sidememberList.nativeElement.scrollTop = newScrollTop;
      });
    });
  }
}
