import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { GrapeService } from '../shared/services/grape.service';
import { VariantClass } from '../shared/models/variant-class';
import {
  UntypedFormBuilder,
  Validators,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { VariantCodesFilterDefinition } from '../shared/models/selected-variantcodes-def';
import { Subscription } from 'rxjs';

const TYPE_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VariantCodesDefinitionComponent),
  multi: true,
};

@Component({
  selector: 'app-variant-codes-definition',
  templateUrl: './variant-codes-definition.component.html',
  styleUrls: ['./variant-codes-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TYPE_CONTROL_ACCESSOR],
})
export class VariantCodesDefinitionComponent
  implements OnInit, ControlValueAccessor
{
  @Input() enableVariantClassFamily = true;
  @Input() enableVariantClassOption = true;
  @Input() isDisabled = false;
  @Input() touched = false;
  @Input() variantClasses: VariantClass[];

  public filterDefinitionList: UntypedFormArray;
  public initialValueObj: VariantCodesFilterDefinition;
  private subscriptions: Subscription[] = [];

  constructor(
    public grapeService: GrapeService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.filterDefinitionList = this.fb.array([]);
  }

  get filterDefinitions(): UntypedFormArray {
    this.filterDefinitionList.markAllAsTouched();
    return this.filterDefinitionList;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterDefinitionList.valueChanges.subscribe((x) => this.onChange(x))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onChange = (delta: VariantCodesFilterDefinition[]) => {};
  public onTouched = () => (this.touched = true);
  public registerOnChange = (fn: any): void => (this.onChange = fn);
  public registerOnTouched = (fn: any): void => (this.onTouched = fn);
  public setDisabledState = (isDisabled: boolean) =>
    (this.isDisabled = isDisabled);

  public isValid(): boolean {
    this.filterDefinitionList.markAllAsTouched();
    this.changeDetector.markForCheck();
    return this.filterDefinitionList.valid;
  }

  public addDefinition(): void {
    this.filterDefinitionList.markAllAsTouched();

    if (!this.isValid()) {
      return;
    }
    if (this.initialValueObj) {
      const defaultObj = {
        class: this.initialValueObj.class
          ? this.initialValueObj.class
          : this.initialValueObj[3].Name,
        family: null,
        option: null,
      };
      this.filterDefinitionList.push(this.getFilterDefinitionForm(defaultObj));
    } else {
      this.filterDefinitionList.push(this.getFilterDefinitionForm());
    }
  }

  public deleteDefinition(index: number): void {
    this.filterDefinitionList.removeAt(index);
    this.filterDefinitionList.markAllAsTouched();
  }

  public writeValue(data: VariantCodesFilterDefinition[]): void {
    const formGroups = data.map((definition: VariantCodesFilterDefinition) => {
      return this.getFilterDefinitionForm(definition);
    });

    this.filterDefinitionList.clear();
    formGroups.forEach((fg) => this.filterDefinitionList.push(fg));
  }

  public canHaveFamily(variantClassName: string): boolean {
    const variantClass = this.getVariantClassByName(variantClassName);
    return variantClass && variantClass.CanHaveFamily;
  }

  public canHaveOption(variantClassName: string): boolean {
    const variantClass = this.getVariantClassByName(variantClassName);
    return variantClass && variantClass.CanHaveOption;
  }

  public isDuplicateEntry(form: UntypedFormGroup): boolean {
    return (
      form.invalid &&
      (form.touched || form.dirty) &&
      form.getError('duplicateEntry')
    );
  }

  public isInvalidFamily(form: UntypedFormGroup): boolean {
    const control = form.get('family');
    return (
      form.invalid &&
      (control.dirty || control.touched) &&
      form.getError('familyError')
    );
  }

  public isInvalidOption(form: UntypedFormGroup): boolean {
    const control = form.get('option');
    return (
      form.invalid &&
      (control.dirty || control.touched) &&
      form.getError('optionError')
    );
  }

  private getVariantClassByName(name: string): VariantClass {
    return this.variantClasses.find((vc) => vc.Name === name);
  }

  private getFilterDefinitionForm(initialValue?: VariantCodesFilterDefinition) {
    const defaultVariantClass = this.variantClasses[3] || null;
    if (initialValue) {
      this.initialValueObj = initialValue;
    }
    const form = this.fb.group(
      {
        class: [
          this.initialValueObj?.class
            ? this.initialValueObj?.class
            : defaultVariantClass.Name,
          Validators.required,
        ],
        family: [],
        option: [],
      },
      {
        validators: [
          (form: UntypedFormGroup) => this.validateUnique(form),
          (form: UntypedFormGroup) => this.validateFamily(form),
          (form: UntypedFormGroup) => this.validateOption(form),
        ],
      }
    );

    this.subscriptions.push(
      form
        .get('class')
        .valueChanges.subscribe((variantClassName: string) =>
          this.onVariantClassChange(form, variantClassName)
        )
    );

    if (initialValue) {
      form.patchValue(initialValue);
    }
    this.initialValueObj = initialValue;
    return form;
  }

  private onVariantClassChange(
    form: UntypedFormGroup,
    variantClassName: string
  ): void {
    const variantClass = this.getVariantClassByName(variantClassName);
    if (!variantClass) return;
    if (!variantClass.CanHaveFamily) {
      form.patchValue({
        family: null,
      });
    }

    if (!variantClass.CanHaveOption) {
      form.patchValue({
        option: null,
      });
    }
  }

  private validateUnique(form: UntypedFormGroup): ValidationErrors | null {
    const numberOfEntries = this.filterDefinitionList.controls.filter(
      (compareForm: UntypedFormGroup) =>
        form.get('class').value === compareForm.get('class').value &&
        form.get('family').value === compareForm.get('family').value &&
        form.get('option').value === compareForm.get('option').value
    );

    if (numberOfEntries.length > 1) {
      return { duplicateEntry: true };
    }

    return null;
  }

  private validateFamily(form: UntypedFormGroup): ValidationErrors | null {
    const variantClass = this.getVariantClassByName(form.get('class').value);

    if (
      variantClass &&
      variantClass.CanHaveFamily &&
      variantClass.MustHaveFamily &&
      !!!form.get('family').value
    ) {
      return { familyError: true };
    }

    return null;
  }

  private validateOption(form: UntypedFormGroup): ValidationErrors | null {
    const variantClass = this.getVariantClassByName(form.get('class').value);

    if (
      variantClass &&
      variantClass.CanHaveOption &&
      variantClass.MustHaveOption &&
      !!!form.get('option').value
    ) {
      return { optionError: true };
    }

    return null;
  }
}
