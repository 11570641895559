import * as GUI from 'babylonjs-gui';
import { Hole } from '../../shared/models/grapeservice/hole';
import { RenderEngine } from '../render-engine';
import { HoleShapeEnum } from '../core/enums/hole-shape-enum';
import { PresentationSettings } from '../core/presentation-settings';
import { HoleInfoEnum } from '../core/enums/holeinfo-enum';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { HoleFlags, HoleShape } from '../../shared/models/hole-enum';

export class HoleInfoGUI {
  public selectedHole: Hole;
  private infoTextOffset = 2.35;
  private defaultInitialOffsetBellow = 1;
  private defaultInitialOffsetAbove = -2;
  protected holeDimensionText: GUI.TextBlock;
  protected holeDataText: GUI.TextBlock;
  protected sidememberInfo: SidememberInfo;
  protected upperTextNode: any;
  protected lowerTextNode: any;

  constructor(
    private engine: RenderEngine,
    private gui: GUI.AdvancedDynamicTexture,
    private settings: PresentationSettings
  ) {
    this.initialize();
  }

  public show(
    hole: Hole,
    sidememberInfo: SidememberInfo,
    upperTextNode: any,
    lowerTextNode: any
  ): void {
    this.selectedHole = hole;
    this.sidememberInfo = sidememberInfo;

    this.upperTextNode = upperTextNode;
    this.lowerTextNode = lowerTextNode;

    this.showInfoAboveHole();
    this.showInfoBelowHole();
  }

  public hide(): void {
    this.selectedHole = null;
    this.holeDimensionText.text = '';
    this.holeDataText.text = '';

    this.holeDimensionText.linkWithMesh(null);
    this.holeDataText.linkWithMesh(null);
  }

  private initialize(): void {
    this.settings.wasUpdated.subscribe(() => {
      this.show(
        this.selectedHole,
        this.sidememberInfo,
        this.upperTextNode,
        this.lowerTextNode
      );
    });

    this.holeDimensionText = new GUI.TextBlock('HoleDimensionText', '');
    this.holeDimensionText.fontFamily = this.settings.guiFont.guiFontFamily;
    this.holeDimensionText.fontSize = this.settings.guiFont.guiFontSize;
    this.holeDimensionText.color = this.settings.guiFont.guiFontColor;
    this.holeDimensionText.textWrapping = true;
    this.gui.addControl(this.holeDimensionText);

    this.holeDataText = new GUI.TextBlock('HoleDataText', '');
    this.holeDataText.fontFamily = this.settings.guiFont.guiFontFamily;
    this.holeDataText.fontSize = this.settings.guiFont.guiFontSize;
    this.holeDataText.color = this.settings.guiFont.guiFontColor;
    this.holeDataText.textWrapping = true;
    this.gui.addControl(this.holeDataText);
  }

  private showInfoAboveHole(): void {
    if (!this.holeDimensionText) return;

    this.holeDimensionText.text = '';

    const showInfo =
      this.selectedHole.HoleShape === Number(HoleShapeEnum.Round) ||
      this.selectedHole.HoleShape === Number(HoleShapeEnum.LongShape) ||
      this.selectedHole.HoleShape === Number(HoleShapeEnum.Rectangular)||
      this.selectedHole.HoleShape === Number(HoleShapeEnum.Ellipse);

    if (!showInfo) {
      return;
    }

    let text = '';

    if (this.selectedHole.HoleShape === Number(HoleShapeEnum.Rectangular)) {
      if (
        this.selectedHole.HoleToo === 'VIN' &&
        this.sidememberInfo.VINCode_mark &&
        this.settings.holeInformation.indexOf(HoleInfoEnum.VINCode) !== -1
      ) {
        text += this.sidememberInfo.VINCode_row1 || '';
      }
    } else {
      if (this.settings.holeInformation.indexOf(HoleInfoEnum.Diameter) === -1) {
        return;
      }
      if (this.selectedHole.HoleShape === Number(HoleShapeEnum.LongShape)) {
        text = `°${this.selectedHole.Alpha.toFixed(0)}`;
      } else {
        text = `Ø${this.selectedHole.Diameter.toFixed(2)}`;
      }
      if (this.selectedHole.HoleShape === Number(HoleShapeEnum.Ellipse)) {
        text = `°${this.selectedHole.Alpha.toFixed(0)}`;
      } else {
        text = `Ø${this.selectedHole.Diameter.toFixed(2)}`;
      }
    }

    this.holeDimensionText.text = text;
    this.holeDimensionText.linkWithMesh(this.upperTextNode);

    this.holeDimensionText.linkOffsetY = -this.calculateLinkOffset(
      text,
      this.defaultInitialOffsetAbove
    );
  }

  private showInfoBelowHole(): void {
    if (!this.holeDataText) return;

    this.holeDataText.text = '';

    const showInfo =
      this.selectedHole.HoleShape !== Number(HoleShapeEnum.Cut) &&
      this.selectedHole.HoleShape !== Number(HoleShapeEnum.MarkingPoint) &&
      this.selectedHole.HoleShape !== Number(HoleShapeEnum.NoShape);

    if (!showInfo) {
      return;
    }

    let text = '';
    const excludedKeys = [HoleInfoEnum.Diameter, HoleInfoEnum.VINCode];

    if (
      this.selectedHole.HoleShape === Number(HoleShapeEnum.Rectangular) &&
      this.selectedHole.HoleToo === 'VIN' &&
      this.sidememberInfo.VINCode_mark &&
      this.settings.holeInformation.indexOf(HoleInfoEnum.VINCode) !== -1
    ) {
      text += `${this.sidememberInfo.VINCode_row2 || ''}\n`;
    }

    for (const property of this.settings.holeInformation) {
      const propertyName = HoleInfoEnum[property].toString();
      const propertyEnum = <HoleInfoEnum>property;

      if (
        !this.selectedHole.hasOwnProperty(propertyName) ||
        excludedKeys.indexOf(propertyEnum) !== -1
      ) {
        continue;
      }

      let value = this.selectedHole[propertyName];

      const holeXYZInfoExist = [
        HoleInfoEnum.Hole_X,
        HoleInfoEnum.Hole_Y,
        HoleInfoEnum.Hole_Z,
      ].indexOf(propertyEnum);

      if (holeXYZInfoExist !== -1) {
        value = value.toFixed(1);
      }
      if (value.length === 0) {
        value = '_ ? _';
      }

      text += String(value);
      text += '\n';
    }

    this.holeDataText.text = text;
    this.holeDataText.linkWithMesh(this.lowerTextNode);
    this.holeDataText.linkOffsetY = this.calculateLinkOffset(
      text,
      this.defaultInitialOffsetBellow
    );
  }

  private calculateLinkOffset(text: string, initialOffset?: number): number {
    return (
      (initialOffset ? initialOffset : 0) +
      this.getTextHeight(this.getNumLines(text))
    );
  }

  private getTextHeight(lineNumbers) {
    return (
      (lineNumbers *
        (this.settings.guiFont.guiFontSize *
          this.settings.guiFont.guiLineHeight)) /
      this.infoTextOffset
    );
  }
  private getNumLines(text: string) {
    if (text.indexOf('\n') !== -1) {
      return text.split('\n').length;
    }
    return this.defaultInitialOffsetBellow;
  }

  get isUniqueRoundHole() {
    return (
      (this.selectedHole.HoleShape === HoleShape.Round ||
        this.selectedHole.HoleShape === HoleShape.LongShape|| this.selectedHole.HoleShape === HoleShape.Ellipse) &&
      (this.selectedHole.HoleFlag === HoleFlags.UniqueInSidemember1 ||
        this.selectedHole.HoleFlag === HoleFlags.UniqueInSidemember2)
    );
  }
}
