<div class="card card-default settingspanel m-0">
  <div class="card-body p-0 pb-3">
    <div class="row no-gutter">
      <div class="col-12 no-gutter px-5 pb-3">
        <div class="col-12 no-gutter verticalspace">
          <h5>{{ 'HOLE_INFORMATION.TITLE' | translate }}</h5>
        </div>
      </div>
      <div class="col-6">
        <div>
          <label>
            <input (change)="changeHoleInformation('HoleObj')" [checked]="isChecked('HoleObj')" class="checkbox"
              value="holeNo" id="holeNo" name="holeNo" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.HOLE_NO' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('HoleToo')" [checked]="isChecked('HoleToo')" class="checkbox"
              value="holeTo" id="holeTo" name="holeTo" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.HOLE_TO' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('AObj')" [checked]="isChecked('AObj')" class="checkbox"
              value="partNumber" id="partNumber" name="partNumber" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.PART_NUMBER' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('ASName')" [checked]="isChecked('ASName')" class="checkbox"
              value="partName" id="partName" name="partName" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.PART_NAME' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('Hole_X')" [checked]="isChecked('Hole_X')" class="checkbox" value="x"
              id="x" name="x" type="checkbox" />
            X
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('Hole_Y')" [checked]="isChecked('Hole_Y')" class="checkbox" value="y"
              id="y" name="y" type="checkbox" />
            Y
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('Hole_Z')" [checked]="isChecked('Hole_Z')" class="checkbox" value="z"
              id="z" name="z" type="checkbox" />
            Z
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('Diameter')" [checked]="isChecked('Diameter')" class="checkbox"
              value="diameter" id="diameter" name="diameter" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.DIAM' | translate }}
          </label>
        </div>
      </div>
      <div class="col-6">
        <div>
          <label>
            <input (change)="changeHoleInformation('HPSName')" [checked]="isChecked('HPSName')" class="checkbox"
              value="patternFi" id="patternFi" name="patternFi" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.PATTERN_FI' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('HPObj')" [checked]="isChecked('HPObj')" class="checkbox"
              value="patternFiNum" id="patternFiNum" name="patternFiNum" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.PATTERN_FI_NO' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('HoleType')" [checked]="isChecked('HoleType')" class="checkbox"
              value="type" id="type" name="type" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.TYPE' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('GObj')" [checked]="isChecked('GObj')" class="checkbox" value="gObj"
              id="gObj" name="gObj" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.GOBJ' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('GSName')" [checked]="isChecked('GSName')" class="checkbox"
              value="gsName" id="gsName" name="gsName" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.GSNAME' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('VINCode')" [checked]="isChecked('VINCode')" class="checkbox"
              value="vinCode" id="vinCode" name="vinCode" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.VIN_CODE' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('SupplOper')" [checked]="isChecked('SupplOper')" class="checkbox"
              value="supplOper" id="supplOper" name="supplOper" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.SUPPLOPER' | translate }}
          </label>
        </div>
        <div>
          <label>
            <input (change)="changeHoleInformation('Psi')" [checked]="isChecked('Psi')" class="checkbox" value="Psi"
              id="Psi" name="Psi" type="checkbox" />
            {{ 'HOLE_INFORMATION.COLUMNS.PSI' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutter selectlistsection">
      <div class="col-12">
        <div>
          <label>
            <input [(ngModel)]="sidememberPresentation.printRestrictedAreas" name="RestrictedAreas" 
             class="checkbox" type="checkbox" />
            {{ 'SIDEMEMBER_PRESENTATION.PRINT_RESTRICTED_AREAS' | translate }}
          </label>
        </div>
      </div>
      <div class="col-12">
        <div>
          <label>
            <input [(ngModel)]="sidememberPresentation.printHoleMarking" name="PPTRHoleMarking" 
             class="checkbox" type="checkbox" />
            {{ 'SIDEMEMBER_PRESENTATION.PRINT_MARKING' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutter" *ngIf="totalPictureSettings$ | async as totalPictureSettings">
      <div class="col-12 no-gutter selectlistsection px-5 pb-3">
      </div>
      <div class="col-12 no-gutter pb-3 verticalspace">
        <h5>{{ 'TOTAL_PICTURE.TITLE' | translate }}</h5>
      </div>
      <div class="col-6">
        <div>
          <label><input [(ngModel)]="totalPictureSettings.printHoleNumbers" name="totalPicturePrintHoleNumbers"
              class="checkbox" type="checkbox" />
            {{ 'TOTAL_PICTURE.PRINT_HOLE_NUMBERS' | translate }}
          </label>
        </div>
        <div>
          <label><input [(ngModel)]="totalPictureSettings.printHoleEnlarged" name="totalPicturePrintHoleEnlarged"
              class="checkbox" type="checkbox" />
            {{ 'TOTAL_PICTURE.PRINT_HOLE_ENLARGED' | translate }}
          </label>
        </div>
      </div>
      <div class="col-6">
        <div>
          <label><input [(ngModel)]="totalPictureSettings.printTickMarks"
              (ngModelChange)="toggleTotalPictureTickMarks($event)" name="totalPicturePrintTickMarks" class="checkbox"
              type="checkbox" />
            {{ 'TOTAL_PICTURE.PRINT_TICK_MARKS' | translate }}

          </label>
        </div>
        <div>
          <label><input [(ngModel)]="totalPictureSettings.printXm" [disabled]="!totalPictureSettings.printTickMarks"
              name="totalPicturePrintXm" class="checkbox" type="checkbox" />
            {{ 'TOTAL_PICTURE.PRINT_XM' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-right bg-white">
    <button (click)="saveSettings()" class="btn btn-primary btn-sm btn-sz saveBtn">
      {{ 'SAVE' | translate }}
    </button>
  </div>
</div>