import { Injectable, Renderer2, RendererFactory2 , ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GrapeDataModel } from '../shared/models/grapeservice/grape.data';
import { Hole } from '../shared/models/grapeservice/hole';
import { LocaleService } from '../shared/services/locale.service';
import { UserSettingsService } from '../shared/services/usersettings.service';

@Injectable({
  providedIn: 'root'
})
export class HoleInformationPrintService {
  private renderer: Renderer2;

  private contentElement: ElementRef;

  constructor(
    rendererFactory: RendererFactory2,
    private translate: TranslateService,
    private settings: UserSettingsService,
    private localeService: LocaleService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public create(elementRef: ElementRef, data: GrapeDataModel) {
    this.createLayout(elementRef);
  
    this.drawSidememberData(data);
    this.drawHoleInformationData(data);

    this.print();

    this.cleanUp();
  }

  private createLayout(elementRef: ElementRef) {
    this.contentElement = elementRef;
  }

  private print() {
    window.print();
  }

  private drawSidememberData(data: GrapeDataModel) {
    const table = this.renderer.createElement('table');
    this.renderer.addClass(table, 'table');
    this.renderer.addClass(table, 'table-sm');
    this.renderer.addClass(table, 'table-bordered');

    const date = new Date();
    const datestamp = date.toLocaleDateString(this.localeService.locale);
    const timestamp = date.toLocaleTimeString(this.localeService.locale);

    const headers = [
      this.translate.instant('TOTAL_PICTURE.HEADERS.ID'),
      this.translate.instant('TOTAL_PICTURE.HEADERS.TYPE'),
      this.translate.instant('TOTAL_PICTURE.HEADERS.CU'),
      this.translate.instant('TOTAL_PICTURE.HEADERS.CHNO'),
      this.translate.instant('TOTAL_PICTURE.HEADERS.VER'),
      this.translate.instant('TOTAL_PICTURE.HEADERS.LEN'),
      `${this.translate.instant('DATE')} / ${this.translate.instant('TIME')}`,
      this.translate.instant('HOLE_INFORMATION.NUMBER_OF_HOLES')
    ];

    const hfInfoValues = [
      data.HFInfo.PopId || '',
      data.HFInfo.TypeText || '',
      data.HFInfo.CUSName || '',
      data.HFInfo.ChassisNumber || '',
      this.zeroPadding(data.HFInfo.Version, 3) || '',
      `${Math.abs(
        data.HFInfo.BD_BlankEnd_X - data.HFInfo.BD_BlankBeg_X
      ).toFixed(0)} mm`,
      `${datestamp} ${timestamp}`,
      `${this.getFilteredHoles(data).length} ${this.translate.instant('PAGINATION.OF')} ${data.Holes.length}`
    ];

    for (let i = 0; i < headers.length; i++) {
      const row = this.renderer.createElement('tr');
      const keyColumn = this.renderer.createElement('th');
      const valueColumn = this.renderer.createElement('td');
      
      keyColumn.appendChild(this.renderer.createText(headers[i]));
      valueColumn.appendChild(this.renderer.createText(hfInfoValues[i]));
      row.appendChild(keyColumn);
      row.appendChild(valueColumn);
      table.appendChild(row);
    }

    this.renderer.appendChild(this.contentElement.nativeElement, table);
  }

  drawHoleInformationData(data: GrapeDataModel) {
    const table = this.renderer.createElement('table');
    this.renderer.addClass(table, 'table');
    this.renderer.addClass(table, 'table-sm');
    this.renderer.addClass(table, 'table-bordered');
    this.renderer.addClass(table, 'table-top-spacing');
  
    const headers = [
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.HOLE_NO'), field: 'HoleObj' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.HOLE_TO'), field: 'HoleToo' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.HOLE_TYPE'), field: 'HSToo' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.PART_NUMBER'), field: 'AObj' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.PART_NAME'), field: 'ASName' },
      { header: 'X', field: 'Hole_X' },
      { header: 'Y', field: 'Hole_Y' },
      { header: 'Z', field: 'Hole_Z' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.DIAM'), field: 'Diameter' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.LENGTH'), field: 'Length' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.SUPPLOPER'), field: 'SupplOper' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.PATTERN_FI'), field: 'HPSName' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.PATTERN_FI_NO'), field: 'HPObj' },
      { header: this.translate.instant('HOLE_INFORMATION.COLUMNS.TYPE'), field: 'HoleType' },
    ];

    const headerRow = this.renderer.createElement('tr');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    for (const header of headers) {
      const headerCell = this.renderer.createElement('th');
      const headerDiv = this.renderer.createElement('div');
      if (header.header.length > 4) {
        this.renderer.addClass(headerCell, 'rotate')
      }
      
      headerDiv.appendChild(this.renderer.createText(header.header));
      headerCell.appendChild(headerDiv);
      headerRow.appendChild(headerCell);
    }
    thead.appendChild(headerRow);
    table.appendChild(thead);

    for (const hole of this.getFilteredHoles(data)) {
      const row = this.renderer.createElement('tr');
      for (const header of headers) {
        const valueColumn = this.renderer.createElement('td');
        
        valueColumn.appendChild(this.renderer.createText(hole[header.field]))
        row.appendChild(valueColumn);
      }
      tbody.appendChild(row);
    }
    table.appendChild(tbody);

    this.renderer.appendChild(this.contentElement.nativeElement, table);
  }

  private getFilteredHoles(data: GrapeDataModel): Hole[] {
    if (this.settings.holeinfo.searchFilterActive)
      return data.FilteredHoles;
    return data.Holes;
  }

  private cleanUp() {
    for (let child of this.contentElement.nativeElement.childNodes) {
      this.renderer.removeChild(this.contentElement.nativeElement, child);
    }
  }

  private zeroPadding(input: string, maxLength: number): string {
    const numZeroes = Math.max(0, maxLength - input?.length);
    let zeroString = '';

    for (let index = 0; index < numZeroes; index += 1) {
      zeroString += '0';
    }

    return `${zeroString}${input}`;
  }

}
