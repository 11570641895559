import {
  UniversalCamera,
  Vector3,
  Scene,
  Animation,
  Camera,
  Angle,
  Matrix
} from 'babylonjs';
import { RenderEngine } from '../render-engine';
import { Point } from '../../shared/models/rendering/point';
import { InputController } from '../input/controller/input-controller';
import { InputCommand } from '../input/command/input-command';
import { CommandType } from '../input/command/command-type';
import { InputObserver } from '../input/command/input-observer';

export interface CameraDefaults {
  position: Vector3;
  upVector: Vector3;
  target: Vector3;
  orthoTop: number;
  rotation: Vector3;
}

export class CameraController implements InputObserver {
  protected camera: UniversalCamera;
  protected scene: Scene;
  protected canvas: HTMLCanvasElement;
  protected engine: RenderEngine;
  protected pointerLoc: Point;

  protected panSpeed = 25;
  protected panDampening = 5.0;

  protected zoomSpeed = 100;
  protected zoomDampening = 3.0;
  protected zoomTowardsCursor = true;
  protected zoomLevel = 1;
  protected initialZoom = 500;
  protected currentZoom = 500;
  protected maxZoom = 100;
  protected minZoom = 3500;

  protected dragAcceleration = 1.2;
  protected dragPrevLoc: Point;
  protected dragPointerDown: Point;
  protected dragPointerUp: Point;

  protected initialCameraPosition: Vector3;
  protected defaults: CameraDefaults;

  constructor(name: string, engine: RenderEngine, initialPosition: Vector3) {
    this.camera = new UniversalCamera(name, initialPosition, engine.scene);
    this.camera.layerMask = 1;
    this.camera.upVector = new Vector3(0, -1, 0);
    this.camera.setTarget(Vector3.Zero());

    this.scene = engine.scene;
    this.canvas = engine.canvas;
    this.engine = engine;

    this.camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    this.camera.orthoTop = this.currentZoom;
    this.camera.maxZ = 10000;
    this.camera.minZ = -10000;

    this.initialCameraPosition = initialPosition;

    this.camera.storeState();

    this.defaults = {
      upVector: this.camera.upVector,
      position: this.camera.position,
      rotation: this.camera.rotation,
      target: this.camera.getTarget(),
      orthoTop: this.camera.orthoTop
    };

    this.updateOrtographicView();
  }

  get  cameraEngineInputElement(): HTMLElement {
    return this.camera.getEngine()?.getInputElement();
  }

  public getCamera(): UniversalCamera {
    return this.camera;
  }

  public getPosition(): Vector3 {
    return this.camera.position;
  }

  public setPosition(position: Vector3): void {
    position.y = this.camera.position.y;
    this.camera.position = position;
    this.broadcastProjection();
  }

  public getZoomLevel(): number {
    return this.zoomLevel;
  }

  public rotate(axis: Vector3, degrees: number): void {
    if (axis.x !== 0) {
      this.camera.rotation.x = Angle.FromDegrees(degrees).radians();
    }

    if (axis.y !== 0) {
      this.camera.rotation.y = Angle.FromDegrees(degrees).radians();
    }

    if (axis.z !== 0) {
      this.camera.rotation.z = Angle.FromDegrees(degrees).radians();
    }
  }

  public moveTo(position: Vector3, speed?: number): void {
    position.y = this.camera.position.y;

    const animateCamera = new Animation(
      'CameraMoveAnimation',
      'position',
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    const keys = [];

    keys.push({
      frame: 0,
      value: this.camera.position
    });

    keys.push({
      frame: 100,
      value: position
    });

    animateCamera.setKeys(keys);
    this.camera.animations = [];
    this.camera.animations.push(animateCamera);
    this.scene.beginAnimation(
      this.camera,
      0,
      100,
      false,
      speed ? speed : 1,
      () => {
        this.camera.animations = [];
        this.broadcastProjection();
      }
    );
  }


  public attachControl(): void {
    this.camera.attachControl(this.cameraEngineInputElement, true);
  }

  public clearInput(): void {
    this.camera.inputs.clear();
  }

  public addInput(input): void {
    this.camera.inputs.add(input);
    input.addInputObserver(this);
  }

  public onInput(command: InputCommand): void {
    if (
      command.type === CommandType.CAMERA_MOVE_UP ||
      command.type === CommandType.CAMERA_MOVE_DOWN ||
      command.type === CommandType.CAMERA_MOVE_LEFT ||
      command.type === CommandType.CAMERA_MOVE_RIGHT
    ) {
      this.pan(command.type);
    }

    if (command.type === CommandType.CAMERA_ZOOM_IN) {
      if (this.currentZoom > this.maxZoom) {
        this.zoom(-this.zoomSpeed, this.zoomTowardsCursor);
      }
    }

    if (command.type === CommandType.CAMERA_ZOOM_OUT) {
      if (this.currentZoom < this.minZoom) {
        this.zoom(this.zoomSpeed, this.zoomTowardsCursor);
      }
    }

    if (command.type === CommandType.SCROLL_WHEEL_PRESSED) {
      this.dragPointerDown = {
        x: command.data.clientX,
        y: command.data.clientY
      };
    }

    if (command.type === CommandType.POINTER_MOVE) {
      const event = command.data as PointerEvent;
      this.pointerLoc = { x: event.clientX, y: event.clientY };
      this.drag(event);
    }

    if (command.type === CommandType.SCROLL_WHEEL_RELEASED) {
      this.dragPointerUp = null;
      this.dragPointerDown = null;
    }

    if (command.type === CommandType.FOCUS_OUT) {
      this.dragPointerUp = null;
      this.dragPointerDown = null;
    }
  }

  public render(): void {}

  public resize(): void {
    this.updateOrtographicView();
  }

  public reset(): void {
    this.camera.upVector = this.defaults.upVector;
    this.camera.orthoTop = this.defaults.orthoTop;
    this.currentZoom = this.initialZoom;
    this.camera.restoreState();
  }

  public inverse(): void {
    this.camera.position.y *= -1;
    this.camera.upVector = this.camera.upVector.multiply(new Vector3(0, -1, 0));
    this.camera.setTarget(Vector3.Zero());
    this.rotate(new Vector3(0, 1, 0), 180);
  }

  public externalZoom(zoomIn?: boolean): void {
    if (zoomIn) {
      if (this.currentZoom > this.maxZoom) {
        this.zoom(-this.zoomSpeed, false);
      }
    } else {
      if (this.currentZoom < this.minZoom) {
        this.zoom(this.zoomSpeed, false);
      }
    }
  }

  public onCameraMovement: (
    leftTop: Vector3,
    leftBottom: Vector3,
    rightTop: Vector3,
    rightBottom: Vector3
  ) => void;

  public refresh(): void {
    this.broadcastProjection();
  }

  public broadcastProjection(): void {
    const canvasWidth = this.canvas.clientWidth;
    const canvasHeight = this.canvas.clientHeight;

    let ray = this.scene.createPickingRay(0, 0, Matrix.Identity(), this.camera);
    const leftTop = ray.origin ? ray.origin : Vector3.Zero();

    ray = this.scene.createPickingRay(
      0,
      canvasHeight,
      Matrix.Identity(),
      this.camera
    );
    const leftBottom = ray.origin ? ray.origin : Vector3.Zero();

    ray = this.scene.createPickingRay(
      canvasWidth,
      0,
      Matrix.Identity(),
      this.camera
    );
    const rightTop = ray.origin ? ray.origin : Vector3.Zero();

    ray = this.scene.createPickingRay(
      canvasWidth,
      canvasHeight,
      Matrix.Identity(),
      this.camera
    );
    const rightBottom = ray.origin ? ray.origin : Vector3.Zero();

    if (this.onCameraMovement) {
      this.onCameraMovement(leftTop, leftBottom, rightTop, rightBottom);
    }
  }

  private drag(event: PointerEvent): void {
    if (this.dragPointerDown) {
      const screenToWorld = this.scene.createPickingRay(
        event.clientX,
        event.clientY,
        Matrix.Identity(),
        this.camera
      );
      const screenToWorldPrev = this.scene.createPickingRay(
        this.dragPrevLoc.x,
        this.dragPrevLoc.y,
        Matrix.Identity(),
        this.camera
      );
      const diffX = screenToWorld.origin.x - screenToWorldPrev.origin.x;
      const diffZ = screenToWorld.origin.z - screenToWorldPrev.origin.z;
      this.camera.position.x -= diffX;
      this.camera.position.z -= diffZ;
      this.broadcastProjection();
    }
    this.dragPrevLoc = { x: event.clientX, y: event.clientY };
  }

  private pan(command: CommandType) {
    const translateX = this.camera.rotation.x > 0 ? 1 : -1;
    const dampening = (this.currentZoom / this.minZoom) * this.panDampening;
    const animationRatio = this.scene.getAnimationRatio();

    let multiplier = this.camera.position.y > 0 ? -1 : 1;
    multiplier *= this.camera.upVector.y;

    if (command === CommandType.CAMERA_MOVE_UP) {
      this.camera.position.z +=
        this.panSpeed * multiplier * dampening * animationRatio;
    }

    if (command === CommandType.CAMERA_MOVE_DOWN) {
      this.camera.position.z -=
        this.panSpeed * multiplier * dampening * animationRatio;
    }

    if (command === CommandType.CAMERA_MOVE_LEFT) {
      this.camera.position.x -=
        this.panSpeed * translateX * multiplier * dampening * animationRatio;
    }

    if (command === CommandType.CAMERA_MOVE_RIGHT) {
      this.camera.position.x +=
        this.panSpeed * translateX * multiplier * dampening * animationRatio;
    }

    this.broadcastProjection();
  }

  private zoom(value: number, towardsCursor: boolean): void {
    const dampening = (this.currentZoom / this.minZoom) * this.zoomDampening;
    const zoomValue = value * dampening;

    if (towardsCursor) {
      const screenToWorld = this.scene.createPickingRay(
        this.engine.scene.pointerX,
        this.engine.scene.pointerY,
        Matrix.Identity(),
        this.camera
      );
      const multiplier = (1 / this.camera.orthoTop) * zoomValue;
      this.camera.position.x -=
        (screenToWorld.origin.x - this.camera.position.x) * multiplier;
      this.camera.position.z -=
        (screenToWorld.origin.z - this.camera.position.z) * multiplier;
    }

    const rightSidemember = this.engine.zIndex.direction === -1;
    const maxY =
      this.initialCameraPosition.y +
      (rightSidemember ? this.minZoom : this.maxZoom);
    const minY =
      this.initialCameraPosition.y -
      (rightSidemember ? this.maxZoom : this.minZoom);
    const cameraY =
      this.camera.position.y - zoomValue * this.engine.zIndex.direction;

    this.camera.position.y = Math.max(Math.min(cameraY, maxY), minY);
    this.camera.orthoTop += zoomValue;

    this.currentZoom = Math.min(
      Math.max(this.camera.orthoTop, this.maxZoom),
      this.minZoom
    );
    this.zoomLevel = this.currentZoom / this.initialZoom;

    this.updateOrtographicView();
  }

  private updateOrtographicView(): void {
    const aspect = this.camera.getEngine().getAspectRatio(this.camera);
    this.camera.orthoTop = this.currentZoom;
    this.camera.orthoBottom = -this.currentZoom;
    this.camera.orthoLeft = -this.currentZoom * aspect;
    this.camera.orthoRight = this.currentZoom * aspect;
    this.broadcastProjection();
  }
}
