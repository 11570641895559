import { Color3 } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';
import { Settings } from './interfaces/settings';
import { HoleInfoEnum } from './enums/holeinfo-enum';
import { GuiFont } from './interfaces/gui-font';
import { HoleColors } from './interfaces/hole-colors';
import { RulerConfig } from './interfaces/ruler-config';
import { HoleDistanceOption } from '../../shared/models/hole-distance-option';
import { DistanceTextBackground } from './interfaces/distance-text-background';

export class PresentationSettings implements Settings {
  public wasUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public holeInformation: HoleInfoEnum[] = [
    HoleInfoEnum.Diameter,
    HoleInfoEnum.HoleObj,
    HoleInfoEnum.AObj,
  ];

  public showUpsideDown = false;
  public showSidememberOrientation = true;
  public printHoleMarking = true;
  public printRestrictedAreas = true;
  public persistErrors = false;
  public antiAliasing = true;
  public adaptToDeviceRatio = true;
  public holeDistanceOption = HoleDistanceOption.centerToCenter;

  public guiFont: GuiFont = {
    guiFontFamily: 'Arial',
    guiFontSize: 12,
    guiLineHeight: 1.8,
    guiFontColor: 'black',
  };

  public distanceTextBackground: DistanceTextBackground = {
    width: 0.04,
    height: 0.03,
    opacity: 0.9,
    borderTickness: 0,
    color: 'white',
  };

  public holeColors: HoleColors = {
    initialHoleColor: new Color3(0, 0, 1),
    selectedHoleColor: new Color3(1, 0.07, 0.57),
    markedHoleColor: new Color3(0, 1, 0),
    highlightedHoleColor: new Color3(0, 1, 1),
    holeOutlineColor: new Color3(0, 0, 0),
    transparentColor: new Color3(1, 1, 1),
    duplicateColor: new Color3(0.66, 0.66, 0.66),
  };

  public rulerConfig: RulerConfig = {
    showRuler: true,
    rulerSectionsCount: 5,
    rulerBoxHeight: 30,
    rulerBoxColor: '#f2f2f2',
    rulerBoxAlpha: 0.9,
    rulerLineWidth: 2,
    rulerLineHeight: 8,
    rulerLineOffset: 2,
    rulerLineColor: '#666666',
    rulerValueFontFamily: 'Arial',
    rulerValueFontSize: 12,
    rulerValueFontColor: '#666666',
  };

  constructor(settings?: Settings) {
    this.update(settings);
  }

  public update(settings: Settings) {
    if (!settings) {
      return;
    }

    this.holeInformation =
      settings.holeInformation !== null
        ? settings.holeInformation
        : this.holeInformation;

    this.showUpsideDown =
      settings.showUpsideDown !== null
        ? settings.showUpsideDown
        : this.showUpsideDown;
    this.printHoleMarking =
      settings.printHoleMarking !== null
        ? settings.printHoleMarking
        : this.printHoleMarking;
    this.printRestrictedAreas =
      settings.printRestrictedAreas !== null
        ? settings.printRestrictedAreas
        : this.printRestrictedAreas;
    this.showSidememberOrientation =
      settings.showSidememberOrientation !== null
        ? settings.showSidememberOrientation
        : this.showSidememberOrientation;
    this.persistErrors =
      settings.persistErrors !== null
        ? settings.persistErrors
        : this.persistErrors;
    this.antiAliasing =
      settings.antiAliasing !== null
        ? settings.antiAliasing
        : this.antiAliasing;
    this.adaptToDeviceRatio =
      settings.adaptToDeviceRatio !== null
        ? settings.adaptToDeviceRatio
        : this.adaptToDeviceRatio;

    this.guiFont.guiFontFamily =
      settings.guiFont.guiFontFamily !== null
        ? settings.guiFont.guiFontFamily
        : this.guiFont.guiFontFamily;
    this.guiFont.guiFontSize =
      settings.guiFont.guiFontSize !== null
        ? settings.guiFont.guiFontSize
        : this.guiFont.guiFontSize;
    this.guiFont.guiLineHeight =
      settings.guiFont.guiLineHeight !== null
        ? settings.guiFont.guiLineHeight
        : this.guiFont.guiLineHeight;
    this.guiFont.guiFontColor =
      settings.guiFont.guiFontColor !== null
        ? settings.guiFont.guiFontColor
        : this.guiFont.guiFontColor;

    this.distanceTextBackground.width =
      settings.distanceTextBackground.width !== null
        ? settings.distanceTextBackground.width
        : this.distanceTextBackground.width;
    this.distanceTextBackground.height =
      settings.distanceTextBackground.height !== null
        ? settings.distanceTextBackground.height
        : this.distanceTextBackground.height;
    this.distanceTextBackground.color =
      settings.distanceTextBackground.color !== null
        ? settings.distanceTextBackground.color
        : this.distanceTextBackground.color;
    this.distanceTextBackground.opacity =
      settings.distanceTextBackground.opacity !== null
        ? settings.distanceTextBackground.opacity
        : this.distanceTextBackground.opacity;
    this.distanceTextBackground.borderTickness =
      settings.distanceTextBackground.borderTickness !== null
        ? settings.distanceTextBackground.borderTickness
        : this.distanceTextBackground.borderTickness;

    // Hole color settings
    this.holeColors.initialHoleColor =
      settings.holeColors.initialHoleColor !== null
        ? settings.holeColors.initialHoleColor
        : this.holeColors.initialHoleColor;
    this.holeColors.selectedHoleColor =
      settings.holeColors.selectedHoleColor !== null
        ? settings.holeColors.selectedHoleColor
        : this.holeColors.selectedHoleColor;
    this.holeColors.markedHoleColor =
      settings.holeColors.markedHoleColor !== null
        ? settings.holeColors.markedHoleColor
        : this.holeColors.markedHoleColor;
    this.holeColors.highlightedHoleColor =
      settings.holeColors.highlightedHoleColor !== null
        ? settings.holeColors.highlightedHoleColor
        : this.holeColors.highlightedHoleColor;
    this.holeColors.holeOutlineColor =
      settings.holeColors.holeOutlineColor !== null
        ? settings.holeColors.holeOutlineColor
        : this.holeColors.holeOutlineColor;
    this.holeColors.transparentColor =
      settings.holeColors.transparentColor !== null
        ? settings.holeColors.transparentColor
        : this.holeColors.transparentColor;
    this.holeColors.duplicateColor =
      settings.holeColors.duplicateColor !== null
        ? settings.holeColors.duplicateColor
        : this.holeColors.duplicateColor;

    // Ruler config settings
    this.rulerConfig.showRuler =
      settings.rulerConfig.showRuler !== null
        ? settings.rulerConfig.showRuler
        : this.rulerConfig.showRuler;
    this.rulerConfig.rulerBoxAlpha =
      settings.rulerConfig.rulerBoxAlpha !== null
        ? settings.rulerConfig.rulerBoxAlpha
        : this.rulerConfig.rulerBoxAlpha;
    this.rulerConfig.rulerBoxColor =
      settings.rulerConfig.rulerBoxColor !== null
        ? settings.rulerConfig.rulerBoxColor
        : this.rulerConfig.rulerBoxColor;
    this.rulerConfig.rulerBoxHeight =
      settings.rulerConfig.rulerBoxColor !== null
        ? settings.rulerConfig.rulerBoxHeight
        : this.rulerConfig.rulerBoxHeight;
    this.rulerConfig.rulerLineColor =
      settings.rulerConfig.rulerLineColor !== null
        ? settings.rulerConfig.rulerLineColor
        : this.rulerConfig.rulerLineColor;
    this.rulerConfig.rulerLineHeight =
      settings.rulerConfig.rulerLineHeight !== null
        ? settings.rulerConfig.rulerLineHeight
        : this.rulerConfig.rulerLineHeight;
    this.rulerConfig.rulerLineOffset =
      settings.rulerConfig.rulerLineOffset !== null
        ? settings.rulerConfig.rulerLineOffset
        : this.rulerConfig.rulerLineOffset;
    this.rulerConfig.rulerLineWidth =
      settings.rulerConfig.rulerLineWidth !== null
        ? settings.rulerConfig.rulerLineWidth
        : this.rulerConfig.rulerLineWidth;
    this.rulerConfig.rulerSectionsCount =
      settings.rulerConfig.rulerSectionsCount !== null
        ? settings.rulerConfig.rulerSectionsCount
        : this.rulerConfig.rulerSectionsCount;
    this.rulerConfig.rulerValueFontColor =
      settings.rulerConfig.rulerValueFontColor !== null
        ? settings.rulerConfig.rulerValueFontColor
        : this.rulerConfig.rulerValueFontColor;
    this.rulerConfig.rulerValueFontFamily =
      settings.rulerConfig.rulerValueFontFamily !== null
        ? settings.rulerConfig.rulerValueFontFamily
        : this.rulerConfig.rulerValueFontFamily;
    this.rulerConfig.rulerValueFontSize =
      settings.rulerConfig.rulerValueFontSize !== null
        ? settings.rulerConfig.rulerValueFontSize
        : this.rulerConfig.rulerValueFontSize;

    this.holeDistanceOption =
      settings.holeDistanceOption !== null
        ? settings.holeDistanceOption
        : this.holeDistanceOption;

    this.wasUpdated.next(true);
  }
}
