import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { GrapeService } from '../services/grape.service';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.html',
  styleUrls: ['./not-found-component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private grapeService: GrapeService,
    private msalService: MsalService
  ) {}
  public granted: boolean;
  public requiredRoles: any[];
  ngOnInit() {
    this.checkUserRole();
  }

  goHomePage() {
    // this.router.navigate(['/sidemember-presentation']);
  }
  public async checkUserRole() {
    let user = this.msalService.instance.getAllAccounts();
    console.log(user, "user")
    console.log(user[0]?.idTokenClaims, "user role", !user[0]?.idTokenClaims.roles);
    if (user[0]?.idTokenClaims.roles) {
      console.log("i am in side")
      this.goHomePage();
    }
  }
}
