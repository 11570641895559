import { Settings } from './settings';

export class TotalPictureSettings extends Settings {
  public printHoleNumbers = true;
  public printHoleEnlarged = true;
  public printXm = true;
  public printTickMarks = true;

  public load(): void {
    const storedSettings = <TotalPictureSettings>super.load();
    this.printHoleNumbers = super.isStored('printHoleNumbers', storedSettings)
      ? storedSettings.printHoleNumbers
      : this.printHoleNumbers;
    this.printHoleEnlarged = super.isStored('printHoleEnlarged', storedSettings)
      ? storedSettings.printHoleEnlarged
      : this.printHoleEnlarged;
    this.printXm = super.isStored('printXm', storedSettings)
      ? storedSettings.printXm
      : this.printXm;
    this.printTickMarks = super.isStored('printTickMarks', storedSettings)
      ? storedSettings.printTickMarks
      : this.printTickMarks;
  }

  public save(): void {
    super.save(this);
  }
}
