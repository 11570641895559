import { Component, ViewChild, OnInit } from '@angular/core';
import { VariantClass } from '../shared/models/variant-class';
import { Observable } from 'rxjs';
import { GrapeService } from '../shared/services/grape.service';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { filter } from 'rxjs/operators';
import { VariantCodesDefinitionComponent } from '../variant-codes-definition/variant-codes-definition.component';

@Component({
  selector: 'app-sidemember-list-variantcode-search',
  templateUrl: './sidemember-list-variantcode-search.component.html',
  styleUrls: ['./sidemember-list-variantcode-search.component.scss']
})
export class SidememberListVariantCodeSearchComponent implements OnInit {
  @ViewChild('filterList')
  filterList: VariantCodesDefinitionComponent;

  public variantClasses$: Observable<VariantClass[]>;
  public filterDefinitionList: UntypedFormControl;

  constructor(
    private settings: UserSettingsService,
    private grapeService: GrapeService,
    private fb: UntypedFormBuilder
  ) {
    this.settings.variantCodesSearch.load();

    this.variantClasses$ = this.grapeService.variantClassesSearch.pipe(
      filter(variantClasses => variantClasses !== null)
    );
  }

  ngOnInit() {
    this.filterDefinitionList = this.fb.control(
      this.settings.variantCodesSearch.variantCodeSearchDefinitions
    );
  }

  public save(): void {
    if (!this.filterList.isValid()) return;
    this.settings.variantCodesSearch.variantCodeSearchDefinitions = this.filterDefinitionList.value;
    this.settings.variantCodesSearch.save();
  }
}
