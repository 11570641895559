import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HoleDistanceOption } from '../models/hole-distance-option';
import { IndexlistEntry } from '../models/indexlist-entry';

@Injectable()
export class UIEventService {
  constructor() {}

  private settingsChangedSource = new BehaviorSubject<boolean>(undefined);
  settingsChanged = this.settingsChangedSource.asObservable();
  changedSettings() {
    this.settingsChangedSource.next(true);
  }

  private selectedItemSource = new BehaviorSubject<IndexlistEntry>(undefined);
  selectedItem = this.selectedItemSource.asObservable();
  selectItem(value?: IndexlistEntry) {
    this.selectedItemSource.next(value);
  }

  private indexListCancelledSource = new BehaviorSubject<boolean>(undefined);
  indexListCancelled = this.indexListCancelledSource.asObservable();
  indexListCancel(value?: boolean) {
    this.indexListCancelledSource.next(value);
  }

  private sidememberViewExpandedStatusSource = new BehaviorSubject<boolean>(
    undefined
  );
  sidememberViewExpandedStatus = this.sidememberViewExpandedStatusSource.asObservable();
  sidememberViewExpanded(value?: boolean) {
    this.sidememberViewExpandedStatusSource.next(value);
  }

  private sidememberFlipStatusSource = new BehaviorSubject<boolean>(undefined);
  sidememberFlipStatus = this.sidememberFlipStatusSource.asObservable();
  sidememberFlip(value?: boolean) {
    this.sidememberFlipStatusSource.next(value);
  }

  private autoPrintStartedSource = new BehaviorSubject<boolean>(false);
  autoPrintStartedStatus = this.autoPrintStartedSource.asObservable();
  autoPrintStarted(value?: boolean) {
    this.autoPrintStartedSource.next(value);
  }

  private holeDistanceModeSource = new BehaviorSubject<HoleDistanceOption>(undefined);
  holeDistanceModeStatus = this.holeDistanceModeSource.asObservable();
  holeDistanceMode(value?: HoleDistanceOption) {
    this.holeDistanceModeSource.next(value);
  }
}
