<div id="TotalPicture" #totalPicture></div>

<div class="card card-default flex-grow-1 d-flex flex-column auto-print-panel panel-height">
  <div class="card-header card-border d-block p-4">
    <div class="row no-gutters d-flex flex-row">
      <div class="col-3 d-flex flex-row">
        <div class='btn-group' role='group' aria-label='Start/Stop auto printing'>
          <button (click)="start()" [ngClass]="{
              'btn-success': !isRunning,
              'btn-secondary': isRunning
            }" [disabled]="isRunning" class="flex-grow-1 btn btn-size">
            {{ 'AUTO_PRINT.START' | translate}}
          </button>
          <button (click)="stop()" [ngClass]="{
              'btn-danger': isRunning || isStopping,
              'btn-secondary': !isRunning
            }" [disabled]="!isRunning || isStopping" class="flex-grow-1 btn btn-size">
            {{ (isStopping ? 'AUTO_PRINT.STOPPING' : 'AUTO_PRINT.STOP') | translate }}
          </button>
        </div>

      </div>
      <div class="col-9 d-flex flex-row align-items-center justify-content-end">

        <div class="sdds-headline-05 selected-datasource d-flex justify-content-center align-items-center mr-5">
          <span class="mr-2"> {{ 'MONA_DATABASE' | translate }}: {{ selectedDataSource$ | async }}</span>
        </div>
        
        <div class="sdds-dropdown sdds-dropdown-small" >
          <select name="nativeDropdown" id="mySelect" (change)="showErrorsOnly($event)">
          <option value="false" class="dropdown-item" 
          [ngClass]="{ selected: !settings.autoPrint.showErrorsOnly }">
          {{ 'AUTO_PRINT.SHOW_ALL_EVENTS' | translate }}</option>
          <option value="true" class="dropdown-item"
          [ngClass]="{ selected: settings.autoPrint.showErrorsOnly }">
          {{ 'AUTO_PRINT.SHOW_ONLY_ERRORS' | translate }}</option>
          </select>
      </div>

        <button (click)="showSettings()" class="btn btn-secondary btn-setting-size"
          [disabled]="isRunning ? 'disabled' : null">
          <div class="d-flex ">
            <mat-icon class="settings-margin">settings</mat-icon>&nbsp;{{ 'SETTINGS' | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body card-border p-0 d-flex flex-column flex-grow-1 card-overflow" >
    <div class="log flex-grow-1 d-flex flex-column">
      <table class="table table-panel flex-grow-1 flex-shrink-0">
        <thead>
          <tr class="row">
            <th class="col-2">{{ 'DATE' | translate }}</th>
            <th class="col-10">{{ 'MESSAGE' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="log-entry row" *ngFor="let entry of log$ | async">
            <td class="col-2" [ngClass]="{ error: entry.isError }">
              {{ entry.date | LocaleDate }}
            </td>
            <td class="col-10" [ngClass]="{ error: entry.isError }">
              {{ entry.message }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer card-border p-4 mt-0">
    <div class="no-gutters d-flex flex-row align-items-center">
      <div class="d-flex flex-row flex-grow-1 flex-shrink-1 align-items-center">
        <div class="status mr-1 pr-1 text-center font-sm">
          {{ statusText$ | async }}
        </div>
        <div class="progress-text mr-1 pr-1 text-center font-sm">
          {{ progressText$ | async }}
        </div>
        <div class="progress m-0 ml-4 mr-4 flex-grow-1 flex-shrink-1 text-center">
          <div [attr.aria-valuenow]="progress$ | async" [ngStyle]="{ width: (progress$ | async) + '%' }"
            class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn-secondary w-100 font-sm btn-clear-log" (click)="clearLog()">
          {{ 'AUTO_PRINT.CLEAR_LOG' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>