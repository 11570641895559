import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalResult, ModalType } from '../models/enums';
import { AppConfigService } from './app-config.service';
import { ModalDialogService } from './modal-dialog.service';

const userActionWindowSec: number = 10 * 60;
@Injectable()
export class AuthService {
  protected userInfoSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  public userInfo: Observable<string> = this.userInfoSubject.asObservable();
  public userToken = new BehaviorSubject<string>(null);
  authWindow:any;

  public get keycloakInstance() {
    return this.keycloak;
  }

  constructor(
    private keycloak: KeycloakService,
    private config: AppConfigService,
    private modal: ModalDialogService,
    private translate: TranslateService
  ) {}

  // public get tokenParsed() {
  //   return this.keycloak.getKeycloakInstance().tokenParsed;
  // }

  public initKeycloak(
    token?: string,
    refreshToken?: string,
    idToken?: string
  ): Promise<boolean> {
    const keycloakOptions = this.config.keycloak;
    keycloakOptions.initOptions.token = token;
    keycloakOptions.initOptions.refreshToken = refreshToken;
    keycloakOptions.initOptions.idToken = idToken;
    return this.keycloak.init(keycloakOptions);
  }

  public getAuthDetails(): {
    token: string;
    refreshToken: string;
    idToken: string;
  } {
    const { token, refreshToken, idToken } =
      this.keycloak.getKeycloakInstance();
    return { token, refreshToken, idToken };
  }

  public async isLoggedIn(): Promise<boolean> {
    return (
      (await this.keycloak.isLoggedIn()) && !this.keycloak.isTokenExpired()
      //true
    );
  }

  // public async loadUserProfile() {
  //   return await this.keycloak.loadUserProfile();
  // }
  // public async loadAllUsers() {
  //   const login = await this.isLoggedIn();
  //   if (login) {
  //     return await this.keycloak.getUserRoles(true);
  //   }
  // }

  // public async GrapeUserRole() {
  //   const login = await this.isLoggedIn();
  //   if (login) {
  //     const resource = this.tokenParsed.resource_access[this.config['keycloak']['config']['clientId']];
  //     return resource ? resource.roles : [];
  //   }
  // }

  public startAuthentication(): any {
    return this.keycloak.login();
  }

  public startAuthenticationPopup(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      this.modal.show(
        this.translate.instant('AUTH.RE_LOGIN') +
          '<br />' +
          this.translate.instant('AUTH.ALLOW_POPUP'),
        ModalType.None,
        this.translate.instant('AUTH.SESSION_EXPIRED')
      );
      console.log("in open popup")

      this.authWindow= Window;
      const callback = async (evt: MessageEvent<any>) => {

        try {
          const { token, refreshToken, idToken } = evt.data;
          console.log(token,"token")
          if (token) {
            await this.initKeycloak(token, refreshToken, idToken);
            this.modal.close(ModalResult.Ok);
            await this.authWindow.close();
            window.removeEventListener('message', callback, false);
            resolve(token);
          }
        } catch (error) {
          reject(error);
        } finally {
          await this.authWindow.close();
          this.modal.close(ModalResult.Ok);
        }
      };

      window.addEventListener('message', callback, false);
       this.authWindow =  window.open(
        '/silent-login',
        'Login',
        'height=400,width=500'
      );
    });
  }



  // public signOut(): void {
  //   this.keycloak.logout();
  // }

  // public getCurrentToken(): string {
  //   return this.keycloak.getKeycloakInstance().token;
  // }

  public getToken(): Promise<string> {
    return this.keycloak.getToken();
  }
}
