import { Mesh } from 'babylonjs';
import { HoleDistanceOption } from '../../../shared/models/hole-distance-option';

export class ZIndexController {
  public direction = 1;
  public baseDistanceLineZIndex = 2000;
  protected zIndex: Mesh[] = [];
  protected minZIndex = 2;
  protected maxZIndex = 1;
  protected minZIndexForEtoEMode = 5000;

  public get(layerIndex: number): any {
    return this.zIndex[layerIndex];
  }

  public add(mesh: any): void {
    this.zIndex.push(mesh);
    this.maxZIndex += 1;
  }

  public clear(): void {
    this.zIndex = [];
  }

  public reset(): void {
    let zIndexOffset = 0;
    for (const mesh of this.zIndex) {
      mesh.position.z = (this.minZIndex + zIndexOffset) * this.direction;
      zIndexOffset += 1;
    }
    this.maxZIndex = this.zIndex.length + 1;
  }

  public moveToFront(meshesToMoveFront: Mesh[]): void {
    const meshes = meshesToMoveFront ? meshesToMoveFront : [];
    this.reset();
    const maxIndex = this.maxZIndex * this.direction;
    meshes.forEach((mesh: Mesh, index: number) => {
      mesh.position.z = maxIndex + (index + 1) * this.direction;
    });
    this.maxZIndex += meshes.length;
  }

  public moveToFrontForDistanceMode(
    meshesToMoveFront: Mesh[],
    holeDistanceOption: HoleDistanceOption
  ): void {
    const meshes = meshesToMoveFront ? meshesToMoveFront : [];
    let baseIndex: number;
    if (holeDistanceOption == HoleDistanceOption.edgeToEdge) {
      baseIndex = this.minZIndexForEtoEMode * this.direction;
    } else {
      baseIndex = this.maxZIndex * this.direction;
    }
    meshes.forEach((mesh: Mesh, index: number) => {
      mesh.position.z = baseIndex + (index + 1) * this.direction;
    });
  }

  public moveToBack(mesh: Mesh, relatedMeshes?: Mesh[]): void {
    const meshes = relatedMeshes ? relatedMeshes : [];
    this.reset();
    const minZIndex = this.minZIndex * this.direction;
    let relatedIndex = 1;
    for (const relatedMesh of meshes) {
      relatedMesh.position.z = minZIndex + relatedIndex * this.direction;
      relatedIndex += 1;
    }
    mesh.position.z = minZIndex;
  }
}
