<mat-form-field>
  <ng-container *ngIf="multiple; else singleValue">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-list #chipList [aria-label]="placeholder" (click)="openAutocomplete(trigger)">
      <mat-chip
        *ngFor="let item of value || []"
        [removable]="true"
        (removed)="remove(item)">
        {{item}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #optionInput
        #trigger="matAutocompleteTrigger"
        [formControl]="multiSelectCtrl"
        [disableControl]="disabled"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of multiFilteredItems | async" #matOption [value]="item" 
        (click)="openAutocomplete(trigger, matOption)"
        [ngClass]="{ 'selected': isSelected(item) }">
        {{item}}
      </mat-option>
    </mat-autocomplete>
  </ng-container>

  <ng-template #singleValue>
    <input matInput type="text" 
      class="input-with-suffix"
      #trigger="matAutocompleteTrigger"
      (keydown.enter)="trigger.closePanel()"
      [formControl]="singleSelectCtrl"
      [placeholder]="placeholder" 
      [(ngModel)]="value" 
      [disableControl]="disabled" 
      [matAutocomplete]="autoSingle">
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete #autoSingle="matAutocomplete">
      <mat-option *ngFor="let item of singleFilteredItems | async" [value]="item">{{item}}</mat-option>
    </mat-autocomplete>
  </ng-template>
</mat-form-field>