import { IndexlistDto } from '../dto/indexlist.dto';
import { IndexlistMapper } from '../mapping/indexlist.mapper';
import { IndexlistEntry } from '../indexlist-entry';

export class Indexlist {
  public entries: IndexlistEntry[];

  static fromDto(dto: IndexlistDto): Indexlist {
    const indexlistEntries: IndexlistEntry[] = [];

    for (const entryDto of dto.entries) {
      const indexlistEntry = IndexlistMapper.fromDto(entryDto);
      indexlistEntries.push(indexlistEntry);
    }

    const model = new Indexlist();
    model.entries = indexlistEntries;

    return model;
  }
}
