import { AppComponent } from './app.component';
import { AppConfigService } from './shared/services/app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AuthGuardService } from './shared/services/auth-guard.service';
import { AuthService } from './shared/services/auth.service';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { AutoPrintComponent } from './auto-print/auto-print.component';
import { AutoPrintSettingsComponent } from './auto-print-settings/auto-print-settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HAMMER_LOADER } from '@angular/platform-browser';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ContextMenuModule } from 'ngx-contextmenu';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { DisposableGuardService } from './shared/services/disposable-guard.service';
import { FileSaverModule } from 'ngx-filesaver';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { AngularResizedEventModule } from 'angular-resize-event';
import { GrapeDataService } from './shared/services/grape-data.service';
import { GrapeService } from './shared/services/grape.service';
import { HoleDuplicatesComponent } from './hole-duplicates/hole-duplicates.component';
import { HoleDuplicatesService } from './shared/services/hole-duplicates.service';
import { HoleDistanceService } from './shared/services/hole-distance.service';
import { HoleInformationComponent } from './hole-information/hole-information.component';
import { HoleSearchDefinitionComponent } from './hole-search-definition/hole-search-definition.component';
import { HoleSearchService } from './shared/services/hole-search.service';
import { HoleSidememberSegmentService } from './shared/services/hole-sidemember-segment.service';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LocaleDatePipe } from './shared/pipes/local-date.pipe';
import { LocaleDateShortPipe } from './shared/pipes/local-date-short.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalDialogComponent } from './shared/components/modal-dialog/modal-dialog.component';
import { NotifyToastrService } from './shared/services/notify-toastr.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';

import { NgSelectModule } from '@ng-select/ng-select';
import { NoCommaPipe } from './shared/pipes/no-comma.pipe';
import { NotFoundComponent } from './shared/not-found/not-found-component';
import { NullStringPipe } from './shared/pipes/null-string.pipe';
import { NumbersOnlyDirective } from './shared/directives/numbers-only.directive';
import { PrefixStringPipe } from './shared/pipes/prefix-string.pipe';
import { SelectDataSourceComponent } from './select-datasource/select-datasource.component';
import { SettingsComponent } from './settings/settings.component';
import { SidememberCanvasComponent } from './sidemember-canvas/sidemember-canvas.component';
import { SidememberCanvasInfoComponent } from './sidemember-canvas-info/sidemember-canvas-info.component';
import { SidememberInformationComponent } from './sidemember-information/sidemember-information.component';
import { SidememberListComponent } from './sidemember-list/sidemember-list.component';
import { SidememberListFilterComponent } from './sidemember-list-filter/sidemember-list-filter.component';
import { SidememberListVariantCodeSearchComponent } from './sidemember-list-variantcode-search/sidemember-list-variantcode-search.component';
import { SidememberMinimapComponent } from './sidemember-minimap/sidemember-minimap.component';
import { SidememberPresentationComponent } from './sidemember-presentation/sidemember-presentation.component';
import { SidememberPreviewComponent } from './sidemember-preview/sidemember-preview.component';
import { SidememberSelectService } from './shared/services/sidemember-select.service';
import { TotalPictureComponent } from './total-picture/total-picture.component';
import { TotalPicturePrintingService } from './total-picture/total-picture-printing.service';
import { TotalPictureService } from './total-picture/total-picture.service';
import { UIEventService } from './shared/services/ui-event.service';
import { UserSettingsService } from './shared/services/usersettings.service';
import { VariantCodesComponent } from './variant-codes/variant-codes.component';
import { VariantCodesDefinitionComponent } from './variant-codes-definition/variant-codes-definition.component';
import { VariantCodesFilterComponent } from './variant-codes-filter/variant-codes-filter.component';
import { VariantCodesListComponent } from './variant-codes-list/variant-codes-list.component';
import { Vector3StringPipe } from './shared/pipes/vector3-string.pipe';
import { WindowRef } from './shared/services/window-ref.service';
import { UppercaseDirective } from './shared/directives/uppercase.directive';
import { SilentLoginComponent } from './silent-login/silent-login.component';
import { LanguageHandlingComponent } from './language-handling/language-handling.component';
import { LocaleService } from './shared/services/locale.service';
import { MultiSelectComponent } from './shared/components/multi-select/multi-select.component';
import { DisableFormControlDirective } from './shared/directives/disable-form-control.directive';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
// import { UserManagementComponent } from './user-management/user-management.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environmentAd } from '../environments/environment';
// export function initializeKeycloak(authService: AuthService) {
//    // console.log(authService.initKeycloak(),"authService.initKeycloak()")
//   return () => authService.initKeycloak();
// }

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environmentAd.clientId,
          authority:environmentAd.authority,
          redirectUri: environmentAd.redirectUrl,
        },
        cache: {
          // cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["Enter_the_Graph_Endpoint_Here/v1.0/me", ["user.read"]],
        ]),
      }
    ),
    AppRoutingModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    ContextMenuModule.forRoot(),
    FileSaverModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatGridListModule,
    ModalModule.forRoot(),
    NgSelectModule,
    ReactiveFormsModule,
    TableModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatTooltipModule,
    MatTabsModule,
    AngularResizedEventModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AppComponent,
    AutoPrintComponent,
    AutoPrintComponent,
    AutoPrintSettingsComponent,
    AutoPrintSettingsComponent,
    HoleDuplicatesComponent,
    HoleInformationComponent,
    HoleSearchDefinitionComponent,
    LocaleDatePipe,
    LocaleDateShortPipe,
    NoCommaPipe,
    NotFoundComponent,
    NullStringPipe,
    NumbersOnlyDirective,
    DisableFormControlDirective,
    PrefixStringPipe,
    SelectDataSourceComponent,
    SettingsComponent,
    SidememberCanvasComponent,
    SidememberCanvasInfoComponent,
    SidememberInformationComponent,
    SidememberListComponent,
    SidememberListFilterComponent,
    SidememberListVariantCodeSearchComponent,
    SidememberMinimapComponent,
    SidememberPresentationComponent,
    SidememberPreviewComponent,
    TotalPictureComponent,
    UppercaseDirective,
    VariantCodesComponent,
    VariantCodesDefinitionComponent,
    VariantCodesFilterComponent,
    VariantCodesListComponent,
    Vector3StringPipe,
    ModalDialogComponent,
    SilentLoginComponent,
    LanguageHandlingComponent,
    MultiSelectComponent,
    // UserManagementComponent,
  ],
  providers: [
    AuthService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeKeycloak,
    //   multi: true,
    //   deps: [AuthService],
    // },
    AppConfigService,
    AuthGuardService,
    DisposableGuardService,
    GrapeDataService,
    GrapeService,
    HoleDuplicatesService,
    HoleDistanceService,
    HoleSearchService,
    HoleSidememberSegmentService,
    TotalPicturePrintingService,
    TotalPictureService,
    UIEventService,
    UserSettingsService,
    SidememberSelectService,
    NotifyToastrService,
    WindowRef,
    {
      provide: HAMMER_LOADER,
      useValue: () => new Promise(() => {}),
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptorService,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    LocaleService,
    ContextMenuModule,
  ],
  bootstrap: [AppComponent,MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
