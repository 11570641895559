import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  HostListener,
} from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { Sidemember } from '../shared/models/grapeservice/sidemember';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SidememberInfoSettings } from '../shared/settings/sidemember-info-settings';
import { CriteriaColors } from '../shared/models/search-criteria-color';

@Component({
  selector: 'app-sidemember-information',
  templateUrl: './sidemember-information.component.html',
  styleUrls: ['./sidemember-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidememberInformationComponent implements OnInit {
  @ViewChild('hScroll') hScroll: ElementRef;
  @ViewChild('vScroll') vScroll: ElementRef;
  @Input() collapsed: boolean;
  @Input() sidememberIndex?: number = null;
  public cardColorCue: string = 'unset';

  public grpData: GrapeData = null;
  public settings: SidememberInfoSettings;
  public sidemember$: BehaviorSubject<Sidemember> = new BehaviorSubject(null);

  private subscriptions: Subscription[] = [];

  constructor(
    private userSettings: UserSettingsService,
    private grapeDataService: GrapeDataService
  ) {}

  ngOnInit() {
    if (!this.sidememberIndex || this.sidememberIndex === 1) {
      this.settings = this.userSettings.sidememberinfo;
    } else {
      this.settings = this.userSettings.compareSidememberinfo;
    }
    this.settings.load();
    this.subscribeForData();
    this.grpData = this.grapeDataService.grapeData;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  //#region data
  private subscribeForData() {
    this.subscriptions.push(
      this.grapeDataService.data.subscribe((data: GrapeData) => {
        if (data.ChangedObject === GrapeObjectType.Sidemember) {
          let sidememberData: Sidemember;
          if (!this.sidememberIndex) {
            sidememberData = { ...data.Data };
          } else {
            sidememberData =
              this.sidememberIndex === 1
                ? data.Data.sidemember1
                : data.Data.sidemember2;

            let cardColor =
              this.sidememberIndex === 1
                ? CriteriaColors.DarkGreen
                : CriteriaColors.Orange;
            this.cardColorCue = `5px solid ${cardColor}`;
          }
          const sidemember = {
            FlangeHeight: sidememberData.FlangeHeight,
            HFInfo: sidememberData.HFInfo,
            Height: sidememberData.Height,
            Holes: [...sidememberData.Holes],
            Reinforcements: [...sidememberData.Reinforcements],
            Width: sidememberData.Width,
          };

          this.sidemember$.next(sidemember);
        }
      })
    );
  }

  public errText() {
    return this.grpData.Data.loadError?.error;
  }

  public isOpenSidememberError() {
    return this.grapeDataService.errorOpenSidemember;
  }
  //#endregion data

  public getKeys(sidemember: Sidemember): string[] {
    return Object.keys(sidemember.HFInfo) || [];
  }

  @HostListener('window:resize')
  public onHorizontalScroll(): void {
    if (
      this.vScroll &&
      this.vScroll.nativeElement &&
      this.hScroll.nativeElement.clientWidth !== 0
    ) {
      this.vScroll.nativeElement.style.width = `${
        this.hScroll.nativeElement.clientWidth +
        this.hScroll.nativeElement.scrollLeft
      }px`;
    }
  }

  public setCollapsed(): void {
    this.settings.collapsed = !this.settings.collapsed;
    this.settings.save();
  }
}
