import { EventEmitter } from '@angular/core';

export class Settings {
  public name: string;
  public onSave: EventEmitter<string>;
  public onLoad: EventEmitter<string>;

  constructor(name: string) {
    this.name = name;
    this.onSave = new EventEmitter<string>();
    this.onLoad = new EventEmitter<string>();
  }

  public save(settings: Settings): void {
    localStorage.removeItem(settings.name);
    localStorage.setItem(settings.name, this.getStringifed(settings));
  }

  public remove(settingsName: string): void {
    localStorage.removeItem(settingsName);
  }

  private getStringifed(settings: any) {
    const cache = [];
    try {
      return JSON.stringify(settings, (key, value) => {
        if (typeof value === 'object'
          && value !== null) {
          if (cache.indexOf(value) !== -1) {
            try {
              return JSON.parse(JSON.stringify(value));
            } catch (error) {
              return;
            }
          }
          cache.push(value);
        }
        return value;
      });
    } catch (error) {
      console.log(error);
    }
  }

  protected load(): any {
    return JSON.parse(localStorage.getItem(this.name));
  }

  protected isStored(propertyName: string, storedSettings?: Settings): boolean {
    if (!storedSettings) return false;
    return storedSettings.hasOwnProperty(propertyName);
  }
}