<div class="card-header card-header-fh no-gutter d-flex flex-row align-items-center">
  <div class="col-md-8">
    <h5>{{ 'VAR_CODE.TITLE' | translate }}</h5>
  </div>
  <div class="col-md-4 text-right">
    <button [disabled]="this.sortedVariantCodes?.length === 0"   mat-icon-button title="{{ 'VAR_CODE.SET_SELECTED' | translate }}"
      (click)="showVariantCodesFilter()">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <button mat-icon-button (click)="toggleFilter()"   id="varCodeToggleFilter" [title]="filterToggleTitle | async"
      [disabled]="!isFilterEnabled">
      <mat-icon *ngIf="settings.variantCodesFilter.isFilterActive && isFilterEnabled ">check_box</mat-icon>
      <mat-icon *ngIf="!settings.variantCodesFilter.isFilterActive || !isFilterEnabled ">check_box_outline_blank
      </mat-icon>
    </button>
    <span class="linkSeparator"></span>
    <button mat-icon-button type="button" class="expand-mr"  (click)="toggleCollapse()" [attr.title]="(isCollapsed ? 'EXPAND' : 'COLLAPSE') | translate">
      <mat-icon *ngIf="isCollapsed">expand_less</mat-icon>
      <mat-icon *ngIf="!isCollapsed">expand_more</mat-icon>
    </button>
  </div>
</div>
<div [hidden]="isCollapsed" class="card-body fh-panel-child d-flex flex-column position-relative">
  <div class="loader" *ngIf="(grapeDataService.errorPreview || grapeDataService.errorVariantCode  | async) && showData">
    <div class="error">{{ 'VAR_CODE.LOADING_ERROR' | translate }}</div>
  </div>
  <div class="loader" *ngIf="(grapeDataService.loadVariantCode | async) && showData">
    <div class="dots">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
  <ng-container *ngIf="
        settings.variantCodesFilter.isFilterActive &&
        (selectedVariantCodes$ | async) as selectedVariantCodes
      ">
    <div class="variant-codes-list" *ngIf="selectedVariantCodes.length !== 0"
      (scroll)="onHorizontalScroll('filter')" #hScrollFilteredList>
      <table class="table table-primary table-hover table-sm table-header">
        <thead>
          <tr>
            <th *ngFor="let column of variantCodesColumns" [ngClass]="{
                  asc: isActiveSortColumn(column.value, false, true),
                  desc: isActiveSortColumn(column.value, true, true)
                }" (click)="sortBy(column.value, true)" class="sorting none {{ column.class }}">
              {{ column.name }}
            </th>
          </tr>
        </thead>
      </table>
      <div class="pane-vScroll" #variantCodesFiltered>
        <table class="selected-variant-codes table table-info table-sm table-fixed">
          <tbody>
            <tr *ngFor="
                  let selectedVariantCode of selectedVariantCodes;
                  trackBy: trackByFunction
                " [ngClass]="{
                  'not-found': selectedVariantCode.NotFound && !(grapeDataService.loadVariantCode | async)
                }">
              <td class="separated {{ variantCodesColumns[0].class }}">
                {{ selectedVariantCode?.VarClass }}
              </td>
              <td class="separated {{ variantCodesColumns[1].class }}">
                {{ selectedVariantCode?.VarFam }}
              </td>
              <td class="separated {{ variantCodesColumns[2].class }}">
                {{ selectedVariantCode?.VarOpt }}
              </td>
              <td class="separated {{ variantCodesColumns[3].class }}">
                {{ selectedVariantCode?.OptName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <div class="pane-hScroll" (scroll)="onHorizontalScroll('list')" #hScrollList>
    <table class="table table-hover table-sm table-header" [hidden]="isCollapsed">
      <thead>
        <tr>
          <th *ngFor="let column of variantCodesColumns" [ngClass]="{
              asc: isActiveSortColumn(column.value, false),
              desc: isActiveSortColumn(column.value, true)
            }" (click)="sortBy(column.value)" class="sorting none {{ column.class }}">
            {{ column.name }}
          </th>
        </tr>
      </thead>
    </table>

    <div class="flex-grow-1 pane-vScroll" #variantCodesList>
      <table class="table table-bordered table-sm table-fixed"
        *ngIf="showData && !(grapeDataService.loadVariantCode | async) && !(grapeDataService.errorVariantCode | async)">
        <tbody>
          <tr *ngFor="
            let variantCode of sortedVariantCodes$ | async;
            trackBy: trackByFunction
          ">
            <td class="{{ variantCodesColumns[0].class }}">
              {{ variantCode.VarClass }}
            </td>
            <td class="{{ variantCodesColumns[1].class }}">
              {{ variantCode.VarFam }}
            </td>
            <td class="{{ variantCodesColumns[2].class }}">
              {{ variantCode.VarOpt }}
            </td>
            <td class="{{ variantCodesColumns[3].class }}">
              {{ variantCode.OptName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div [hidden]="isCollapsed" class="card-footer">
    <strong>{{ 'VAR_CODE.NUMBER_OF' | translate }}:</strong>
    <span *ngIf="showData && !(grapeDataService.loadVariantCode | async)">
      {{ variantCodes?.length }}
    </span>
  </div>