import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixString'
})
export class PrefixStringPipe implements PipeTransform {

  transform(val: any, prefix: string): string {
    if (val !== undefined && val !== null) {
      return prefix + val;
    }
    return '';
  }

}