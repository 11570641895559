<div class="card card-default fh-panel">
  <div class="card-header card-header-fh no-gutter d-flex flex-row align-items-center">
    <div class="col-md-3">
      <h5>{{ 'SIDEMEMBER_LIST.FILTERS.TITLE' | translate }}</h5>
    </div>
    <div class="col-md-8 text-align-right linkSeparator">
      <div class="sdds-toggle sdds-toggle-sm " tabindex="0">
        <label class="sdds-toggle-label transform-label" for="customSwitch1"> {{ 'SIDEMEMBER_LIST.FILTERS.USE_MULTIPLE_CHASSIS_FILTER' | translate }}</label> 
        <input [checked]="this.enableMultiChassis"(change)="toggle($event)" type="checkbox" class="sdds-toggle-input" id="customSwitch1">
        <span class="sdds-toggle-switch"></span> 
     </div>
    </div>
    <div  class="col-md-1 text-align-right ">
      <button mat-icon-button type="button" class="expand-mr" (click)="setCollapsed()" 
      title="{{ (this.settings.indexlist.collapsedFilter ? 'EXPAND' : 'COLLAPSE') | translate }}">
      <mat-icon *ngIf="settings.indexlist.collapsedFilter">expand_less</mat-icon>
      <mat-icon *ngIf="!settings.indexlist.collapsedFilter" >expand_more</mat-icon>
    </button>
    </div>
  </div>

  <div [hidden]="settings.indexlist.collapsedFilter" class="card-body p-4">
    <div *ngIf="!this.enableMultiChassis" class="form-group m-0 p-0 mb-1">
      <input type="text" class="form-control" placeholder="{{ 'CHASSIS_NO' | translate }}" [(ngModel)]="chassisNumber"
        (ngModelChange)="searchChassisNumber()" maxlength="8" />
    </div>

    <div *ngIf="this.enableMultiChassis" class="form-group m-0 p-0 mb-1">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let chassisNo of selectedChassisList"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chassisNo)">
            {{chassisNo}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            maxlength="8"
            placeholder="{{ 'SIDEMEMBER_LIST.FILTERS.ENTER_MULTIPLE_CHASSIS_NUMBER' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
      <mat-error *ngIf="this.chassisError && this.chipValue.length!==0"> {{this.errorMsg}} </mat-error>
    </div>

    <div class="form-group m-0 p-0 mt-4">
      <label>{{ 'SIDEMEMBER_LIST.FILTERS.HF_STATUS' | translate }}</label>
      <div class="hf-status d-flex flex-row">
        <div class="mr-4">
          <label><input class="checkbox mr-1" value="1" name="1" type="checkbox" (change)="changeHfStatus('1')"
              [checked]="hfStatusSelection.indexOf('1') > -1" />
            1</label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="2" name="2" type="checkbox" (change)="changeHfStatus('2')"
              [checked]="hfStatusSelection.indexOf('2') > -1" />
            2
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="3" name="3" type="checkbox" (change)="changeHfStatus('3')"
              [checked]="hfStatusSelection.indexOf('3') > -1" />
            3
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="4" name="4" type="checkbox" (change)="changeHfStatus('4')"
              [checked]="hfStatusSelection.indexOf('4') > -1" />
            4
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="5" name="5" type="checkbox" (change)="changeHfStatus('5')"
              [checked]="hfStatusSelection.indexOf('5') > -1" />
            5
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="6" name="6" type="checkbox" (change)="changeHfStatus('6')"
              [checked]="hfStatusSelection.indexOf('6') > -1" />
            6
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="7" name="7" type="checkbox" (change)="changeHfStatus('7')"
              [checked]="hfStatusSelection.indexOf('7') > -1" />
            7
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="8" name="8" type="checkbox" (change)="changeHfStatus('8')"
              [checked]="hfStatusSelection.indexOf('8') > -1" />
            8
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="9" name="9" type="checkbox" (change)="changeHfStatus('9')"
              [checked]="hfStatusSelection.indexOf('9') > -1" />
            9
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="P" name="P" type="checkbox" (change)="changeHfStatus('P')"
              [checked]="hfStatusSelection.indexOf('P') > -1" />
            P
          </label>
        </div>
        <div class="mr-4">
          <label>
            <input class="checkbox mr-1" value="X" name="X" type="checkbox" (change)="changeHfStatus('X')"
              [checked]="hfStatusSelection.indexOf('X') > -1" />
            X
          </label>
        </div>
      </div>
      <div class="d-flex flex-row mt-3 mb-2">
        <button (click)="selectAllHf()" class="btn btn-sm btn-secondary btn-size-white flex-grow-1 mr-2">
          {{ 'SIDEMEMBER_LIST.FILTERS.SELECT_ALL' | translate }}
        </button>
        <button (click)="selectOnlyHf5()" class="btn btn-sm btn-secondary btn-size-white flex-grow-1 ml-2">
          {{ 'SIDEMEMBER_LIST.FILTERS.ONLY_HF_5' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>