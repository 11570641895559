import { HoleRenderer } from './hole-renderer';
import {
  Vector3,
  MeshBuilder,
  Mesh,
  AbstractMesh,
  Matrix,
  Angle,
  Vector2
} from 'babylonjs';
import { RenderEngine } from '../render-engine';
import { Hole } from '../../shared/models/grapeservice/hole';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';
import { BabylonExtension } from '../babylon/babylon.extension';
import { SidememberSide } from '../../shared/models/sidemember-enum';

export class LongHoleRenderer extends HoleRenderer {
  private mergePosition: Vector3;
  private mergeFirstCirclePosition: Vector3;
  private mergeSecondCirclePosition: Vector3;
  private mergeBoxWidth: number;

  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: Hole,
    protected sidememberData: SidememberInfo,
    protected parent?: AbstractMesh
  ) {
    super(engine, materials, renderData, sidememberData, parent);
  }

  public createMesh() {
    this.mergeBoxWidth = this.renderData.Length - this.renderData.Diameter;

    const circleOffsetX = this.mergeBoxWidth / 2;
    const circleOffset = new Vector3(circleOffsetX, 0, 0);

    this.mergePosition = new Vector3(0, 0, 0);
    this.mergeFirstCirclePosition = this.mergePosition.subtract(circleOffset);
    this.mergeSecondCirclePosition = this.mergePosition.add(circleOffset);

    const firstCircle = this.createDiscMesh(
      this.renderData.Diameter / 2,
      this.mergeFirstCirclePosition
    );

    const centerBox = this.createBoxMesh(
      this.mergeBoxWidth,
      this.renderData.Diameter,
      this.mergePosition
    );

    const secondCircle = this.createDiscMesh(
      this.renderData.Diameter / 2,
      this.mergeSecondCirclePosition
    );

    this.mesh = Mesh.MergeMeshes([firstCircle, centerBox, secondCircle], true);
  }

  protected positionMesh(): void {
    super.positionMesh();
    this.mesh.bakeTransformIntoVertices(this.getRotationMatrix());
  }

  private createDiscMesh(radius: number, position: Vector3): Mesh {
    const mesh = BabylonExtension.createDiscWithoutArcEdge(
      'LongShapeMesh',
      {
        radius,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE,
        tessellation: 18
      },
      this.engine.scene
    );

    mesh.position = position;

    return mesh;
  }

  private createBoxMesh(
    width: number,
    height: number,
    position: Vector3
  ): Mesh {
    const mesh = MeshBuilder.CreateBox(
      'LongShapeMesh',
      {
        width,
        height,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE
      },
      this.engine.scene
    );

    mesh.position = position;

    return mesh;
  }

  protected createOutline(): void {
    const borderWidth = 0.75;
    const borderHeight = 1.25;

    const firstCircle = this.createDiscMesh(
      this.renderData.Diameter / 2 + borderWidth,
      this.mergeFirstCirclePosition
    );

    const centerBox = this.createBoxMesh(
      this.mergeBoxWidth + borderWidth,
      this.renderData.Diameter + borderHeight,
      this.mergePosition
    );

    const secondCircle = this.createDiscMesh(
      this.renderData.Diameter / 2 + borderWidth,
      this.mergeSecondCirclePosition
    );

    const outline = Mesh.MergeMeshes(
      [firstCircle, centerBox, secondCircle],
      true
    );

    outline.parent = this.mesh;
    outline.position = Vector3.Zero();
    outline.position.z -= 1 * this.engine.zIndex.direction;
    outline.material = this.materials.outline;

    outline.bakeTransformIntoVertices(this.getRotationMatrix());
  }

  protected createHoleDuplicate(): void {
    super.createHoleDuplicate();
    this.holeDuplicateMesh.bakeTransformIntoVertices(this.getRotationMatrix());
  }

  protected calculateDuplicateMeshSize(hole: Hole): Vector2 {
    return new Vector2(
      this.mergeBoxWidth + this.renderData.Diameter + this.holeDuplicateSize,
      this.renderData.Diameter + this.holeDuplicateSize
    );
  }

  private getRotationMatrix(): Matrix {
    return Matrix.RotationZ(Angle.FromDegrees(this.renderData.Alpha).radians());
  }
}
