import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { DataSource } from '../shared/models/grapeservice/datasource';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// TODO: Refactor so that the component does not set state
@Component({
  selector: 'app-select-datasource',
  templateUrl: './select-datasource.component.html',
  styleUrls: ['./select-datasource.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDataSourceComponent implements OnInit {
  @Input() autoPrint = false;
  @Output('selectDataSource') onSelectDataSource: EventEmitter<
    DataSource
  > = new EventEmitter();

  public dataSources$: BehaviorSubject<DataSource[]> = new BehaviorSubject([]);
  public selectedDataSource: DataSource = null;
  public grpData: GrapeData;

  private subscriptions: Subscription[] = [];
  private dataSource$: Observable<GrapeData>;

  constructor(
    private settings: UserSettingsService,
    private grapeDataService: GrapeDataService
  ) {
    this.dataSource$ = this.grapeDataService.data.pipe(
      filter(
        (data) =>
          data.ChangedObject === GrapeObjectType.DataSource &&
          data.Data.DataSources.length !== 0
      )
    );

    this.subscribeForData();
  }

  ngOnInit() {
    this.grapeDataService.setDataSources();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public isOpenSidememberError(): boolean {
    if (this.grpData === null) {
      return false;
    }
    return this.grpData.Data.loadError.forDataSources;
  }

  public isLoading(): boolean {
    return !this.grpData || this.grpData.Data.loadingDataSources;
  }

  public selectDataSource(dataSource: DataSource): void {
    if (!dataSource) {
      this.selectedDataSource = null;
      return;
    }

    this.onSelectDataSource.emit(dataSource);

    this.selectedDataSource = dataSource;
  }

  private getStoredDataSource(): string {
    if (this.autoPrint) return this.settings.autoPrint.dataSource;
    return this.settings.indexlist.dataSource;
  }

  private subscribeForData(): void {
    this.subscriptions.push(
      this.dataSource$.subscribe((data: GrapeData) => {
        this.grpData = this.grapeDataService.grapeData;
        this.dataSources$.next(this.grpData.Data.DataSources);

        const selectedDataSource = data.Data.DataSources.find(
          (ds) => ds.DataSourceName === this.getStoredDataSource()
        );

        this.selectDataSource(selectedDataSource);
      })
    );
  }
}
