import { VariantCodes } from './variant-codes';
import { SidememberInfo } from './sidemember-info';
import { Hole, FilteredHole } from './hole';
import { Indexlist } from './indexlist';
import { DataSource } from './datasource';
import { Sidemember } from './sidemember';

// tslint:disable: variable-name
export class GrapeDataModel {
  public Width = 0;
  public Height = 0;
  public FlangeHeight = 0;
  public HFInfo: SidememberInfo = null;
  public Holes: Hole[] = [];
  public Reinforcements: SidememberInfo[] = [];

  public SelectedHole: Hole;
  public SelectedListHole: Hole;
  public RelatedHoles: Hole[] = [];

  public FilteredHoles: FilteredHole[] = [];
  public HoleDuplicates: Hole[] = [];

  public TypeText = '';
  public VariantCodes: VariantCodes[] = [];
  public IndexList: Indexlist = new Indexlist();
  public DataSources: DataSource[] = [];

  public loadingSidemember = false;
  public filteringHoles = false;
  public findingDuplicates = false;
  public loadingVariants = false;
  public loadingIndexList = false;
  public loadingTypeText = false;
  public loadingDataSources = false;
  public loadingMinimap = false;
  public loadingSidememberPreview = false;
  public loadingTotalPicture = false;
  public loadError: LoadError = new LoadError();

  //#region Sidemember Hole Distance
  public SecondaryHoles: Hole[] = [];
  public selectedSecondaryHole: Hole = null;
  public unSelectedSecondaryHole: Hole = null;
  //#endregion

  //#region Sidemember Comparation
  public uniqueSidemember1Holes: Hole[] = [];
  public uniqueSidemember2Holes: Hole[] = [];
  public compareMode = false;
  public sidemember1: Sidemember;
  public sidemember2: Sidemember;
  //#endregion

  public static initFromSidemember(sidemember: Sidemember): GrapeDataModel {
    const data = new GrapeDataModel();
    data.Width = sidemember.Width;
    data.Height = sidemember.Height;
    data.FlangeHeight = sidemember.FlangeHeight;
    data.TypeText = sidemember.HFInfo.TypeText;
    data.HFInfo = sidemember.HFInfo;
    data.Holes = [...sidemember.Holes];
    data.Reinforcements = [...sidemember.Reinforcements];
    return data;
  }
}

export class GrapeData {
  Data: GrapeDataModel = new GrapeDataModel();
  ChangedObject: GrapeObjectType = GrapeObjectType.Nothing;
}

export enum GrapeObjectType {
  Nothing,
  Sidemember,
  Minimap,
  DataSource,
  TypeText,
  SelectedDrawingHole,
  SelectedSecondaryDrawingHole,
  ResetSecondaryDrawingHoles,
  SelectedHole,
  VariantCodes,
  FilteredHoles,
  HoleDuplicates,
  IndexList,
  SidememberPreview,
  TotalPicture,
}

export class LoadError {
  public forSidemember = false;
  public forVariants = false;
  public forIndexList = false;
  public forSidememberPreview = false;
  public forTypeText = false;
  public forDataSources = false;
  public forMinimap = false;
  public forTotalPicture = false;
  error: any = null;
}
