import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-silent-login',
  template: `<h3>
    {{ 'AUTH.RE_LOGIN' | translate }}
    <h3></h3>
  </h3>`,
  styles: [],
})
export class SilentLoginComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    console.log("i am here ")
    const authDetails = this.authService.getAuthDetails();
    window.opener.postMessage(authDetails, location.origin);
  }
}
