import { HoleDuplicatesSettings } from '../settings/hole-duplicates-settings';
import { Hole } from '../models/grapeservice/hole';
import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';

@Injectable()
export class HoleDuplicatesService {
  constructor() {}

  public findHoleDuplicates(holes: Hole[]): Hole[] {
    if (holes.length === 0) return [];

    const settings = new HoleDuplicatesSettings('HoleDuplicatesSettings');
    const duplicatesHoles: Hole[] = [];
    settings.load();
    let duplicatesHolesParts: string[] = [];
    const excludedParts = settings.holeDuplicates.excludedParts.map(x =>
      x.toString()
    );
    const includedParts = settings.holeDuplicates.includedParts.map(x =>
      x.toString()
    );

    holes.forEach((firstHole) => {
      holes.forEach((secondHole) => {
        if (this.areHolesDuplicate(firstHole, secondHole)) {
          duplicatesHolesParts.push(secondHole.AObj);
        }
      });

      duplicatesHolesParts = duplicatesHolesParts.filter(
        x => !excludedParts.includes(x)
      );
      if (
        duplicatesHolesParts.length > 0 &&
        includedParts.includes(firstHole.AObj)
      ) {
        duplicatesHoles.push(firstHole);
      }
      duplicatesHolesParts = [];
    });
    return duplicatesHoles;
  }

  areHolesDuplicate(firstHole: Hole, secondHole: Hole) {
    if (
      !(firstHole.AObj === null || firstHole.AObj === undefined) &&
      !(secondHole.AObj === null || secondHole.AObj === undefined) &&
      firstHole.AObj !== secondHole.AObj
    ) {
      if (
        Math.abs(firstHole.Hole_Z - secondHole.Hole_Z) <= 1 &&
        Math.abs(firstHole.Hole_Y - secondHole.Hole_Y) <= 1 &&
        Math.abs(firstHole.Hole_X - secondHole.Hole_X) <= 1
      ) {
        return true;
      }
    }

    return false;
  }
}
