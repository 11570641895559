import { Injectable, Injector } from '@angular/core';
import { KeycloakOptions } from 'keycloak-angular';
import { environment } from '../../../environments/environment';
import { WindowRef } from './window-ref.service';

declare global {
  interface Window {
    AppConfig: any;
    AppConfigDev: any;
    AppConfigLocal: any;
    AppConfigMock: any;
  }
}

@Injectable()
export class AppConfigService {
  public currentEnvironment: string;
  public grapeApi: string;

  public keycloak: KeycloakOptions;

  constructor(private injector: Injector, private windowRef: WindowRef) {
    let config;

    if (environment.production) {
      config = this.windowRef.nativeWindow.AppConfig;
    } else {
      if (location.hostname === 'localhost') {
        config = environment.mock
          ? this.windowRef.nativeWindow.AppConfigMock
          : this.windowRef.nativeWindow.AppConfigLocal;
      } else {
        config = this.windowRef.nativeWindow.AppConfigDev;
      }
    }

    this.currentEnvironment = config.currentEnvironment;
    this.grapeApi = config.grapeApi;
    this.keycloak = config.keycloak;
  }
}
