import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, AfterViewInit {
  selection: string[] | string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  multiSelectCtrl = new UntypedFormControl();
  singleSelectCtrl = new UntypedFormControl();
  multiFilteredItems: Observable<string[]>;
  singleFilteredItems: Observable<string[]>;
  _optionList: string[];
  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;

  @Input() placeholder: string[];
  @Input() set optionList(options: string[]) {
    this._optionList = options;
    this.clearSelection(false);
  }
  get optionList(): string[] { return this._optionList; }
  @Input() multiple: boolean;
  @Input() disabled: boolean;

  @Input('value') get value(): string[] | string {
    if (this.multiple && !this.selection) {
      this.selection = [];
    }
    return this.selection;
  }

  set value(value: string[] | string) {
    if (Array.isArray(value) && value.length === 0) {
      value = null;
    }
    this.selection = value;
    this.valueChange.emit(this.selection);
  }

  @Output('valueChange') valueChange = new EventEmitter<string[] | string>();

  constructor() { }

  ngOnInit(): void { 
    this.multiFilteredItems = this.multiSelectCtrl.valueChanges.pipe(
      startWith(null as string),
      map((item: string | null) => item ? this._filter(item) : this.optionList.slice())
    );
    this.singleFilteredItems = this.singleSelectCtrl.valueChanges.pipe(
      startWith(''),
      map((item: string | null) => this._filter(item))
    );
  }

  ngAfterViewInit(): void { 
    this.singleSelectCtrl.setValue(this.value || ''); 
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();
    const newArray = this.value as string[];

    if (value && newArray.indexOf(value) < 0) {
      newArray.push(value.trim());
      this.value = newArray;
    }

    if (input) {
      input.value = null;
    }

    this.multiSelectCtrl.setValue(null);
  }

  remove(value: string): void {
    if (!Array.isArray(this.value))
      return;
    const newArray = this.value as string[];
    const index = newArray.indexOf(value);

    if (index >= 0) {
      newArray.splice(index, 1);
      this.value = newArray;
    }
  }

  openAutocomplete(trigger: MatAutocompleteTrigger, matOption: MatOption) {
    // Auto re-open selection box if multiple selection
    if (this.multiple) {
      trigger.openPanel();
      if (matOption) {
        matOption.focus();
      }
    }
  }

  isSelected(item: string) {
    if (!Array.isArray(this.value))
      return false;
    const array = this.value as string[];
    return array.indexOf(item) >= 0;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const newArray = this.value as string[];
    if (newArray.indexOf(event.option.viewValue) < 0) {
      newArray.push(event.option.viewValue);
      this.value = newArray;
    } else {
      this.remove(event.option.viewValue);
    }
    this.optionInput.nativeElement.value = '';
    this.multiSelectCtrl.setValue(null);
  }

  clearSelection(resetValue: boolean): void {
    if (resetValue) {
      this.value = null;
    }
    this.multiSelectCtrl.setValue(null);
    this.singleSelectCtrl.setValue(this.value || '');
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionList.filter(item => item.toLowerCase().includes(filterValue));
  }
}
