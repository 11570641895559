import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard } from 'keycloak-angular';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService extends KeycloakAuthGuard {
    constructor(protected router: Router, protected authService: AuthService) {
        super(router, authService.keycloakInstance);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            //console.log(this.authenticated,"this.authenticated")
           // console.log(this.authService.startAuthentication(),"this.authService.startAuthentication()")

            if (!this.authenticated) {
                 this.authService.startAuthentication();
                return;
            }

            // let granted: boolean = false;
            let granted: boolean = true;

            /* Check for roles */
            console.log('role restriction given at app-routing.module for this route', route.data.roles);
            console.log('User roles coming after login from keycloak :', this.roles);
            const requiredRoles = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0) {
                granted = true;
            } else {
                for (const requiredRole of requiredRoles) {
                    if (this.roles.indexOf(requiredRole) > -1) {
                        granted = true;
                        break;
                    }
                }
            }

            // if (granted === false) {
            //     this.router.navigate(['/access-denied']);
            // }
            resolve(granted);
        });
    }
}