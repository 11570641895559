import { Injectable } from '@angular/core';
import { IndexListSettings } from '../settings/indexlist-settings';
import { HoleInfoSettings } from '../settings/hole-info-settings';
import { HoleInfoTableSettings } from '../settings/hole-info-table-settings';
import { SidememberPresentationSettings } from '../settings/sidemember-presentation-settings';
import { SidememberInfoSettings } from '../settings/sidemember-info-settings';
import { VariantCodesSettings } from '../settings/variant-codes-settings';
import { Settings as reSettings } from '../../rendering/core/interfaces/settings';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HoleDuplicatesSettings } from '../settings/hole-duplicates-settings';
import { VariantCodeSearchSettings } from '../settings/variantcode-search-settings';
import { HoleInfoEnum } from '../../rendering/core/enums/holeinfo-enum';
import { GuiFont } from '../../rendering/core/interfaces/gui-font';
import { DistanceTextBackground } from '../../rendering/core/interfaces/distance-text-background';
import { HoleColors } from '../../rendering/core/interfaces/hole-colors';
import { RulerConfig } from '../../rendering/core/interfaces/ruler-config';
import { TotalPictureSettings } from '../settings/total-picture-settings';
import { AutoPrintSettings } from '../settings/auto-print-settings';
import { VariantCodesFilterSettings } from '../settings/variant-codes-filter-settings';
import { HoleDistanceSettings } from '../settings/hole-distance-settings';
import { LanguageSettings } from '../settings/language-setting';

enum SettingName {
  SidememberPresentation = 'SidememberPresentationSettings',
  IndexList = 'IndexListSettings',
  HoleInfo = 'HoleInfoSettings',
  HoleInfoTable = 'HoleInfoTableSettings',
  SidememberInfo = 'SidememberInfoSettings',
  CompareSidememberInfo = 'CompareSidememberInfoSettings',
  VariantCodes = 'VariantCodesSettings',
  VariantCodeSearch = 'VariantCodesSearchSettings',
  VariantCodesFilter = 'VariantCodesFilterSettings',
  HoleDuplicates = 'HoleDuplicatesSettings',
  HoleDistance = 'HoleDistanceSettings',
  TotalPicture = 'TotalPictureSettings',
  AutoPrint = 'AutoPrintSettings',
  Language = 'LanguageSettings',
}

@Injectable()
export class UserSettingsService {
  public sidememberPresentation: SidememberPresentationSettings;
  public indexlist: IndexListSettings;
  public holeinfo: HoleInfoSettings;
  public holeinfotable: HoleInfoTableSettings;
  public sidememberinfo: SidememberInfoSettings;
  public compareSidememberinfo: SidememberInfoSettings;
  public renderSetting = {} as reSettings;
  public variantCodes: VariantCodesSettings;
  public variantCodesSearch: VariantCodeSearchSettings;
  public variantCodesFilter: VariantCodesFilterSettings;
  public holeDuplication: HoleDuplicatesSettings;
  public holeDistance: HoleDistanceSettings;
  public totalPicture: TotalPictureSettings;
  public autoPrint: AutoPrintSettings;
  public languageSettings: LanguageSettings;

  public renderEngineSettingsSource = new BehaviorSubject<reSettings>(
    undefined
  );

  private subscriptions: Subscription[] = [];

  public validateSettingFile(data: any): boolean {
    return (
      data.hasOwnProperty(SettingName.SidememberPresentation) ||
      data.hasOwnProperty(SettingName.IndexList) ||
      data.hasOwnProperty(SettingName.HoleInfo) ||
      data.hasOwnProperty(SettingName.HoleInfoTable) ||
      data.hasOwnProperty(SettingName.SidememberInfo) ||
      data.hasOwnProperty(SettingName.CompareSidememberInfo) ||
      data.hasOwnProperty(SettingName.VariantCodes) ||
      data.hasOwnProperty(SettingName.HoleDuplicates) ||
      data.hasOwnProperty(SettingName.HoleDistance) ||
      data.hasOwnProperty(SettingName.VariantCodeSearch) ||
      data.hasOwnProperty(SettingName.VariantCodesFilter) ||
      data.hasOwnProperty(SettingName.TotalPicture) ||
      data.hasOwnProperty(SettingName.Language) ||
      data.hasOwnProperty(SettingName.AutoPrint)
    );
  }

  public removeAllLocalSettings() {
    this.sidememberPresentation.remove(SettingName.SidememberPresentation);
    this.indexlist.remove(SettingName.IndexList);
    this.holeinfo.remove(SettingName.HoleInfo);
    this.holeinfotable.remove(SettingName.HoleInfoTable);
    this.sidememberinfo.remove(SettingName.SidememberInfo);
    this.compareSidememberinfo.remove(SettingName.CompareSidememberInfo);
    this.variantCodes.remove(SettingName.VariantCodes);
    this.holeDuplication.remove(SettingName.HoleDuplicates);
    this.holeDistance.remove(SettingName.HoleDistance);
    this.variantCodesSearch.remove(SettingName.VariantCodeSearch);
    this.variantCodesFilter.remove(SettingName.VariantCodesFilter);
    this.autoPrint.remove(SettingName.TotalPicture);
    this.autoPrint.remove(SettingName.AutoPrint);
    this.languageSettings.remove(SettingName.Language);
  }

  public setImportedData(data: any) {
    this.setSettingData(
      SettingName.SidememberPresentation,
      data[SettingName.SidememberPresentation]
    );
    this.setSettingData(SettingName.IndexList, data[SettingName.IndexList]);
    this.setSettingData(SettingName.HoleInfo, data[SettingName.HoleInfo]);
    this.setSettingData(
      SettingName.HoleInfoTable,
      data[SettingName.HoleInfoTable]
    );
    this.setSettingData(
      SettingName.SidememberInfo,
      data[SettingName.SidememberInfo]
    );
    this.setSettingData(
      SettingName.CompareSidememberInfo,
      data[SettingName.CompareSidememberInfo]
    );
    this.setSettingData(
      SettingName.VariantCodes,
      data[SettingName.VariantCodes]
    );
    this.setSettingData(
      SettingName.HoleDuplicates,
      data[SettingName.HoleDuplicates]
    );
    this.setSettingData(
      SettingName.HoleDistance,
      data[SettingName.HoleDistance]
    );
    this.setSettingData(
      SettingName.VariantCodeSearch,
      data[SettingName.VariantCodeSearch]
    );
    this.setSettingData(
      SettingName.VariantCodesFilter,
      data[SettingName.VariantCodesFilter]
    );
    this.setSettingData(
      SettingName.TotalPicture,
      data[SettingName.TotalPicture]
    );
    this.setSettingData(SettingName.AutoPrint, data[SettingName.AutoPrint]);
    this.setSettingData(SettingName.Language, data[SettingName.Language]);
  }

  private setSettingData(settingName: string, data: any) {
    if (data) {
      localStorage.setItem(settingName, data);
    }
  }

  constructor() {
    this.sidememberPresentation = new SidememberPresentationSettings(
      SettingName.SidememberPresentation
    );
    this.indexlist = new IndexListSettings(SettingName.IndexList);
    this.holeinfo = new HoleInfoSettings(SettingName.HoleInfo);
    this.holeinfotable = new HoleInfoTableSettings(SettingName.HoleInfoTable);
    this.sidememberinfo = new SidememberInfoSettings(
      SettingName.SidememberInfo
    );
    this.compareSidememberinfo = new SidememberInfoSettings(
      SettingName.CompareSidememberInfo
    );
    this.variantCodes = new VariantCodesSettings(SettingName.VariantCodes);
    this.holeDuplication = new HoleDuplicatesSettings(
      SettingName.HoleDuplicates
    );
    this.holeDistance = new HoleDistanceSettings(SettingName.HoleDistance);
    this.totalPicture = new TotalPictureSettings(SettingName.TotalPicture);
    this.variantCodesSearch = new VariantCodeSearchSettings(
      SettingName.VariantCodeSearch
    );
    this.variantCodesFilter = new VariantCodesFilterSettings(
      SettingName.VariantCodesFilter
    );
    this.autoPrint = new AutoPrintSettings(SettingName.AutoPrint);
    this.languageSettings = new LanguageSettings(SettingName.Language);

    this.subscriptions.push(
      this.indexlist.onSave.subscribe((value: string) =>
        this.indexListSaveActions(value)
      ),
      this.holeinfo.onSave.subscribe((value: string) =>
        this.holeInfoSaveActions(value)
      ),
      this.holeinfotable.onSave.subscribe((value: string) =>
        this.holeInfoSaveActions(value)
      ),
      this.sidememberinfo.onSave.subscribe((value: string) =>
        this.sidememberInfoSaveActions(value)
      ),

      this.sidememberPresentation.onLoad.subscribe((value: string) =>
        this.sidememberPresentationSaveActions(value)
      ),
      this.indexlist.onLoad.subscribe((value: string) =>
        this.indexListLoadActions(value)
      ),
      this.holeinfo.onLoad.subscribe((value: string) =>
        this.holeInfoLoadActions(value)
      ),
      this.holeinfotable.onLoad.subscribe((value: string) =>
        this.holeInfoLoadActions(value)
      ),
      this.sidememberinfo.onLoad.subscribe((value: string) =>
        this.sidememberInfoLoadActions(value)
      )
    );

    this.setSidememberPresentationSettings(null);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private sidememberPresentationSaveActions(value: string) {
    this.setSidememberPresentationSettings(value);
    this.renderEngineSettingsSource.next(this.renderSetting);
  }

  private indexListSaveActions(value: string) {
    // None for now
  }

  private holeInfoSaveActions(value: string) {
    // None for now
  }
  private sidememberInfoSaveActions(value: string) {
    // None for now
  }
  private indexListLoadActions(value: string) {
    // None for now
  }
  private holeInfoLoadActions(value: string) {
    // None for now
  }
  private sidememberInfoLoadActions(value: string) {
    // None for now
  }

  private setSidememberPresentationSettings(value?: string) {
    this.renderSetting.holeInformation = {} as HoleInfoEnum[];
    this.renderSetting.guiFont = {} as GuiFont;
    this.renderSetting.holeColors = {} as HoleColors;
    this.renderSetting.rulerConfig = {} as RulerConfig;
    this.renderSetting.distanceTextBackground = {} as DistanceTextBackground;

    this.renderSetting.showUpsideDown = this.sidememberPresentation.showUpsideDown;
    this.renderSetting.printHoleMarking = this.sidememberPresentation.printHoleMarking;
    this.renderSetting.printRestrictedAreas = this.sidememberPresentation.printRestrictedAreas;
    this.renderSetting.holeInformation = <HoleInfoEnum[]>(
      this.sidememberPresentation.holeInformation
    );
    this.renderSetting.showSidememberOrientation = this.sidememberPresentation.showSidememberOrientation;
    this.renderSetting.persistErrors = this.sidememberPresentation.persistErrors;

    this.renderSetting.adaptToDeviceRatio = null; // adaptToDeviceRatio;
    this.renderSetting.antiAliasing = null; // antiAliasing;

    this.renderSetting.guiFont = {
      guiFontFamily: null,
      guiFontSize: null,
      guiLineHeight: null,
      guiFontColor: null,
    };

    this.renderSetting.distanceTextBackground = {
      width: null,
      height: null,
      opacity: null,
      borderTickness: null,
      color: null,
    };

    this.renderSetting.holeColors = {
      highlightedHoleColor: null,
      holeOutlineColor: null,
      initialHoleColor: null,
      markedHoleColor: null,
      selectedHoleColor: null,
      transparentColor: null,
      duplicateColor: null,
    };

    this.renderSetting.rulerConfig = {
      rulerBoxAlpha: null,
      rulerBoxColor: null,
      rulerBoxHeight: null,
      rulerLineColor: null,
      rulerLineHeight: null,
      rulerLineOffset: null,
      rulerLineWidth: null,
      rulerSectionsCount: null,
      rulerValueFontColor: null,
      rulerValueFontFamily: null,
      rulerValueFontSize: null,
      showRuler: null,
    };
  }
}
