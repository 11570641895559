import {
  AbstractMesh,
  Material,
  StandardMaterial,
  Color3,
  Scene
} from 'babylonjs';
export class MeshController {
  constructor(private scene: Scene) {}

  public setMaterial(mesh: AbstractMesh, material: Material): void {
    if (!mesh || !material) {
      return;
    }

    mesh.material = material;
  }

  public createMaterial(
    scene: Scene,
    color: Color3,
    name?: string
  ): StandardMaterial {
    const existingMaterial = this.scene.getMaterialByName(
      name
    ) as StandardMaterial;

    const material = existingMaterial
      ? existingMaterial
      : new StandardMaterial(name, scene);
    material.emissiveColor = color;

    return material;
  }
}
