import { Settings } from './settings';

export class VariantCodesSettings extends Settings {
  public collapsed = false;
  public sortColumn = 'VarClass';
  public sortDesc = true;

  public load(): void {
    const storedSettings = <VariantCodesSettings>super.load();
    this.collapsed = super.isStored('collapsed', storedSettings)
      ? storedSettings.collapsed
      : this.collapsed;
    this.sortColumn = super.isStored('sortColumn', storedSettings)
      ? storedSettings.sortColumn
      : this.sortColumn;
    this.sortDesc = super.isStored('sortDesc', storedSettings)
      ? storedSettings.sortDesc
      : this.sortDesc;
  }

  public save(): void {
    super.save(this);
  }
}
