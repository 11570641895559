import { ElementRef, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import {
  GrapeData,
  GrapeDataModel,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import {
  TotalPictureDrawingSettings,
  TotalPicturePaperSettings,
} from './total-picture.model';
import { TotalPictureService } from './total-picture.service';
import { UserSettingsService } from '../shared/services/usersettings.service';

export interface TotalPicturePrintId {
  dataSource: string;
  ipoId: string;
  version: string;
  popId?: string;
  wpl?: string;
}

export interface TotalPicturePrintError {
  data: GrapeDataModel;
  message: string;
}

@Injectable()
export class TotalPicturePrintingService implements OnDestroy {
  public renderer: Renderer2;
  public pictureCount = 0;
  public currentlyProcessing = 0;
  public isProcessing: boolean;
  public isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public done$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public error$: BehaviorSubject<TotalPicturePrintError> = new BehaviorSubject(
    null
  );
  public processPicture$: BehaviorSubject<TotalPicturePrintId> = new BehaviorSubject(
    null
  );

  private totalPictureRequest$: Observable<GrapeData>;
  private totalPictureRequestError$: Observable<GrapeData>;
  private processQueue: TotalPicturePrintId[] = [];
  private processPicture: TotalPicturePrintId;
  private subscriptions: Subscription[] = [];
  private host: ElementRef;

  private totalPicturePaperSettings: TotalPicturePaperSettings = {
    widthCm: 29.7,
    heightCm: 19.5,
    marginsCm: {
      left: 2.5,
      right: 2.5,
      top: 1,
      bottom: 1,
    },
  };

  constructor(
    private totalPicture: TotalPictureService,
    private settings: UserSettingsService,
    private grapeData: GrapeDataService
  ) {
    this.loadSettings();
    this.initializeObservables();
    this.subscribeToData();

    window.onbeforeprint = () => {
      this.completePrinting();
    };
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public print(
    totalPictures: TotalPicturePrintId[],
    host: ElementRef
  ): Promise<void> {
    return new Promise<void>(() => {
      if (!this.renderer || !host) return;

      this.host = host;

      this.pictureCount = totalPictures.length;
      this.isProcessing = true;
      this.isProcessing$.next(this.isProcessing);

      this.createProcessQueue(totalPictures);
      this.processNext();
    });
  }

  private loadSettings(): void {
    this.settings.indexlist.load();
    this.settings.sidememberPresentation.load();
    this.settings.totalPicture.load();
  }

  private initializeObservables(): void {
    this.totalPictureRequest$ = this.grapeData.data.pipe(
      filter(
        (data: GrapeData) => data.ChangedObject === GrapeObjectType.TotalPicture
      )
    );

    this.totalPictureRequestError$ = this.grapeData.data.pipe(
      filter((data: GrapeData) => data.Data.loadError.forTotalPicture)
    );
  }

  private createProcessQueue(totalPictures: TotalPicturePrintId[]): void {
    this.processQueue.push(...totalPictures);
  }

  private processNext(): void {
    const totalPicture = this.processQueue.shift();
    this.currentlyProcessing += 1;
    this.processPicture = totalPicture;
    this.requestTotalPicture(totalPicture);
  }

  private requestTotalPicture(totalPicture: TotalPicturePrintId): void {
    this.grapeData.setTotalPicture(
      totalPicture.dataSource,
      totalPicture.ipoId,
      totalPicture.version
    );
  }

  private subscribeToData(): void {
    this.subscriptions.push(
      this.totalPictureRequest$.subscribe((data: GrapeData) => {
        this.addTotalPictureToDOM(data.Data);
        this.processPrinting();
      }),

      this.totalPictureRequestError$.subscribe((data: GrapeData) => {
        this.error$.next({
          data: data.Data,
          message: data.Data.loadError.error,
        });

        this.completePrinting();
      })
    );
  }

  private processPrinting(): void {
    this.processPicture$.next(this.processPicture);

    if (
      this.processQueue.length === 0 &&
      this.host.nativeElement.childNodes.length !== 0
    ) {
      const noMarginPage = this.renderer.createElement('style');
      noMarginPage.innerHTML = '@media print { @page { margin: 0 !important } }';
      
      document.head.appendChild(noMarginPage);
      window.print();
      document.head.removeChild(noMarginPage);
    } else {
      this.processNext();
    }
  }

  private completePrinting(): void {
    if (!this.isProcessing) return;

    this.clearTotalPicture();

    this.isProcessing = false;
    this.isProcessing$.next(this.isProcessing);

    this.pictureCount = 0;
    this.currentlyProcessing = 0;

    this.done$.next(true);
  }

  private addTotalPictureToDOM(data: GrapeDataModel): void {
    const settings = this.getTotalPictureSettings(data);
    const canvas = this.renderer.createElement('canvas');
    const context = canvas.getContext('2d');

    this.totalPicture.create(context, settings);

    this.renderer.appendChild(this.host.nativeElement, canvas);
  }

  private clearTotalPicture(): void {
    const canvases = this.host.nativeElement.childNodes;
    for (const canvas of canvases) {
      this.renderer.removeChild(this.host.nativeElement, canvas);
    }
  }

  private getTotalPictureSettings(
    data: GrapeDataModel
  ): TotalPictureDrawingSettings {
    this.loadSettings();
    return {
      data,
      paperSettings: this.totalPicturePaperSettings,
      scalingRatio: 2, // Draw twice as big to get better resolution on canvas
      sectionWidth: 4055,
      sectionOverlap: 100,
      drawFilteredHoles: true,
      drawUpsideDown: this.settings.sidememberPresentation.showUpsideDown,
      drawXm: this.settings.totalPicture.printXm,
      drawTickMarks: this.settings.totalPicture.printTickMarks,
      enlargeFilteredHoles: this.settings.totalPicture.printHoleEnlarged,
      drawFilteredHoleNumbers: this.settings.totalPicture.printHoleNumbers,
      drawPPTRMarks: this.settings.sidememberPresentation.printHoleMarking,
      drawRestrictedAreas: this.settings.sidememberPresentation.printRestrictedAreas,
    };
  }
}
