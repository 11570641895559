<div class="row card-footer m-0 p-0">
  <div class="section left col-3">
    <span>{{ selectedHole?.HPSName }}</span>
  </div>
  <div class="section text-center center col-6">
    <span class="coordinates" [hidden]="!selectedHole">{{
      holeCoordinates
    }}</span>
    <span class="coordinates" [hidden]="selectedHole">{{
      locationCoordinates
    }}</span>
    <span class="diameter" *ngIf="displayDiameter(selectedHole?.HoleShape)">{{
      selectedHole?.Diameter | prefixString: 'Ø: '
    }}</span>
    <span class="supploper">{{
      selectedHole?.SupplOper | prefixString: 'SupplOper: '
    }}</span>
  </div>
  <div class="section right col-3">
    <span>{{ selectedHole?.ASName }}</span>
  </div>
</div>