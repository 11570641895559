import { HoleRenderer } from './hole-renderer';
import { Mesh, MeshBuilder } from 'babylonjs';
import { SidememberSide } from '../../shared/models/sidemember-enum';

export class RectangularHoleRenderer extends HoleRenderer {
  public createMesh() {
    super.createMesh();
    this.mesh = MeshBuilder.CreatePlane(
      'RectangularHole',
      {
        width: this.renderData.Length,
        height: this.renderData.Diameter,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE,
      },
      this.engine.scene
    );
  }

  public markHoleDuplicate(): void {}
}
