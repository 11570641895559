import * as GUI from 'babylonjs-gui';
import { RenderEngine } from '../render-engine';
import { PresentationSettings } from '../core/presentation-settings';

export class RulerGUI {
  protected renderHeight: number;
  protected renderWidth: number;
  protected sectionWidth: number;
  protected rulerBox: GUI.Rectangle;
  protected rulerLines: GUI.Line[] = [];
  protected rulerValues: GUI.TextBlock[] = [];

  constructor(
    protected engine: RenderEngine,
    protected gui: GUI.AdvancedDynamicTexture,
    private settings: PresentationSettings
  ) {
    this.engine = engine;
    this.gui = gui;
    this.initialize();
  }

  public update(): void {
    this.renderWidth = this.engine.getRenderWidth();

    this.renderHeight = this.settings.rulerConfig.showRuler
      ? this.engine.getRenderHeight()
      : this.engine.getRenderHeight() +
        this.settings.rulerConfig.rulerBoxHeight;

    this.sectionWidth =
      this.renderWidth / this.settings.rulerConfig.rulerSectionsCount;

    this.distributeRulerLines();
    this.updateRulerValues();

    this.gui.update();
  }

  public dispose(): void {
    this.gui.dispose();
  }

  private initialize(): void {
    this.renderHeight = this.settings.rulerConfig.showRuler
      ? this.engine.getRenderHeight()
      : this.engine.getRenderHeight() +
        this.settings.rulerConfig.rulerBoxHeight;
    this.renderHeight = this.engine.getRenderHeight();
    this.sectionWidth =
      this.renderWidth / this.settings.rulerConfig.rulerSectionsCount;
    this.drawRuler();
  }

  private drawRuler(): void {
    this.removeControls();
    this.drawRulerBox();
    this.drawRulerLines();
  }

  private drawRulerBox(): void {
    this.rulerBox = new GUI.Rectangle();
    this.rulerBox.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.rulerBox.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.rulerBox.width = '100%';
    this.rulerBox.height = this.settings.rulerConfig.showRuler
      ? `${this.settings.rulerConfig.rulerBoxHeight}px`
      : `0px`;
    this.rulerBox.background = this.settings.rulerConfig.rulerBoxColor;
    this.rulerBox.zIndex = 0;
    this.rulerBox.alpha = this.settings.rulerConfig.rulerBoxAlpha;
    this.gui.addControl(this.rulerBox);
  }

  private drawRulerLines(): void {
    if (!this.settings.rulerConfig.showRuler) {
      return;
    }
    this.rulerLines = [];
    this.rulerValues = [];
    const y1 =
      this.renderHeight -
      this.settings.rulerConfig.rulerBoxHeight +
      this.settings.rulerConfig.rulerLineOffset;
    const y2 = y1 + this.settings.rulerConfig.rulerLineHeight;
    for (
      let sectionIndex = 1;
      sectionIndex <= this.settings.rulerConfig.rulerSectionsCount - 1;
      sectionIndex += 1
    ) {
      const x =
        (this.renderWidth / this.settings.rulerConfig.rulerSectionsCount) *
        sectionIndex;
      const line = new GUI.Line();
      line.x1 = x;
      line.x2 = x;
      line.y1 = y1;
      line.y2 = y2;
      line.lineWidth = this.settings.rulerConfig.rulerLineWidth;
      line.color = this.settings.rulerConfig.rulerLineColor;
      line.zIndex = 1;
      this.rulerLines.push(line);
      this.gui.addControl(line);
      this.drawRulerValue(x, y2);
    }
  }

  private drawRulerValue(x: number, y: number): void {
    const rulerValue = new GUI.TextBlock();
    rulerValue.text = '';
    rulerValue.width = '60px';
    rulerValue.height = '20px';
    rulerValue.fontFamily = this.settings.rulerConfig.rulerValueFontFamily;
    rulerValue.fontSize = this.settings.rulerConfig.rulerValueFontSize;
    rulerValue.color = this.settings.rulerConfig.rulerValueFontColor;
    rulerValue.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    rulerValue.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    rulerValue.left = x - rulerValue.widthInPixels / 2;
    rulerValue.top = y;
    rulerValue.zIndex = 2;
    this.rulerValues.push(rulerValue);
    this.gui.addControl(rulerValue);
  }

  private distributeRulerLines(): void {
    const y1 =
      this.renderHeight -
      this.settings.rulerConfig.rulerBoxHeight +
      this.settings.rulerConfig.rulerLineOffset;
    const y2 = y1 + this.settings.rulerConfig.rulerLineHeight;
    let sectionIndex = 1;
    for (const line of this.rulerLines) {
      const x =
        (this.renderWidth / this.settings.rulerConfig.rulerSectionsCount) *
        sectionIndex;
      line.x1 = x;
      line.x2 = x;
      line.y1 = y1;
      line.y2 = y2;
      this.repositionRulerValue(sectionIndex - 1, x, y2);
      sectionIndex += 1;
    }
  }

  private repositionRulerValue(index: number, x: number, y: number) {
    const rulerValue = this.rulerValues[index];
    if (!rulerValue) {
      return;
    }
    rulerValue.left = x - rulerValue.widthInPixels / 2;
    rulerValue.top = y;
  }

  private updateRulerValues(): void {
    for (let index = 0; index < this.rulerLines.length; index += 1) {
      const rulerLine = this.rulerLines[index];
      const rulerValue = this.rulerValues[index];
      if (!rulerLine || !rulerValue) {
        continue;
      }
      this.updateRulerValue(rulerLine.centerX, rulerLine.centerY, rulerValue);
    }
  }

  private updateRulerValue(x: number, y: number, value: GUI.TextBlock): void {
    const location = this.engine.screenToWorld(x, y);
    const newValue = String(location.x.toFixed(1));
    if (newValue === value.text) {
      return;
    }
    value.text = newValue;
  }

  private removeControls(): void {
    if (this.rulerBox) {
      this.gui.removeControl(this.rulerBox);
    }

    for (const line of this.rulerLines) {
      this.gui.removeControl(line);
    }

    for (const value of this.rulerValues) {
      this.gui.removeControl(value);
    }
  }
}
