import { Settings } from './settings';
import { VariantCodesFilterDefinition } from '../models/selected-variantcodes-def';

export class VariantCodesFilterSettings extends Settings {
  public collapsed = false;
  public isFilterActive = false;
  public variantCodesDefinitions: VariantCodesFilterDefinition[] = [];
  public sortColumn = 'class';
  public sortDesc = true;

  public load(): void {
    const storedSettings = <VariantCodesFilterSettings>super.load();
    this.variantCodesDefinitions = super.isStored(
      'variantCodesDefinitions',
      storedSettings
    )
      ? storedSettings.variantCodesDefinitions
      : this.variantCodesDefinitions;
    this.isFilterActive = super.isStored('isFilterActive', storedSettings)
      ? storedSettings.isFilterActive
      : this.isFilterActive;
    this.sortColumn = super.isStored('sortColumn', storedSettings)
      ? storedSettings.sortColumn
      : this.sortColumn;
    this.sortDesc = super.isStored('sortDesc', storedSettings)
      ? storedSettings.sortDesc
      : this.sortDesc;
  }

  public save(): void {
    super.save(this);
  }
}
