// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  mock: false,
  version: 'DEV',
  major: 0,
  minor: 2,
  patch: 0,
  sidememberConfig: {
    startZAxis: 1000,
    defaultWebHeight: 270,
    measureDecimalPlaces: 1
  }
};

export const environmentAd = {
  production: false,
  clientId: '3224efb7-a315-4fd6-be59-d34d76de126e',
  authority: 'https://login.partner.microsoftonline.cn/e71a154e-e269-46af-a3af-fdff3eab0663/',
  redirectUrl: 'https://grape.devtest.gf.aws.scania.com.cn/sidemember-presentation'
};
