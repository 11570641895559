import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { Indexlist } from '../shared/models/grapeservice/indexlist';
import {
  GrapeData,
  GrapeObjectType
} from '../shared/models/grapeservice/grape.data';
import { Subscription, BehaviorSubject , Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { IndexlistEntry } from '../shared/models/indexlist-entry';
import { COMMA, ENTER ,SPACE} from '@angular/cdk/keycodes';
import { FormControl} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-sidemember-list-filter',
  templateUrl: './sidemember-list-filter.component.html',
  styleUrls: ['./sidemember-list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidememberListFilterComponent implements OnInit {

  public chassisNumber: string;
  public enableMultiChassis = false;
  public selectedChassisList = [];
  public chassisList = [];
  public errorMsg: string = "";
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA,SPACE];
  public chassisError: boolean=false
  public chipValue: string;
  private subscriptions: Subscription[] = [];


  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @Output() hfStatusChanged = new EventEmitter<string[]>();
  @Output() chassisNumberChanged = new EventEmitter<string>();
  

  constructor(public settings: UserSettingsService, private grapeDataService: GrapeDataService, public translate: TranslateService) {
    this.settings.indexlist.load();
  }

  async ngOnInit() {
    this.hfStatusSelection = this.settings.indexlist.hfStatus;
    const tempChassisList = this.settings.indexlist.chassisSearchNumber.split(",");
    this.selectedChassisList = tempChassisList?.length <= 1 ? [] : tempChassisList;
    this.enableMultiChassis = tempChassisList?.length <= 1 ? false : true;
    this.chassisNumber = this.selectedChassisList?.length > 1 ? "" :  this.settings.indexlist.chassisSearchNumber;
    this.subscribeForData();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public hfStatusSelection = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'P',
    'X'
  ];


  public selectAllHf() {
    this.hfStatusSelection = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'P',
      'X'
    ];

    this.storeHFSelection();
    this.hfStatusChanged.emit(this.hfStatusSelection);
  }

  public selectOnlyHf5() {
    this.hfStatusSelection = ['5'];
    this.storeHFSelection();
    this.hfStatusChanged.emit(this.hfStatusSelection);
  }

  private storeHFSelection() {
    this.settings.indexlist.hfStatus = this.hfStatusSelection;
    this.settings.indexlist.save();
  }

  private storeChassisNumber() {
    this.settings.indexlist.chassisSearchNumber = this.chassisNumber;
    this.settings.indexlist.save();
  }

  private storeListOfChassis(chassiValues) {
    this.settings.indexlist.chassisSearchNumber = chassiValues;
    this.settings.indexlist.save();
  }


  public setCollapsed(): void {
    this.settings.indexlist.collapsedFilter = !this.settings.indexlist
      .collapsedFilter;
    this.settings.indexlist.save();
  }

  public changeHfStatus(status: string): void {
    const hfStatusIndex = this.hfStatusSelection.indexOf(status);
    if (hfStatusIndex > -1) {
      this.hfStatusSelection.splice(hfStatusIndex, 1);
    } else {
      this.hfStatusSelection.push(status);
    }

    this.storeHFSelection();
    this.hfStatusChanged.emit(this.hfStatusSelection);
  }

  public searchChassisNumber() {
    this.selectedChassisList = [];
    this.storeChassisNumber();
    this.chassisNumberChanged.emit(this.chassisNumber);
  }

  public toggle(val){
    this.enableMultiChassis = val.target.checked;
    if(!this.enableMultiChassis){
      this.searchChassisNumber();
    }
    else{
       this.applyChassisListFilter();
    }
  }

  public filterChassisNumbers() {
    const concatenatedValue  = this.selectedChassisList.join(",");
    this.storeListOfChassis(concatenatedValue);
    this.chassisNumberChanged.emit(concatenatedValue);
  }

  private subscribeForData() {
    if (this.chassisList?.length === 0) {
      this.subscriptions.push(this.grapeDataService.data.subscribe((data: GrapeData) => {
        if(Object.values(data?.Data?.IndexList)?.length) {
          data.Data.IndexList.entries.forEach((x) => {
            this.chassisList.push(x.chassisNumber.toLowerCase());
          });
        }
      }));
    }
  }
  
  public applyChassisListFilter(){
    this.chassisNumber = "";
    this.filterChassisNumbers();
  }
  
  public add(event: MatChipInputEvent): void {
    this.chipValue = "";
    this.chassisError=false;
    const input = event.input;
    const value = event.value;
    this.chipValue = value;
    if (value?.length <= 8 && value?.length >= 4) {
      if (this.chassisList.includes(value.toLowerCase()) && this.selectedChassisList.findIndex(x => x.toLowerCase() === value.toLowerCase()) === -1) {
        if ((value || '').trim()) {
          this.selectedChassisList.push(value.trim());
          this.applyChassisListFilter();
        }
        if (input) {
          input.value = '';
        }
      }
      else {
        this.chassisError = true;
        this.errorMsg = this.translate.instant('SIDEMEMBER_LIST.FILTERS.ENTER_CHASSIS_IS_INVALID', { chipValue: this.chipValue });
      }
    }
    else {
      this.chassisError = true;
      this.errorMsg = this.translate.instant('SIDEMEMBER_LIST.FILTERS.ENTER_VALID_LENGTH_CHASSIS_EXP');
    }
    if (input) {
      input.value = '';
    }
  }

  public remove(chassis: string): void {
    this.chassisError = false;
    this.chipValue = "";
    const index = this.selectedChassisList.indexOf(chassis);
    if (index >= 0) {
      this.selectedChassisList.splice(index, 1);
      this.applyChassisListFilter();
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedChassisList.push(event.option.viewValue);
  }

}
