import { Component, ViewChild, TemplateRef } from '@angular/core';
import { HoleSearchDef } from '../shared/models/hole-search-def';
import { Hole } from '../shared/models/grapeservice/hole';
import { List } from 'linqts';
import { of } from 'rxjs'; 
import { KeyValuePair } from '../shared/models/key-value-pair.model';
import { SupplOper } from '../shared/models/supploper';
import { SearchCriteriaColor } from '../shared/models/search-criteria-color';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { Subscription } from 'rxjs';
import { isNumber } from '../shared/logic/type-utils';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { ModalType, ModalResult } from '../shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectComponent } from '../shared/components/multi-select/multi-select.component';
import { AnyRecordWithTtl } from 'dns';

interface SearchTermEvent {
  items: string[];
  term: string;
}

@Component({
  selector: 'app-hole-search-definition',
  templateUrl: './hole-search-definition.component.html',
  styleUrls: ['./hole-search-definition.component.scss'],
})
export class HoleSearchDefinitionComponent {
  @ViewChild('definitionAlertModal')
  definitionAlertModal: TemplateRef<any>;

  //#region properties
  private holeInfoColumns = [
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_NO'), value: 'HoleObj', UseColor: true, Operators: [] },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_TO'), value: 'HoleToo', UseColor: true, Operators: [] },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_TYPE'), value: 'HSToo', UseColor: true, Operators: [] },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.PART_NUMBER'), value: 'AObj', UseColor: true, Operators: ['IN'] },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.PART_NAME'), value: 'ASName', UseColor: true, Operators: ['IN'] },
    {
      name: of('X'),
      value: 'Hole_X',
      UseColor: true,
      Operators: ['>', '<', '[]'],
    },
    {
      name: of('Y'),
      value: 'Hole_Y',
      UseColor: true,
      Operators: ['>', '<', '[]'],
    },
    {
      name: of('Z'),
      value: 'Hole_Z',
      UseColor: true,
      Operators: ['>', '<', '[]'],
    },
    {
      name: this.translate.stream('HOLE_INFORMATION.COLUMNS.DIAM'),
      value: 'Diameter',
      UseColor: true,
      Operators: ['>', '<', '[]'],
    },
    {
      name: this.translate.stream('HOLE_INFORMATION.COLUMNS.LENGTH'),
      value: 'Length',
      UseColor: true,
      Operators: ['>', '<', '[]'],
    },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.SUPPLOPER'), value: 'SupplOper', UseColor: true, Operators: [] },
    {
      name: this.translate.stream('HOLE_INFORMATION.COLUMNS.PATTERN_FI'),
      value: 'HPSName',
      UseColor: true,
      Operators: ['IN'],
    },
    {
      name: this.translate.stream('HOLE_INFORMATION.COLUMNS.PATTERN_FI_NO'),
      value: 'HPObj',
      UseColor: true,
      Operators: ['IN'],
    },
    // { name: 'HPC/GP', value: 'HPCGP', UseColor: false, Operators: [] },
    {
      name: this.translate.stream('HOLE_INFORMATION.COLUMNS.TYPE'),
      value: 'HSToo',
      UseColor: true,
      Operators: ['>', '<', '[]', 'IN'],
    },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.GOBJ'), value: 'GObj', UseColor: true, Operators: [] },
    { name: this.translate.stream('HOLE_INFORMATION.COLUMNS.GSNAME'), value: 'GSName', UseColor: true, Operators: [] },
  ];

  public prevTermValue = '';
  public searchDefs: HoleSearchDef[] = [];

  private subscriptions: Subscription[] = [];
  private holeAvailColors = new SearchCriteriaColor();
  private supplOper: KeyValuePair[] = new SupplOper().values;
  private indexFieldCounter = 0;
  private holes: Hole[] = [];
  private isDirty = false;
  private isChanged = false;
  //#endregion properties

  //#region ng
  constructor(
    private grapeDataService: GrapeDataService,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService
  ) {
    this.subscribeForData();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  //#endregion ng

  //#region data
  private subscribeForData() {
    this.subscriptions.push(
      this.grapeDataService.data.subscribe((data: GrapeData) => {
        if (data.ChangedObject === GrapeObjectType.Sidemember) {
          this.holes = [...data.Data.Holes];
        }
      })
    );
  }
  //#endregion data

  //#region getters and setters
  public get getHoleInfoColumns() {
    return this.holeInfoColumns;
  }

  public get isSaved(): boolean {
    return !this.isDirty;
  }

  public set saved(saved: boolean) {
    this.isDirty = !saved;
  }

  public get hasChanged(): boolean {
    return this.isChanged;
  }

  public set hasChanged(changed: boolean) {
    this.isChanged = changed;
  }

  public get searchDefinitions(): HoleSearchDef[] {
    return this.searchDefs;
  }

  public get colors(): SearchCriteriaColor {
    return this.holeAvailColors;
  }

  private get freeColor(): string {
    const colorInx = this.holeAvailColors.colors.findIndex((x) => {
      return !x.used;
    });

    return colorInx !== -1
      ? this.holeAvailColors.colors[colorInx].value
      : this.holeAvailColors.colors[this.holeAvailColors.colors.length - 1]
          .value;
  }
  //#endregion

  //#region public methods
  public initialize(defs: HoleSearchDef[], colors: SearchCriteriaColor) {
    this.isDirty = false;
    this.isChanged = false;

    this.setSearchDefs(defs);
    this.setColorsUsed(colors);
    this.setIndexFieldCounter();
  }

  public onChange() {
    this.isDirty = true;
    this.isChanged = true;
  }

  public getOtherOperators(def: HoleSearchDef): string[] {
    if (!def || def.attributeName === '') {
      return;
    }
    const operators = this.holeInfoColumns.find((x) => {
      return x.value === def.attributeName;
    }).Operators;
    return operators;
  }

  public isItTheFromToOperator(def: HoleSearchDef): boolean {
    return (
      this.searchDefs.find((item) => {
        return item.index === def.index;
      }).operator === '[]'
    );
  }

  public isLastDefItem(def: HoleSearchDef) {
    return (
      this.searchDefs.findIndex((x) => {
        return x.index === def.index;
      }) <
      this.searchDefs.length - 1
    );
  }
  //#endregion

  //#region html events
  public onAddDefinitions() {
    const currentLastDef = this.searchDefs[this.searchDefs.length - 1];
    if (currentLastDef && currentLastDef.attributeName === '') {
      this.showInfoModal(
        this.translate.instant('HOLE_INFORMATION.FILL_SEARCH_CRITERIA')
      );
      return;
    }
    if (
      currentLastDef &&
      currentLastDef.operator !== '[]' &&
      !currentLastDef.value1
    ) {
      this.showInfoModal(this.translate.instant('HOLE_INFORMATION.ENTER_VALUE'));
      return;
    }
    if (
      currentLastDef &&
      currentLastDef.operator === '[]' &&
      (!currentLastDef.value1 || !currentLastDef.value2)
    ) {
      this.showInfoModal(this.translate.instant('HOLE_INFORMATION.ENTER_VALUES'));
      return;
    }

    const def = new HoleSearchDef(null);
    def.index = ++this.indexFieldCounter;
    this.searchDefs.push(def);

    this.onChange();
  }

  public validate(shoMessage = true): boolean {
    const isValid =
      this.searchDefinitions.filter(
        (def) =>
          (def && def.attributeName === '') ||
          (def && def.operator !== '[]' && !def.value1) ||
          (def && def.operator === '[]' && (!def.value1 || !def.value2))
      ).length === 0;

    if (!isValid && shoMessage) {
      this.showInfoModal(this.translate.instant('HOLE_INFORMATION.ENTER_VALID_SET'));
    }

    return isValid;
  }

  public onOperatorChange(def: HoleSearchDef) {
    this.setDefBackgroundColor(def);
    if (this.isItTheFromToOperator(def)) {
      return;
    }

    if (Array.isArray(def.value1) || def.operator === 'IN') {
      def.value1 = null;
    }

    def.value2 = null;

    this.onChange();
  }

  public onDeleteDef(def: HoleSearchDef) {
    this.releaseColor(def);
    this.searchDefs = this.searchDefs.filter((x) => x.index !== def.index);

    this.onChange();
  }

  public loadSearchList(def: HoleSearchDef) {
    def.searchList = [];
    const dist = new List<Hole>(this.holes)
      .DistinctBy((x) => x[def.attributeName].toString())
      .ToArray();

    if (def.attributeName.toLowerCase() === 'supploper') {
      this.supplOper.forEach((x) =>
        def.searchList.push(`${x.value} (${x.key})`)
      );
    } else {
      for (let i = 0; i < dist.length; i++) {
        def.searchList.push(dist[i][def.attributeName].toString());
      }

      def.searchList.sort((x, y) => {
        if (isNumber(x) && isNumber(y)) {
          return Number(x) > Number(y) ? 1 : -1;
        }

        return x.toUpperCase() > y.toUpperCase() ? 1 : -1;
      });
    }
  }


  public onIsAndClick(value: boolean, def: HoleSearchDef) {
    if (def.isAnd !== value) {
      def.isAnd = value;
      this.onChange();
    }
  }

  public onSearchAttributeChange(
    def: HoleSearchDef, 
    valueCtrl1: MultiSelectComponent, 
    valueCtrl2: MultiSelectComponent
  ) {
    this.setDefBackgroundColor(def);
    def.operator = '=';
    def.value1 = null;
    def.value2 = null;
    this.onChange();
    this.loadSearchList(def);
    valueCtrl1?.clearSelection(true);
    valueCtrl2?.clearSelection(true);
  }

  //#endregion

  //#region private methods
  private setIndexFieldCounter() {
    this.indexFieldCounter =
      this.searchDefs.length !== 0
        ? this.searchDefs[this.searchDefs.length - 1].index
        : 0;
  }

  private setSearchDefs(defs: HoleSearchDef[]) {
    this.searchDefs = [];
    defs.forEach((item) => {
      this.loadSearchList(item);
      this.searchDefs.push(item);
    });
  }

  private setColorsUsed(colors: SearchCriteriaColor) {
    colors.colors.forEach((item) => {
      this.holeAvailColors.colors.find((x) => x.value === item.value).used =
        item.used;
    });
  }

  private setDefBackgroundColor(def: HoleSearchDef) {
    this.releaseColor(def);
    if (this.usesColor(def)) {
      this.setBackgroundColor(def);
    }
  }

  private setColorAsUsed(color: string) {
    const colorInx = this.holeAvailColors.colors.findIndex(
      (x) => x.value === color
    );
    if (colorInx > -1 && colorInx < this.holeAvailColors.colors.length - 1) {
      this.holeAvailColors.colors[colorInx].used = true;
    }
  }

  private releaseColor(def: HoleSearchDef) {
    const colorInx = this.holeAvailColors.colors.findIndex(
      (x) => x.value === def.color
    );
    if (colorInx !== -1 && colorInx < this.holeAvailColors.colors.length - 1) {
      this.holeAvailColors.colors[colorInx].used = false;
      def.color = '';
    }
  }

  private setBackgroundColor(def: HoleSearchDef) {
    def.color = this.freeColor;
    this.setColorAsUsed(def.color);
  }

  private usesColor(def: HoleSearchDef): boolean {
    return this.holeInfoColumns.find((x) => x.value === def.attributeName)
      .UseColor;
  }

  private async showInfoModal(message: string) {
    const result: ModalResult = await this.modalDialogService.show(
      message,
      ModalType.Ok
    );
    if (result === this.translate.instant(ModalResult.Ok)) {
      return;
    }
  }
  //#endregion
}
