import { CommandType } from './command-type';

export class InputCommand {

  public handler: () => void;
  public type: CommandType;
  public data: any;

  constructor(commandType: CommandType) {
    this.type = commandType;
  }

}