<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'AUTO_PRINT.SETTINGS.TITLE' | translate }}</h4>
</div>

<div
  class="modal-body px-5 py-4 pb-5 d-flex flex-column"
  *ngIf="settings$ | async as settings"
>
  <div class="form-group">
    <label for="interval">{{ 'AUTO_PRINT.SETTINGS.INTERVAL' | translate }}</label>
      <select class="form-control font-bold select-time-drop-down" [(ngModel)]="settings.interval">
        <option *ngFor="let interval of intervals" [ngValue]="interval"  [ngClass]="{'font-bold':interval == settings.interval}">
          {{interval}}
        </option>
      </select>
  </div>

  <div class="form-group">
    <label for="dataSource">{{ 'MONA_DATABASE' | translate }}</label>
    <app-select-datasource
      [attr.aria-placeholder]="'SELECT_MONA_DATABASE' | translate"
      (selectDataSource)="selectDataSource($event)"
      [autoPrint]="true"
      class="mb-4 font-bold"
      #dataSource
    ></app-select-datasource>
  </div>

  <mat-form-field>
    <mat-chip-list aria-label="Workplaces" #workplaceList>
      <mat-chip
        *ngFor="let workplace of sortedWorkplaces"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="removeWorkplace(workplace)"
      >
        {{ workplace }}
        <div matChipRemove *ngIf="removable">
          <mat-icon>close</mat-icon>
        </div>
      </mat-chip>
      <input
        [matChipInputFor]="workplaceList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addWorkplace($event)"
        [placeholder]="'AUTO_PRINT.SETTINGS.ENTER_WORKSPACE' | translate"
        autocomplete="off"
      />
    </mat-chip-list>
  </mat-form-field>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-size-white"
    [disabled]="!settings$.value || !settings$.value.isValid"
    (click)="close()"
  >
    {{ 'CLOSE' | translate }}
  </button>
</div>
