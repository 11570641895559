import { Settings } from './settings';
import { VariantCodesFilterDefinition } from '../models/selected-variantcodes-def';

export class VariantCodeSearchSettings extends Settings {
  public collapsed = false;
  public isFilterActive = false;
  public variantCodeSearchDefinitions: VariantCodesFilterDefinition[] = [];

  public load(): void {
    const storedSettings = <VariantCodeSearchSettings>super.load();
    this.variantCodeSearchDefinitions = super.isStored(
      'variantCodeSearchDefinitions',
      storedSettings
    )
      ? storedSettings.variantCodeSearchDefinitions
      : this.variantCodeSearchDefinitions;
    this.isFilterActive = super.isStored('isFilterActive', storedSettings)
      ? storedSettings.isFilterActive
      : this.isFilterActive;
  }

  public save(): void {
    super.save(this);
  }
}
