import { Vector3, Color4, Scene, Mesh } from 'babylonjs';

export class GridController {

  protected scene: Scene;

  protected gridSquareSize = 500;
  protected gridLineLength = 25000;
  protected gridLineWidth = 2;
  protected gridLineColor = new Color4(0.8, 0.8, 0.8);

  protected origoLineLength = 1000000;
  protected origoLineWidth = 5;
  protected origoLineColorX = new Color4(1, 0, 0);
  protected origoLineColorY = new Color4(0, 1, 0);
  protected origoLineColorZ = new Color4(0, 0, 1);

  constructor(scene: Scene, gridSquareSize?: number) {
    this.scene = scene;
    this.gridSquareSize = gridSquareSize || 500;
  }

  public renderOrigo(): void {
    const origoX = Mesh.CreateLines('lines', [new Vector3(-this.origoLineLength, 0, -1), new Vector3(this.origoLineLength, 0, -1)], this.scene);
    origoX.edgesColor = this.origoLineColorX;
    origoX.edgesWidth = this.origoLineWidth;
    origoX.enableEdgesRendering();

    const origoY = Mesh.CreateLines('lines', [new Vector3(0, -this.origoLineLength, -1), new Vector3(0, this.origoLineLength, -1)], this.scene);
    origoY.edgesColor = this.origoLineColorY;
    origoY.edgesWidth = this.origoLineWidth;
    origoY.enableEdgesRendering();

    const origoZ = Mesh.CreateLines('lines', [new Vector3(0, 0, -this.origoLineLength), new Vector3(0, 0, this.origoLineLength)], this.scene);
    origoZ.edgesColor = this.origoLineColorZ;
    origoZ.edgesWidth = this.origoLineWidth;
    origoZ.enableEdgesRendering();
  }

  public renderGrid(): void {

    const y = 0;

    for (let z = -this.gridLineLength; z < this.gridLineLength; z += this.gridSquareSize) {
      const lineX = Mesh.CreateLines('GridlineX', [new Vector3(-this.gridLineLength, y, z), new Vector3(this.gridLineLength, y, z)], this.scene);
      lineX.edgesColor = new Color4(0.8, 0.8, 0.8);
      lineX.edgesWidth = this.gridLineWidth;
      lineX.enableEdgesRendering();
    }

    for (let x = -this.gridLineLength; x < this.gridLineLength; x += this.gridSquareSize) {
      const lineZ = Mesh.CreateLines('GridlineZ', [new Vector3(x, y, -this.gridLineLength), new Vector3(x, y, this.gridLineLength)], this.scene);
      lineZ.edgesColor = new Color4(0.8, 0.8, 0.8);
      lineZ.edgesWidth = this.gridLineWidth;
      lineZ.enableEdgesRendering();
    }
  }
}