import { KeyValuePair } from './key-value-pair.model';

export class SupplOper {

  public values: KeyValuePair[] = [
    { key: '0', value: 'None' },
    { key: '1', value: 'Reamed together' },
    { key: '2', value: 'C Sunk' },
    { key: '3', value: 'Reamed' },
    { key: '4', value: 'Riveted' },
    { key: '5', value: 'Marked' }];


  public findKeyByValue(value1: string): string {
    for (const item of this.values) {
      if (value1.includes(item.value)) {
        return item.key.toString();
      }
    }
    return '';
  }




}