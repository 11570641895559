<div class="d-flex flex-column flex-grow-1 flex-shrink-1">
  <canvas oncontextmenu="return false;" #canvas></canvas>
</div>

<div class="minimapInfo flex-grow-0 flex-shrink-0" *ngIf="showMinimapInfo">
  <div class="wrapper" *ngIf="hfInfo$ | async as hfInfo">
    <table>
      <tbody>
        <tr>
          <th>Id</th>
          <td>{{ hfInfo.IPOId }}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{{ hfInfo.TypeText }}</td>
        </tr>
        <tr>
          <th>CU</th>
          <td>{{ hfInfo.CUSName }}</td>
        </tr>
        <tr>
          <th>ChNo</th>
          <td>{{ hfInfo.ChassisNumber }}</td>
        </tr>
        <tr>
          <th>IPO</th>
          <td>{{ hfInfo.IPOId }}</td>
        </tr>
        <tr>
          <th>Ver</th>
          <td>{{ hfInfo.Version }}</td>
        </tr>
        <tr>
          <th>Cons</th>
          <td>
            {{ hfInfo.BatchNumber }}-{{ hfInfo.SequenceNumber }}
            {{ hfInfo.Origin }}
          </td>
        </tr>
        <tr>
          <th>BD</th>
          <td>{{ hfInfo.BDObj }}</td>
        </tr>
        <tr>
          <th>Len</th>
          <td>{{ sidememberWidth }}</td>
        </tr>
        <tr>
          <th>Supp</th>
          <td>{{ hfInfo.SupplierName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
