import {
  AbstractMesh,
  Color4,
  Mesh,
  Octree,
  Material,
  StandardMaterial
} from 'babylonjs';
import { RenderEngine } from '../render-engine';
import { Hole } from '../../shared/models/grapeservice/hole';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';
import { PresentationSettings } from '../core/presentation-settings';

export class BaseRenderer {
  public mesh: Mesh;
  public upperTextNode: any;
  public lowerTextNode: any;

  protected readonly ASSEMBLY_LINE_POSITION_Y = 385;
  protected readonly ASSEMBLY_LINE_POSITION_Z = 1000;
  protected octree: Octree<AbstractMesh>;
  protected outlineColor: Color4;
  protected outlineWidth = 5;

  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: Hole | SidememberInfo,
    protected sidememberData?: SidememberInfo,
    protected parent?: AbstractMesh,
    protected secondaryHole?: Hole,
    protected settings?: PresentationSettings,
  ) {
    this.engine = engine;
    this.outlineColor = Color4.FromColor3(this.materials.outline.emissiveColor);

    this.createMesh();
    this.createOutline();
    this.setInitialMaterial();
    this.optimize();
  }

  public dispose(): void {
    if (this.mesh) {
      this.mesh.dispose();
    }
  }

  public setMaterial(material: StandardMaterial): void {
    if (!this.mesh) {
      return;
    }

    this.mesh.material = material;
  }

  protected createMesh(): void {}

  protected setInitialMaterial(): void {}

  protected createOutline(): void {
    if (!this.mesh) {
      return;
    }

    this.mesh.edgesWidth = this.outlineWidth;
    this.mesh.edgesColor = this.outlineColor;

    this.mesh.enableEdgesRendering();
  }

  protected optimize(): void {
    this.octree = this.engine.scene.createOrUpdateSelectionOctree();

    if (!this.mesh) {
      return;
    }

    this.mesh.alwaysSelectAsActiveMesh = true;
    this.mesh.cullingStrategy =
      AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
  }
}
