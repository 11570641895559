<div class="col-md-8 d-flex flex-column flex-grow-1 m-0 p-0" [ngClass]="{
    'col-md-12': settings.indexlist.fullSize || isOpenSidememberError(),
    'col-md-8': !settings.indexlist.fullSize
  }">
  <div class="card card-default d-flex flex-column flex-grow-1 m-0 p-0">
    <div class="card-header header-search no-gutter flex-grow-0 flex-shrink-0">
      <div class="row">
        <div class="col-md-6 no-gutter d-flex flex-row align-items-center">
          <div [hidden]="inDefineFilterMode" class="database-title">
            <h5>{{ 'MONA_DATABASE' | translate}}</h5>
          </div>
          <div [hidden]="inDefineFilterMode" class="col-md-5">
            <app-select-datasource (selectDataSource)="selectDataSource($event)" #dataSource></app-select-datasource>
          </div>
          <div *ngIf="inDefineFilterMode" class="sdds-headline-05 search-defination-title">
            {{ 'SIDEMEMBER_LIST.VAR_CODE_SEARCH_DEF' | translate}}
          </div>
        </div>
        <div *ngIf="!inDefineFilterMode"   class="col-md-6 headerbuttonsalign" [hidden]="isOpenSidememberError()"
          [attr.disabled]="!isDataSourceSelected() ? 'disabled' : null">
          <button [disabled]="isSidememberSelectionNotExist || isTotalPictureDisabled"  mat-icon-button
            title="{{ 'PRINT' | translate}}" (click)="printTotalPicture()" id="TotalPictureLink">
            <mat-icon>print</mat-icon>
          </button>

          <button [disabled]="isSidememberSelectionNotExist"   mat-icon-button (click)="exportToExcel()"
            id="ExportToExcelLink" title="{{ 'EXPORT_EXCEL' | translate}}">
            <mat-icon>archive</mat-icon>
          </button>

          <button mat-icon-button   id="RefreshSidememberListDataLink" title="{{ 'REFRESH_SHORTCUT' | translate}}"
            (click)="refreshList()">
            <mat-icon>refresh</mat-icon>
          </button>

          <div class="linkSeparator-header">&nbsp;</div>

          <button   mat-icon-button (click)="showFilterDefinitions()" id="variantCodeSearchFilter"
            title="{{ 'SIDEMEMBER_LIST.VAR_CODE_SEARCH_FILTER' | translate}}">
            <mat-icon>filter_alt</mat-icon>
          </button>

          <button   mat-icon-button (click)="setFilterState()" id="toggleFilterDef" [title]="filterToggleTitle | async"
            [disabled]="!isFilterEnabled ">
            <mat-icon *ngIf="searchIsFilterActive && isFilterEnabled ">check_box</mat-icon>
            <mat-icon *ngIf="!searchIsFilterActive || !isFilterEnabled ">check_box_outline_blank</mat-icon>
          </button>

          <div class="linkSeparator-header">&nbsp;</div>

          <button mat-icon-button id="selectListExpandLink"   (click)="setFullSize(this.sidememberSelection[0]?.ipoId)"
            title="{{ (this.settings.indexlist.fullSize ? 'SHOW_RIGHT_PANES' : 'HIDE_RIGHT_PANES') | translate }}">
            <mat-icon [ngClass]="{
              'mat-icon-flip-horizontal': !settings.indexlist.fullSize,
              '': this.settings.variantCodes.hidden
            }">menu_open</mat-icon>
          </button>
        </div>

        <div *ngIf="inDefineFilterMode" class="col-md-6 headerbuttonsalign">
          <button mat-icon-button   (click)="save()" id="saveFilterDef" title="{{ 'SAVE' | translate }}">
            <mat-icon>save</mat-icon>
          </button>
          <div class="linkSeparator-header">&nbsp;</div>
          <button mat-icon-button  (click)="validateFilterDefinitionsClosing()" id="closeFilterDef"
            title="{{ 'CLOSE' | translate }}">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <div *ngIf="isOpenSidememberError()" class="card-body no-gutter overflow-auto">
        <div class="errmessage">
          {{ 'SIDEMEMBER_LIST.ERROR_LOADING_LIST' | translate }}
        </div>
      </div>

      <div *ngIf="(grapeDataService.errorOpenSidemember | async)" class="card-body no-gutter overflow-auto">
        <div class="errmessage">
          {{ 'SIDEMEMBER_LIST.ERROR_OPENING' | translate }}
        </div>
      </div>

      <div [hidden]="inDefineFilterMode || isOpenSidememberError() ||(grapeDataService.errorOpenSidemember | async) "
        [class.d-flex]="!(inDefineFilterMode || isOpenSidememberError() ||(grapeDataService.errorOpenSidemember | async))"
        class="card-body flex-column flex-grow-1 m-0 p-0" #panelBody>
        <div class="loader" *ngIf="isLoading()">
          <div>
            <div class="spinner"></div>
            <div class="message">
              {{ 'SIDEMEMBER_LIST.LOADING' | translate }}
            </div>
          </div>
        </div>

        <!-- Table Header -->
        <table class="filterbar table flex-grow-0">
          <tr class="rowheader filterbar">
            <td class="filterbar-nav-prevnext p-0">
              <div class="d-inline-flex">
                <button mat-icon-button id="gotoFirstPage" title="{{ 'PAGINATION.FIRST_PAGE' | translate }}"
                  (click)="gotoFirstPage()" [disabled]="pagerPage === 1">
                  <mat-icon>first_page</mat-icon>
                </button>
                <button mat-icon-button id="gotoPrevPage" title="{{ 'PAGINATION.PREVIOUS_PAGE' | translate }}"
                  (click)="goPrevPage()" [disabled]="pagerPage === 1">
                  <mat-icon>chevron_left</mat-icon>
                </button>
              </div>
            </td>

            <td class="filterbar-infotext">
              {{pagerText}}
            </td>

            <td class="filterbar-nav-prevnext p-0">
              <div class="d-inline-flex">
                <button mat-icon-button id="gotoNextPage" title="{{ 'PAGINATION.NEXT_PAGE' | translate }}"
                  (click)="goNextPage()" [disabled]="pagerPage === numPages || numPages === 0">
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <button mat-icon-button id="gotoLastPage" title="{{ 'PAGINATION.LAST_PAGE' | translate }}"
                  (click)="gotoLastPage()" [disabled]="pagerPage === numPages || numPages === 0">
                  <mat-icon>last_page</mat-icon>
                </button>
              </div>
            </td>
          </tr>
        </table>

        <!-- Table Data -->
        <div class="pane-hScroll sidememberlistpane flex-grow-1 flex-shrink-1" (scroll)="onHorizontalScroll()" #hScroll>
          <table class="table table-hover table-sm table-header">
            <thead>
              <tr>
                <th #indexlistHeader *ngFor="let column of indexlistColumns" (click)="sortBy(column.value)"
                  class="sorting none {{ column.class }}" [ngClass]="{
                    asc: activeSort(column.value, false),
                    desc: activeSort(column.value, true)
                  }">
                  {{ column.name | async }}
                </th>
              </tr>
            </thead>
          </table>

          <div class="pane-vScroll" #sidememberList>
            <table class="table table-borderless table-hover table-sm">
              <tbody>
                <tr *ngFor="
                    let entry of indexlistPageEntries;
                    let i = index;
                    trackBy: trackByEntryIndex
                  " [contextMenu]="sidememberContextMenu" [contextMenuSubject]="entry"
                  [ngClass]="{ 'selected selected-row': selected(entry) }" (click)="indexlistClick($event, entry, i)"
                  (contextmenu)="indexlistClick($event, entry, i, true)" (dblclick)="openSidemember()">
                  <td class="{{ indexlistColumns[0].class }}">
                    {{ entry.chassisNumber }}
                  </td>
                  <td class="{{ indexlistColumns[1].class }}">
                    {{ entry.ipoId }}
                  </td>
                  <td class="{{ indexlistColumns[2].class }}">
                    {{ entry.version }}
                  </td>
                  <td class="{{ indexlistColumns[3].class }}">
                    {{ entry.ciId }}
                  </td>
                  <td class="{{ indexlistColumns[4].class }}">
                    {{ entry.hfStatus }}
                  </td>
                  <td class="{{ indexlistColumns[5].class }} cuNameAlign">
                    {{ entry.cusName }}
                  </td>
                  <td class="{{ indexlistColumns[6].class }}">
                    {{ entry.assemblyDate | LocaleDate }}
                  </td>
                  <td class="{{ indexlistColumns[7].class }}">
                    {{ entry.prodIndividual }}
                  </td>
                  <td class="{{ indexlistColumns[8].class }}">
                    {{ entry.syncTime | LocaleDateShort }}
                  </td>
                  <td  matTooltip="{{entry.supplierName}}" class="{{ indexlistColumns[9].class }}">
                    {{ entry.supplierName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="pagination-center flex-grow-0">
          <span class="typetext">
            <ng-container *ngIf="gotSingleSelection && !isLoading()">
              <strong [hidden]="isLoadingTypeText()">{{ this.typeText }}</strong>
            </ng-container>
          </span>
        </div>

        <div class="d-flex flex-column flex-grow-0 preview" [hidden]="isLoading()">
          <div class="loader" *ngIf="(grapeDataService.errorPreview | async)">
            <div class="error"> {{grapeDataService.grapeData.Data.loadError?.error}}</div>
          </div>

          <div class="loader" *ngIf="isLoadingPreview">
            <div>
              <div class="dots">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>
          <app-sidemember-preview *ngIf="showPreview" #preview>
          </app-sidemember-preview>
        </div>


        <context-menu #sidememberContextMenu>
          <ng-template (execute)="openSidemember()" contextMenuItem *ngIf="this.sidememberSelection.length <= 2">
            <span [class.disabled]="isloadingPreviewErrorOccured"
              *ngIf=" this.sidememberSelection.length !==2; else showCompare">
              {{ 'SIDEMEMBER_LIST.OPEN' | translate }}
            </span>
            <ng-template #showCompare>
              <span [class.disabled]="isloadingPreviewErrorOccured">
                {{ 'SIDEMEMBER_LIST.COMPARE' | translate }}
              </span>
            </ng-template>
          </ng-template>
          <ng-template (execute)="exportSelectedSidemember()" contextMenuItem>
            <span [class.disabled]="isloadingPreviewErrorOccured">
              {{ 'SIDEMEMBER_LIST.EXPORT' | translate }}
              <span *ngIf="this.sidememberSelection.length > 1">s</span>
            </span>
          </ng-template>
        </context-menu>
      </div>

      <div *ngIf="inDefineFilterMode" class="card-body m-0 p-0" #panelBody>
        <div class="pane-hScroll">
          <app-sidemember-list-variantcode-search class="m-3 h-0" #variantCodeSearchDefs>
          </app-sidemember-list-variantcode-search>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="lists" class="col-md-4 d-flex flex-column pr-0"
  [hidden]="settings.indexlist.fullSize || isOpenSidememberError()">
  <div class="loader" *ngIf="isLoading()"></div>
  <app-sidemember-list-filter [showData]="gotSingleSelection" #sidememberListFilter [ngClass]="{
      collapsed: settings?.indexlist?.collapsedFilter
    }" class="mb-4" [attr.disabled]="!isDataSourceSelected() ? 'disabled' : null"></app-sidemember-list-filter>
  <variant-codes [showData]="gotSingleSelection" [visibility]="!settings.indexlist.fullSize" [ngClass]="{
      collapsed: settings?.variantCodes?.collapsed,
      expanded: !settings?.variantCodes?.collapsed
    }" [attr.disabled]="!isDataSourceSelected() ? 'disabled' : null"></variant-codes>
</div>