import { Settings } from './settings';
import { SidememberListSelectionModel } from '../models/sidemember-list-selection.model';
export class IndexListSettings extends Settings {
  public sortColumn = 'IPOId';
  public sortDesc = true;
  public dataSource: string = null;
  public hfStatus: any[] = ['5'];
  public chassisSearchNumber = '';
  public lastOpenedFiles: SidememberListSelectionModel[] = [];
  public indexlistEntriesDateTime: Date = new Date();
  public fullSize = false;
  public collapsedFilter = false;

  public load(): void {
    const storedSettings = <IndexListSettings>super.load();

    this.dataSource = super.isStored('dataSource', storedSettings)
      ? storedSettings.dataSource
      : this.dataSource;
    this.sortColumn = super.isStored('sortColumn', storedSettings)
      ? storedSettings.sortColumn
      : this.sortColumn;
    this.sortDesc = super.isStored('sortDesc', storedSettings)
      ? storedSettings.sortDesc
      : this.sortDesc;
    this.chassisSearchNumber = super.isStored(
      'chassisSearchNumber',
      storedSettings
    )
      ? storedSettings.chassisSearchNumber
      : this.chassisSearchNumber;
    this.indexlistEntriesDateTime = super.isStored(
      'indexlistEntriesDateTime',
      storedSettings
    )
      ? storedSettings.indexlistEntriesDateTime
      : this.indexlistEntriesDateTime;
    this.fullSize = super.isStored('fullSize', storedSettings)
      ? storedSettings.fullSize
      : this.fullSize;
    this.collapsedFilter = super.isStored('collapsedFilter', storedSettings)
      ? storedSettings.collapsedFilter
      : this.collapsedFilter;

    this.lastOpenedFiles = super.isStored('lastOpenedFiles', storedSettings)
      ? storedSettings.lastOpenedFiles
      : this.lastOpenedFiles;

    this.hfStatus = super.isStored('hfStatus', storedSettings)
      ? storedSettings.hfStatus
      : this.hfStatus;

    this.onLoad.emit(this.name);
  }

  public save(): void {
    super.save(this);
    this.onSave.emit(this.name);
  }
}
