import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { Vector3, PickingInfo } from 'babylonjs';
import { Vector3StringPipe } from '../shared/pipes/vector3-string.pipe';
import { HoleShape } from '../shared/models/hole-enum';
import { Hole } from '../shared/models/grapeservice/hole';
import { WorldTransformation } from '../rendering/core/world-transformation';
import { GrapeData } from '../shared/models/grapeservice/grape.data';
import { SidememberSide } from '../shared/models/sidemember-enum';

@Component({
  selector: 'app-sidemember-canvas-info',
  templateUrl: './sidemember-canvas-info.component.html',
  styleUrls: ['./sidemember-canvas-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidememberCanvasInfoComponent implements OnInit {
  @Input() pointerLoc: Vector3;
  @Input() pointerInfo: PickingInfo;
  @Input() data: GrapeData;
  @Input() selectedHole: Hole;

  constructor() { }

  ngOnInit() { }

  get holeCoordinates(): string {
    if (!this.selectedHole) {
      return '';
    }

    const formatter = new Vector3StringPipe();
    return formatter.transform(this.selectedHoleCoordinates());
  }

  get locationCoordinates(): string {
    if (!this.pointerInfo || !this.data) {
      return '';
    }

    const formatter = new Vector3StringPipe();
    const hoveringOverSidemember = this.pointerInfo.pickedMesh !== null;

    if (!hoveringOverSidemember) {
      return '';
    }

    return formatter.transform(this.pointerCoordinates(), true);
  }

  public displayDiameter(shape: HoleShape): boolean {
    return shape === HoleShape.Round;
  }

  private pointerCoordinates(): Vector3 {
    return WorldTransformation.worldToSidememberPosition(
      this.pointerLoc,
      this.data.Data.HFInfo.FrameSide as SidememberSide,
      this.data.Data.Height
    );
  }

  private selectedHoleCoordinates(): Vector3 {
    const holeCoordinates = new Vector3(
      this.selectedHole.Hole_X,
      this.selectedHole.Hole_Y,
      this.selectedHole.Hole_Z
    );
    return holeCoordinates;
  }
}
