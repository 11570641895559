import { InputCommand } from './input-command';
import { CommandType } from './command-type';

export class PointerCommand extends InputCommand {

  public data: PointerEvent;

  constructor(type: CommandType, event: PointerEvent) {
    super(type);
    this.data = event;
  }

}