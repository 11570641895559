import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';

@Component({
  selector: 'variant-codes',
  templateUrl: './variant-codes.component.html',
  styleUrls: ['./variant-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantCodesComponent implements OnInit {
  @Input('showData') showData: boolean;
  @Input('visibility') set visibility(visible: boolean) {
    this.showData = visible;
  }

  public showVariantCodesFilter: boolean;
  public visible: boolean;

  constructor(public settings: UserSettingsService) {
    this.loadSettings();
  }

  ngOnInit() {}

  public toggleCollapse(): void {
    this.settings.variantCodes.collapsed = !this.settings.variantCodes
      .collapsed;
    this.settings.variantCodes.save();
  }

  public showFilter(): void {
    this.showVariantCodesFilter = true;
  }

  public showList(): void {
    this.showVariantCodesFilter = false;
  }

  private loadSettings(): void {
    this.settings.variantCodes.load();
  }
}
