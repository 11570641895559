import { IndexlistEntry } from './indexlist-entry';

export class SidememberListSelectionModel {
  public dataSource: string;
  public entry: IndexlistEntry;
  public persistError: boolean;

  constructor(dataSource: string, entry: IndexlistEntry) {
    this.dataSource = dataSource;
    this.entry = entry;
  }
}
