export enum CommandType {
  POINTER_LEFT_DOWN,
  POINTER_LEFT_UP,
  POINTER_RIGHT_DOWN,
  POINTER_RIGHT_UP,
  POINTER_CTRL_LEFT,
  POINTER_MOVE,
  CAMERA_MOVE_UP,
  CAMERA_MOVE_DOWN,
  CAMERA_MOVE_LEFT,
  CAMERA_MOVE_RIGHT,
  CAMERA_ZOOM_IN,
  CAMERA_ZOOM_OUT,
  FOCUS_OUT,
  SCROLL_WHEEL_PRESSED,
  SCROLL_WHEEL_RELEASED
}