export enum Keyboard {
  UP = 38,
  DOWN = 40,
  LEFT = 37,
  RIGHT = 39,
  W = 87,
  S = 83,
  A = 65,
  D = 68,
  PLUS = 107,
  MINUS = 109,
  HOME = 36,
  END = 35,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  ENTER = 13
}
