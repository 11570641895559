export class SearchCriteriaColor {
  public colors = [
    { name: 'Red', value: '#FF0000', used: false },
    { name: 'Green', value: '#00FF00', used: false },
    { name: 'Gray', value: '#C0C0C0', used: false },
    { name: 'DarkPurple', value: '#800080', used: false },
    { name: 'DarkBlue', value: '#000080', used: false },
    { name: 'Yellow', value: '#FFFF00', used: false },
    { name: 'DarkRed', value: '#800000', used: false },
    { name: 'DarkGreen', value: '#008000', used: false },
    { name: 'Blue', value: '#0000FF', used: false },
    { name: 'Purple', value: '#FF00FF', used: false },
    { name: 'Black', value: '#000000', used: false },
    { name: 'DarkGray', value: '#A7A7A7', used: false },
    { name: 'Orange', value: '#E18C00', used: false },
    { name: 'Olive', value: '#808000', used: false },
    { name: 'DarkCyan', value: '#008080', used: false },
    { name: 'Cyan', value: '#00FFFF', used: false },
    { name: 'Gold:', value: '#E1D3B7', used: true } // used: true must never change, used when all other colors are used
  ];
}

export enum CriteriaColors {
  Red = '#FF0000',
  Green = '#00FF00',
  Gray = '#C0C0C0',
  DarkPurple = '#800080',
  DarkBlue = '#000080',
  Yellow = '#FFFF00',
  DarkRed = '#800000',
  DarkGreen = '#008000',
  Blue = '#0000FF',
  Purple = '#FF00FF',
  Black = '#000000',
  DarkGray = '#A7A7A7',
  Orange = '#E18C00',
  Olive = '#808000',
  DarkCyan = '#008080',
  Cyan = '#00FFFF',
  Gold = '#E1D3B7',
  White = '#ffffff',
  Pink = '#ff1291'
}
