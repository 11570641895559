<div class="alert alert-warning">
  <mat-icon>warning</mat-icon> {{ 'SIDEMEMBER_LIST.VAR_CODE_SEARCH.WARNING_SERVER_SIDE' | translate }}
</div>

<app-variant-codes-definition
  class="col-12 col-md-10 m-0 p-0 col-lg-8"
  [variantClasses]="variantClasses$ | async"
  [formControl]="filterDefinitionList"
  #filterList
></app-variant-codes-definition>

<!-- <div class="defpanemargin">
    <div style="width: 100%">
        <strong style="color:chocolate; border-bottom: 1px solid #CECECE; padding: 5px;">
            Please note: This filter is executed on the server side. Depending on the number of criteria this could be time consuming​
        </strong>
    </div>
    <div style="margin-top: 8px;">
        <table class="deftable" *ngFor="let def of searchDefs">
            <tr class="defsrowheigth">
                <td class="classcolumn deftd">
                    <select id="selectsearchcriteriaclass{{def.index}}" class="defField" [(ngModel)]="def.VarClass"
                        (ngModelChange)="onCodeClassChange(def)">
                        <option value="AC" selected>AC</option>
                        <option value="CO">CO</option>
                        <option value="FFU">FFU</option>
                        <option value="FPC">FPC</option>
                        <option value="MCC">MCC</option>
                    </select>
                </td>

                <td class="varfamcolumn deftd">
                    <input placeholder="Family" id="selectsearchcriteriafamily{{def.index}}"
                        [(ngModel)]="def.VarFam" (ngModelChange)="onChange()">
                </td>

                <td class="varoptcolumn deftd">
                    <input [disabled]="isNotAcOrFpc(def)" placeholder="{{isNotAcOrFpc(def) ? '' : 'Option'}}"
                        id="selectsearchcriteriaclass{{def.index}}" [(ngModel)]="def.VarOpt"
                        (ngModelChange)="onChange()">
                </td>

                <td class="trashcolumn">
                    <a href="javascript:void(0);" (click)="onDeleteDef(def)" id="deleteFilterDef{{def.index}}"
                        title="Delete this filter definition">
                        <span class="header-icon action-icon glyphicon glyphicon-trash"></span>
                    </a>
                </td>
            </tr>
        </table>
    </div>

    <div class="col-md-12" style="margin-top: 8px;">
        <a href="javascript:void(0);" (click)="onAddDefinitions()" id="addFilterDef"
            title="Add search filter definition">
            <span class="header-icon action-icon glyphicon glyphicon-plus"></span> Add filter definition
        </a>
    </div>
</div> -->
