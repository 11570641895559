<div class="content">
  <div class="size404">
    <div class="text-center">
      <span class="align-middle"><mat-icon>block</mat-icon></span>
      <span *ngIf="!granted" class="align-middle">401</span>
      <span  class="align-middle"></span>
    </div>
  </div>
  <div *ngIf="!granted">
    {{ 'access.UNAUTHORIZED_USER' | translate }}
  </div>
  <ng-template >
    {{ '404.PAGE_NOT_FOUND' | translate }}
    <div>
      <button
        type="button"
        *ngIf="!granted"
        (click)="goHomePage()"
        class="btn btn-sm btn-secondary ml-1 flex-grow-1 back-button"
      >
        <div class="d-flex">
          <mat-icon>arrow_back</mat-icon>
          {{ '404.GO_BACK_HOME' | translate }}
        </div>
      </button>
    </div>
  </ng-template>
</div>
