import { Settings } from './settings';
import { HoleDistanceOption } from '../models/hole-distance-option';

export class HoleDistanceSettings extends Settings {
  public holeDistanceOption: HoleDistanceOption = HoleDistanceOption.centerToCenter;

  public load(): void {
    const storedSettings = <HoleDistanceSettings>super.load();
    this.holeDistanceOption = super.isStored('holeDistanceOption', storedSettings) ? storedSettings.holeDistanceOption : this.holeDistanceOption;
  }

  public save(): void {
    super.save(this);
  }
}
