import { Pipe, PipeTransform } from '@angular/core';
import { Vector3 } from 'babylonjs';

@Pipe({
  name: 'vector3string'
})
export class Vector3StringPipe implements PipeTransform {
  transform(val: Vector3, round?: boolean): string {
    if (val !== undefined && val !== null) {
      if (round) {
        return `X: ${Math.round(val.x).toFixed(1)} Y: ${Math.round(
          val.y
        ).toFixed(1)} Z: ${Math.round(val.z).toFixed(1)}`;
      }
      return `X: ${val.x.toFixed(1)} Y: ${val.y.toFixed(1)} Z: ${val.z.toFixed(
        1
      )}`;
    }
    return '';
  }
}
