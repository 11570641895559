// tslint:disable-next-line: import-name
import ResizeObserver from 'resize-observer-polyfill';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  GrapeData,
  GrapeDataModel,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { Region } from '../shared/models/rendering/region';
import {
  SidememberDrawing,
  SidememberDrawingOptions,
  HoleDrawingOptions,
} from '../rendering/sidemember-drawing';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { Vector3 } from 'babylonjs';
import { UIEventService } from '../shared/services/ui-event.service';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { SidememberSide } from '../shared/models/sidemember-enum';
import { SidememberInfo } from '../shared/models/grapeservice/sidemember-info';
export interface MiniMapMouseEvent {
  target: any;
  clientX: number;
  clientY: number;
}

@Component({
  selector: 'app-sidemember-minimap',
  templateUrl: './sidemember-minimap.component.html',
  styleUrls: ['./sidemember-minimap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidememberMinimapComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'd-flex flex-row';

  @ViewChild('canvas') canvasRef: ElementRef;

  @Output('pan') pan = new EventEmitter<Vector3>();
  @Output('zoom') zoom = new EventEmitter<boolean>();

  @Input('viewport') set setViewport(region: Region) {
    if (!region || !this.drawing) return;

    const top = SidememberDrawing.vectorToPoint(region.rightTop, this.drawing)
      .y;
    const right = SidememberDrawing.vectorToPoint(region.rightTop, this.drawing)
      .x;
    const left = SidememberDrawing.vectorToPoint(region.leftTop, this.drawing)
      .x;
    const bottom = SidememberDrawing.vectorToPoint(
      region.leftBottom,
      this.drawing
    ).y;

    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);

    this.viewport = {
      top,
      bottom,
      left,
      right,
      width,
      height,
    };

    this.update();
  }

  @Input() showMinimapInfo: boolean;

  @Input() set selectedHoleOptions(value: HoleDrawingOptions) {
    if (!this.drawingOptions) return;

    this.drawingOptions.holeOptions = value;
  }

  get canvasRect(): ClientRect | DOMRect {
    if (!this.canvas) return;
    return this.canvas.getBoundingClientRect();
  }

  get scale(): number {
    if (!this.canvas || !this.canvasRect) return;
    return this.canvasRect.width / this.canvas.width;
  }

  public hfInfo$: BehaviorSubject<SidememberInfo> = new BehaviorSubject(null);

  private drawingOptions: SidememberDrawingOptions = {
    scalingRatio: 0.5,
    lineWidth: 4.5,
    lineColor: '#000000',
    drawUpsideDown: false,
    clipFromBegX: true,
    includeCutouts: true,
    colorRoundHole: true,
    drawPPTRMarks: true,
    holeOptions: {
      showFilteredHoles: false,
      showRelatedHoles: false,
      showSelectedHole: false,
      showHoleDuplicates: true,
    },
  };

  private canvas: HTMLCanvasElement;
  private data: GrapeDataModel;
  private drawing: SidememberDrawing;
  private viewport: any;
  private isPanning: boolean;
  private flipSidemember$: Observable<boolean>;
  private toggleRedraw$: Observable<GrapeData>;
  private subscriptions: Subscription[] = [];

  constructor(
    public settings: UserSettingsService,
    private uiEventService: UIEventService,
    private grapeDataService: GrapeDataService,
    private host: ElementRef,
    private element: ElementRef
  ) {
    this.settings.sidememberPresentation.load();

    this.flipSidemember$ = this.uiEventService.sidememberFlipStatus.pipe(
      filter(
        (isFlipped: boolean) =>
          this.drawing !== undefined && this.drawing !== null
      )
    );

    this.toggleRedraw$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          data.Data !== undefined &&
          data.Data !== null &&
          (data.ChangedObject === GrapeObjectType.Minimap ||
            data.ChangedObject === GrapeObjectType.FilteredHoles ||
            data.ChangedObject === GrapeObjectType.HoleDuplicates ||
            data.ChangedObject === GrapeObjectType.SelectedDrawingHole ||
            data.ChangedObject === GrapeObjectType.SelectedHole ||
            data.ChangedObject ===
              GrapeObjectType.SelectedSecondaryDrawingHole ||
            data.ChangedObject === GrapeObjectType.ResetSecondaryDrawingHoles)
        );
      })
    );

    this.subscribeForData();
  }

  ngOnInit() {
    this.drawingOptions.drawUpsideDown = this.settings.sidememberPresentation.showUpsideDown;
  }

  ngAfterViewInit() {
    this.canvas = this.canvasRef.nativeElement;

    this.observeResize();

    this.canvas.onwheel = (event: WheelEvent) => this.mouseWheel(event);

    this.canvas.onpointerdown = (event: PointerEvent) =>
      this.pointerDown(event);

    this.canvas.onpointermove = (event: PointerEvent) =>
      this.pointerMove(event);

    this.canvas.onpointerup = (event: PointerEvent) => this.pointerUp(event);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private subscribeForData() {
    this.subscriptions.push(
      this.toggleRedraw$.subscribe((data: GrapeData) => {
        this.data = JSON.parse(JSON.stringify(data.Data));
        this.hfInfo$.next(this.data.HFInfo);
        this.createDrawing();
        this.update();
      }),

      this.flipSidemember$.subscribe((flipped: boolean) => {
        this.drawingOptions.drawUpsideDown = flipped;
        this.createDrawing();
        this.update();
      })
    );
  }

  public reDrawMinimap() {
    this.createDrawing();
    this.update();
  }

  private createDrawing(): void {
    const sidememberWidth = Math.abs(
      this.data.HFInfo.BD_BlankEnd_X - this.data.HFInfo.BD_BlankBeg_X
    );

    const longSidemember = sidememberWidth > 8000;

    // TODO: Calculate real pixels to use as margins instead
    this.drawingOptions.margins = {
      top: longSidemember ? 300 : 150,
      left: 150,
      bottom: longSidemember ? 300 : 150,
      right: 150,
    };

    this.drawingOptions.scalingRatio = longSidemember ? 0.2 : 0.5;
    this.drawingOptions.drawPPTRMarks = this.settings.sidememberPresentation.printHoleMarking;
    this.drawingOptions.drawRestrictedAreas = this.settings.sidememberPresentation.printRestrictedAreas;

    if (this.data.compareMode) {
      this.drawingOptions.holeOptions.showFilteredHoles = false;
      this.drawingOptions.holeOptions.showRelatedHoles = false;
    }
    this.drawing = SidememberDrawing.create(this.data, this.drawingOptions);
  }

  private update(): void {
    if (!this.drawing) return;
    if (!this.canvas) return;

    this.clear();

    const context = this.canvas.getContext('2d');

    context.imageSmoothingEnabled = false;
    context.imageSmoothingQuality = 'high';

    this.canvas.width = this.drawing.canvas.width;
    this.canvas.height = this.drawing.canvas.height;

    context.drawImage(
      this.drawing.canvas,
      0,
      0,
      this.drawing.canvas.width,
      this.drawing.canvas.height
    );

    this.drawViewport(context, this.drawing, this.viewport);
  }

  private drawViewport(
    context: CanvasRenderingContext2D,
    drawing: SidememberDrawing,
    viewport: any
  ): void {
    if (!viewport) return;

    context.save();

    SidememberDrawing.translateToSidememberMatrix(
      context,
      drawing.data,
      drawing.options
    );

    context.beginPath();

    context.setLineDash([]);
    context.strokeStyle = '#000000';
    context.lineWidth = 3 / this.scale;

    let x: number;
    let y: number;

    // TODO: Clean up and improve
    if (drawing.data.HFInfo.FrameSide === SidememberSide.Left) {
      x = drawing.options.drawUpsideDown ? viewport.right : viewport.left;
    } else if (drawing.data.HFInfo.FrameSide === SidememberSide.Right) {
      x = drawing.options.drawUpsideDown ? viewport.left : viewport.right;
    }

    y = drawing.options.drawUpsideDown ? viewport.top : viewport.bottom;

    context.rect(x, y, viewport.width, viewport.height);

    context.stroke();
    context.closePath();
    context.restore();
  }

  private mouseWheel(event: WheelEvent): void {
    if (!this.isPanning) {
      const position = this.translatePointer(event);
      this.pan.emit(position);
    }

    this.zoom.emit(event.deltaY < 0);
  }
  

  private pointerDown(event: PointerEvent): void {
    this.isPanning = true;

    const position = this.translatePointer(event);

    this.pan.emit(position);
  }

  private pointerMove(event: PointerEvent): void {
    if (!this.isPanning) {
      return;
    }

    const position = this.translatePointer(event);

    this.pan.emit(position);
  }

  private pointerUp(event: PointerEvent): void {
    this.isPanning = false;
  }

  private translatePointer(event: MiniMapMouseEvent): Vector3 {
    const canvas = event.target as HTMLCanvasElement;
    const canvasRect = canvas.getBoundingClientRect();

    const scaleX = canvasRect.width / canvas.width;
    const scaleY = canvasRect.height / canvas.height;

    const x = (event.clientX - canvasRect.left) / scaleX;
    const y = (event.clientY - canvasRect.top) / scaleY;

    if (!this.drawing) return new Vector3(0, 0, 0);

    return SidememberDrawing.pointToVector({ x, y }, this.drawing);
  }

  private observeResize(): void {
    const resizeObserver = new ResizeObserver(() => {
      this.update();
    });

    resizeObserver.observe(this.host.nativeElement);
  }

  private clear(): void {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
