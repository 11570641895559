import { UserSettingsService } from './../shared/services/usersettings.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifyToastrService } from '../shared/services/notify-toastr.service';
import { LocaleService } from '../shared/services/locale.service';

interface Language {
  code: string;
  name: string;
}

@Component({
  selector: 'app-language-handling',
  templateUrl: './language-handling.component.html',
  styleUrls: ['./language-handling.component.scss'],
})
export class LanguageHandlingComponent implements OnInit {
  public availableLanguages: Language[] = [
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'Français' },
    { code: 'nl', name: 'Nederlands' },
    { code: 'pt', name: 'Português' },
    { code: 'sv', name: 'Svenska' },
    { code: 'cn', name: 'Chinese' },
  ];

  constructor(
    private translate: TranslateService,
    private toastr: NotifyToastrService,
    private settings: UserSettingsService,
    private localeService: LocaleService
  ) {
    this.settings.languageSettings.load();
  }

  public ngOnInit(): void {
    this.setDefaultLangAndLocale();
  console.log(this.availableLanguages, "availableLanguages")

  }

  public changeLanguage(language: string): void {
    const selectedLanguage = this.availableLanguages.find(
      (lang) => lang.code === language
    );
    this.translate.use(language).subscribe(
      () => {
        this.localeService.registerCulture(selectedLanguage.code);
      },
      (error) => {
        console.log('Error changing language', error);
        this.toastr.showError(
          `Could not change language to ${selectedLanguage.name}`
        );
        this.setDefaultLangAndLocale();
      },
      () => {
        this.settings.languageSettings.code = selectedLanguage.code;
        this.settings.languageSettings.langName = selectedLanguage.name;
        this.settings.languageSettings.save();
      }
    );
  }

  public getCurrentLanguage(langCode: string = undefined): string {
    if (!langCode) {
      const storedLangCode = this.settings.languageSettings.code;
      langCode = storedLangCode ? storedLangCode : this.translate.currentLang;
    }

    const selectedLanguage = this.availableLanguages.find(
      (item) => item.code === langCode
    );

    if (selectedLanguage) {
      return selectedLanguage.name;
    }
    return '_';
  }

  private setDefaultLangAndLocale() {
    if (this.settings.languageSettings.code) {
      this.translate.use(this.settings.languageSettings.code);
      this.localeService.registerCulture(this.settings.languageSettings.code);
    } else {
      this.localeService.registerCulture(this.translate.currentLang);
    }
  }
}
