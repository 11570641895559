import { environment } from './../environments/environment';
import {
  Component,
  ElementRef,
  ViewChild,
  TemplateRef,
  HostListener,
  Inject,
} from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { AppConfigService } from './shared/services/app-config.service';
import { Subject, Subscription } from 'rxjs';
import { User } from './shared/models/user';
import { TranslateService } from '@ngx-translate/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSaverService } from 'ngx-filesaver';
import { UserSettingsService } from './shared/services/usersettings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UIEventService } from './shared/services/ui-event.service';
import { GrapeService } from './shared/services/grape.service';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import {
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public version = `${environment.version} ${environment.major}.${environment.minor}.${environment.patch} `;
  public user: User;
  private userActivity;
  private userInactive: Subject<any> = new Subject();
  // public superUser: boolean = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public disabledImport = false;
  private subscriptions: Subscription[] = [];

  @ViewChild('importModalError')
  importModalError: TemplateRef<any>;
  private importModalErrorRef: BsModalRef;
  // public requiredRoles: any[];
  public userProfile: object;
  // public rolesList: any[];
  constructor(
    public router: Router,
    private grapeService: GrapeService,
    private el: ElementRef,
    private authService: AuthService,
    private configService: AppConfigService,
    iconRegistry: MatIconRegistry,
    domSanitzer: DomSanitizer,
    private fileSaver: FileSaverService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    private uiEventservice: UIEventService,
    private translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authMsalService: MsalService
  ) {
    setTheme('bs4');
    this.addExtraIcons(iconRegistry, domSanitzer);

    const browserLanguage = this.translate.getBrowserLang();
    this.translate.setDefaultLang('en');
    this.translate.use(browserLanguage);
    // this.setTimeout();
    // this.userInactive.subscribe(() =>
    //  // this.logOut()
    // );
    //this.ngOnDestroy()
  }
  // logOut() {
  //   console.log('user has been inactive for 1s');
  //   window.localStorage.clear();
  //   window.sessionStorage.clear();
  //   this.authMsalService.logout();
  //   console.log("i am logging off")

  // }

  // public async checkUserAccess() {
  //   await this.authorizedUserRoles();
  //   await this.loadUserProfile();
  //   await this.getUserRoles();
  //   this.superUser = (await this.authService.GrapeUserRole()).includes('super_user');
  // }
  // public async authorizedUserRoles() {
  //   const grapeAccess = await this.authService.GrapeUserRole();
  //   if (grapeAccess?.length === 0) {
  //     this.router.navigateByUrl('/access-denied');
  //   }
  // }
  // public async loadUserProfile() {
  //   this.userProfile = await this.authService.loadUserProfile();
  //   this.user = {
  //     userId: this.userProfile['attributes']['globalUser']?this.userProfile['attributes']['globalUser'][0]:this.userProfile['attributes']['xdsUser'][0],
  //     userName: this.userProfile['username'],
  //     userEmail: this.userProfile['email']
  //   };
  // }
  // public getUserRoles() {
  //   return this.grapeService.GetallRoles().subscribe({
  //     next: (data: any) => {
  //       this.rolesList = data;
  //       return true;
  //     },
  //     complete: () => { },
  //     error: () => {
  //       this.router.navigateByUrl('/access-denied');
  //       return false;
  //     }
  //   });
  // }
  async ngOnInit() {
    // this.checkUserAccess();
    // this.loadUserProfile();

    // window.onload = function () {
    //   // sessionStorage.clear();
    // };
    // await this.getUserRole();

    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        await this.setLoginDisplay();
      });

    // this.login()
  }

  async login() {
    if (this.msalGuardConfig.authRequest) {
      await this.authMsalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      await this.authMsalService.loginRedirect();
    }
  }
  // setTimeout() {
  //   this.userActivity = setTimeout(() => this.userInactive.next(undefined), 30000);
  // }

  // @HostListener('window:mousemove') refreshUserState() {
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  // }
  async getUserRole() {
    this.userProfile = await this.authMsalService.instance.getAllAccounts();
    console.log(this.userProfile, 'user');

    this.user = {
      userId: this.userProfile[0]?.name.split(' ')[0],
      userName: this.userProfile[0]?.name,
      userEmail: this.userProfile[0]?.username,
    };

    var request = { scopes: ['User.Read'] };
    this.authMsalService
      .acquireTokenSilent(request)
      .subscribe((tokenResponse) => {
        console.log(tokenResponse, 'tokenResponse');
      });
    console.log(this.userProfile[0], 'user profile');
    console.log(this.userProfile[0]?.idTokenClaims, 'user role ');
    console.log(!this.userProfile[0]?.idTokenClaims.roles, ' Validation ');
    if (!this.userProfile[0]?.idTokenClaims.roles) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  setLoginDisplay() {
    // this.loginDisplay = this.authMsalService.instance.getAllAccounts().length > 0;
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(async (result: EventMessage) => {
        console.log(result);
        await this.getUserRole();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // ngOnDestroy() {
  //   this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  // }

  public hideCorporateUIHeader(): boolean {
    return this.router.url.indexOf('/total-picture') !== -1;
  }

  private addExtraIcons(
    iconRegistry: MatIconRegistry,
    domSanitzer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'export',
      domSanitzer.bypassSecurityTrustResourceUrl('assets/images/export.svg')
    );
    iconRegistry.addSvgIcon(
      'import',
      domSanitzer.bypassSecurityTrustResourceUrl('assets/images/import.svg')
    );
  }

  exportSettings() {
    const jsonData = JSON.stringify(localStorage);
    const fileName = this.user
      ? this.user.userId + '_GrapeSettings'
      : 'GrapeSettings';

    this.fileSaver.saveText(jsonData, `${fileName}.json`);
  }

  public openFile(event): void {
    if (event.target.files.length === 0) {
      return;
    }

    const selectedFile: File = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      try {
        const data = JSON.parse(event.currentTarget.result);
        if (this.userSettingsService.validateSettingFile(data)) {
          this.userSettingsService.removeAllLocalSettings();
          this.userSettingsService.setImportedData(data);
          location.reload();
        } else {
          this.showimportModalError();
        }
      } catch (error) {
        this.showimportModalError();
      }
    };

    reader.readAsText(selectedFile);
  }

  public showimportModalError() {
    this.importModalErrorRef = this.modalService.show(this.importModalError, {
      class: 'modal-sm',
      keyboard: true,
    });
  }

  public hideImportModalError() {
    if (!this.importModalErrorRef) return;
    this.importModalErrorRef.hide();
  }
  public async indexList() {
    if (await !this.userProfile[0]?.idTokenClaims.roles) {
      this.router.navigateByUrl('/access-denied');
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
