<div id="TotalPicture" #totalPicture></div>
<div id="HoleInformationPrint" #holeInformationPrint></div>

<div id="TotalPictureProgress" class="loader" *ngIf="totalPicturePrinting.isProcessing">
  <div class="wrapper">
    <div class="progress">
      <div [attr.aria-valuenow]="totalPictureProgress" [ngStyle]="{ width: totalPictureProgressPercentage }"
        class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="message">
      {{ totalPictureProgressMessage }}
    </div>
  </div>
</div>

<div class="loader" *ngIf="isSidememberLoading()">
  <div>
    <div class="spinner"></div>
    <div class="message">
      {{ 'SIDEMEMBER_PRESENTATION.LOADING' | translate }}
    </div>
  </div>
</div>

<div id="SidememberPresentation" class="d-flex flex-row flex-grow-1 contentPadding">
  <div class="col-left d-flex flex-column px-0 panel-height" [ngClass]="{
      'col-md-12': settings.sidememberPresentation.fullSize,
      'col-md-8': !settings.sidememberPresentation.fullSize
    }">
    <div class="card card-default fh-panel flex-grow-1 d-flex flex-column">
      <div class="card-header card-header-fh no-gutter row align-items-center">
        <div class="col-lg-12 col-xl-6">
            <div class="d-flex flex-row no-gutters sdds-headline-05" [hidden]="isSidememberLoading()">
              <div>{{ 'SIDEMEMBER_PRESENTATION.CHASSIS_NO' | translate }}:</div>
              <div class="pl-2">
                <div>{{ getPanelHeading(1).chassisNumber }} V{{ getPanelHeading(1).version }}</div>
                <div *ngIf="isCompareMode">{{ getPanelHeading(2).chassisNumber }} V{{ getPanelHeading(2).version }}
                </div>
              </div>
              <div class="pl-5">{{ 'SIDEMEMBER_PRESENTATION.CU' | translate }}:</div>
              <div class="pl-2">
                <div>{{ getPanelHeading(1).cuName }}</div>
                <div *ngIf="isCompareMode">{{ getPanelHeading(2).cuName }}</div>
              </div>
              <div class="pl-2" *ngIf="isCompareMode">
                <div class="compare-color-indicator" [ngStyle]="{ 'background-color': getPanelHeading(1).colorHint }">
                </div>
                <div class="compare-color-indicator" [ngStyle]="{ 'background-color': getPanelHeading(2).colorHint }">
                </div>
              </div>
            </div>
        </div>

        <div class="col-lg-12 col-xl-6 headerbuttonsalign-list">
          <span class="data-source-header" title="{{ 'SIDEMEMBER_PRESENTATION.SELECTED_MONA_DATABASE' | translate }}">
            {{getSelectedDataSource()}}
          </span>
          <button mat-icon-button  (click)="showSMListModal()" id="btnSidememberListOpen" title="{{ 'SIDEMEMBER_LIST.TITLE' | translate }}">
            <mat-icon>menu</mat-icon>
          </button>

          <div class="linkSeparator">&nbsp;</div>

          <div class="mat-slider-multiple-labels">
            <mat-label title="{{ 'SIDEMEMBER_PRESENTATION.C2C' | translate }}"
              (click)="saveSidememberDistanceOption(holeDistanceOption.centerToCenter)" [ngClass]="{ 'selected': this.settings.holeDistance.holeDistanceOption ===  holeDistanceOption.centerToCenter ,
               'disabled' : isOpenSidememberError()}">
              C-C
            </mat-label>
            <mat-slide-toggle
              [checked]="this.settings.holeDistance.holeDistanceOption === holeDistanceOption.edgeToEdge"
              (change)="saveSidememberDistanceOption($event.checked ? holeDistanceOption.edgeToEdge : holeDistanceOption.centerToCenter)"
              [disabled]="isOpenSidememberError()">
            </mat-slide-toggle>
            <mat-label title="{{ 'SIDEMEMBER_PRESENTATION.E2E' | translate }}"
              (click)="saveSidememberDistanceOption(holeDistanceOption.edgeToEdge)"
              [ngClass]="{ 'selected': this.settings.holeDistance.holeDistanceOption ===  holeDistanceOption.edgeToEdge,
              'disabled' : isOpenSidememberError()}">
              E-E
            </mat-label>
          </div>

          <button mat-icon-button (click)="toggleDrawingFlip()"  id="toggleSidemember" [attr.title]="
            (this.settings.sidememberPresentation.showUpsideDown
              ? 'SIDEMEMBER_PRESENTATION.ORIENTATION.UPSIDE_DOWN'
              : 'SIDEMEMBER_PRESENTATION.ORIENTATION.NORMAL') | translate
          " [disabled]="isOpenSidememberError()">
            <mat-icon *ngIf="!this.settings.sidememberPresentation.showUpsideDown">arrow_upward</mat-icon>
            <mat-icon *ngIf="this.settings.sidememberPresentation.showUpsideDown">arrow_downward</mat-icon>
          </button>

            <span (isOpenChange)="settingsToggle($event)" class="settings-icon-pt" dropdown #settingsDropdown="bs-dropdown">
              <button mat-icon-button aria-controls="settings-dropdown" dropdownToggle title="{{ 'SETTINGS' | translate }}"
                [disabled]="isOpenSidememberError()">
                <mat-icon>settings</mat-icon>
              </button>
              <div (click)="$event.stopPropagation()" id="settings-dropdown"
                class="dropdown-menu dropdown-menu-right pt-5 settingswidth" *dropdownMenu>
                <app-settings (save)="saveSettings(settingsDropdown)" #settings></app-settings>
              </div>
            </span>
  

          <div class="linkSeparator">&nbsp;</div>

          <button mat-icon-button (click)="printSidememberTotalPicture()"  id="TotalPictureLink"
            title="{{ 'SIDEMEMBER_PRESENTATION.PRINT' | translate }}" [disabled]="printDisabled()">
            <mat-icon>print</mat-icon>
          </button>

          <div class="linkSeparator">&nbsp;</div>

          <button mat-icon-button class="expand-mr" id="togglefullfsize"  (click)="expandDrawing()" [attr.title]="
                (settings.sidememberPresentation.fullSize
                ? 'SHOW_RIGHT_PANES'
                : 'HIDE_RIGHT_PANES') | translate
            ">
            <mat-icon [ngClass]="{
              'mat-icon-flip-horizontal': !settings.sidememberPresentation.fullSize,
              '': settings.sidememberPresentation.fullSize
            }">menu_open</mat-icon>
          </button>
        </div>
      </div>

      <div class="card-body flex-grow-1 card-invalid" *ngIf="isOpenSidememberError()">
        <div class="errmessage">
          {{ errorText }}
        </div>
      </div>

      <div class="card-body flex-grow-1 p-0 d-flex flex-column" [hidden]="isOpenSidememberError()">
        <app-sidemember-canvas class="d-flex flex-column flex-grow-1" [setSelectedHole]="selectedHole$ | async"
          (holeSelection)="canvasHoleSelection($event)" #sidememberCanvas></app-sidemember-canvas>
      </div>
    </div>
  </div>

  <div id="Information" class="col-md-4 col-right pr-0 pl-4 panel-height" [hidden]="settings.sidememberPresentation.fullSize">
    <app-hole-information id="AppHole" [ngClass]="{
        collapsed: settings?.holeinfo?.collapsed,
        expanded: !settings?.holeinfo?.collapsed
      }" [setSelectedHoles]="selectedHoles$ | async" (holeSelect)="infoHoleSelection($event)"
      [holeInformationPrintRef]="holeInformationPrintRef"></app-hole-information>

    <app-sidemember-information [ngClass]="{
        collapsed: settings?.sidememberinfo?.collapsed,
        expanded: !settings?.sidememberinfo?.collapsed
      }" [sidememberIndex]="isCompareMode ? 1 : null"></app-sidemember-information>

    <app-sidemember-information [ngClass]="{
        collapsed: settings?.compareSidememberinfo?.collapsed,
        expanded: !settings?.compareSidememberinfo?.collapsed
      }" *ngIf="isCompareMode" sidememberIndex="2"></app-sidemember-information>

    <app-hole-duplicates [ngClass]="{
        collapsed: settings?.holeDuplication?.collapsed,
        expanded: !settings?.holeDuplication?.collapsed
      }"></app-hole-duplicates>
  </div>
</div>


<ng-template #smListModal>
  <div class="modal-body d-flex flex-column flex-grow-1 " id="SidememberList">
    <app-sidemember-list class="d-flex flex-row flex-grow-1" (printTotalPicture)="printIndexlistTotalPicture($event)"
      #indexlist>
    </app-sidemember-list>
  </div>
  <div class="modal-footer">
    <button type="button"  (click)="cancelSMListModal()" [ngClass]="{disabled: isFirstPage}" [disabled]="isFirstPage"
      class="btn btn-sm btn-secondary sm-select-btn btn-size-white">
      {{ 'CANCEL' | translate }}
    </button>
    <button type="button" (click)="openSidemember()" title="Open sidemember"
      [ngClass]="{disabled: isOpenButtonDisabled(this.indexlist.sidememberSelection.length)}"
      [disabled]="isOpenButtonDisabled(this.indexlist.sidememberSelection.length)"
      class="btn btn-sm btn-primary sm-select-btn btn-sz">
      <span *ngIf="!this.indexlist.gotDoubleSelection || this.isIndexListLoading(); else showCompare">
        {{ 'OPEN' | translate }}
      </span>
      <ng-template #showCompare>
        {{ 'COMPARE' | translate }}
      </ng-template>
    </button>
  </div>

</ng-template>