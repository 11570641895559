import { Injectable } from '@angular/core';
import {
  GrapeData,
  GrapeObjectType,
  GrapeDataModel,
} from '../models/grapeservice/grape.data';
import { UserSettingsService } from './usersettings.service';
import { GrapeService } from './grape.service';
import { HoleSearchService } from './hole-search.service';
import { DataSource } from '../models/grapeservice/datasource';
import { Hole } from '../models/grapeservice/hole';
import { Sidemember } from '../models/grapeservice/sidemember';
import { HoleDuplicatesService } from './hole-duplicates.service';
import stringHash from 'string-hash';
import { Subscription, BehaviorSubject } from 'rxjs';
import { VariantCodes } from '../models/grapeservice/variant-codes';
import { Indexlist } from '../models/grapeservice/indexlist';
import { take, debounceTime } from 'rxjs/operators';
import { HoleCalculation } from '../../rendering/core/hole-calculation';
import { UIEventService } from './ui-event.service';
import { HoleDistanceService } from './hole-distance.service';
import { HoleFlags, HoleShape } from '../models/hole-enum';
import { HoleDistanceOption } from '../models/hole-distance-option';
import { SidememberSide } from '../models/sidemember-enum';
import { SidememberInfo } from '../models/grapeservice/sidemember-info';
import { NotifyToastrService } from './notify-toastr.service';
export interface GetSidememberDataOptions {
  dataSource: string;
  ipoId: string;
  version: string;
  findHoleDuplicates: boolean;
  filterHoles: boolean;
}

export interface GetSidememberDataPayload {
  dataSource: string;
  ipoId: string;
  version: string;
  findHoleDuplicates: boolean;
  filterHoles: boolean;
  changedObject: GrapeObjectType;
  doneHandler?: () => void;
  errorHandler?: (error: any) => void;
}

// TODO: Break data service loose from other dependencies:
// TODO: - Remove user settings from the data service
// TODO: - Remove UI-related code
// TODO: Ensure that there is one single source of truth, currently all components set GrapeData
@Injectable()
export class GrapeDataService {
  public grapeData: GrapeData;
  public data: BehaviorSubject<GrapeData>;

  public errorVariantCode = new BehaviorSubject<boolean>(false);
  public loadVariantCode = new BehaviorSubject<boolean>(false);
  public errorPreview = new BehaviorSubject<boolean>(false);
  public errorOpenSidemember = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription[] = [];

  constructor(
    public settings: UserSettingsService,
    private uiEventService: UIEventService,
    private grapeService: GrapeService,
    private holeSearcher: HoleSearchService,
    private holeDistanceService: HoleDistanceService,
    private holeDuplicates: HoleDuplicatesService,
    private toastr: NotifyToastrService
  ) {
    this.grapeData = new GrapeData();
    this.data = new BehaviorSubject<GrapeData>(this.grapeData);

    this.loadSettingsData();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public setLastOpenendSidemember(lastOpenedSmData: GrapeDataModel) {
    if (lastOpenedSmData) {
      this.grapeData.Data.Width = lastOpenedSmData.Width;
      this.grapeData.Data.Height = lastOpenedSmData.Height;
      this.grapeData.Data.FlangeHeight = lastOpenedSmData.FlangeHeight;
      this.grapeData.Data.TypeText = lastOpenedSmData.TypeText;
      this.grapeData.Data.HFInfo = lastOpenedSmData.HFInfo;
      this.grapeData.Data.Holes = [...lastOpenedSmData.Holes];
      this.grapeData.Data.Reinforcements = [...lastOpenedSmData.Reinforcements];
      this.grapeData.Data.FilteredHoles = [...lastOpenedSmData.FilteredHoles];
      this.grapeData.Data.HoleDuplicates = [...lastOpenedSmData.HoleDuplicates];
    }
  }

  public setCompare(
    dataSource: string,
    ipoId: string,
    version: number,
    ipoId2: string,
    version2: number
  ) {
    this.grapeData.Data.loadingSidemember = true;
    this.grapeData.Data.loadError.forSidemember = false;
    this.grapeData.Data.loadError.error = null;
    this.errorOpenSidemember.next(false);

    this.subscriptions.push(
      this.grapeService
        .getSideMembersForCompare(
          dataSource,
          ipoId,
          version + '',
          ipoId2,
          version2 + ''
        )
        .subscribe(
          (sidemembers: Sidemember[]) => {
            this.grapeData.Data.sidemember1 = sidemembers[0];
            this.grapeData.Data.sidemember2 = sidemembers[1];

            this.setBiggerSM();
            this.setUniqueHoles();
            this.setHolesForCompare();
            this.setReinforcements();
            this.setHoleDuplicatesForCompare();
            this.grapeData.Data.compareMode = true;
            this.grapeData.Data.loadingSidemember = false;
            this.grapeData.ChangedObject = GrapeObjectType.Sidemember;
            this.data.next(this.grapeData);
            this.grapeData.ChangedObject = GrapeObjectType.Minimap;
            this.data.next(this.grapeData);
          },
          (error: any) => {
            //this.toastr.showError(error.error?.info);
            this.onGetSidememberDataError(error);
          }
        )
    );
  }

  setHoleDuplicatesForCompare() {
    this.grapeData.Data.HoleDuplicates = [
      ...this.holeDuplicates.findHoleDuplicates(
        this.grapeData.Data.sidemember1.Holes
      ),
      ...this.holeDuplicates.findHoleDuplicates(
        this.grapeData.Data.sidemember2.Holes
      ),
    ];
  }

  setReinforcements() {
    this.grapeData.Data.Reinforcements = [
      ...this.grapeData.Data.sidemember1.Reinforcements,
      ...this.grapeData.Data.sidemember2.Reinforcements,
    ];
  }

  setHolesForCompare() {
    const sm1 = this.grapeData.Data.sidemember1;
    const sm2 = this.grapeData.Data.sidemember2;

    if (sm1.HFInfo.FrameSide === sm2.HFInfo.FrameSide) {
      this.grapeData.Data.HFInfo.FrameSide = sm1.HFInfo.FrameSide;
    } else {
      if (sm1.HFInfo.FrameSide === SidememberSide.Right) {
        sm1.Holes.forEach((hole) => {
          hole.Hole_Y = hole.Hole_Y * -1;
        });
      } else {
        sm2.Holes.forEach((hole) => {
          hole.Hole_Y = hole.Hole_Y * -1;
        });
      }
      this.grapeData.Data.HFInfo.FrameSide = SidememberSide.Left;
    }
    this.grapeData.Data.Holes = [...sm1.Holes, ...sm2.Holes];

    this.generateHoleIds(this.grapeData.Data.Holes);
    HoleCalculation.sortHolesByRadius(this.grapeData.Data.Holes);
    this.clearHoleProperties();
  }

  setUniqueHoles() {
    this.grapeData.Data.uniqueSidemember1Holes = this.setSideMembersUniqueHoles(
      this.grapeData.Data.sidemember1.Holes,
      this.grapeData.Data.sidemember2.Holes,
      HoleFlags.UniqueInSidemember1
    );

    this.grapeData.Data.uniqueSidemember2Holes = this.setSideMembersUniqueHoles(
      this.grapeData.Data.sidemember2.Holes,
      this.grapeData.Data.sidemember1.Holes,
      HoleFlags.UniqueInSidemember2
    );
  }

  setBiggerSM() {
    const sm1 = this.grapeData.Data.sidemember1;
    const sm2 = this.grapeData.Data.sidemember2;

    this.grapeData.Data.HFInfo = new SidememberInfo();

    this.grapeData.Data.Height =
      sm1.Height >= sm2.Height ? sm1.Height : sm2.Height;

    this.grapeData.Data.Width = sm1.Width >= sm2.Width ? sm1.Width : sm2.Width;

    this.grapeData.Data.FlangeHeight =
      sm1.FlangeHeight >= sm2.FlangeHeight
        ? sm1.FlangeHeight
        : sm2.FlangeHeight;

    this.grapeData.Data.HFInfo.BD_BlankBeg_X =
      sm1.HFInfo.BD_BlankBeg_X <= sm2.HFInfo.BD_BlankBeg_X
        ? sm1.HFInfo.BD_BlankBeg_X
        : sm2.HFInfo.BD_BlankBeg_X;

    this.grapeData.Data.HFInfo.BD_BlankEnd_X =
      sm1.HFInfo.BD_BlankEnd_X >= sm2.HFInfo.BD_BlankEnd_X
        ? sm1.HFInfo.BD_BlankEnd_X
        : sm2.HFInfo.BD_BlankEnd_X;

    this.grapeData.Data.HFInfo.FlangeWidth =
      sm1.HFInfo.FlangeWidth >= sm2.HFInfo.FlangeWidth
        ? sm1.HFInfo.FlangeWidth
        : sm2.HFInfo.FlangeWidth;

    this.grapeData.Data.HFInfo.WebHeight =
      sm1.HFInfo.WebHeight >= sm2.HFInfo.WebHeight
        ? this.grapeData.Data.sidemember1.HFInfo.WebHeight
        : sm2.HFInfo.WebHeight;
  }

  private setSideMembersUniqueHoles(
    sm1Holes: Hole[],
    sm2Holes: Hole[],
    holeFlag: HoleFlags
  ) {
    const uniqueHoles: Hole[] = [];
    for (let i = 0; i <= sm1Holes.length - 1; i++) {
      let equalFound = false;
      for (let j = 0; j <= sm2Holes.length - 1; j++) {
        if (this.isCompareEqual(sm1Holes[i], sm2Holes[j])) {
          equalFound = true;
          break;
        }
      }
      if (!equalFound) {
        sm1Holes[i].HoleFlag = holeFlag;
        uniqueHoles.push(sm1Holes[i]);
      }
    }
    return uniqueHoles;
  }

  isCompareEqual(hole1: Hole, hole2: Hole) {
    const tol = 1.0;
    const exactSameXPosition =
      hole1.Hole_X - tol < hole2.Hole_X && hole1.Hole_X + tol > hole2.Hole_X;
    const exactSameYPosition =
      Math.abs(hole1.Hole_Y) - tol < Math.abs(hole2.Hole_Y) &&
      Math.abs(hole1.Hole_Y) + tol > Math.abs(hole2.Hole_Y);
    const exactSameZPosition =
      hole1.Hole_Z - tol < hole2.Hole_Z && hole1.Hole_Z + tol > hole2.Hole_Z;
    const exactSameDiameter =
      hole1.Diameter - tol < hole2.Diameter &&
      hole1.Diameter + tol > hole2.Diameter;

    return (
      exactSameXPosition &&
      exactSameYPosition &&
      exactSameZPosition &&
      exactSameDiameter
    );
  }

  public resetErrors() {
    this.errorPreview.next(false);
    this.errorVariantCode.next(false);
    this.errorOpenSidemember.next(false);
    this.errorVariantCode.next(false);
  }

  public setDataSources() {
    this.grapeData.Data.DataSources = [];
    this.grapeData.Data.loadingDataSources = true;
    this.grapeData.Data.loadError.forDataSources = false;
    this.grapeData.Data.loadError.error = null;
    this.subscriptions.push(
      this.grapeService
        .getDataSources()
        .pipe(take(1), debounceTime(500))
        .subscribe(
          (data: DataSource[]) => {
            this.grapeData.Data.DataSources = data;
            this.grapeData.ChangedObject = GrapeObjectType.DataSource;
            this.grapeData.Data.loadingDataSources = false;
            this.data.next(this.grapeData);
          },
          (error: any) => {
            //this.toastr.showError(error?.error?.info);
            this.grapeData.Data.loadingDataSources = false;
            this.grapeData.Data.loadError.forDataSources = true;
            this.grapeData.Data.loadError.error = error.error?.message;
            this.data.next(this.grapeData);
          }
        )
    );
  }

  public setHoleDuplicates() {
    this.grapeData.ChangedObject = GrapeObjectType.HoleDuplicates;

    this.settings.holeDuplication.load();
    this.grapeData.Data.HoleDuplicates = this.settings.holeDuplication
      .showDuplicates
      ? this.holeDuplicates.findHoleDuplicates(this.grapeData.Data.Holes)
      : [];
    this.data.next(this.grapeData);
  }

  public setFilteredHoles(enabled: boolean) {
    this.settings.holeinfo.load();
    this.grapeData.Data.filteringHoles = true;

    this.clearHoleProperties();

    if (enabled) {
      this.filterHoles(this.grapeData.Data.Holes);
    }

    this.grapeData.Data.filteringHoles = false;
    this.grapeData.ChangedObject = GrapeObjectType.FilteredHoles;
    this.data.next(this.grapeData);
  }

  public setIndexList(dataSource: string) {
    this.grapeData.Data.loadError.forIndexList = false;
    this.grapeData.Data.IndexList.entries = [];
    this.grapeData.Data.loadingIndexList = true;
    this.grapeData.Data.loadError.error = null;
    this.settings.indexlist.load();
    this.settings.variantCodesSearch.load();
    if (
      this.settings.variantCodesSearch.isFilterActive &&
      this.settings.variantCodesSearch.variantCodeSearchDefinitions.length !== 0
    ) {
      this.getIndexListWithFilter(dataSource);
    } else {
      this.getIndexList(dataSource);
    }
  }

  public setSelectedDrawingHole(selectedHole: Hole) {
    this.clearHoleProperties();
    if (selectedHole) {
      this.grapeData.Data.SelectedHole = selectedHole;
      this.addRelatedHoles(selectedHole);
    }

    this.grapeData.ChangedObject = GrapeObjectType.SelectedDrawingHole;
    this.data.next(this.grapeData);
  }

  public setSelectedSecondaryDrawingHole(secondaryHole: Hole) {
    if (
      secondaryHole &&
      this.grapeData.Data.SelectedHole !== secondaryHole &&
      this.isSecondaryHoleApplicable(secondaryHole)
    ) {
      if (
        this.grapeData.Data.SecondaryHoles.find(
          (x) => x.HoleId === secondaryHole.HoleId
        )
      ) {
        const index = this.grapeData.Data.SecondaryHoles.indexOf(secondaryHole);
        this.grapeData.Data.SecondaryHoles.splice(index, 1);
        this.grapeData.Data.unSelectedSecondaryHole = secondaryHole;
        this.grapeData.Data.selectedSecondaryHole = null;
      } else {
        this.grapeData.Data.SecondaryHoles.push(secondaryHole);
        this.grapeData.Data.unSelectedSecondaryHole = null;
        this.grapeData.Data.selectedSecondaryHole = secondaryHole;
      }
      this.grapeData.ChangedObject =
        GrapeObjectType.SelectedSecondaryDrawingHole;
      this.data.next(this.grapeData);
    }
  }

  public changedHoleDistanceMode(mode: HoleDistanceOption) {
    if (mode === HoleDistanceOption.edgeToEdge) {
      if (
        this.grapeData.Data.SelectedHole &&
        this.grapeData.Data.SelectedHole.HoleShape !== HoleShape.Round
      ) {
        this.grapeData.Data.SecondaryHoles = [];
      } else if (
        this.grapeData.Data.SecondaryHoles.length > 0 &&
        this.grapeData.Data.SecondaryHoles.findIndex(
          (x) => x.HoleShape !== HoleShape.Round
        ) !== -1
      ) {
        this.grapeData.Data.SecondaryHoles = this.grapeData.Data.SecondaryHoles.filter(
          (x) => x.HoleShape === HoleShape.Round
        );
      }
    }

    this.grapeData.ChangedObject = GrapeObjectType.ResetSecondaryDrawingHoles;
    this.data.next(this.grapeData);
  }

  public setSelectedListHole(selectedHole: Hole) {
    this.grapeData.Data.SelectedHole = null;
    this.grapeData.Data.SelectedListHole = null;
    this.grapeData.Data.RelatedHoles = [];
    if (selectedHole) {
      this.grapeData.Data.SelectedListHole = selectedHole;
      this.addRelatedHoles(selectedHole);
    }
    this.grapeData.ChangedObject = GrapeObjectType.SelectedHole;
    this.data.next(this.grapeData);
  }

  public setSideMember(
    dataSource: string,
    ipoId: string,
    version: string,
    localFile?: Sidemember
  ) {
    this.grapeData.Data.loadingSidemember = true;
    this.grapeData.Data.loadError.forSidemember = false;
    this.grapeData.Data.loadError.error = null;
    this.errorOpenSidemember.next(false);

    const payload: GetSidememberDataPayload = {
      dataSource,
      ipoId,
      version,
      findHoleDuplicates: this.settings.holeDuplication.showDuplicates,
      filterHoles: this.settings.holeinfo.searchFilterActive,
      changedObject: GrapeObjectType.Sidemember,
      errorHandler: (error: any) => this.onGetSidememberDataError(error),
      doneHandler: () => {
        this.grapeData.Data.loadingSidemember = false;
        this.grapeData.Data.compareMode = false;
        this.settings.sidememberPresentation.persistErrors = false;
        this.settings.sidememberPresentation.save();
      },
    };

    this.getSidememberData(payload, localFile);
  }

  public setMinimap(
    dataSource: string,
    ipoId: string,
    version: string,
    localFile?: Sidemember
  ) {
    this.grapeData.Data.loadingMinimap = true;
    this.grapeData.Data.loadError.forMinimap = false;
    this.grapeData.Data.loadError.error = null;

    const payload: GetSidememberDataPayload = {
      dataSource,
      ipoId,
      version,
      findHoleDuplicates: this.settings.holeDuplication.showDuplicates,
      filterHoles: this.settings.holeinfo.searchFilterActive,
      changedObject: GrapeObjectType.Minimap,
      errorHandler: (error: any) => this.onGetMinimapDataError(error),
      doneHandler: () => {
        this.grapeData.Data.loadingMinimap = false;
        this.settings.sidememberPresentation.persistErrors = false;
        this.settings.sidememberPresentation.save();
      },
    };

    if (!this.errorOpenSidemember.value) {
      this.getSidememberData(payload);
    }
  }

  public setSidememberPreview(
    dataSource: string,
    ipoId: string,
    version: string
  ) {
    this.grapeData.Data.loadingSidememberPreview = true;
    this.grapeData.Data.loadError.forSidememberPreview = false;
    this.grapeData.Data.loadError.error = null;
    this.errorPreview.next(false);

    const payload: GetSidememberDataPayload = {
      dataSource,
      ipoId,
      version,
      findHoleDuplicates: this.settings.holeDuplication.showDuplicates,
      filterHoles: this.settings.holeinfo.searchFilterActive,
      changedObject: GrapeObjectType.SidememberPreview,
      errorHandler: (error: any) => this.onGetIndexListPreviewDataError(error),
      doneHandler: () => {
        this.grapeData.Data.loadingSidememberPreview = false;
        this.settings.sidememberPresentation.persistErrors = false;
        this.settings.sidememberPresentation.save();
      },
    };

    if (!this.errorOpenSidemember.value) {
      this.getSidememberData(payload);
    }
  }

  public setTotalPicture(dataSource: string, ipoId: string, version: string) {
    this.grapeData.Data.loadingTotalPicture = true;
    this.grapeData.Data.loadError.forTotalPicture = false;
    this.grapeData.Data.loadError.error = null;

    const payload: GetSidememberDataPayload = {
      dataSource,
      ipoId,
      version,
      filterHoles: true,
      findHoleDuplicates: this.settings.holeDuplication.showDuplicates,
      changedObject: GrapeObjectType.TotalPicture,
      errorHandler: (error: any) => this.onGetTotalPictureDataError(error),
      doneHandler: () => {
        this.grapeData.Data.loadingTotalPicture = false;
      },
    };

    if (!this.errorOpenSidemember.value) {
      this.getSidememberData(payload);
    }
  }

  public setTypeText(ipoId: string, dataSource: string) {
    this.grapeData.Data.loadingTypeText = true;
    this.grapeData.Data.loadError.forTypeText = false;
    this.grapeData.Data.loadError.error = null;
    this.subscriptions.push(
      this.grapeService
        .getTypeText(dataSource, ipoId)
        .pipe(take(1), debounceTime(500))
        .subscribe(
          (data: string) => {
            this.grapeData.Data.TypeText = data;
            this.grapeData.Data.loadingTypeText = false;
            this.grapeData.ChangedObject = GrapeObjectType.TypeText;
            this.data.next(this.grapeData);
          },
          (error: any) => {
            //this.toastr.showError(error.error?.info);
            this.grapeData.Data.loadingTypeText = false;
            this.grapeData.Data.loadError.forTypeText = true;
            this.grapeData.Data.loadError.error = error.error?.message;
            this.data.next(this.grapeData);
          }
        )
    );
  }

  public setVariantCodes(ipoId: string, dataSource: string) {
    this.grapeData.Data.VariantCodes = [];
    this.grapeData.Data.loadingVariants = true;
    this.loadVariantCode.next(true);
    this.grapeData.Data.loadError.forVariants = false;
    this.grapeData.Data.loadError.error = null;
    this.errorVariantCode.next(false);

    if (ipoId) {
      this.subscriptions.push(
        this.grapeService
          .getVariantCodes(dataSource, ipoId)
          .pipe(take(1), debounceTime(500))
          .subscribe(
            (data: VariantCodes[]) => {
              this.grapeData.Data.VariantCodes = data;
              this.grapeData.ChangedObject = GrapeObjectType.VariantCodes;
              this.grapeData.Data.loadingVariants = false;
              this.loadVariantCode.next(false);
              this.data.next(this.grapeData);
            },
            (error: any) => {
              //.showError(error.error?.info);
              this.grapeData.Data.loadingVariants = false;
              this.loadVariantCode.next(false);
              this.grapeData.Data.loadError.forVariants = true;
              this.grapeData.Data.loadError.error = error.error?.message;
              this.errorVariantCode.next(true);
              this.data.next(this.grapeData);
            }
          )
      );
    } else {
      this.grapeData.Data.VariantCodes = [];
      this.grapeData.ChangedObject = GrapeObjectType.VariantCodes;
      this.grapeData.Data.loadingVariants = false;
      this.loadVariantCode.next(false);
      this.data.next(this.grapeData);
    }
  }

  public setSidememberFromFile(event: any, dataSource: string): void {
    if (event.target.files.length === 0) {
      return;
    }

    const selectedFile: File = event.target.files[0];
    const reader = new FileReader();

    this.grapeData.Data.loadingSidemember = true;
    this.grapeData.Data.loadingMinimap = true;
    this.grapeData.Data.loadError.forSidemember = false;
    this.grapeData.Data.loadError.error = null;
    this.errorOpenSidemember.next(false);

    reader.onload = (event: any) => {
      try {
        const data: Sidemember = JSON.parse(event.currentTarget.result);

        this.setMinimap(
          dataSource,
          data.HFInfo.IPOId,
          data.HFInfo.Version,
          data
        );

        this.setSideMember(
          dataSource,
          data.HFInfo.IPOId,
          data.HFInfo.Version,
          data
        );

        this.uiEventService.indexListCancel(true);
      } catch (error) {
        this.errorOpenSidemember.next(true);
        this.toastr.showWarning('Could not load the selected file');
      }
    };

    reader.readAsText(selectedFile);
  }

  //#region Private
  private getIndexList(dataSource: string): void {
    this.subscriptions.push(
      this.grapeService
        .getIndexList(dataSource)
        .pipe(take(1), debounceTime(500))
        .subscribe(
          (data: Indexlist) => {
            this.grapeData.ChangedObject = GrapeObjectType.IndexList;
            this.grapeData.Data.IndexList = data;
            this.grapeData.Data.loadingIndexList = false;
            this.data.next(this.grapeData);
          },
          (error: any) => {
            //this.toastr.showError(error.error?.info);
            this.grapeData.ChangedObject = GrapeObjectType.IndexList;
            this.grapeData.Data.loadingIndexList = false;
            this.grapeData.Data.loadError.forIndexList = true;
            this.grapeData.Data.loadError.error = error.error?.message;
            this.data.next(this.grapeData);
          }
        )
    );
  }

  private getIndexListWithFilter(dataSource: string): void {
    this.subscriptions.push(
      this.grapeService
        .getIndexListWithFilter(
          dataSource,
          this.settings.variantCodesSearch.variantCodeSearchDefinitions
        )
        .pipe(take(1), debounceTime(500))
        .subscribe(
          (data: Indexlist) => {
            this.grapeData.Data.IndexList = data;
            this.grapeData.ChangedObject = GrapeObjectType.IndexList;
            this.grapeData.Data.loadingIndexList = false;
            this.data.next(this.grapeData);
          },
          (error: any) => {
            //this.toastr.showError(error.error?.info);
            this.grapeData.Data.loadingIndexList = false;
            this.grapeData.Data.loadError.forIndexList = true;
            this.grapeData.Data.loadError.error = error.error?.message;
            this.data.next(this.grapeData);
          }
        )
    );
  }

  private getSidememberData(
    payload: GetSidememberDataPayload,
    localFile?: Sidemember
  ) {
    if (localFile) {
      this.processSidemember(payload, localFile);
      return;
    }

    // TODO: Improve performance by only fetching data when it's required, not every time...
    this.subscriptions.push(
      this.grapeService
        .getSidememberData(payload.dataSource, payload.ipoId, payload.version)
        .pipe(take(1), debounceTime(500))
        .subscribe(
          (sidemember: Sidemember) => {
            this.processSidemember(payload, sidemember);
          },
          (error: any) => {
            console.log(error,"error");
            if (payload.errorHandler) {
              payload.errorHandler(error);
            }
          }
        )
    );
  }

  private processSidemember(
    payload: GetSidememberDataPayload,
    sidemember?: Sidemember
  ): void {
    this.grapeData.ChangedObject = payload.changedObject;

    if (sidemember) {
      this.setSidememberData(sidemember);
    }

    this.clearHoleProperties();

    if (payload.findHoleDuplicates) {
      this.findHoleDuplicates();
    }

    if (payload.filterHoles) {
      this.filterHoles(this.grapeData.Data.Holes);
    }

    if (payload.doneHandler) {
      payload.doneHandler();
    }

    this.data.next(this.grapeData);
  }

  private setSidememberData(sidemember: Sidemember): void {
    this.grapeData.Data.HFInfo = { ...sidemember.HFInfo };
    this.grapeData.Data.Holes = [...sidemember.Holes];
    this.grapeData.Data.Reinforcements = [...sidemember.Reinforcements];
    this.grapeData.Data.FlangeHeight = sidemember.FlangeHeight;
    this.grapeData.Data.Height = sidemember.Height;
    this.grapeData.Data.Width = sidemember.Width;

    this.generateHoleIds(this.grapeData.Data.Holes);
    HoleCalculation.sortHolesByRadius(this.grapeData.Data.Holes);
  }

  private generateHoleIds(holes: Hole[]): void {
    holes.forEach((hole) => (hole.HoleId = this.generateHoleId(hole)));
  }

  private generateHoleId(hole: Hole): number {
    return stringHash(JSON.stringify(hole));
  }

  private findHoleDuplicates(): void {
    this.grapeData.Data.HoleDuplicates = this.holeDuplicates.findHoleDuplicates(
      this.grapeData.Data.Holes
    );
  }

  private onGetSidememberDataError(error: any): void {
    this.grapeData.Data.loadingSidemember = false;
    this.grapeData.Data.loadError.forSidemember = true;
    this.errorOpenSidemember.next(true);
    this.grapeData.Data.loadError.error = error.error?.message;
    this.grapeData.ChangedObject === GrapeObjectType.Sidemember;
    this.data.next(this.grapeData);
  }

  private onGetMinimapDataError(error: any): void {
    this.grapeData.Data.loadingMinimap = false;
    this.grapeData.Data.loadError.forMinimap = true;
    this.grapeData.Data.loadError.error = error.error?.message;
    this.grapeData.ChangedObject === GrapeObjectType.Minimap;
    this.data.next(this.grapeData);
  }

  private onGetIndexListPreviewDataError(error: any): void {
    this.grapeData.Data.loadingSidememberPreview = false;
    this.grapeData.Data.loadError.forSidememberPreview = true;
    this.errorPreview.next(true);
    this.grapeData.Data.loadError.error = error.error?.message;
    this.grapeData.ChangedObject === GrapeObjectType.SidememberPreview;
    this.data.next(this.grapeData);
  }

  private onGetTotalPictureDataError(error: any): void {
    this.grapeData.Data.loadingTotalPicture = false;
    this.grapeData.Data.loadError.forTotalPicture = true;
    this.grapeData.Data.loadError.error = error.error?.message;
    this.grapeData.ChangedObject === GrapeObjectType.TotalPicture;
    this.data.next(this.grapeData);
  }

  private addRelatedHoles(selectedHole: Hole): void {
    if (!this.grapeData.Data.compareMode) {
      this.grapeData.Data.RelatedHoles.push(
        ...this.grapeData.Data.Holes.filter(
          (relatedHole) =>
            (selectedHole.AObj === relatedHole.AObj && // have the same part number
              Number(selectedHole.AObj) !== 0 && // and part number is not 0
              !(relatedHole.HoleId === selectedHole.HoleId)) || // and exclude the selected hole
            this.holeDuplicates.areHolesDuplicate(selectedHole, relatedHole)
        )
      );
    }
  }

  private filterHoles(holesToFilter: Hole[]): void {
    this.grapeData.Data.FilteredHoles = this.holeSearcher.filterHoles(
      holesToFilter,
      this.settings.holeinfo.holeSearchDefinitions
    );
  }

  private clearHoleProperties(): void {
    this.grapeData.Data.SecondaryHoles = [];
    this.grapeData.Data.RelatedHoles = [];
    this.grapeData.Data.FilteredHoles = [];
    this.grapeData.Data.SelectedHole = null;
    this.grapeData.Data.SelectedListHole = null;
  }

  private isSecondaryHoleApplicable(secondaryHole: Hole) {
    try {
      this.holeDistanceService.validateHoleSelection(
        this.grapeData.Data.HFInfo,
        this.grapeData.Data.SelectedHole,
        secondaryHole
      );
    } catch (error) {
      return false;
    }
    return true;
  }

  private loadSettingsData() {
    this.settings.holeDuplication.load();
    this.settings.indexlist.load();
    this.settings.variantCodesFilter.load();
    this.settings.variantCodesSearch.load();
    this.settings.holeinfo.load();
    this.settings.sidememberPresentation.load();
    this.settings.variantCodes.load();
  }
  //#endregion
}
