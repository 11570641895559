import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router, Params } from '@angular/router';
import { SidememberListSelectionModel } from '../models/sidemember-list-selection.model';
import { Title } from '@angular/platform-browser';
import { UserSettingsService } from './usersettings.service';
import { IndexlistEntry } from '../models/indexlist-entry';
import { GrapeDataService } from './grape-data.service';
import { Subscription } from 'rxjs';
import {
  GrapeData,
  GrapeObjectType,
  GrapeDataModel,
} from '../models/grapeservice/grape.data';
import { filter } from 'rxjs/operators';
import { GrapeService } from './grape.service';

@Injectable()
export class SidememberSelectService {
  public modalTemplate: TemplateRef<any>;
  public firstPage = true;
  public sidememberSelection: SidememberListSelectionModel;
  public sidememberSelectionPreview: SidememberListSelectionModel;
  public sidememberCompareSelection: SidememberListSelectionModel;
  public sidememberCompareSelected: SidememberListSelectionModel;
  private sidememberSelectionData: GrapeDataModel;

  private subscriptions: Subscription[] = [];
  private modal: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private titleService: Title,
    private settings: UserSettingsService,
    private grapeDataService: GrapeDataService,
    private grapeService: GrapeService
  ) {
    this.subscribeToData();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private subscribeToData() {
    this.subscriptions.push(
      this.grapeDataService.data
        .pipe(
          filter(
            (data: GrapeData) =>
              data.ChangedObject === GrapeObjectType.Sidemember
          )
        )
        .subscribe((grpData) => {
          if (!grpData.Data.loadError.forSidemember) {
            // Sidemember data
            this.sidememberSelectionData = new GrapeDataModel();
            this.sidememberSelectionData.Width = grpData.Data.Width;
            this.sidememberSelectionData.Height = grpData.Data.Height;
            this.sidememberSelectionData.FlangeHeight =
              grpData.Data.FlangeHeight;
            this.sidememberSelectionData.HFInfo = grpData.Data.HFInfo;
            this.sidememberSelectionData.Holes = [...grpData.Data.Holes];
            this.sidememberSelectionData.Reinforcements = [
              ...grpData.Data.Reinforcements,
            ];

            this.sidememberSelectionData.TypeText = grpData.Data.TypeText;
            this.sidememberSelectionData.FilteredHoles = [
              ...grpData.Data.FilteredHoles,
            ];
            this.sidememberSelectionData.HoleDuplicates = [
              ...grpData.Data.HoleDuplicates,
            ];

            this.saveLastOpenedSidemember();
          }
        })
    );
  }

  private saveLastOpenedSidemember() {
    if (this.sidememberSelection) {
      this.settings.indexlist.dataSource = this.sidememberSelection.dataSource;
      this.settings.indexlist.lastOpenedFiles = [];
      this.settings.indexlist.lastOpenedFiles.push(this.sidememberSelection);
      this.settings.indexlist.save();
    }
  }

  private setPageTitle(compareMode: boolean): void {
    const sm1Info = `${this.sidememberSelection.entry.ipoId}/${this.sidememberSelection.entry.version}`;

    const pageTitle = compareMode
      ? `Grape ${sm1Info} vs ${`${this.sidememberCompareSelected.entry.ipoId}/${this.sidememberCompareSelected.entry.version} (${this.sidememberSelection.dataSource})`}`
      : `Grape ${sm1Info}${` (${this.sidememberSelection.dataSource})`}`;

    this.titleService.setTitle(pageTitle);
  }

  private setSideMemberSelection(): void {
    if (this.sidememberCompareSelected) {
      this.grapeDataService.setCompare(
        this.sidememberSelection.dataSource,
        this.sidememberSelection.entry.ipoId,
        this.sidememberSelection.entry.version,
        this.sidememberCompareSelected.entry.ipoId,
        this.sidememberCompareSelected.entry.version
      );
      this.setPageTitle(true);
    } else {
      this.grapeDataService.setSideMember(
        this.sidememberSelection.dataSource,
        this.sidememberSelection.entry.ipoId,
        this.sidememberSelection.entry.version.toString()
      );
      this.setPageTitle(false);
      this.grapeDataService.setMinimap(
        this.sidememberSelection.dataSource,
        this.sidememberSelection.entry.ipoId,
        this.sidememberSelection.entry.version.toString()
      );
    }
  }

  public handleParams(params: Params) {
    const validDownloadParams =
      params.dataSource !== null &&
      params.dataSource !== undefined &&
      params.ipoId !== null &&
      params.ipoId !== undefined &&
      params.version !== null &&
      params.version !== undefined;

    if (!validDownloadParams) {
      setTimeout(async () => {
        this.sidememberSelection = null;
        this.firstPage = true;
        // this.showModal();
        if(this.firstPage){
          this.showModal();
        }

        // Adding it as it was inside subscribe getallroles
        //     if(this.firstPage){
        //       this.showModal();
        //     }
        // this.grapeService.GetallRoles().subscribe({
        //   next: (data: any) =>  {
        //   },
        //   complete: () => {},
        //   error: () => {
        //       this.router.navigateByUrl('/access-denied');
        //     if(this.firstPage){
        //       this.showModal();
        //     }
        //   }
        // });
      });
      return;
    }

    const entry = new IndexlistEntry();
    entry.ipoId = params.ipoId;
    entry.version = +params.version;
    this.sidememberSelection = new SidememberListSelectionModel(
      params.dataSource,
      entry
    );

    if (params.compareIpoId && params.compareVersion) {
      const compareEntry = new IndexlistEntry();
      compareEntry.ipoId = params.compareIpoId;
      compareEntry.version = +params.compareVersion;
      this.sidememberCompareSelected = new SidememberListSelectionModel(
        params.dataSource,
        compareEntry
      );
    } else {
      this.sidememberCompareSelected = null;
    }

    this.firstPage = false;
    this.hideModal();
    this.setSideMemberSelection();
  }

  public showModal() {
    if (this.modalService.getModalsCount() > 0) {
      return;
    }
    this.modal = this.modalService.show(this.modalTemplate, {
      backdrop: 'static',
      class: 'fullscreen-modal',
      keyboard: false,
      initialState: this.firstPage,
    });
  }

  public hideModal() {
    if (this.modal) {
      this.modal.hide();
    }
  }

  public cancelModal() {
    if (this.modal) {
      this.grapeDataService.setLastOpenendSidemember(
        this.sidememberSelectionData
      );
      this.modal.hide();
    }
  }

  private openedSMMatchingSelection() {
    if (this.sidememberSelection && this.sidememberSelectionPreview) {
      let selected = `${this.sidememberSelection.entry.ipoId}/${this.sidememberSelection.entry.version}`;
      let newSelection = `${this.sidememberSelectionPreview.entry.ipoId}/${this.sidememberSelectionPreview.entry.version}`;

      if (this.sidememberCompareSelection || this.sidememberCompareSelected) {
        selected += `/${this.sidememberCompareSelected?.entry.ipoId}/${this.sidememberCompareSelected?.entry.version}`;
        newSelection += `/${this.sidememberCompareSelection?.entry.ipoId}/${this.sidememberCompareSelection?.entry.version}`;
      }
      return selected === newSelection;
    }
    return false;
  }

  public clearSidememberCompareSelection() {
    this.sidememberCompareSelection = null;
  }

  public clearSidememberSelection() {
    this.sidememberSelectionPreview = null;
  }

  public openSidemember(): void {
    if (this.openedSMMatchingSelection()) {
      this.cancelModal();
      return;
    }

    if (this.sidememberCompareSelection !== null) {
      this.router.navigate([
        '/sidemember-compare',
        this.sidememberSelectionPreview.dataSource,
        this.sidememberSelectionPreview.entry.ipoId,
        this.sidememberSelectionPreview.entry.version,
        this.sidememberCompareSelection.entry.ipoId,
        this.sidememberCompareSelection.entry.version,
      ]);
    } else {
      this.router.navigate([
        '/sidemember-presentation',
        this.sidememberSelectionPreview.dataSource,
        this.sidememberSelectionPreview.entry.ipoId,
        this.sidememberSelectionPreview.entry.version,
      ]);
    }
  }
}
