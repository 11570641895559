export class IndexlistEntry {
  public ipoId: string;
  public version: number;
  public ciId: number;
  public hfStatus: string;
  public cusName: string;
  public assemblyDate: Date;
  public supplierName: string;
  public chassisNumber: string;
  public prodIndividual: string;
  public syncTime: string;
}
