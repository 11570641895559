import { Settings } from './settings';
import { HoleDuplicates } from '../models/hole-duplicates';

export class HoleDuplicatesSettings extends Settings {
  public collapsed = false;
  public showDuplicates = false;
  public holeDuplicates: HoleDuplicates = new HoleDuplicates();

  public load(): void {
    const storedSettings = <HoleDuplicatesSettings>super.load();
    this.collapsed = super.isStored('collapsed', storedSettings) ? storedSettings.collapsed : this.collapsed;
    this.showDuplicates = super.isStored('showDuplicates', storedSettings) ? storedSettings.showDuplicates : this.showDuplicates;
    this.holeDuplicates = super.isStored('holeDuplicates', storedSettings) ? storedSettings.holeDuplicates : this.holeDuplicates;

  }

  public save(): void {
    super.save(this);
  }
}
