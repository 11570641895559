import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { VariantCodesDefinitionComponent } from '../variant-codes-definition/variant-codes-definition.component';
import { GrapeService } from '../shared/services/grape.service';
import { VariantClass } from '../shared/models/variant-class';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { ModalType, ModalResult } from '../shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: '[app-variant-codes-filter]',
  templateUrl: './variant-codes-filter.component.html',
  styleUrls: ['./variant-codes-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantCodesFilterComponent {
  @Output('showVariantCodes') showVariantCodes = new EventEmitter();
  @Output('saveSelectedVariantCodes')
  saveSelectedVariantCodes = new EventEmitter();
  @Output('collapse') collapse = new EventEmitter();

  @Input('isCollapsed') isCollapsed: boolean;

  @ViewChild('filterList')
  filterList: VariantCodesDefinitionComponent;

  public variantClasses$: Observable<VariantClass[]>;
  public filterDefinitionList: UntypedFormControl;
  constructor(
    private fb: UntypedFormBuilder,
    private settings: UserSettingsService,
    private grapeService: GrapeService,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService
  ) {
    this.settings.variantCodesFilter.load();

    this.filterDefinitionList = this.fb.control(
      this.settings.variantCodesFilter.variantCodesDefinitions
    );

    this.variantClasses$ = this.grapeService.variantClasses.pipe(
      filter((variantClasses) => variantClasses !== null)
    );
  }

  get filterDefExists(): boolean {
    return this.filterDefinitionList.touched || this.filterDefinitionList.dirty;
  }

  public async validateClosing() {
    if (!this.filterList.isValid()) {
      await this.showValidateModalOnClose();
    } else if (this.filterDefExists) {
      const result: ModalResult = await this.modalDialogService.show(
        this.translate.instant('SAVE_CONFIRMATION'),
        ModalType.YesNoCancel
      );
      switch (result) {
        case ModalResult.Yes:
          this.saveVariantCodeDefinition();
          this.close();
          break;
        case ModalResult.No:
          this.close();
          return;
        default:
          return;
      }
    }
    if (
      !this.filterDefExists ||
      this.filterList.filterDefinitions.length === 0
    ) {
      this.close();
    }
  }

  public async save() {
    if (
      !this.filterList.isValid() ||
      (!this.filterDefExists && this.filterList.filterDefinitions.length === 0)
    ) {
      return;
    }

    const showPopup =
      !this.settings.variantCodesFilter.isFilterActive &&
      this.filterDefinitionList.value.length > 0;

    if (showPopup) {
      await this.showActivationFilterModalOnSave();
    }
    this.saveVariantCodeDefinition();
    this.close();
  }

  private saveVariantCodeDefinition() {
    if (!this.filterList.isValid()) return;
    this.settings.variantCodesFilter.variantCodesDefinitions = this.filterDefinitionList.value;
    if (this.settings.variantCodesFilter.variantCodesDefinitions.length === 0) {
      this.settings.variantCodesFilter.isFilterActive = false;
    }
    this.settings.variantCodesFilter.save();
  }

  public toggleCollapse(): void {
    this.collapse.emit();
  }

  public close(): void {
    this.showVariantCodes.emit();
  }

  //#region Modal
  async showValidateModalOnClose() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_ERROR'),
      ModalType.YesCancel
    );
    if (result !== ModalResult.Yes) {
      return;
    }
    this.saveVariantCodeDefinition();
    this.close();
  }

  async showActivationFilterModalOnSave() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_CONFIRMATION'),
      ModalType.YesNo
    );
    if (result === ModalResult.Yes) {
      this.settings.variantCodesFilter.isFilterActive = true;
    }
  }

  //#endregion
}
