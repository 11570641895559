import { RenderEngine } from '../render-engine';
import { MeshBuilder, Mesh, Vector3, Angle } from 'babylonjs';
import { SidememberSide } from '../../shared/models/sidemember-enum';
import { BabylonExtension } from '../babylon/babylon.extension';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { BaseRenderer } from './base-renderer';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';

export class SidememberRenderer extends BaseRenderer {
  private meshWidth: number;
  private meshHeight: number;
  private webHeight: number;
  private flangeWidth: number;

  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: SidememberInfo
  ) {
    super(engine, materials, renderData);
  }

  protected createMesh(): void {
    this.webHeight = +this.renderData.WebHeight;
    this.flangeWidth = +this.renderData.FlangeWidth;
    this.createWeb();
    this.createFlanges();
  }

  protected setInitialMaterial(): void {
    super.setInitialMaterial();
    this.setMaterial(this.materials.transparent);
  }

  private createWeb(): void {
    this.meshWidth =
      this.renderData.BD_BlankEnd_X - this.renderData.BD_BlankBeg_X;
    this.meshHeight = this.webHeight + this.flangeWidth * 2;

    this.mesh = MeshBuilder.CreatePlane(
      'Web',
      {
        width: this.meshWidth,
        height: this.meshHeight,
        sideOrientation:
          this.renderData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE
      },
      this.engine.scene
    );

    const x = this.renderData.BD_BlankBeg_X + this.meshWidth / 2;

    const y =
      this.renderData.FrameSide === SidememberSide.Right
        ? this.ASSEMBLY_LINE_POSITION_Y
        : -this.ASSEMBLY_LINE_POSITION_Y;

    const z =
      this.ASSEMBLY_LINE_POSITION_Z + this.meshHeight / 2 - this.flangeWidth;

    this.mesh.position = new Vector3(x, y, z);

    this.mesh.rotate(new Vector3(1, 0, 0), Angle.FromDegrees(90).radians());
  }

  private createFlanges(): void {
    const centerX = this.meshWidth / 2;
    const centerY = +this.renderData.WebHeight / 2;
    const centerZ = this.renderData.FrameSide === SidememberSide.Right ? 1 : -1;
    const center = new Vector3(centerX, centerY, centerZ);

    this.createFlangeLine(-center.x, -center.y, center.x, -center.y, center.z);
    this.createFlangeLine(-center.x, center.y, center.x, center.y, center.z);
  }

  private createFlangeLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    z: number
  ): void {
    const startPoint = new Vector3(x1, y1, z);
    const endPoint = new Vector3(x2, y2, z);

    const line = BabylonExtension.createDashedLinesWithColor(
      'FlangeLine',
      {
        gapSize: 1,
        dashSize: 1,
        color: this.outlineColor,
        points: [startPoint, endPoint],
        updatable: true,
        dashNb: 800
      },
      this.engine.scene
    );

    line.parent = this.mesh;
  }
}
