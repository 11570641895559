import { Injectable } from '@angular/core';
import { HoleSearchDef } from '../models/hole-search-def';
import { Hole, FilteredHole } from '../models/grapeservice/hole';
import { SearchCriteriaColor } from '../models/search-criteria-color';
import { SupplOper } from '../models/supploper';

@Injectable()
export class HoleSearchService {
  private holeAvailColors = new SearchCriteriaColor();
  private supplOper = new SupplOper();

  constructor() { }

  //#region public methods
  public filterHoles(
    holes: Hole[],
    criterias: HoleSearchDef[]
  ): FilteredHole[] {
    return this.filter(holes, criterias);
  }
  //#endregion public methods

  //#region private methods
  private filter(holes: Hole[], criterias: HoleSearchDef[]): FilteredHole[] {
    let allHandled = false;
    const foundHoles = [];
    let curPos = 0;

    if (criterias.length === 0) {
      return [];
    }

    while (!allHandled) {
      let intersectedHoles: FilteredHole[] = this.factorySearchMethod(holes, criterias[curPos]);
      while (criterias[curPos].isAnd && curPos < criterias.length - 1) {
        curPos = curPos + 1;
        const searchResult = this.factorySearchMethod(holes, criterias[curPos]);
        intersectedHoles = this.intersect(searchResult, intersectedHoles);
      }
      foundHoles.push(intersectedHoles);
      curPos = curPos + 1;
      if (curPos > criterias.length - 1) {
        allHandled = true;
      }
    }

    const sumResult: FilteredHole[] = [];
    for (let i = 0; i < foundHoles.length; i = i + 1) {
      this.union(foundHoles[i], sumResult);
    }
    return sumResult.reverse(); // Sort by found color order.
  }

  private intersect(
    thisList: FilteredHole[],
    withThisList: FilteredHole[]
  ): FilteredHole[] {
    const tmpList: FilteredHole[] = [];
    withThisList.forEach((item) => {
      const found = thisList.find(
        x =>
          item.AObj === x.AObj &&
          item.Hole_X === x.Hole_X &&
          item.Hole_Y === x.Hole_Y &&
          item.Hole_Z === x.Hole_Z
      );
      if (found) {
        if (item.HoleColor === 'Cyan' && found.HoleColor !== 'Cyan') {
          item.HoleColor = found.HoleColor;
        }
        tmpList.push(item);
      }
    });
    return tmpList;
  }

  private union(
    thisList: FilteredHole[],
    intoThisList: FilteredHole[]
  ): FilteredHole[] {
    thisList.forEach((item) => {
      const found = intoThisList.find(
        x =>
         item.HoleId === x.HoleId
      );
      if (!found) {
        intoThisList.push(item);
      } else if (found.HoleColor === 'Cyan' && item.HoleColor !== 'Cyan') {
        found.HoleColor = item.HoleColor;
      }
    });
    return intoThisList;
  }

  private factorySearchMethod(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    switch (criteria.operator.toLowerCase()) {
      case '=':
        return this.equalSearch(holes, criteria);
      case '>':
        return this.biggerThanSearch(holes, criteria);
      case '<':
        return this.smallerThanSearch(holes, criteria);
      case 'in':
        return this.inSearchCriteriaSplit(holes, criteria);
      case '[]':
        return this.betweenSearch(holes, criteria);
      default:
        return [];
    }
  }

  private getColor(criteria: HoleSearchDef): string {
    // return criteria.color;
    const foundColor = this.holeAvailColors.colors.find(
      x => x.value === criteria.color
    );

    // TODO: Default hole search color should be declared in settings
    const defaultColor = 'Cyan';

    return foundColor ? foundColor.name : defaultColor;
  }

  private inSearchCriteriaSplit(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    let searchResult: FilteredHole[] = [];
    criteria.value1.forEach((term) => {
      const partNoResult = this.equalSearchForSplitString(holes, term, criteria);
      searchResult = [...searchResult, ...partNoResult];
    });
    return searchResult;
  }

  private equalSearchForSplitString(
    holes: Hole[],
    term: string,
    criteria: HoleSearchDef
  ): FilteredHole[] {
    const filteredHoles = holes.filter(
      x =>
        x[criteria.attributeName].toString().toLowerCase() ===
        term.toLowerCase()
    );
    const searchResult: FilteredHole[] = this.deepCopy(filteredHoles);
    searchResult.forEach(x => (x.HoleColor = this.getColor(criteria)));
    return searchResult;
  }

  private equalSearch(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    let criteriaValue = criteria.value1;
    if (criteria.attributeName.toLowerCase() === 'supploper') {
      criteriaValue = this.supplOper.findKeyByValue(criteria.value1);
    }

    const filteredHoles = holes.filter(
      x =>
        x[criteria.attributeName].toString().toLowerCase() ===
        criteriaValue.toLowerCase()
    );

    const searchResult: FilteredHole[] = this.deepCopy(filteredHoles);
    searchResult.forEach(x => (x.HoleColor = this.getColor(criteria)));
    return searchResult;
  }

  private betweenSearch(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    const filteredHoles = this.areValuesNumbers(criteria.value1, criteria.value2)
      ? holes.filter(
        x =>
          x[criteria.attributeName] >= Number.parseFloat(criteria.value1) &&
          x[criteria.attributeName] <= Number.parseFloat(criteria.value2)
      )
      : [];

    const searchResult: FilteredHole[] = this.deepCopy(filteredHoles);
    searchResult.forEach(x => (x.HoleColor = this.getColor(criteria)));
    return searchResult;
  }

  private biggerThanSearch(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    const filteredHoles = this.isValueANumber(criteria.value1)
      ? holes.filter(
        x => x[criteria.attributeName] > Number.parseFloat(criteria.value1)
      )
      : [];
    const searchResult: FilteredHole[] = this.deepCopy(filteredHoles);
    searchResult.forEach(x => (x.HoleColor = this.getColor(criteria)));
    return searchResult;
  }

  private smallerThanSearch(holes: Hole[], criteria: HoleSearchDef): FilteredHole[] {
    const filteredHoles = this.isValueANumber(criteria.value1)
      ? holes.filter(
        x => x[criteria.attributeName] < Number.parseFloat(criteria.value1)
      )
      : [];

    const searchResult: FilteredHole[] = this.deepCopy(filteredHoles);
    searchResult.forEach(x => (x.HoleColor = this.getColor(criteria)));
    return searchResult;
  }

  deepCopy(holes: any): any {
    return JSON.parse(JSON.stringify(holes));
  }

  private areValuesNumbers(value1: string, value2: string): boolean {
    return this.isValueANumber(value1) && this.isValueANumber(value2);
  }

  private isValueANumber(value: string): boolean {
    return !Number.isNaN(Number.parseFloat(value));
  }
  //#endregion private methods
}
