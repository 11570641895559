export enum HoleInfoEnum {
  HoleObj,
  HoleToo,
  AObj,
  ASName,
  Hole_X,
  Hole_Y,
  Hole_Z,
  Diameter,
  HPSName,
  HPObj,
  HoleType,
  GObj,
  GSName,
  VINCode,
  SupplOper,
  Psi,
  HSToo
}
// export enum HoleInfoEnum {
//     'diameter',
//     'holeNo',
//     'holeTo',
//     'partNumber',
//     'partName',
//     'x',
//     'y',
//     'z',
//     'patternFiNum',
//     'type',
//     'gObj',
//     'gsName',
//     'supplOper',
//     'vinCode'
//   }
