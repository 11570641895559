import { Settings } from './settings';

export class LanguageSettings extends Settings {
  code: string;
  langName: string;
  public load(): void {
    const storedSettings = <LanguageSettings>super.load();
    this.code = super.isStored('code', storedSettings)
      ? storedSettings.code
      : this.code;
    this.langName = super.isStored('langName', storedSettings)
      ? storedSettings.langName
      : this.langName;

    this.onLoad.emit(this.name);
  }

  public save(): void {
    super.save(this);
    this.onSave.emit(this.name);
  }
}
