export enum HoleShape {
  NoShape = 0,
  Round = 1,
  LongShape = 2,
  MarkingPoint = 3,
  Rectangular = 4,
  Cut = 5,
  Ellipse = 6
}

export enum HoleSurface {
  NoSurface = 0,
  UpperFlange = 1,
  LowerFlange = 2,
  CenterLine = 3,
  Web = 4,
  Edge = 5
}

export enum HoleFlags {
  UniqueInSidemember1,
  UniqueInSidemember2,
}
