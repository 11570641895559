import { HoleRenderer } from './hole-renderer';
import { RenderEngine } from '../render-engine';
import { Hole } from '../../shared/models/grapeservice/hole';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { AbstractMesh, MeshBuilder, TransformNode } from 'babylonjs';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';

export class NoShapeRenderer extends HoleRenderer {
  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: Hole,
    protected sidememberData: SidememberInfo,
    protected parent?: AbstractMesh
  ) {
    super(engine, materials, renderData, sidememberData, parent);
  }
}
