import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  TotalPictureDrawingSettings,
  TotalPictureSection,
  TotalPictureTextSettings,
  TotalPictureSectionInput,
  TotalPictureAxisTick,
} from './total-picture.model';
import { SidememberDrawing } from '../rendering/sidemember-drawing';
import { SidememberSide } from '../shared/models/sidemember-enum';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { GrapeDataModel } from '../shared/models/grapeservice/grape.data';
import { Point } from '../shared/models/rendering/point';
import { HoleSearchDef } from '../shared/models/hole-search-def';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../shared/services/locale.service';

// TODO: Extract the service into multiple classes to simplify and make it clean
@Injectable()
export class TotalPictureService {
  private renderer: Renderer2;
  private screenDpi = 96;

  constructor(
    private rendererFactory: RendererFactory2,
    private userSettings: UserSettingsService,
    private translate: TranslateService,
    private localeService: LocaleService
  ) {
    this.userSettings.sidememberPresentation.load();
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.screenDpi = this.calcScreenDPI();
  }

  public create(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings
  ): void {
    const drawing = this.createDrawing(settings);

    this.resizeTargetCanvas(context, settings);
    this.drawTimestamp(context, settings, drawing.data);

    const drawingY = this.cmToPx(0.4, settings.scalingRatio);
    context.translate(0, drawingY);

    this.drawHeaderLabel(context, settings, drawing.data);
    this.drawSections(context, settings, drawing);
    this.drawSidememberData(context, settings, drawing.data);

    if (settings.drawFilteredHoles) {
      this.drawHoleSearchData(context, settings);
    }
  }

  private resizeTargetCanvas(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings
  ): void {
    this.renderer.setProperty(
      context.canvas,
      'width',
      this.cmToPx(settings.paperSettings.widthCm, settings.scalingRatio)
    );

    this.renderer.setProperty(
      context.canvas,
      'height',
      this.cmToPx(settings.paperSettings.heightCm, settings.scalingRatio)
    );

    this.renderer.setStyle(
      context.canvas,
      'width',
      `${100}%`
    );
    this.renderer.setStyle(
      context.canvas,
      'height',
      `${100}%`
    );
  }

  private createDrawing(
    settings: TotalPictureDrawingSettings
  ): SidememberDrawing {
    return SidememberDrawing.create(settings.data, {
      scalingRatio: settings.scalingRatio,
      lineWidth: 2,
      lineColor: '#000000',
      includeCutouts: false,
      drawUpsideDown: settings.drawUpsideDown,
      drawPPTRMarks: settings.drawPPTRMarks,
      drawRestrictedAreas: settings.drawRestrictedAreas,
      holeOptions: {
        enlargeFilteredHoles: settings.enlargeFilteredHoles,
        showFilteredHoleNumbers: settings.drawFilteredHoleNumbers,
        showFilteredHoles: true,
        showHoleDuplicates: true,
        showRelatedHoles: false,
        showSelectedHole: false,
      },
    });
  }

  private drawTimestamp(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings,
    data: GrapeDataModel
  ): void {
    const date = new Date();
    const datestamp = date.toLocaleDateString(this.localeService.locale);
    const timestamp = date.toLocaleTimeString(this.localeService.locale);
    const margins = this.getPixelMargins(settings);
    const text = `${data.HFInfo.ChassisNumber}    ${datestamp} ${timestamp}`;
    const origin: Point = {
      x: context.canvas.width / 2,
      y: margins.top,
    };

    this.drawText(context, [text], {
      origin,
      fontSize: 11,
      alignment: 'center',
      scalingRatio: settings.scalingRatio,
    });
  }

  private drawHeaderLabel(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings,
    data: GrapeDataModel
  ): void {
    const margins = this.getPixelMargins(settings);

    const rightSidemember = data.HFInfo.FrameSide === SidememberSide.Right;
    const upsideDown = this.userSettings.sidememberPresentation.showUpsideDown;

    const side: string = rightSidemember
      ? this.translate.instant('TOTAL_PICTURE.ORIENTATION.RIGHT')
      : this.translate.instant('TOTAL_PICTURE.ORIENTATION.LEFT');
    const orientation: string = upsideDown
      ? this.translate.instant('TOTAL_PICTURE.ORIENTATION.UPSIDE_DOWN')
      : '';

    const originLeft: Point = {
      x: margins.left,
      y: margins.top,
    };

    const originRight: Point = {
      x: context.canvas.width - margins.right,
      y: margins.top,
    };

    let origin: Point = rightSidemember ? originRight : originLeft;
    let transformOrigin: 'right' | 'left' = rightSidemember ? 'right' : 'left';

    if (upsideDown) {
      origin = rightSidemember ? originLeft : originRight;
      transformOrigin = rightSidemember ? 'left' : 'right';
    }

    const headerLabelSettings: TotalPictureTextSettings = {
      origin,
      transformOrigin,
      fontSize: 17,
      lineHeight: 21,
      scalingRatio: settings.scalingRatio,
      baseLine: 'top',
    };

    this.drawText(context, [side, orientation], headerLabelSettings);
  }

  private drawSections(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings,
    drawing: SidememberDrawing
  ): void {
    const margins = this.getPixelMargins(settings);
    const sectionScale = this.getSectionScale(context, settings);

    const frontInput = this.createFrontSectionInput(settings, drawing);
    const middleInput = this.createMiddleSectionInput(settings, drawing);
    const rearInput = this.createRearSectionInput(settings, drawing);
    const extraInput = this.createExtraSectionInput(settings, drawing);

    const sectionSpacing = this.cmToPx(1.4, settings.scalingRatio);

    context.save();

    context.translate(margins.left, margins.top);
    const frontSection = this.drawSection(context, frontInput, settings);

    context.translate(0, frontSection.height + sectionSpacing);
    const middleSection = this.drawSection(context, middleInput, settings);

    context.translate(0, middleSection.height + sectionSpacing);
    const rearSection = this.drawSection(context, rearInput, settings);

    const extraOffset =
      this.getAreaWidth(context, settings) -
      extraInput.image.width * sectionScale;

    context.translate(extraOffset, rearSection.height + sectionSpacing);
    const extraSection = this.drawSection(context, extraInput, settings);

    context.restore();
  }

  private drawSidememberData(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings,
    data: GrapeDataModel
  ): void {
    const margins = this.getPixelMargins(settings);
    const boxWidth = this.cmToPx(6.4, settings.scalingRatio);
    const boxHeight = this.cmToPx(4.9, settings.scalingRatio);
    const boxPadding = this.cmToPx(0.18, settings.scalingRatio);
    const boxLeft = margins.left;
    const boxTop = context.canvas.height - margins.bottom - boxHeight;

    const boxOrigin: Point = {
      x: boxLeft,
      y: boxTop,
    };

    context.save();
    context.lineWidth = 1;
    context.translate(0, context.canvas.height);
    context.scale(1, -1);
    context.beginPath();
    context.rect(boxOrigin.x, margins.bottom, boxWidth, boxHeight);
    context.stroke();
    context.closePath();
    context.restore();

    const headerTextSettings: TotalPictureTextSettings = {
      origin: {
        x: boxOrigin.x + boxPadding,
        y: boxOrigin.y + boxPadding,
      },
      fontSize: 11,
      lineHeight: 15,
      scalingRatio: settings.scalingRatio,
      baseLine: 'top',
    };

    const headers = [
      this.translate.instant('TOTAL_PICTURE.HEADERS.ID') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.TYPE') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.CU') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.CHNO') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.IPO') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.VER') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.CONS') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.BD') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.LEN') + ':',
      this.translate.instant('TOTAL_PICTURE.HEADERS.SUPP') + ':',
    ];

    this.drawText(context, headers, headerTextSettings);

    const valueOffset = this.cmToPx(1.25, settings.scalingRatio);

    const valueTextSettings: TotalPictureTextSettings = {
      ...headerTextSettings,
      maxWidth: boxWidth - valueOffset,
      origin: {
        x: headerTextSettings.origin.x + valueOffset,
        y: headerTextSettings.origin.y,
      },
    };

    const hfInfoValues = [
      data.HFInfo.PopId || '',
      data.HFInfo.TypeText || '',
      data.HFInfo.CUSName || '',
      data.HFInfo.ChassisNumber || '',
      this.zeroPadding(data.HFInfo.IPOId, 10) || '',
      this.zeroPadding(data.HFInfo.Version, 3) || '',
      `${data.HFInfo.BatchNumber || ''} - ${data.HFInfo.SequenceNumber || ''}${
        data.HFInfo.Origin || ''
      }`,
      data.HFInfo.BDObj,
      `${Math.abs(
        data.HFInfo.BD_BlankEnd_X - data.HFInfo.BD_BlankBeg_X
      ).toFixed(0)} mm`,
    ];

    const supplierName = this.wrapSupplierName(
      context,
      valueTextSettings,
      data.HFInfo.SupplierName
    );

    this.drawText(
      context,
      [...hfInfoValues, ...supplierName],
      valueTextSettings
    );
  }

  private wrapSupplierName(
    context: CanvasRenderingContext2D,
    settings: TotalPictureTextSettings,
    supplierName: string
  ): string[] {
    if (!supplierName) return [];

    const supplierNameLines: string[] = [];
    const words = supplierName.split(' ');

    let line = '';

    for (let n = 0; n < words.length; n += 1) {
      const testLine = `${line}${words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width * settings.scalingRatio;

      if (testWidth > settings.maxWidth && n > 0) {
        supplierNameLines.push(line);
        line = `${words[n]} `;
      } else {
        line = testLine;
      }
    }

    supplierNameLines.push(line);

    return supplierNameLines;
  }

  private drawHoleSearchBox(
    context: CanvasRenderingContext2D,
    boxLeft: number,
    boxBottom: number,
    boxWidth: number,
    boxHeight: number
  ) {
    context.save();
    context.lineWidth = 1;
    context.translate(0, context.canvas.height);
    context.scale(1, -1);
    context.beginPath();
    context.rect(boxLeft, boxBottom, boxWidth, boxHeight);
    context.stroke();
    context.closePath();
    context.restore();
  }

  private drawHoleSearchBoxSplitters(
    context: CanvasRenderingContext2D,
    amountOfColumns: number,
    columnWidth: number,
    boxLeft: number,
    boxTop: number,
    boxHeight: number,
    boxPadding: number
  ) {
    for (let section = 1; section < amountOfColumns; section++) {
      let x = boxLeft + columnWidth * section;
      let y = boxTop + boxPadding;
      context.beginPath();
      context.lineWidth = 1;
      context.moveTo(x, y);
      context.lineTo(x, y + boxHeight - boxPadding * 2);
      context.strokeStyle = '#A7A7A7';
      context.stroke();
    }
  }

  private drawHoleSearchData(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings
  ): void {
    const validHoleSearchDefs =
      this.userSettings.holeinfo.holeSearchDefinitions.filter(
        (definition: HoleSearchDef) =>
          definition.color && ['IN', '='].indexOf(definition.operator) >= 0 // TODO: Add a criteria to determine if it's a custom search or not in SearchDef instead
      );

    if (validHoleSearchDefs.length === 0) return;

    const holeSearchRowsPerColumn = 16;
    const totalLineCount = validHoleSearchDefs.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (Array.isArray(currentValue.value1) ? currentValue.value1.length : 1),
      0
    );

    const margins = this.getPixelMargins(settings);
    const columnWidth = this.cmToPx(3, settings.scalingRatio);
    const amountOfColumns =
      1 + Math.floor((totalLineCount - 1) / holeSearchRowsPerColumn);
    const boxWidth = columnWidth * amountOfColumns;
    const boxHeight = this.cmToPx(4.9, settings.scalingRatio);
    const boxLeft = margins.left + this.cmToPx(6.6, settings.scalingRatio);
    const boxRight = boxLeft + columnWidth + 2;
    const boxTop = context.canvas.height - margins.bottom - boxHeight;
    const boxPadding = this.cmToPx(0.18, settings.scalingRatio);

    const contentOrigin: Point = {
      x: boxLeft + boxPadding,
      y: boxTop + boxPadding,
    };

    this.drawHoleSearchBox(
      context,
      boxLeft,
      margins.bottom,
      boxWidth,
      boxHeight
    );
    this.drawHoleSearchBoxSplitters(
      context,
      amountOfColumns,
      columnWidth,
      boxLeft,
      boxTop,
      boxHeight,
      boxPadding
    );

    const attributes: string[] = [];
    const values: string[] = [];

    const circleRadius = this.cmToPx(0.12, settings.scalingRatio);
    const circleMarginRight = this.cmToPx(0.15, settings.scalingRatio);
    const circleMarginBottom = this.cmToPx(0.05, settings.scalingRatio);
    let circleX = contentOrigin.x + circleRadius;
    let circleY = contentOrigin.y + circleRadius;

    context.save();

    let holeIndex = 0;
    validHoleSearchDefs.forEach((definition: HoleSearchDef) => {
      // TODO: Figure out a better way to map AObj to PartNumber for display purposes
      const attributeName =
        definition.attributeName === 'AObj'
          ? 'PartNumber'
          : definition.attributeName;

      const drawCircle = () => {
        if (holeIndex > 0 && holeIndex % holeSearchRowsPerColumn === 0) {
          circleY = contentOrigin.y + circleRadius;
          circleX += columnWidth;
        }
        context.beginPath();
        context.fillStyle = definition.color;
        context.ellipse(
          circleX,
          circleY,
          circleRadius,
          circleRadius,
          0,
          0,
          Math.PI * 2
        );
        context.fill();
        context.stroke();
        context.closePath();
      };

      drawCircle();

      if (Array.isArray(definition.value1) && definition.value1.length > 1) {
        definition.value1.forEach((value) => {
          let firstItem = definition.value1.indexOf(value) === 0;

          if (
            !firstItem &&
            holeIndex > 0 &&
            holeIndex % holeSearchRowsPerColumn === 0
          ) {
            firstItem = true;
            drawCircle();
          }

          attributes.push(firstItem ? attributeName : '');
          values.push(`${definition.operator} ${value}`);

          circleY += circleRadius * 2 + circleMarginBottom;
          holeIndex++;
        });
      } else {
        attributes.push(attributeName);
        values.push(`${definition.operator} ${definition.value1}`);

        circleY += circleRadius * 2 + circleMarginBottom;
        holeIndex++;
      }
    });

    context.restore();

    const textOffset: Point = {
      x: circleMarginRight,
      y: this.cmToPx(0.03, settings.scalingRatio),
    };

    const baseTextSettings: TotalPictureTextSettings = {
      origin: {
        x: contentOrigin.x + circleRadius * 2 + textOffset.x,
        y: contentOrigin.y + textOffset.y,
      },
      fontSize: 8,
      lineHeight: 11,
      scalingRatio: settings.scalingRatio,
      baseLine: 'top',
      holeSearchRowsPerColumn: holeSearchRowsPerColumn,
    };

    this.drawText(context, attributes, baseTextSettings);

    const valueTextSettings: TotalPictureTextSettings = {
      ...baseTextSettings,
      alignment: 'left',
      transformOrigin: 'right',
      origin: {
        x: boxRight - boxPadding,
        y: baseTextSettings.origin.y,
      },
    };

    this.drawText(context, values, valueTextSettings);
  }

  private drawText(
    context: CanvasRenderingContext2D,
    textLines: string[],
    settings: TotalPictureTextSettings
  ): void {
    const fontSize = settings.fontSize * settings.scalingRatio;
    const lineHeight =
      (settings.lineHeight || settings.fontSize) * settings.scalingRatio;

    context.save();

    context.font = `${settings.bold ? 'bold ' : ''}${fontSize}px ${
      settings.fontFamily || 'sans-serif'
    }`;

    context.textBaseline = settings.baseLine || 'alphabetic';
    context.textAlign = settings.alignment || 'left';

    const y = settings.origin.y || 0;
    let x = settings.origin.x || 0;

    if (settings.transformOrigin === 'right') {
      textLines.forEach((text: string) => {
        const measure = context.measureText(text);
        x = Math.min(settings.origin.x - measure.width, x);
      });
    }

    textLines.forEach((text: string, index: number) => {
      let linePosY = index * lineHeight;
      let linePosX = x;
      if (settings.holeSearchRowsPerColumn) {
        linePosY = (index % settings.holeSearchRowsPerColumn) * lineHeight;
        linePosX +=
          Math.floor(index / settings.holeSearchRowsPerColumn) *
          this.cmToPx(3, settings.scalingRatio);
      }
      context.fillText(`${text}`, linePosX, y + linePosY);
    });

    context.restore();
  }

  private createFrontSectionInput(
    settings: TotalPictureDrawingSettings,
    drawing: SidememberDrawing
  ): TotalPictureSectionInput {
    const width = settings.sectionWidth;
    const height = drawing.canvas.height;
    const left = 0;

    const sectionImage = this.copySectionFromOriginalImage(drawing, settings, {
      left,
      width,
      height,
      top: 0,
      bottom: height,
      right: left + width,
      x: 0,
      y: 0,
      toJSON: null,
    });

    return {
      image: sectionImage,
      name: this.translate.instant('TOTAL_PICTURE.SECTION.FRONT'),
      axis: {
        begValue: drawing.data.HFInfo.BD_BlankBeg_X,
        endValue: left + width,
        drawBegX: drawing.data.HFInfo.BD_BlankBeg_X,
      },
    };
  }

  private createMiddleSectionInput(
    settings: TotalPictureDrawingSettings,
    drawing: SidememberDrawing
  ): TotalPictureSectionInput {
    const width = settings.sectionWidth;
    const height = drawing.canvas.height;
    const left = settings.sectionWidth - settings.sectionOverlap;

    const sectionImage = this.copySectionFromOriginalImage(drawing, settings, {
      left,
      width,
      height,
      top: 0,
      bottom: height,
      right: left + width,
      x: 0,
      y: 0,
      toJSON: null,
    });

    return {
      image: sectionImage,
      name: this.translate.instant('TOTAL_PICTURE.SECTION.MIDDLE'),
      axis: {
        begValue: left,
        endValue: left + width,
      },
    };
  }

  private createRearSectionInput(
    settings: TotalPictureDrawingSettings,
    drawing: SidememberDrawing
  ): TotalPictureSectionInput {
    const width = settings.sectionWidth;
    const height = drawing.canvas.height;
    const left = (settings.sectionWidth - settings.sectionOverlap) * 2;

    const sectionImage = this.copySectionFromOriginalImage(drawing, settings, {
      left,
      width,
      height,
      top: 0,
      bottom: height,
      right: left + width,
      x: 0,
      y: 0,
      toJSON: null,
    });

    return {
      image: sectionImage,
      name: this.translate.instant('TOTAL_PICTURE.SECTION.REAR'),
      axis: {
        begValue: left,
        endValue: left + width,
      },
    };
  }

  private createExtraSectionInput(
    settings: TotalPictureDrawingSettings,
    drawing: SidememberDrawing
  ): TotalPictureSectionInput {
    const width = 890;
    const height = drawing.canvas.height;
    const left = (settings.sectionWidth - settings.sectionOverlap) * 3;

    const sectionImage = this.copySectionFromOriginalImage(drawing, settings, {
      left,
      width,
      height,
      top: 0,
      bottom: height,
      right: left + width,
      x: 0,
      y: 0,
      toJSON: null,
    });

    return {
      image: sectionImage,
      name: this.translate.instant('TOTAL_PICTURE.SECTION.EXTRA'),
      axis: {
        begValue: left,
        endValue: left + width,
      },
    };
  }

  private copySectionFromOriginalImage(
    original: SidememberDrawing,
    settings: TotalPictureDrawingSettings,
    section: any
  ): HTMLCanvasElement {
    const buffer = document.createElement('canvas');
    const context = buffer.getContext('2d');

    buffer.width = section.width;
    buffer.height = section.height;

    if (this.shouldDrawInverted(settings)) {
      section.left =
        original.data.HFInfo.BD_BlankEnd_X - section.width - section.left;

      const viewportOffset = Math.abs(
        original.data.HFInfo.BD_BlankEnd_X - original.viewport.right
      );

      section.left += viewportOffset;
    }

    context.drawImage(
      original.canvas,
      section.left * settings.scalingRatio,
      section.top * settings.scalingRatio,
      section.width * settings.scalingRatio,
      section.height * settings.scalingRatio,
      0,
      0,
      buffer.width,
      buffer.height
    );

    return buffer;
  }

  private drawSection(
    context: CanvasRenderingContext2D,
    input: TotalPictureSectionInput,
    settings: TotalPictureDrawingSettings
  ): TotalPictureSection {
    const sectionScale = this.getSectionScale(context, settings);
    const aspectRatio = input.image.width / input.image.height;
    const width = input.image.width * sectionScale;
    const height = width / aspectRatio;
    const transform = context.getTransform();

    context.drawImage(input.image, 0, 0, width, height);

    const section: TotalPictureSection = {
      width,
      image: input.image,
      x: transform.e,
      y: transform.f,
      height: height / settings.scalingRatio,
      name: input.name,
      axis: input.axis,
    };

    this.drawSectionLabel(context, section, settings);
    this.drawSectionAxis(context, section, settings);

    return section;
  }

  private drawSectionLabel(
    context: CanvasRenderingContext2D,
    section: TotalPictureSection,
    settings: TotalPictureDrawingSettings
  ): void {
    if (!section.name) return;

    const labelX = section.width / 2;

    let labelY = section.height + this.cmToPx(0.2, settings.scalingRatio);
    labelY += this.cmToPx(0.45, settings.scalingRatio);

    const textSettings: TotalPictureTextSettings = {
      origin: {
        x: labelX,
        y: labelY,
      },
      fontSize: 16,
      lineHeight: 20,
      alignment: 'center',
      scalingRatio: settings.scalingRatio,
      baseLine: 'top',
    };

    this.drawText(context, [section.name], textSettings);
  }

  private drawSectionAxis(
    context: CanvasRenderingContext2D,
    section: TotalPictureSection,
    settings: TotalPictureDrawingSettings
  ): void {
    const scale = this.getSectionScale(context, settings);
    const axisX1 =
      section.axis && section.axis.drawBegX ? section.axis.drawBegX * scale : 0;
    const axisX2 =
      section.axis && section.axis.drawEndX
        ? section.axis.drawEndX * scale
        : section.width;
    const axisY = section.height + this.cmToPx(0.4, settings.scalingRatio);

    context.save();
    context.translate(0, axisY);

    if (this.shouldDrawInverted(settings)) {
      context.scale(-1, 1);
      context.translate(-section.width, 0);
    }

    context.strokeStyle = '#000000';
    context.lineWidth = 1;

    context.beginPath();
    context.moveTo(axisX1, 0);
    context.lineTo(axisX2, 0);
    context.stroke();
    context.closePath();

    this.drawXmLabels(context, section, settings);

    if (settings.drawTickMarks) {
      this.drawAxisTicks(context, section, settings);
    }

    context.restore();
  }

  private drawXmLabels(
    context: CanvasRenderingContext2D,
    section: TotalPictureSection,
    settings: TotalPictureDrawingSettings
  ): void {
    context.save();

    const scale = this.getSectionScale(context, settings);
    const drawInverted = this.shouldDrawInverted(settings);
    const y = this.cmToPx(0.1, settings.scalingRatio);

    let leftX = section.axis.drawBegX * scale || 0;
    let rightX = section.image.width * scale;

    if (drawInverted) {
      context.scale(-1, 1);
      leftX *= -1;
      rightX *= -1;
    }

    const labelSettings: TotalPictureTextSettings = {
      fontSize: 10,
      baseLine: 'hanging',
      scalingRatio: settings.scalingRatio,
    };

    const leftXmSettings: TotalPictureTextSettings = {
      ...labelSettings,
      alignment: drawInverted ? 'right' : 'left',
      origin: {
        y,
        x: leftX,
      },
    };

    const rightXmSettings: TotalPictureTextSettings = {
      ...labelSettings,
      alignment: drawInverted ? 'left' : 'right',
      origin: {
        y,
        x: rightX,
      },
    };

    const leftXmVal = `Xm${section.axis.begValue.toFixed(0)}`;
    const rightXmVal = `Xm${section.axis.endValue.toFixed(0)}`;

    this.drawText(context, [leftXmVal], leftXmSettings);
    this.drawText(context, [rightXmVal], rightXmSettings);

    context.restore();
  }

  private drawAxisTicks(
    context: CanvasRenderingContext2D,
    section: TotalPictureSection,
    settings: TotalPictureDrawingSettings
  ): void {
    const tickNth = 500;
    const labelNth = 1000;
    const shortTickSize = this.cmToPx(0.08, settings.scalingRatio);
    const longTickSize = shortTickSize * 2;
    const scale = this.getSectionScale(context, settings);
    const numberOfTicks = Math.ceil(settings.sectionWidth / tickNth);
    const initialValue = Math.ceil(section.axis.begValue / tickNth) * tickNth;
    const initialDrawX = Math.abs(initialValue - section.axis.begValue) * scale;
    const tickOffset = (section.axis.drawBegX || 0) * scale;

    let value = initialValue;
    let x = tickOffset + initialDrawX;

    for (let tickIndex = 0; tickIndex < numberOfTicks; tickIndex += 1) {
      const isEvenTick = value % labelNth === 0;
      const isLabelTick = isEvenTick && settings.drawXm;

      const tick: TotalPictureAxisTick = {
        value,
        x,
        y: 0,
        size: isEvenTick && !isLabelTick ? longTickSize : shortTickSize,
      };

      const visibleTick =
        tick.value >= section.axis.begValue &&
        tick.value <= section.axis.endValue;

      if (visibleTick) {
        context.beginPath();
        context.moveTo(tick.x, tick.y);
        context.lineTo(tick.x, -tick.size);
        context.stroke();
        context.closePath();

        if (isLabelTick) {
          this.drawTickLabel(context, section, settings, tick);
        }
      }

      x += tickNth * scale;
      value += tickNth;
    }
  }

  // TODO: Refactoring, reduce number of arguments
  private drawTickLabel(
    context: CanvasRenderingContext2D,
    section: TotalPictureSection,
    settings: TotalPictureDrawingSettings,
    tick: TotalPictureAxisTick
  ): void {
    const isInverted = this.shouldDrawInverted(settings);
    const scale = this.getSectionScale(context, settings);
    const labelOffsetY = this.cmToPx(0.07, settings.scalingRatio);
    const labelWidth: number = context.measureText(tick.value.toString()).width;
    const labelLeft: number = tick.x - labelWidth / 2;
    const labelRight: number = tick.x + labelWidth / 2;

    let alignment: CanvasTextAlign = 'center';

    const edgeLeft = (section.axis.drawBegX || 0) * scale;
    const edgeRight = settings.sectionWidth * scale;

    if (labelLeft < edgeLeft) {
      alignment = isInverted ? 'right' : 'left';
    } else if (labelRight > edgeRight) {
      alignment = isInverted ? 'left' : 'right';
    }

    context.save();

    if (this.shouldDrawInverted(settings)) {
      context.scale(-1, 1);
      tick.x *= -1;
    }

    context.translate(0, -tick.size);

    const tickLabelSettings: TotalPictureTextSettings = {
      alignment,
      origin: {
        x: tick.x,
        y: tick.y - labelOffsetY,
      },
      fontSize: 8,
      scalingRatio: settings.scalingRatio,
      baseLine: 'alphabetic',
    };

    this.drawText(context, [tick.value.toString()], tickLabelSettings);

    context.restore();
  }

  private getSectionScale(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings
  ): number {
    const areaWidth = this.getAreaWidth(context, settings);
    return areaWidth / settings.sectionWidth;
  }

  private getAreaWidth(
    context: CanvasRenderingContext2D,
    settings: TotalPictureDrawingSettings
  ): number {
    const margins = this.getPixelMargins(settings);
    return context.canvas.width - (margins.left + margins.right);
  }

  private getPixelMargins(settings: TotalPictureDrawingSettings) {
    const left = this.cmToPx(
      settings.paperSettings.marginsCm.left,
      settings.scalingRatio
    );

    const right = this.cmToPx(
      settings.paperSettings.marginsCm.right,
      settings.scalingRatio
    );

    const top = this.cmToPx(
      settings.paperSettings.marginsCm.top,
      settings.scalingRatio
    );

    const bottom = this.cmToPx(
      settings.paperSettings.marginsCm.bottom,
      settings.scalingRatio
    );

    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);

    return {
      left,
      right,
      top,
      bottom,
      width,
      height,
    };
  }

  private shouldDrawInverted(settings: TotalPictureDrawingSettings): boolean {
    const rightSidemember =
      settings.data.HFInfo.FrameSide === SidememberSide.Right;

    const invertDrawing =
      (settings.drawUpsideDown && !rightSidemember) ||
      (!settings.drawUpsideDown && rightSidemember);

    return invertDrawing;
  }

  private zeroPadding(input: string, maxLength: number): string {
    const numZeroes = Math.max(0, maxLength - input.length);
    let zeroString = '';

    for (let index = 0; index < numZeroes; index += 1) {
      zeroString += '0';
    }

    return `${zeroString}${input}`;
  }

  private cmToPx(cm: number, scalingRatio = 1): number {
    const inchInCm = 2.54;
    return (cm / inchInCm) * this.screenDpi * scalingRatio;
  }

  private calcScreenDPI(): number {
    const element = document.createElement('div');
    element.style.width = '1in';
    document.body.appendChild(element);
    const dpi = element.offsetWidth;
    document.body.removeChild(element);
    return dpi;
  }
}
