import { Vector3 } from 'babylonjs';
import { Hole } from '../../shared/models/grapeservice/hole';
import { SidememberSide } from '../../shared/models/sidemember-enum';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { HoleSurfaceEnum } from './enums/hole-surface-enum';

export class HoleCalculation {
  public static calculateFlangeLocation(
    parentPosition: Vector3,
    hole: Hole,
    surface: HoleSurfaceEnum
  ): number {
    const offsetZ = Math.abs(parentPosition.y) - Math.abs(hole.Hole_Y);

    let flangeLocation = hole.Hole_Z;

    if (surface === HoleSurfaceEnum.UpperFlange) {
      flangeLocation += offsetZ;
    } else if (surface === HoleSurfaceEnum.LowerFlange) {
      flangeLocation -= offsetZ;
    }

    return flangeLocation;
  }

  public static calculateHoleSurface(
    hole: Hole,
    sidemember: SidememberInfo
  ): HoleSurfaceEnum {
    const sidememberZ = 1000;
    const sidememberY =
      sidemember.FrameSide === SidememberSide.Right ? 385 : -385;

    const upperFlangeZ = sidememberZ + Number(sidemember.WebHeight);
    const lowerFlangeZ = sidememberZ;

    let isFlangeHole = hole.Hole_Y > sidememberY;

    if (sidemember.FrameSide === SidememberSide.Right) {
      isFlangeHole = hole.Hole_Y < sidememberY;
    }

    const isUpperFlangeHole = isFlangeHole && hole.Hole_Z === upperFlangeZ;
    const isLowerFlangeHole = isFlangeHole && hole.Hole_Z === lowerFlangeZ;

    if (isUpperFlangeHole) {
      return HoleSurfaceEnum.UpperFlange;
    }
    if (isLowerFlangeHole) {
      return HoleSurfaceEnum.LowerFlange;
    }
    return HoleSurfaceEnum.Web;
  }

  public static sortHolesByRadius(holes: Hole[]): void {
    holes.sort((holeA, holeB): number => {
      if (holeA.Diameter / 2 > holeB.Diameter / 2) return -1;
      if (holeA.Diameter / 2 < holeB.Diameter / 2) return 1;
      return 0;
    });
  }
}
