import {
  Component,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { HoleDuplicates } from '../shared/models/hole-duplicates';
import { FileSaverService } from 'ngx-filesaver';
import { List } from 'linqts';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { ModalType, ModalResult } from '../shared/models/enums';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-hole-duplicates',
  templateUrl: './hole-duplicates.component.html',
  styleUrls: ['./hole-duplicates.component.scss'],
})
export class HoleDuplicatesComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  public holeDuplicates: HoleDuplicates = new HoleDuplicates();
  public newExcludedPartNo = '';
  public newIncludedPartNo = '';
  public hideErrorMessage = true;
  public editDuplicates = false;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private dirtyForm = false;
  private readonly includedInputId: string = 'includedPartInput';
  private readonly excludedInputId: string = 'excludedPartInput';

  constructor(
    public settings: UserSettingsService,
    private fileSaver: FileSaverService,
    private grapeDataService: GrapeDataService,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService
  ) {
    this.loadLocalStorageData();
  }

  public togglePanel() {
    this.settings.holeDuplication.collapsed = !this.settings.holeDuplication
      .collapsed;
    this.settings.holeDuplication.save();
  }

  public showHoleDuplicates() {
    this.settings.holeDuplication.showDuplicates = !this.settings
      .holeDuplication.showDuplicates;
    this.settings.holeDuplication.save();
    this.hideErrorMessage = true;
    this.grapeDataService.setHoleDuplicates();
  }

  get filterToggleTitle(): Observable<string> {
    return this.settings.holeDuplication.holeDuplicates.excludedParts.length >
      0 || this.settings.holeDuplication.holeDuplicates.includedParts.length > 0
      ? this.translate.stream('HOLE_DUPLICATES.TOGGLE')
      : this.translate.stream('NO_FILTER');
  }

  get isFilterEnabled(): boolean {
    return (
      this.settings.holeDuplication.holeDuplicates.excludedParts.length > 0 ||
      this.settings.holeDuplication.holeDuplicates.includedParts.length > 0
    );
  }

  public isOpenSidememberError() {
    return this.grapeDataService.errorOpenSidemember;
  }

  get isListEmpty(): boolean {
    return (
      this.holeDuplicates.excludedParts.length < 1 &&
      this.holeDuplicates.includedParts.length < 1
    );
  }

  public deleteIncludedPart(partNo: string) {
    const index = this.holeDuplicates.includedParts.indexOf(partNo);
    this.holeDuplicates.includedParts.splice(index, 1);

    this.dirtyForm = true;
    this.hideErrorMessage = true;
  }

  public deleteExcludedPart(partNo: string) {
    const index = this.holeDuplicates.excludedParts.indexOf(partNo);
    this.holeDuplicates.excludedParts.splice(index, 1);

    this.dirtyForm = true;
    this.hideErrorMessage = true;
  }

  public addPartToIncludedList() {
    if (this.checkPartNoSuitability(this.newIncludedPartNo)) {
      this.holeDuplicates.includedParts.push(this.newIncludedPartNo);
      this.sortIncludedParts();
    }

    this.dirtyForm = true;
    this.newIncludedPartNo = '';
    this.hideErrorMessage = true;
  }

  public addPartToExcludedList() {
    if (this.checkPartNoSuitability(this.newExcludedPartNo)) {
      this.holeDuplicates.excludedParts.push(this.newExcludedPartNo);
      this.sortExcludedParts();
    }

    this.dirtyForm = true;
    this.newExcludedPartNo = '';
    this.hideErrorMessage = true;
  }

  public openEditDuplicates() {
    this.editDuplicates = !this.editDuplicates;
    if (this.settings.holeDuplication.collapsed) {
      this.togglePanel();
    }
  }

  public clearList() {
    this.settings.holeDuplication.showDuplicates = false;
    this.settings.holeDuplication.save();
    this.holeDuplicates = new HoleDuplicates();
    this.dirtyForm = true;
  }

  public checkTypedChar(input) {
    return input.keyCode === 43 || input.keyCode === 45 ? false : true;
  }

  public async validateImportAsync() {
    const gotLoadedDuplicates =
      this.holeDuplicates.includedParts.length > 0 ||
      this.holeDuplicates.excludedParts.length > 0;

    if (gotLoadedDuplicates) {
      await this.showImportConfirmationModalAsync();
    } else {
      this.importFile();
    }
  }

  public closeEditDuplicates(restore = false) {
    if (restore) this.loadLocalStorageData();
    this.editDuplicates = false;
    this.dirtyForm = false;
    this.hideErrorMessage = true;
  }

  public async validateClosingAsync() {
    if (this.dirtyForm) {
      await this.showSaveChangesModalOnCloseAsync();
    } else {
      this.closeEditDuplicates(true);
    }
  }

  //#region Modal
  async showActivationFilterModalOnSaveAsync() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_CONFIRMATION'),
      ModalType.YesNo
    );
    if (result === ModalResult.Yes) {
      this.settings.holeDuplication.showDuplicates = true;
    }
    this.persistHoleDuplicates();
  }

  async showSaveChangesModalOnCloseAsync() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('SAVE_CONFIRMATION'),
      ModalType.YesNoCancel
    );
    switch (result) {
      case ModalResult.Yes:
        await this.saveHoleDuplicatesAsync(true);
        break;
      case ModalResult.No:
        this.closeEditDuplicates(true);
        break;
      default:
        return;
    }
  }

  async showImportConfirmationModalAsync() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('HOLE_DUPLICATES.IMPORT_CONFIRMATION'),
      ModalType.YesCancel
    );
    if (result === ModalResult.Yes) {
      this.importFile();
    } else {
      return;
    }
  }

  ////#endregion

  public importFile(): void {
    this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  public async saveHoleDuplicatesAsync(onCancel = false) {
    if (!this.dirtyForm && this.isListEmpty) {
      return;
    }
    this.persistHoleDuplicates();

    if (
      !this.settings.holeDuplication.showDuplicates &&
      this.isFilterEnabled &&
      !onCancel
    ) {
      await this.showActivationFilterModalOnSaveAsync();
    }

    this.grapeDataService.setHoleDuplicates();
    this.closeEditDuplicates();
  }

  private persistHoleDuplicates() {
    this.settings.holeDuplication.holeDuplicates = this.holeDuplicates;
    if (!this.isFilterEnabled) {
      this.settings.holeDuplication.showDuplicates = false;
    }
    this.settings.holeDuplication.save();
  }

  public openFile(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }
    this.isLoading$.next(true);
    const selectedFile: File = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = (event: any) => {
      try {
        this.hideErrorMessage = true;
        this.holeDuplicates = JSON.parse(event.currentTarget.result);
        this.refineImportedFile();
        this.dirtyForm = true;
      } catch (error) {
        this.holeDuplicates.includedParts = [];
        this.holeDuplicates.excludedParts = [];
        this.isLoading$.next(false);
        this.hideErrorMessage = false;
      }
    };
  }

  public refineImportedFile(): void {
    let orderedList = new List(this.holeDuplicates.includedParts);
    this.holeDuplicates.includedParts = orderedList.Distinct().ToArray();

    orderedList = new List(this.holeDuplicates.excludedParts);
    this.holeDuplicates.excludedParts = orderedList.Distinct().ToArray();

    if (
      this.holeDuplicates.excludedParts.length === 0 &&
      this.holeDuplicates.includedParts.length === 0
    ) {
      this.hideErrorMessage = false;
    } else {
      this.hideErrorMessage = true;
      const holeDupl = this.holeDuplicates;
      holeDupl.includedParts.forEach((inPart: string) => {
        holeDupl.excludedParts.forEach((exPart, exPartIndex) => {
          if (inPart === exPart) {
            this.holeDuplicates.excludedParts.splice(exPartIndex, 1);
          }
        });
      });

      this.sortIncludedParts();
      this.sortExcludedParts();
    }
    this.isLoading$.next(false);
  }

  public exportHoleDuplicates() {
    const jsonData = JSON.stringify(this.holeDuplicates, null, 4);
    this.fileSaver.saveText(jsonData, `HoleDuplicates.json`);
  }

  private loadLocalStorageData(): void {
    this.settings.holeDuplication.load();
    this.holeDuplicates = this.settings.holeDuplication.holeDuplicates;
  }

  private sortIncludedParts(): void {
    this.holeDuplicates.includedParts.sort((x, y) => (x > y ? 1 : -1));
  }

  private sortExcludedParts(): void {
    this.holeDuplicates.excludedParts.sort((x, y) => (x > y ? 1 : -1));
  }

  private checkPartNoSuitability(partNo: string): boolean {
    if (partNo === null || partNo === '') {
      return false;
    }

    const existIncludedList = this.holeDuplicates.includedParts.includes(
      partNo
    );
    const existInExcludedList = this.holeDuplicates.excludedParts.includes(
      partNo
    );
    if (existIncludedList || existInExcludedList) {
      return false;
    }

    return true;
  }
  @HostListener('keydown.Enter', ['$event'])
  private onEnter(): void {
    if (document.activeElement.id === this.includedInputId) {
      this.addPartToIncludedList();
    } else if (document.activeElement.id === this.excludedInputId) {
      this.addPartToExcludedList();
    }
  }
}
