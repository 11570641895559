import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'LocaleDate',
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(value: string | Date): string {
    if (!value) {
      return '';
    }

    if (value instanceof Date) {
      return value.toLocaleString(this.localeService.locale);
    }

    const date = new Date(value);
    return date.toLocaleString(this.localeService.locale);
  }
}
