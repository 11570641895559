import { AbstractMesh, MeshBuilder, Vector3 } from 'babylonjs';
import { Hole } from '../../shared/models/grapeservice/hole';
import { BaseRenderer } from './base-renderer';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';
import { RenderEngine } from '../render-engine';
import { HoleCalculation } from '../core/hole-calculation';
import { PresentationSettings } from '../core/presentation-settings';
import { HoleSurfaceEnum } from '../core/enums/hole-surface-enum';
import { WorldTransformation } from '../core/world-transformation';
import { SidememberSide } from '../../shared/models/sidemember-enum';

export class HoleDistanceRenderer extends BaseRenderer {
  private get holeSurface(): HoleSurfaceEnum {
    return HoleCalculation.calculateHoleSurface(
      this.renderData,
      this.sidememberData
    );
  }

  private get holeSurfaceSecondaryHole(): HoleSurfaceEnum {
    return HoleCalculation.calculateHoleSurface(
      this.secondaryHole,
      this.sidememberData
    );
  }

  private get baseZIndexForLine(): number {
    return this.sidememberData.FrameSide === SidememberSide.Left
      ? this.engine.zIndex.baseDistanceLineZIndex
      : -1 * this.engine.zIndex.baseDistanceLineZIndex;
  }



  private get startPoint(): Vector3 {
    return new Vector3(
      this.renderData.Hole_X,
      this.baseZIndexForLine,
      HoleCalculation.calculateFlangeLocation(
        this.parent.position,
        this.renderData,
        this.holeSurface
      )
    );
  }
  private get endPoint(): Vector3 {
    return new Vector3(
      this.secondaryHole.Hole_X,
      this.baseZIndexForLine,
      HoleCalculation.calculateFlangeLocation(
        this.parent.position,
        this.secondaryHole,
        this.holeSurfaceSecondaryHole
      )
    );
  }

  constructor(
    protected settings: PresentationSettings,
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: Hole,
    protected secondaryHole: Hole,
    protected sidememberData: SidememberInfo,
    protected parent?: AbstractMesh
  ) {
    super(
      engine,
      materials,
      renderData,
      sidememberData,
      parent,
      secondaryHole,
      settings
    );
    this.createTextNode();
  }

  protected createMesh(): void {
    this.mesh = MeshBuilder.CreateLines(
      'HoleDistance',
      {
        points: [this.startPoint, this.endPoint],
        colors: [this.outlineColor, this.outlineColor, this.outlineColor],
      },
      this.engine.scene
    );
  }

  public getSecondaryHoleId(): number {
    return this.secondaryHole.HoleId;
  }

  private createTextNode(): void {
    const planeMesh = MeshBuilder.CreatePlane(
      'DistanceTextPlane',
      { size: 0 },
      this.engine.scene
    );
    planeMesh.position = WorldTransformation.worldToLocal(
      Vector3.Lerp(this.startPoint, this.endPoint, 0.5),
      this.parent.position
    );
    planeMesh.parent = this.parent;
    planeMesh.material = this.materials.transparent;
    this.upperTextNode = planeMesh;
  }
}
