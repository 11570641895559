import { RulerGUI } from './ruler-gui';
import * as GUI from 'babylonjs-gui';
import { RenderEngine } from '../render-engine';
import { Hole } from '../../shared/models/grapeservice/hole';
import { HoleInfoGUI } from './hole-info-gui';
import { PresentationSettings } from '../core/presentation-settings';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { HoleDistanceGUI } from './hole-distance-gui';

export class SidememberGUI {
  protected gui: GUI.AdvancedDynamicTexture;
  protected selectedHole: Hole;
  protected holeDimensionText: GUI.TextBlock;
  protected holeInformationText: GUI.TextBlock;
  protected holeInfo: HoleInfoGUI;
  protected activeHoleInfoGUIs: HoleInfoGUI[] = [];
  protected uniqueHoleInfoGUIs: HoleInfoGUI[] = [];
  protected activeDistanceInfo: HoleDistanceGUI[] = [];
  protected ruler: RulerGUI;

  constructor(
    private engine: RenderEngine,
    private settings: PresentationSettings
  ) {
    this.gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
    this.gui.renderAtIdealSize = true;
    this.holeInfo = new HoleInfoGUI(this.engine, this.gui, this.settings);
    this.ruler = new RulerGUI(this.engine, this.gui, this.settings);
  }

  public showHoleInfo(
    hole: Hole,
    sidememberInfo: SidememberInfo,
    upperTextNode: any,
    lowerTextNode: any
  ): void {
    this.selectedHole = hole;
    this.holeInfo.show(hole, sidememberInfo, upperTextNode, lowerTextNode);
  }

  public showSecondHoleInfo(
    hole: Hole,
    sidememberInfo: SidememberInfo,
    upperTextNode: any,
    lowerTextNode: any
  ): void {
    const holegui = new HoleInfoGUI(this.engine, this.gui, this.settings);
    this.activeHoleInfoGUIs.push(holegui);
    holegui.show(hole, sidememberInfo, upperTextNode, lowerTextNode);
  }

  public showHoleDistance(
    secondaryHoleId: number,
    holeDistance: string,
    upperTextNode: any
  ) {
    const distanceInfo = new HoleDistanceGUI(
      this.engine,
      this.gui,
      this.settings
    );
    distanceInfo.show(secondaryHoleId, holeDistance, upperTextNode);
    this.activeDistanceInfo.push(distanceInfo);
  }

  public clearAllHoleDistances() {
    for (const distanceInfo of this.activeDistanceInfo) {
      distanceInfo.destroy();
    }
    this.activeDistanceInfo = [];
  }

  public removeHoleDistance(secondaryHoleId: number) {
    
    const resIndex = this.activeDistanceInfo.findIndex(
      (x) => x.secondaryHoleId === secondaryHoleId
    );
    if (resIndex !== -1) {
      this.activeDistanceInfo[resIndex].destroy();
      this.activeDistanceInfo.splice(resIndex, 1);
    }
  }

  public hideHoleInfo(): void {
    this.holeInfo.hide();
  }

  public hideAllSecondaryHolesInfo(): void {
    this.activeHoleInfoGUIs.forEach((holeInfoGui) => {
      holeInfoGui.hide();
    });
    this.activeHoleInfoGUIs = [];
  }

  public hideSecondaryHoleInfo(holeId: number): void {
    const resIndex = this.activeHoleInfoGUIs.findIndex(
      (x) => x.selectedHole.HoleId === holeId
    );
    if (resIndex !== -1) {
      this.activeHoleInfoGUIs[resIndex].hide();
      this.activeHoleInfoGUIs.splice(resIndex, 1);
    }
  }

  public update(): void {
    this.ruler.update();
  }

  public reset(): void {
    this.ruler.update();
    this.holeInfo.hide();
  }

  public dispose(): void {
    this.ruler.dispose();
    this.gui.dispose();
  }
}
