import { BaseRenderer } from './base-renderer';
import { Vector3, AbstractMesh } from 'babylonjs';
import { RenderEngine } from '../render-engine';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { WorldTransformation } from '../core/world-transformation';
import { SidememberSide } from '../../shared/models/sidemember-enum';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';
import { BabylonExtension } from '../babylon/babylon.extension';

export class ReinforcementRenderer extends BaseRenderer {
  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: SidememberInfo,
    protected sidememberData: SidememberInfo,
    protected parent: AbstractMesh
  ) {
    super(engine, materials, renderData, sidememberData, parent);
  }

  protected createMesh(): void {
    const begPos = WorldTransformation.worldToLocal(
      new Vector3(this.renderData.BD_BlankBeg_X, 0, 0),
      this.parent.position
    );

    const endPos = WorldTransformation.worldToLocal(
      new Vector3(this.renderData.BD_BlankEnd_X, 0, 0),
      this.parent.position
    );

    const lineHeight =
      Number(this.sidememberData.WebHeight) +
      Number(this.sidememberData.FlangeWidth) * 2;

    const lineZ =
      this.sidememberData.FrameSide === SidememberSide.Right ? 1 : -1;

    this.createReinforcementLine(
      begPos.x,
      -(lineHeight / 2),
      begPos.x,
      lineHeight / 2,
      lineZ
    );

    this.createReinforcementLine(
      endPos.x,
      -(lineHeight / 2),
      endPos.x,
      lineHeight / 2,
      lineZ
    );
  }

  private createReinforcementLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    z: number
  ): void {
    const startPoint = new Vector3(x1, y1, z);
    const endPoint = new Vector3(x2, y2, z);

    const line = BabylonExtension.createDashedLinesWithColor(
      'FlangeLine',
      {
        gapSize: 1,
        dashSize: 1,
        color: this.outlineColor,
        points: [startPoint, endPoint],
        updatable: true,
        dashNb: 100
      },
      this.engine.scene
    );

    line.parent = this.parent;
  }
}
