<div class="d-flex flex-column flex-grow-1 flex-shrink-1">
  <canvas
    class="flex-grow-1 flex-shrink-1 canvas-style"
    oncontextmenu="return false;"
    #canvas
    width="882"
    height="348"
  ></canvas>

  <app-sidemember-minimap
    class="flex-grow-0 flex-shrink-0"
    [hidden]="
      !settings?.sidememberPresentation?.showSidememberOrientation || false
    "
    [showMinimapInfo]="false"
    [selectedHoleOptions]="selectedHoleOptions"
    [viewport]="engineCameraMovement$ | async"
    (pan)="onMinimapPanning($event)"
    (zoom)="onMinimapZooming($event)"
    #minimap
  >
  </app-sidemember-minimap>
</div>

<app-sidemember-canvas-info
  [selectedHole]="selectedHole$ | async"
  [data]="sidememberInit$ | async"
  [pointerLoc]="pointerLoc$ | async"
  [pointerInfo]="pointerInfo$ | async"
></app-sidemember-canvas-info>
