import { GrapeDataModel } from '../shared/models/grapeservice/grape.data';
import { RenderEngine } from './render-engine';
import { SidememberSide } from '../shared/models/sidemember-enum';
import { SidememberRenderer } from './renderers/sidemember-renderer';
import { HoleRenderer } from './renderers/hole-renderer';
import { ReinforcementRenderer } from './renderers/reinforcement-renderer';
import { RoundHoleRenderer } from './renderers/round-hole-renderer';
import { RectangularHoleRenderer } from './renderers/rectangular-hole-renderer';
import { NoShapeRenderer } from './renderers/noshape-renderer';
import { LongHoleRenderer } from './renderers/long-hole-renderer';
import { SidememberMaterial } from '../shared/models/rendering/sidemember-material';
import { Vector3, StandardMaterial, AbstractMesh, Color3 } from 'babylonjs';
import { SidememberGUI } from './gui/sidemember-gui';
import { HoleShapeEnum } from './core/enums/hole-shape-enum';
import { Hole, FilteredHole } from '../shared/models/grapeservice/hole';
import { HoleMeshes } from '../shared/models/rendering/hole-meshes';
import { PresentationSettings } from './core/presentation-settings';
import { SearchCriteriaColor } from '../shared/models/search-criteria-color';
import { HoleDistanceService } from '../shared/services/hole-distance.service';
import { HoleDistanceRenderer } from './renderers/hole-distance-renderer';
import { HoleSidememberSegmentService } from '../shared/services/hole-sidemember-segment.service';
import { HoleDistanceOption } from '../shared/models/hole-distance-option';

export class SidememberRendering {
  public holeMeshes: HoleMeshes;

  private gui: SidememberGUI;
  private materials: SidememberMaterial;
  private renderedSidemember: SidememberRenderer;
  private renderedReinforcements: ReinforcementRenderer[] = [];
  private renderedHoles: HoleRenderer[] = [];
  private PPTRHoles: HoleRenderer[] = [];
  private rectangularHoles: HoleRenderer[] = [];
  private renderedDistances: HoleDistanceRenderer[] = [];
  private searchHoleColors = new SearchCriteriaColor();
  private selectedHole: Hole;

  public get isRectangularHoleHidden() {
    return !this.settings.printRestrictedAreas;
  }

  constructor(
    private engine: RenderEngine,
    private data: GrapeDataModel,
    private settings: PresentationSettings,
    private holeDistanceService: HoleDistanceService,
    private holeSidememberSegmentService: HoleSidememberSegmentService
  ) {
    this.createMaterials();
    this.createMeshes();
    this.createGUI();
  }

  public onRender(): void {
    this.gui.update();
  }

  public dispose(): void {
    this.invertTextNodes();

    if (this.renderedSidemember) {
      this.renderedSidemember.dispose();
      this.renderedSidemember = null;
    }

    for (const distanceLine of this.renderedDistances) {
      distanceLine.dispose();
    }

    this.renderedDistances = [];

    for (const reinforcement of this.renderedReinforcements) {
      reinforcement.dispose();
    }

    this.renderedReinforcements = [];

    for (const hole of this.renderedHoles) {
      hole.dispose();
    }

    this.renderedHoles = [];

    this.gui.dispose();
  }

  public resetHoleDuplicates(): void {
    this.renderedHoles.forEach((renderedHole: HoleRenderer) =>
      renderedHole.hideHoleDuplicate()
    );
  }

  public resetAllHoles(resetFilter = true): void {
    this.renderedHoles.forEach((renderedHole: HoleRenderer) =>
      renderedHole.reset(resetFilter, this.data.compareMode)
    );
  }

  public hideAllHoleDistances(): void {
    this.renderedDistances.forEach((renderedDistance: HoleDistanceRenderer) => {
      renderedDistance.dispose();
    });

    this.gui.clearAllHoleDistances();
    this.renderedDistances = [];
  }

  public removeHoleDistance(holeId: number): void {
    const resIndex = this.renderedDistances.findIndex(
      (x) => x.getSecondaryHoleId() === holeId
    );

    if (resIndex !== -1) {
      this.renderedDistances[resIndex].dispose();
      this.renderedDistances.splice(resIndex, 1);
    }

    this.gui.removeHoleDistance(holeId);
  }

  public selectHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.select();
  }

  public markHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.mark();
  }

  public markSecondaryHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.markSecondary();
  }

  public unMarkSecondaryHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.reset(false, this.data.compareMode);
  }

  public highlightHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.highlight();
  }

  public showHoleDuplicate(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    renderedHole.showHoleDuplicate();
  }

  public setVisibilityPPTRHoleMarks(printHoleMarking: boolean): void {
    this.PPTRHoles.forEach((renderer) => {
      renderer.setVisibilityPPTRHoleMarks(printHoleMarking);
    });
  }

  public setVisibilityRestrictedAreas(printRestrictedAreas: boolean): void {
    this.rectangularHoles.forEach((renderer) => {
      renderer.setVisibilityRestrictedAreas(printRestrictedAreas);
    });
    if (
      +this.selectedHole?.HoleShape === HoleShapeEnum.Rectangular ||
      +this.selectedHole?.HoleShape === HoleShapeEnum.Cut
    ) {
      this.hideHoleInfo();
    }
  }

  public filterHole(hole: FilteredHole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (!renderedHole) return;

    const material = this.materials[hole.HoleColor.toLowerCase()];
    if (!material) return;

    renderedHole.filterByMaterial(this.materials[hole.HoleColor.toLowerCase()]);
  }

  public moveToFront(additionalHoles: Hole[], selectedHole?: Hole): void {
    const mashesToMoveFront = [];
    additionalHoles.forEach((hole: Hole) => {
      const renderer = this.getHoleRenderer(hole);
      if (renderer) {
        mashesToMoveFront.push(renderer.mesh);
      }
    });

    if (selectedHole !== null && selectedHole !== undefined) {
      const renderer = this.getHoleRenderer(selectedHole);
      if (renderer) {
        mashesToMoveFront.push(renderer.mesh);
      }
    }
    this.engine.zIndex.moveToFront(mashesToMoveFront);
  }

  public moveToFrontForDistanceMode(
    selectedHoles: Hole[],
    holeDistanceOption: HoleDistanceOption
  ): void {
    const mashesToMoveFront = [];
    selectedHoles.forEach((hole: Hole) => {
      const renderer = this.getHoleRenderer(hole);
      if (renderer) {
        mashesToMoveFront.push(renderer.mesh);
      }
    });
    this.engine.zIndex.moveToFrontForDistanceMode(
      mashesToMoveFront,
      holeDistanceOption
    );
  }

  public showHoleInfo(hole: Hole): void {
    this.selectedHole = hole;

    const renderedHole = this.getHoleRenderer(hole);

    const hideHoleInfoForRestrictedArea =
      (this.isRectangularHoleHidden &&
        +hole?.HoleShape === HoleShapeEnum.Rectangular) ||
      +hole?.HoleShape === HoleShapeEnum.Cut;

    if (!renderedHole || hideHoleInfoForRestrictedArea) return;

    this.gui.showHoleInfo(
      hole,
      this.data.HFInfo,
      renderedHole.upperTextNode,
      renderedHole.lowerTextNode
    );
  }

  public showSecondaryHoleInfo(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);

    if (!renderedHole) return;

    this.gui.showSecondHoleInfo(
      hole,
      this.data.HFInfo,
      renderedHole.upperTextNode,
      renderedHole.lowerTextNode
    );
  }

  public drawHoleDistanceLine(primaryHole: Hole, secondaryHole: Hole) {
    const renderer = new HoleDistanceRenderer(
      this.settings,
      this.engine,
      this.materials,
      primaryHole,
      secondaryHole,
      this.data.HFInfo,
      this.renderedSidemember.mesh
    );

    const distanceText = this.holeDistanceService
      .calculate(primaryHole, secondaryHole)
      .toString();
    this.gui.showHoleDistance(
      secondaryHole.HoleId,
      distanceText,
      renderer.upperTextNode
    );

    this.renderedDistances.push(renderer);
  }

  public hideHoleInfo(): void {
    this.selectedHole = null;
    this.gui.hideHoleInfo();
    this.gui.hideAllSecondaryHolesInfo();
  }

  public hideAllSecondaryHolesInfo(): void {
    this.gui.hideAllSecondaryHolesInfo();
  }

  public hideSecondaryHoleInfo(holeId: number): void {
    this.gui.hideSecondaryHoleInfo(holeId);
  }

  public centerOnHole(hole: Hole): void {
    const renderedHole = this.getHoleRenderer(hole);
    if (renderedHole && renderedHole.mesh) {
      this.engine.focusOn(renderedHole.mesh, true);
    } else {
      this.engine.moveTo(
        new Vector3(
          hole.Hole_X,
          this.engine.cameraController.getPosition().y,
          hole.Hole_Z
        ),
        true
      );
    }
  }

  public centerOnSidemember(): void {
    const sidememberOffsetX =
      Math.abs(
        this.data.HFInfo.BD_BlankEnd_X - this.data.HFInfo.BD_BlankBeg_X
      ) / 2;

    const totalOffsetX = sidememberOffsetX - this.data.HFInfo.BD_BlankBeg_X / 2;
    const focusOffset = new Vector3(-totalOffsetX, 0, 0);

    this.engine.focusOn(this.renderedSidemember.mesh, false, 1, focusOffset);
  }

  public invertTextNodes(): void {
    for (const hole of this.renderedHoles) {
      hole.invertTextNodes();
    }
  }

  public redraw(): void {
    if (this.selectedHole) {
      this.showHoleInfo(this.selectedHole);
    }
  }

  private getHoleRenderer(hole: Hole): HoleRenderer {
    if (!hole) return null;
    return this.renderedHoles.find((renderer) =>
      renderer.mesh ? renderer.holeId === hole.HoleId : null
    );
  }

  public setSettings(settings: PresentationSettings): void {
    // TODO: Listen to changes of material colors
    this.settings.holeInformation = settings.holeInformation;
    this.settings.printRestrictedAreas = settings.printRestrictedAreas;
    this.settings.holeDistanceOption = settings.holeDistanceOption;
    this.materials.default.emissiveColor = settings.holeColors.initialHoleColor;
    this.materials.duplicate.emissiveColor = settings.holeColors.duplicateColor;
  }

  private createMaterials(): void {
    this.materials = {
      transparent: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.transparentColor,
        'Transparent'
      ),
      outline: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.holeOutlineColor,
        'Outline'
      ),
      default: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.initialHoleColor,
        'Default'
      ),
      selected: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.selectedHoleColor,
        'Selected'
      ),
      marked: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.markedHoleColor,
        'Marked'
      ),
      highlight: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.highlightedHoleColor,
        'Highlight'
      ),
      duplicate: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.settings.holeColors.duplicateColor,
        'Duplicate'
      ),
      red: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Red'),
        'Red'
      ),
      black: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Black'),
        'Black'
      ),
      blue: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Blue'),
        'Blue'
      ),
      cyan: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Cyan'),
        'Cyan'
      ),
      darkblue: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkBlue'),
        'DarkBlue'
      ),
      darkcyan: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkCyan'),
        'DarkCyan'
      ),
      darkgray: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkGray'),
        'DarkGray'
      ),
      darkgreen: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkGreen'),
        'DarkGreen'
      ),
      darkpurple: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkPurple'),
        'DarkPurple'
      ),
      darkred: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('DarkRed'),
        'DarkRed'
      ),
      gold: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Gold'),
        'Gold'
      ),
      gray: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Gray'),
        'Gray'
      ),
      green: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Green'),
        'Green'
      ),
      olive: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Olive'),
        'Olive'
      ),
      orange: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Orange'),
        'Orange'
      ),
      purple: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Purple'),
        'Purple'
      ),
      yellow: this.engine.meshController.createMaterial(
        this.engine.scene,
        this.getSearchHoleColor('Yellow'),
        'Yellow'
      ),
    };
  }

  private getSearchHoleColor(color: string): Color3 {
    const foundColor = this.searchHoleColors.colors.find(
      (x) => x.name === color
    );
    if (foundColor) {
      return foundColor
        ? Color3.FromHexString(foundColor.value)
        : Color3.FromHexString('#0000FF');
    }
  }

  private createGUI(): void {
    this.gui = new SidememberGUI(this.engine, this.settings);
  }

  private createMeshes(): void {
    this.createSidemember();
    if (!this.data.compareMode) {
      this.createReinforcements();
    }
    this.createHoles();
  }

  private createSidemember(): void {
    this.renderedSidemember = new SidememberRenderer(
      this.engine,
      this.materials,
      this.data.HFInfo
    );
  }

  private createReinforcements(): void {
    for (const reinforcement of this.data.Reinforcements) {
      const renderer = new ReinforcementRenderer(
        this.engine,
        this.materials,
        reinforcement,
        this.data.HFInfo,
        this.renderedSidemember.mesh
      );

      this.renderedReinforcements.push(renderer);
    }
  }

  private createHoles(): void {
    const HOLE_RENDERER = {
      [HoleShapeEnum.NoShape]: NoShapeRenderer,
      [HoleShapeEnum.Round]: RoundHoleRenderer,
      [HoleShapeEnum.LongShape]: LongHoleRenderer,
      [HoleShapeEnum.Rectangular]: RectangularHoleRenderer,
      [HoleShapeEnum.Cut]: NoShapeRenderer,
      [HoleShapeEnum.MarkingPoint]: NoShapeRenderer,
      [HoleShapeEnum.Ellipse]: LongHoleRenderer,

    };

    this.holeMeshes = {};

    for (const hole of this.data.Holes) {
      const renderer = new HOLE_RENDERER[hole.HoleShape](
        this.engine,
        this.materials,
        hole,
        this.data.HFInfo,
        this.renderedSidemember.mesh,
        this.settings
      );

      if (renderer.mesh) {
        if (this.data.HFInfo.FrameSide === SidememberSide.Right) {
          renderer.mesh.position.z -= 2;
        }

        renderer.holeId = hole.HoleId;
        this.holeMeshes[renderer.mesh.uniqueId] = hole.HoleId;

        this.engine.zIndex.add(renderer.mesh);

        renderer.isCompareMode = this.data.compareMode;
      }

      if (+hole.SupplOper === 5) {
        this.PPTRHoles.push(renderer);
      }

      if (+hole.HoleShape === HoleShapeEnum.Rectangular) {
        this.rectangularHoles.push(renderer);
        renderer.setVisibilityRestrictedAreas(
          this.settings.printRestrictedAreas
        );
      }

      this.renderedHoles.push(renderer);
    }
  }

  private setMeshMaterial(
    mesh: AbstractMesh,
    material: StandardMaterial
  ): void {
    this.engine.meshController.setMaterial(mesh, material);
  }
}
