<div class="card-header no-gutter d-flex flex-row align-items-center">
  <div class="col-md-8">
    <h5>{{ 'VAR_CODE.SELECTED_VAR_CODES' | translate }}</h5>
  </div>
  <div class="col-md-4 text-right">
    <button mat-icon-button  id="saveVarCodeFilter" (click)="save()" title="{{ 'SAVE' | translate }}">
      <mat-icon>save</mat-icon>
    </button>

    <button mat-icon-button id="closeVarCodeFilter" (click)="validateClosing()" title="{{ 'CLOSE' | translate }}">
      <mat-icon>cancel</mat-icon>
    </button>
    <span class="linkSeparator"></span>
    <button mat-icon-button type="button" class="expand-mr" (click)="toggleCollapse()"
      [attr.title]="(isCollapsed ? 'EXPAND' : 'COLLAPSE') | translate">
      <mat-icon *ngIf="isCollapsed">expand_less</mat-icon>
      <mat-icon *ngIf="!isCollapsed">expand_more</mat-icon>
    </button>
  </div>
</div>
<div [hidden]="isCollapsed" class="card-body fh-panel-child d-flex flex-column auto-scroll">
  <app-variant-codes-definition [variantClasses]="variantClasses$ | async" [formControl]="filterDefinitionList"
    [enableVariantClassOption]="false" class="m-3 h-0" #filterList></app-variant-codes-definition>
</div>