import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AutoPrintSettings } from '../shared/settings/auto-print-settings';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DataSource } from '../shared/models/grapeservice/datasource';

@Component({
  selector: 'app-auto-print-settings',
  templateUrl: './auto-print-settings.component.html',
  styleUrls: ['./auto-print-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoPrintSettingsComponent implements OnInit {
  @Input('settings') set settings(value: AutoPrintSettings) {
    this.settings$.next(value);
  }

  @Output() dataSourceChange = new EventEmitter<string>();

  public settings$: BehaviorSubject<AutoPrintSettings> = new BehaviorSubject(
    null
  );
  public intervals = [15, 30, 45, 60, 90, 120, 180, 240, 300, 600];
  public separatorKeyCodes = [COMMA, ENTER];
  public selectable = true;
  public removable = true;

  get sortedWorkplaces(): string[] {
    if (!this.settings$.value) {
      return [];
    }

    return this.settings$.value.workPlaces.sort((wplA, wplB) => {
      return wplA.toLowerCase().localeCompare(wplB.toLowerCase());
    });
  }

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() { }

  public close(): void {
    this.modalRef.hide();
  }

  public selectInterval(interval: number): void {
    if (!this.settings$.value) {
      return;
    }

    this.settings$.value.interval = interval;
    this.settings$.value.save();
  }

  public selectDataSource(dataSource: DataSource): void {
    this.settings$.value.dataSource = dataSource.DataSourceName;
    this.dataSourceChange.emit(this.settings$.value.dataSource);
    this.settings$.value.save();
  }

  public addWorkplace(event: MatChipInputEvent): void {
    if (!this.settings$.value || event.value === '') {
      return;
    }

    const input = event.input;
    const workplace = event.value;

    if (this.isValidWorkplace(workplace)) {
      this.settings$.value.workPlaces.push(workplace.trim());
      this.settings$.value.save();
    }

    if (input) {
      input.value = '';
    }
  }

  public removeWorkplace(workplace: string): void {
    if (!this.settings$.value) {
      return;
    }

    const index = this.settings$.value.workPlaces.indexOf(workplace);
    this.settings$.value.workPlaces.splice(index, 1);
    this.settings$.value.save();
  }

  public isSelectedInterval(interval: string): boolean {
    if (!this.settings$.value) {
      return false;
    }

    return this.settings$.value.interval === +interval;
  }

  private isValidWorkplace(name: string): boolean {
    const workplace = (name || '').trim().toLowerCase();

    const settingsValueValid = this.settings$.value || workplace;
    if (!settingsValueValid) {
      return false;
    }

    const lowercaseArray = this.settings$.value.workPlaces.map(wpl =>
      wpl.toLowerCase()
    );

    if (!settingsValueValid || lowercaseArray.includes(workplace)) {
      return false;
    }

    return true;
  }
}
