export enum HoleInfssoEnum {
  diameter = 'diameter',
  holeNo = 'holeNo',
  holeTo = 'holeTo',
  partNumber = 'partNumber',
  partName = 'partName',
  x = 'x',
  y = 'y',
  z = 'z',
  patternFiNum = 'patternFiNum',
  type = 'type',
  gObj = 'gObj',
  gsName = 'gsName',
  supplOper = 'supplOper',
  vinCode = 'vinCode',
}

export enum ModalResult {
  Ok = 'Ok',
  Yes = 'Yes',
  No = 'No',
  Cancel = 'Cancel',
}

export enum ModalType {
  YesCancel,
  YesNo,
  YesNoCancel,
  Ok,
  None
}
