import { Settings } from './settings';
import { EventEmitter } from '@angular/core';
import { HoleInfoEnum } from '../../rendering/core/enums/holeinfo-enum';

export class SidememberPresentationSettings extends Settings {
  public onFlipSidemember: EventEmitter<any>;

  public showUpsideDown = false;
  public printHoleMarking = true;
  public printRestrictedAreas = true;
  public showSidememberOrientation = true;
  public antiAliasing = true;
  public adaptToDeviceRatio = true;
  public defaultHoleColor = '#ffffff';
  public duplicateHoleColor = '#C0C0C0';
  public fullSize = false;
  public persistErrors = false;

  public holeInformation: HoleInfoEnum[] = [
    HoleInfoEnum.Diameter,
    HoleInfoEnum.HoleObj,
    HoleInfoEnum.AObj,
  ];

  protected holeInformationSortOrder: HoleInfoEnum[] = [
    HoleInfoEnum.Diameter,
    HoleInfoEnum.HoleObj,
    HoleInfoEnum.HoleToo,
    HoleInfoEnum.AObj,
    HoleInfoEnum.ASName,
    HoleInfoEnum.Hole_X,
    HoleInfoEnum.Hole_Y,
    HoleInfoEnum.Hole_Z,
    HoleInfoEnum.HPObj,
    HoleInfoEnum.HoleType,
    HoleInfoEnum.GObj,
    HoleInfoEnum.GSName,
    HoleInfoEnum.SupplOper,
    HoleInfoEnum.VINCode,
  ];

  public load(): void {
    const storedSettings = <SidememberPresentationSettings>super.load();
    this.onFlipSidemember = new EventEmitter();

    this.holeInformation = super.isStored('holeInformation', storedSettings)
      ? storedSettings.holeInformation
      : this.holeInformation;
    this.showUpsideDown = super.isStored('showUpsideDown', storedSettings)
      ? storedSettings.showUpsideDown
      : this.showUpsideDown;
    this.printHoleMarking = super.isStored('printHoleMarking', storedSettings)
      ? storedSettings.printHoleMarking
      : this.printHoleMarking;
      this.printRestrictedAreas = super.isStored('printRestrictedAreas', storedSettings)
      ? storedSettings.printRestrictedAreas
      : this.printRestrictedAreas;
    this.showSidememberOrientation = super.isStored(
      'showSidememberOrientation',
      storedSettings
    )
      ? storedSettings.showSidememberOrientation
      : this.showSidememberOrientation;

    this.persistErrors = super.isStored('persistErrors', storedSettings)
      ? storedSettings.persistErrors
      : this.persistErrors;

    this.antiAliasing = super.isStored('antiAliasing', storedSettings)
      ? storedSettings.antiAliasing
      : this.antiAliasing;
    this.adaptToDeviceRatio = super.isStored(
      'adaptToDeviceRatio',
      storedSettings
    )
      ? storedSettings.adaptToDeviceRatio
      : this.adaptToDeviceRatio;
    this.defaultHoleColor = super.isStored('defaultHoleColor', storedSettings)
      ? storedSettings.defaultHoleColor
      : this.defaultHoleColor;
    this.duplicateHoleColor = super.isStored(
      'duplicateHoleColor',
      storedSettings
    )
      ? storedSettings.duplicateHoleColor
      : this.duplicateHoleColor;

    this.fullSize = super.isStored('fullSize', storedSettings)
      ? storedSettings.fullSize
      : this.fullSize;

    this.onLoad.emit(this.name);
  }

  public save(): void {
    super.save(this);
    this.onSave.emit(this.name);
  }
}
