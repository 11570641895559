export class InputEvents {
  public keyDown: (event: KeyboardEvent) => void;
  public keyUp: (event: KeyboardEvent) => void;
  public click: (event: MouseEvent) => void;
  public pointerDown: (event: PointerEvent) => void;
  public pointerUp: (event: PointerEvent) => void;
  public pointerMove: (event: PointerEvent) => void;
  public mouseWheel: (event: WheelEvent) => void;
  public focusOut: (event: any) => void;

  public attach(element: HTMLElement): void {
  if (this.keyUp) { element.addEventListener('keyup', this.keyUp, false); }
  if (this.keyDown) { element.addEventListener('keydown', this.keyDown, false); }
  if (this.click) { element.addEventListener('click', this.click, false); }
  if (this.pointerDown) { element.addEventListener('pointerdown', this.pointerDown, false); }
  if (this.pointerUp) { element.addEventListener('pointerup', this.pointerUp, false); }
  if (this.pointerMove) { element.addEventListener('pointermove', this.pointerMove, false); }
  if (this.mouseWheel) { element.addEventListener('mousewheel', this.mouseWheel, false); }
  if (this.mouseWheel) { element.addEventListener('wheel', this.mouseWheel, false); }
  if (this.focusOut) { element.addEventListener('focusout', this.focusOut, false); }
  }

  public detach(element: HTMLElement): void {
    if (this.keyUp) { element.removeEventListener('keyup', this.keyUp); }
    if (this.keyDown) { element.removeEventListener('keydown', this.keyDown); }
    if (this.click) { element.removeEventListener('click', this.click); }
    if (this.pointerDown) { element.removeEventListener('pointerdown', this.pointerDown); }
    if (this.pointerUp) { element.removeEventListener('pointerup', this.pointerUp); }
    if (this.pointerMove) { element.removeEventListener('pointermove', this.pointerMove); }
    if (this.mouseWheel) { element.removeEventListener('mousewheel', this.mouseWheel); }
    if (this.mouseWheel) { element.removeEventListener('wheel', this.mouseWheel); }
    if (this.focusOut) { element.removeEventListener('focusout', this.focusOut); }
  }
}