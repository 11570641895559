import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotifyToastrService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(content: string) {
    this.snackBar.open(content, 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['success-snackbar'],
    });
  }

  showWarning(content: string) {
    this.snackBar.open(content, 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['warning-snackbar'],
    });
  }

  showError(content: string) {
    this.snackBar.open(content, 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['error-snackbar'],
    });
  }

  showInfo(content: string) {
    this.snackBar.open(content, 'X', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['info-snackbar'],
    });
  }
}
