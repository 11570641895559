import { Vector3, Mesh } from 'babylonjs';
import { SidememberSide } from '../../shared/models/sidemember-enum';

export class WorldTransformation {
  public static worldToLocal(
    worldPosition: Vector3,
    localPosition: Vector3
  ): Vector3 {
    const x = worldPosition.x - Math.abs(localPosition.x);
    const y = worldPosition.z - Math.abs(localPosition.z);
    const z = -1;
    return new Vector3(x, y, z);
  }

  public static localToWorld(
    localPosition: Vector3,
    worldPosition: Vector3
  ): Vector3 {
    const x = localPosition.x + Math.abs(worldPosition.x);
    const z = localPosition.y + Math.abs(worldPosition.z);
    const y = -1;
    return new Vector3(x, y, z);
  }

  public static worldToSidememberPosition(
    position: Vector3,
    sidememberSide: SidememberSide,
    sidememberHeight: number
  ): Vector3 {
    const sidememberZ = 1000;
    const sidememberY = sidememberSide === SidememberSide.Right ? 385 : -385;

    const lowerFlangeZ = sidememberZ;
    const upperFlangeZ = sidememberZ + sidememberHeight;

    const x = position.x;
    let y = position.y;
    let z = position.z;

    let flangeOffsetY = 0;

    const sideMultiplier = sidememberSide === SidememberSide.Right ? -1 : 1;

    if (position.z > upperFlangeZ) {
      flangeOffsetY = upperFlangeZ - position.z;
      z = upperFlangeZ;
      y = sidememberY - flangeOffsetY * sideMultiplier;
    } else if (position.z < lowerFlangeZ) {
      flangeOffsetY = lowerFlangeZ - position.z;
      z = lowerFlangeZ;
      y = sidememberY + flangeOffsetY * sideMultiplier;
    } else {
      y = sidememberY;
    }

    return new Vector3(x, y, z);
  }
}
