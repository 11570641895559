import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import {
  GrapeObjectType,
  GrapeData,
} from '../shared/models/grapeservice/grape.data';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { filter, map } from 'rxjs/operators';
import { VariantCodes } from '../shared/models/grapeservice/variant-codes';
import { VariantCodesFilterDefinition } from '../shared/models/selected-variantcodes-def';
import { VariantClass } from '../shared/models/variant-class';
import { GrapeService } from '../shared/services/grape.service';
import { isNumber } from '../shared/logic/type-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[app-variant-codes-list]',
  templateUrl: './variant-codes-list.component.html',
  styleUrls: ['./variant-codes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantCodesListComponent {
  @Output('collapse') collapse = new EventEmitter();
  @Output('filterVariantCodes') filterVariantCodes = new EventEmitter();

  @Input('isCollapsed') isCollapsed: boolean;

  @Input('showData') set setShowData(showData: boolean) {
    this.showData = showData;
    this.variantCodes = [];
    this.scrollToTop();
    this.refreshData();
  }

  @Input('showVariantCodesFilter') set setShowVariantCodesFilter(_: boolean) {
    this.refreshData();
  }

  @ViewChild('variantCodesFiltered')
  variantCodesFiltered: ElementRef;
  @ViewChild('variantCodesList')
  variantCodesList: ElementRef;
  @ViewChild('hScrollFilteredList')
  hScrollFilteredList: ElementRef;
  @ViewChild('hScrollList')
  hScrollList: ElementRef;

  public showData: boolean;
  public grpData: GrapeData = null;
  public sortedVariantCodes$: BehaviorSubject<
    VariantCodes[]
  > = new BehaviorSubject([]);
  public selectedVariantCodes$: BehaviorSubject<
    VariantCodes[]
  > = new BehaviorSubject([]);
  public variantClasses$: Observable<VariantClass[]>;
  public variantClasses: VariantClass[] = [];
  public endOfVariantCodesList = false;

  public variantCodesColumns = [
    { name: this.translate.instant('VAR_CODE.CLASS'), value: 'VarClass', class: 'w-100' },
    { name: this.translate.instant('VAR_CODE.FAMILY'), value: 'VarFam', class: 'w-100' },
    { name: this.translate.instant('VAR_CODE.OPTION'), value: 'VarOpt', class: 'w-100' },
    { name: this.translate.instant('VAR_CODE.OPTION_NAME'), value: 'OptName', class: 'w-125' },
  ];

  private variantCodes: VariantCodes[];
  private variantCodes$: Observable<VariantCodes[]>;
  private subscriptions: Subscription[] = [];

  constructor(
    public settings: UserSettingsService,
    public grapeDataService: GrapeDataService,
    private grapeService: GrapeService,
    private translate: TranslateService
  ) {
    this.loadSettings();
    this.initializeObservables();
    this.subscribeToData();
  }

  get sortedVariantCodes(): VariantCodes[] {
    if (!this.variantCodes) {
      return [];
    }

    const sortedVariantCodes = this.sortVariantCodes(
      this.variantCodes,
      this.settings.variantCodes.sortColumn,
      this.settings.variantCodes.sortDesc
    );

    return this.settings.variantCodesFilter.isFilterActive
      ? sortedVariantCodes.filter(
          (vc) => !this.selectedVariantCodes.includes(vc)
        )
      : sortedVariantCodes;
  }

  get selectedVariantCodes(): VariantCodes[] {
    return this.sortVariantCodes(
      this.filterSelectedVariantCodes(),
      this.settings.variantCodesFilter.sortColumn,
      this.settings.variantCodesFilter.sortDesc
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public showVariantCodesFilter(): void {
    this.filterVariantCodes.emit();
  }

  public toggleCollapse(): void {
    this.collapse.emit();
  }

  public toggleFilter(): void {
    this.settings.variantCodesFilter.isFilterActive = !this.settings
      .variantCodesFilter.isFilterActive;
    this.settings.variantCodesFilter.save();
    this.refreshData();
  }

  get filterToggleTitle(): Observable<string> {
    return this.settings.variantCodesFilter.variantCodesDefinitions.length > 0
      ? this.translate.stream('VAR_CODE.TOGGLE_FILTER')
      : this.translate.stream('NO_FILTER');
  }

  get isFilterEnabled(): boolean {
    return this.settings.variantCodesFilter.variantCodesDefinitions.length > 0;
  }

  public isActiveSortColumn(
    column: string,
    descending: boolean,
    isFilter: boolean
  ): boolean {
    const settings = isFilter
      ? this.settings.variantCodesFilter
      : this.settings.variantCodes;
    return (
      settings.sortColumn.toLowerCase() === column.toLowerCase() &&
      settings.sortDesc === descending
    );
  }

  public sortBy(column: string, isFilter?: boolean): void {
    this.setSortColumn(column, isFilter);
    this.refreshData();
    this.scrollToTop();
  }

  public trackByFunction(index: number, item: any) {
    if (!item) return null;
    return index;
  }

  private refreshData(): void {
    this.selectedVariantCodes$.next([...this.selectedVariantCodes]);
    this.sortedVariantCodes$.next([...this.sortedVariantCodes]);
  }

  private loadSettings(): void {
    this.settings.variantCodes.load();
    this.settings.variantCodesFilter.load();
  }

  private initializeObservables(): void {
    this.variantCodes$ = this.grapeDataService.data.pipe(
      filter((data) => data.ChangedObject === GrapeObjectType.VariantCodes),
      map((data: GrapeData) => {
        return [...data.Data.VariantCodes];
      })
    );

    this.variantClasses$ = this.grapeService.variantClasses;
  }

  private subscribeToData(): void {
    this.subscriptions.push(
      this.variantCodes$.subscribe((variantCodes: VariantCodes[]) => {
        this.variantCodes = variantCodes;
        this.refreshData();
      }),
      this.grapeDataService.data.subscribe((data: GrapeData) => {
        if (data.Data.loadingVariants) {
          this.variantCodes = [];
          this.refreshData();
        }
      }),
      this.variantClasses$.subscribe(
        (variantClasses) => (this.variantClasses = variantClasses)
      )
    );
  }

  private scrollToTop(): void {
    if (!this.variantCodesList) return;
    this.variantCodesList.nativeElement.scrollTop = 0;
    if (!this.variantCodesFiltered) return;
    this.variantCodesFiltered.nativeElement.scrollTop = 0;
  }

  private setSortColumn(column: string, isFilter?: boolean): void {
    const settings = isFilter
      ? this.settings.variantCodesFilter
      : this.settings.variantCodes;

    if (settings.sortColumn === column) {
      settings.sortDesc = !settings.sortDesc;
    } else {
      settings.sortDesc = false;
    }

    settings.sortColumn = column;
    settings.save();
  }

  private sortVariantCodes(
    input: VariantCodes[],
    sortColumn: string,
    sortDesc: boolean
  ): VariantCodes[] {
    if (!input || input.length === 0) return [];

    const order = sortDesc ? -1 : 1;
    const list = [...input];

    return list.sort((a, b) => {
      const valA = a[sortColumn] || '';
      const valB = b[sortColumn] || '';

      if (valA === valB) return 0;

      if (isNumber(valA) && isNumber(valB)) {
        return Number(valA) > Number(valB) ? order : -order;
      }

      return valA.toUpperCase() > valB.toUpperCase() ? order : -order;
    });
  }

  private filterSelectedVariantCodes(): VariantCodes[] {
    return this.settings.variantCodesFilter.variantCodesDefinitions.map(
      (definition: VariantCodesFilterDefinition) => {
        const definitionCode = {
          FamName: null,
          OptName: null,
          OptValue: null,
          RecordType: null,
          VarClass: definition.class,
          VarFam: definition.family,
          VarOpt: null,
          NotFound: true,
        };

        const variantClass = this.variantClasses
          ? this.variantClasses.find((vc) => vc.Name === definition.class)
          : null;

        if (!variantClass) return definitionCode;

        let findVariantCodesFn: (
          vc: VariantCodes,
          def: VariantCodesFilterDefinition
        ) => void;

        if (variantClass.CanHaveFamily) {
          if (variantClass.MustHaveFamily) {
            findVariantCodesFn = this.findVariantClassWithFamily;
          } else {
            findVariantCodesFn = definition.family
              ? this.findVariantClassWithFamily
              : this.findVariantClass;
          }
        } else {
          findVariantCodesFn = this.findVariantClass;
        }

        const foundVariantCode = this.variantCodes.find((vc: VariantCodes) =>
          findVariantCodesFn(vc, definition)
        );

        return foundVariantCode || definitionCode;
      }
    );
  }

  private findVariantClass(
    variantCode: VariantCodes,
    definition: VariantCodesFilterDefinition
  ): boolean {
    return variantCode.VarClass === definition.class;
  }

  private findVariantClassWithFamily(
    variantCode: VariantCodes,
    definition: VariantCodesFilterDefinition
  ): boolean {
    return (
      variantCode.VarClass === definition.class &&
      variantCode.VarFam === definition.family
    );
  }

  @HostListener('window:resize', ['all'])
  public onHorizontalScroll(type: string): void {
    if (type === 'list' || type === 'all') {
      this.variantCodesList.nativeElement.style.width = `${
        this.hScrollList.nativeElement.clientWidth +
        Math.floor(this.hScrollList.nativeElement.scrollLeft || 0)
      }px`;
    }
    if (type === 'filter' || type === 'all') {
      this.variantCodesFiltered.nativeElement.style.width = `${
        this.hScrollFilteredList.nativeElement.clientWidth +
        Math.floor(this.hScrollFilteredList.nativeElement.scrollLeft || 0)
      }px`;
    }
  }
}
