import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { UIEventService } from '../shared/services/ui-event.service';
import { HoleInfoEnum } from '../rendering/core/enums/holeinfo-enum';
import { TotalPictureSettings } from '../shared/settings/total-picture-settings';
import { BehaviorSubject } from 'rxjs';
import { SidememberPresentationSettings } from '../shared/settings/sidemember-presentation-settings';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  @Output('save') save: EventEmitter<boolean> = new EventEmitter();

  public holeInformationSelection: any[];

  public totalPictureSettings$: BehaviorSubject<TotalPictureSettings> = new BehaviorSubject(
    null
  );
  totalPictureSettings: TotalPictureSettings;
  sidememberPresentation: SidememberPresentationSettings;

  constructor(
    public settings: UserSettingsService,
    private uiEventService: UIEventService
  ) {
    this.loadSettings();
  }

  public loadSettings() {
    this.settings.totalPicture.load();
    this.settings.sidememberPresentation.load();

    this.holeInformationSelection = [
      ...this.settings.sidememberPresentation.holeInformation,
    ];

    this.totalPictureSettings = Object.assign({}, this.settings.totalPicture);
    this.totalPictureSettings$.next(this.totalPictureSettings);

    this.sidememberPresentation = Object.assign({},this.settings.sidememberPresentation)
  }

  public saveSettings() {
    this.settings.sidememberPresentation.holeInformation = this.sortSettingSelections();

    this.settings.sidememberPresentation.printHoleMarking = this.sidememberPresentation.printHoleMarking;
    this.settings.sidememberPresentation.printRestrictedAreas = this.sidememberPresentation.printRestrictedAreas;

    this.settings.totalPicture.printTickMarks = this.totalPictureSettings.printTickMarks;
    this.settings.totalPicture.printHoleNumbers = this.totalPictureSettings.printHoleNumbers;
    this.settings.totalPicture.printXm = this.totalPictureSettings.printXm;
    this.settings.totalPicture.printHoleEnlarged = this.totalPictureSettings.printHoleEnlarged;

    this.settings.totalPicture.save();
    this.settings.sidememberPresentation.save();

    this.uiEventService.changedSettings();
    this.save.emit();
  }

  sortSettingSelections() {
    const holeInformationItemsSorted: any[] = [];
    for (const value in HoleInfoEnum) {
      if (typeof HoleInfoEnum[value] === 'number') {
        if (this.holeInformationSelection.includes(HoleInfoEnum[value])) {
          holeInformationItemsSorted.push(HoleInfoEnum[value]);
        }
      }
    }

    return holeInformationItemsSorted;
  }

  public changeHoleInformation(holeInformation: string): void {
    const holeInformationIndex = this.holeInformationSelection.indexOf(
      HoleInfoEnum[holeInformation]
    );
    if (holeInformationIndex > -1) {
      this.holeInformationSelection.splice(holeInformationIndex, 1);
    } else {
      this.holeInformationSelection.push(HoleInfoEnum[holeInformation]);
    }
  }

  public isChecked(holeInformation: string) {
    if (!this.holeInformationSelection) return false;

    return (
      this.holeInformationSelection.indexOf(HoleInfoEnum[holeInformation]) > -1
    );
  }

  public toggleTotalPictureTickMarks(enabled: boolean): void {
    if (!enabled) {
      this.totalPictureSettings.printXm = false;
    }
  }
}
