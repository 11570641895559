import { Injectable, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class WindowRef {

  constructor(@Inject(DOCUMENT) private document) {
  }

  get nativeWindow(): Window {
    return this.getWindow();
  }

  get nativeDocument(): Document {
    return this.document;
  }

  public getScrollbarSize(renderer: Renderer2): number {

    const inner = renderer.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = renderer.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    renderer.appendChild(this.document.body, outer);

    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';

    let w2 = inner.offsetWidth;
    if (w1 === w2) w2 = outer.clientWidth;

    renderer.appendChild(this.document.body, outer);

    return (w1 - w2);

  }

  private getWindow(): Window {
    return window;
  }

}