import { HoleRenderer } from './hole-renderer';
import { Mesh } from 'babylonjs';
import { BabylonExtension } from '../babylon/babylon.extension';
import { SidememberSide } from '../../shared/models/sidemember-enum';

export class RoundHoleRenderer extends HoleRenderer {
  protected createMesh(): void {
    this.mesh = BabylonExtension.createDiscWithoutArcEdge(
      'RoundHole',
      {
        radius: this.renderData.Diameter / 2,
        tessellation: 18,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE
      },
      this.engine.scene
    );
  }
}
