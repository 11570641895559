import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SidememberPresentationComponent } from './sidemember-presentation/sidemember-presentation.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { DisposableGuardService } from './shared/services/disposable-guard.service';
import { TotalPictureComponent } from './total-picture/total-picture.component';
import { AutoPrintComponent } from './auto-print/auto-print.component';
import { NotFoundComponent } from './shared/not-found/not-found-component';
import { SilentLoginComponent } from './silent-login/silent-login.component';
// import { UserManagementComponent } from './user-management/user-management.component';
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from '@azure/msal-browser';

const appRoutes: Routes = [
  { path: '',
    redirectTo: '/sidemember-presentation',
    pathMatch: 'full' },
  {
    path: 'silent-login',
    component: SilentLoginComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'sidemember-presentation',
    component: SidememberPresentationComponent,
   canActivate: [MsalGuard],
    // canDeactivate: [DisposableGuardService]
  },
  {
    path: 'total-picture/:dataSource/:ipoId/:version',
    component: TotalPictureComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'sidemember-presentation/:dataSource/:ipoId/:version',
    component: SidememberPresentationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'sidemember-compare/:dataSource/:ipoId/:version/:compareIpoId/:compareVersion',
    component: SidememberPresentationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'sidemember-presentation/:localFileName',
    component: SidememberPresentationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'auto-print',
    component: AutoPrintComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
