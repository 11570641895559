import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ModalDialogComponent } from '../components/modal-dialog/modal-dialog.component';
import { ModalType, ModalResult } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class ModalDialogService {
  private dialogRef: MatDialogRef<ModalDialogComponent>;

  constructor(public dialog: MatDialog, private translate: TranslateService) {}

  show(
    message: string,
    modalType: ModalType,
    title?: string,
    positiveBtnText?: string,
    negativeBtnText?: string,
    cancelBtnText?: string
  ): Promise<ModalResult> {
    return new Promise<ModalResult>((resolve, reject) => {
      this.dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '400px',
        maxWidth: '50vw',
        data: {
          message,
          modalType,
          title: title || '',
          positiveBtnText:
            positiveBtnText || modalType === ModalType.Ok
              ? this.translate.instant(ModalResult.Ok)
              : this.translate.instant(ModalResult.Yes),
          negativeBtnText: negativeBtnText || this.translate.instant(ModalResult.No),
          cancelBtnText: cancelBtnText || this.translate.instant(ModalResult.Cancel),
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        return resolve(result);
      });
    });
  }

  close(modalResult: ModalResult) {
    this.dialogRef.close(modalResult);
  } 
}
