<div class="p-4 auto-scroll h-100">
  <div class="col-md-12 p-0">
    <table *ngFor="let def of searchDefs" class="search-def-table">
      <tr class="defvaluesrowheigth">
        <td class="deftd" 
          [ngStyle]="{ 'border-color': def.color }">
          <select id="selectFieldToFilter" class="defField" id="filterAttribute{{ def.index }}"
            [(ngModel)]="def.attributeName" (ngModelChange)="onSearchAttributeChange(def, valueCtrl1, valueCtrl2)">
            <option value="" selected disabled>{{ 'HOLE_INFORMATION.SELECT_ATTRIBUTE' | translate }}</option>
            <option *ngFor="let column of getHoleInfoColumns" value="{{ column.value }}">
              {{ column.name | async }}
            </option>
          </select>
        </td>

        <td [ngStyle]="{ 'border-color': def.color }" class="deftd">
          <select id="selectFieldToFilter" class="defField" id="filterOperatorList{{ def.index }}"
            [(ngModel)]="def.operator" (ngModelChange)="onOperatorChange(def)">
            <option value="=" selected>=</option>
            <option *ngFor="let operator of getOtherOperators(def)" [value]="operator">{{ operator }}</option>
          </select>
        </td>

        <td [ngStyle]="{
            'border-color': def.color,
            'width': isItTheFromToOperator(def) ? '135px' : '300px',
            'max-width': isItTheFromToOperator(def) ? '135px' : '300px'
          }" class="deftd">

          <app-multi-select #valueCtrl1 [optionList]="def.searchList" [(value)]="def.value1" [multiple]="def.operator==='IN'"
            placeholder="{{ 'HOLE_INFORMATION.SELECT_TYPE' | translate }}" [disabled]="def.attributeName === ''">
          </app-multi-select>

        </td>

        <td *ngIf="isItTheFromToOperator(def)" class="deftd whitebg valueandvaluecolumn">
          {{ 'AND' | translate }}
        </td>

        <td *ngIf="isItTheFromToOperator(def)" [ngStyle]="{
            'border-color': def.color,
            'width': '135px',
            'max-width': '135px'
          }" class="deftd valuecolumnwidth">

          <app-multi-select #valueCtrl2 [optionList]="def.searchList" [(value)]="def.value2" [multiple]="false"
            placeholder="{{ 'HOLE_INFORMATION.SELECT_TYPE' | translate }}" [disabled]="def.attributeName === ''">
          </app-multi-select>

        </td>

        <td>
          <button mat-icon-button (click)="onDeleteDef(def)" id="deleteFilterDef{{ def.index }}" title="{{ 'REMOVE' | translate }}">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="whitebg connect-condition">
          <label *ngIf="isLastDefItem(def)"><input class="defField" type="radio" value="true"
              id="andOrAnd{{ def.index }}" name="andor{{ def.index }}" (click)="onIsAndClick(true, def)"
              [checked]="def.isAnd === true" />&nbsp;{{ 'AND_LOWER' | translate }}&nbsp;</label>
          <label *ngIf="isLastDefItem(def)"><input class="defField" type="radio" value="false"
              id="andOrOr{{ def.index }}" name="andor{{ def.index }}" (click)="onIsAndClick(false, def)"
              [checked]="def.isAnd === false" />&nbsp;{{ 'OR_LOWER' | translate }}</label>
        </td>

        <td *ngIf="isItTheFromToOperator(def)" colspan="2" class="whitebg"></td>
        <td class="whitebg"></td>
      </tr>
    </table>
  </div>

  <div class="mt-0 mb-4">
    <button mat-button (click)="onAddDefinitions()" id="addFilterDef" class="btn btn-link p-0" title="{{ 'HOLE_INFORMATION.ADD_FILTER' | translate }}">
      <mat-icon>add</mat-icon> {{ 'ADD'  | translate }}
    </button>
  </div>
</div>