import {
  Engine,
  Scene,
  Vector3,
  Color4,
  UniversalCamera,
  AbstractMesh,
  PickingInfo,
  Matrix,
  SceneOptimizerOptions,
  HardwareScalingOptimization,
  SceneOptimizer,
  ShadowsOptimization,
  LensFlaresOptimization,
  PostProcessesOptimization,
  ParticlesOptimization,
  RenderTargetsOptimization
} from 'babylonjs';
import { CameraController } from './camera/camera-controller';
import { WorldTransformation } from './core/world-transformation';
import { InputObserver } from './input/command/input-observer';
import { ZIndexController } from './core/controller/zindex-controller';
import { MeshController } from './core/controller/mesh-controller';
import { GridController } from './core/controller/grid-controller';
import { InputController } from './input/controller/input-controller';
import { InputCommand } from './input/command/input-command';
import { CommandType } from './input/command/command-type';

export class RenderEngine implements InputObserver {
  public window: Window;
  public canvas: HTMLCanvasElement;
  public canvasRect: ClientRect;
  public scene: Scene;

  public zIndex: ZIndexController;
  public pointerInfo: PickingInfo;
  public pointerLoc: Vector3 = Vector3.Zero();

  public cameraController: CameraController;
  public meshController: MeshController;
  public gridController: GridController;
  public inputController: InputController<UniversalCamera>;

  protected engine: Engine;
  protected showOrigo: boolean;
  protected showGrid: boolean;

  private initializedCanvas = false;

  constructor(
    windowRef: Window,
    canvas: HTMLCanvasElement,
    antiAliasing: boolean,
    adaptToDeviceRatio: boolean,
    showOrigo?: boolean,
    showGrid?: boolean
  ) {
    this.window = windowRef;
    this.canvas = canvas;
    this.canvasRect = this.canvas.getBoundingClientRect();

    this.engine = new Engine(
      this.canvas,
      antiAliasing,
      {
        audioEngine: false,
        autoEnableWebVR: false,
        preserveDrawingBuffer: true,
        stencil: true
      },
      adaptToDeviceRatio
    );

    this.engine.onResizeObservable.add(() => {
      if (!this.initializedCanvas) {
        if (this.onCanvasInit) this.onCanvasInit();
        this.initializedCanvas = true;
        return;
      }
    });

    this.showOrigo = showOrigo || false;

    this.createScene();
    this.setupCamera();
    this.setupInput();
    this.setupRendering();
    this.attachControl();

    this.window.addEventListener('resize', () => {
      this.resize();
    });

    this.zIndex = new ZIndexController();
    this.showGrid = showGrid || false;

    if (this.showGrid) {
      this.gridController.renderGrid();
    }
  }

  public onSceneClick: () => void;
  public onMeshClick: (mesh: AbstractMesh) => void;
  public onMeshRightClick: (mesh: AbstractMesh) => void;
  public onCanvasInit: () => void;
  public onRender: () => void;
  public onFocusOut: () => void;

  public focusOn(
    mesh?: AbstractMesh,
    lerp?: boolean,
    lerpSpeed?: number,
    offset?: Vector3
  ) {
    const x = mesh.position.x;
    const y = this.cameraController.getPosition().y;
    const z = mesh.position.z;

    let movePos = new Vector3(x, y, z);

    if (mesh.parent) {
      const parent = this.scene.getMeshByUniqueID(mesh.parent.uniqueId);
      movePos = WorldTransformation.localToWorld(
        mesh.position,
        parent.position
      );
    }

    movePos = movePos.add(offset ? offset : Vector3.Zero());

    if (lerp) {
      this.cameraController.moveTo(movePos, lerpSpeed ? lerpSpeed : 10);
    } else {
      this.cameraController.setPosition(movePos);
    }
  }

  public moveTo(position: Vector3, lerp?: boolean, lerpSpeed?: number): void {
    if (lerp) {
      this.cameraController.moveTo(position, lerpSpeed ? lerpSpeed : 10);
    } else {
      this.cameraController.setPosition(position);
    }
  }

  public zoom(zoomIn: boolean): void {
    this.cameraController.externalZoom(zoomIn);
  }

  public optimizeScene(): void {
    this.scene.createOrUpdateSelectionOctree();

    this.scene.clearColor = new Color4(255, 255, 255, 1);
    this.scene.audioEnabled = false;
    this.scene.collisionsEnabled = false;
    this.scene.fogEnabled = false;
    this.scene.lensFlaresEnabled = false;
    this.scene.lightsEnabled = false;
    this.scene.particlesEnabled = false;
    this.scene.postProcessesEnabled = false;
    this.scene.probesEnabled = false;
    this.scene.proceduralTexturesEnabled = false;
    this.scene.renderTargetsEnabled = false;
    this.scene.shadowsEnabled = false;
    this.scene.skeletonsEnabled = false;
    this.scene.spritesEnabled = false;
    this.scene.texturesEnabled = false;

    const options = new SceneOptimizerOptions(60, 50000);
    options.optimizations.push(new ShadowsOptimization(0));
    options.optimizations.push(new LensFlaresOptimization(0));
    options.optimizations.push(new PostProcessesOptimization(0));
    options.optimizations.push(new ParticlesOptimization(0));
    options.optimizations.push(new RenderTargetsOptimization(0));
    options.optimizations.push(new HardwareScalingOptimization(0, 1));

    const optimizer = new SceneOptimizer(this.scene, options);
  }

  public getRenderWidth(): number {
    return this.engine.getRenderWidth();
  }

  public getRenderHeight(): number {
    return this.engine.getRenderHeight();
  }

  public getScalingRatio(): number {
    return 1 + (1 - this.engine.getHardwareScalingLevel());
  }

  public getScalingLevel(): number {
    return this.engine.getHardwareScalingLevel();
  }

  public redraw(): void {
    this.engine.wipeCaches(true);
    this.resize();
  }

  public onInput(command: InputCommand): void {
    if (command.type === CommandType.POINTER_LEFT_DOWN) {
      const pickingInfo = this.scene.pick(
        this.scene.pointerX,
        this.scene.pointerY
      );
      if (!pickingInfo.hit || pickingInfo.pickedMesh.id === 'Web') {
        this.onSceneClick();
        return;
      }
      this.onMeshClick(pickingInfo.pickedMesh);
    }
    if (command.type === CommandType.POINTER_RIGHT_DOWN ||
        command.type === CommandType.POINTER_CTRL_LEFT) {
      const pickingInfo = this.scene.pick(
        this.scene.pointerX,
        this.scene.pointerY
      );
      if (!pickingInfo.hit || pickingInfo.pickedMesh.id === 'Web') {
        this.onSceneClick();
        return;
      }
      this.onMeshRightClick(pickingInfo.pickedMesh);
    }


    if (command.type === CommandType.POINTER_MOVE) {
      this.calculatePointerLoc();
    }

    if (command.type === CommandType.FOCUS_OUT) {
      if (this.onFocusOut) {
        this.onFocusOut();
      }
    }
  }

  public setZIndexDirection(direction: number): void {
    this.zIndex.direction = direction;
  }

  public dispose(): void {
    this.inputController.detachControl(this.canvas);
    this.scene.dispose();
    this.engine.stopRenderLoop();
  }

  public screenToWorld(x: number, y: number, ignoreScaling?: boolean): Vector3 {
    const camera = this.cameraController.getCamera();
    const scalingLevel = ignoreScaling
      ? 1
      : this.engine.getHardwareScalingLevel();
    const translatedX = x * scalingLevel;
    const translatedY = y * scalingLevel;
    const ray = this.scene.createPickingRay(
      translatedX,
      translatedY,
      Matrix.Identity(),
      camera
    );
    const location = ray.origin ? ray.origin : Vector3.Zero();
    return location;
  }

  public pxToUnits(pxWidth: number): number {
    const measureStartX = this.screenToWorld(0, 0, true).x;
    const measureEndX = this.screenToWorld(pxWidth, 0, true).x;
    const unitWidth = Math.abs(measureStartX - measureEndX);
    return unitWidth;
  }

  public onCameraMovement: (
    leftTop: Vector3,
    leftBottom: Vector3,
    rightTop: Vector3,
    rightBottom: Vector3
  ) => void;

  protected createScene(): void {
    this.scene = new Scene(this.engine, {
      useClonedMeshMap: true
    });
    this.scene.clearColor = new Color4(1, 1, 1, 1);
    this.scene.hoverCursor = 'default';
    this.optimizeScene();
  }

  protected resize(): void {
    this.engine.resize();
    this.cameraController.resize();
    this.canvasRect = this.canvas.getBoundingClientRect();
  }

  protected calculatePointerLoc(): void {
    const ray = this.scene.createPickingRay(
      this.scene.pointerX,
      this.scene.pointerY,
      Matrix.Identity(),
      this.cameraController.getCamera()
    );

    this.pointerLoc = ray.origin ? ray.origin : Vector3.Zero();
    this.pointerInfo = this.scene.pick(
      this.scene.pointerX,
      this.scene.pointerY
    );
  }

  protected setupCamera(): void {
    this.cameraController = new CameraController(
      'MainCamera',
      this,
      new Vector3(0, 1000, 0)
    );
    this.cameraController.onCameraMovement = (
      leftTop: Vector3,
      leftBottom: Vector3,
      rightTop: Vector3,
      rightBottom: Vector3
    ) => this.onCameraMovement(leftTop, leftBottom, rightTop, rightBottom);
  }

  protected setupInput(): void {
    this.cameraController.clearInput();
    this.inputController = new InputController<UniversalCamera>('Input2D');
    this.inputController.addInputObserver(this);
    this.cameraController.addInput(this.inputController);
  }

  protected attachControl(): void {

    this.cameraController.attachControl();
  }

  protected setupRendering(): void {
    this.engine.runRenderLoop(() => {
      this.render();
    });

    this.gridController = new GridController(this.scene);
    this.meshController = new MeshController(this.scene);

    if (this.showOrigo) {
      this.gridController.renderOrigo();
    }
  }

  protected render(showFps?: boolean): void {
    this.scene.render();
    this.cameraController.render();
    if (this.onRender) {
      this.onRender();
    }
    if (showFps) console.log(this.engine.getFps());
  }
}
