import { AutoPrintLogEntry } from './auto-print-log-entry';
import { AutoPrintSettingsComponent } from '../auto-print-settings/auto-print-settings.component';
import {
  BehaviorSubject,
  interval,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { filter, finalize, takeUntil, takeWhile } from 'rxjs/operators';
import { GrapeService } from '../shared/services/grape.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InitListEntry } from '../shared/models/grapeservice/initlist-entry';
import { InitListSidemember } from '../shared/models/grapeservice/initlist-sidemember';
import {
  TotalPicturePrintId,
  TotalPicturePrintingService,
  TotalPicturePrintError,
} from '../total-picture/total-picture-printing.service';
import { TypeState } from 'typestate';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { AutoPrintStatusDTO } from '../shared/models/grapeservice/auto-print-status-dto';
import { Title } from '@angular/platform-browser';
import { UIEventService } from '../shared/services/ui-event.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

enum AutoPrintStatus {
  Init,
  Ready,
  Start,
  Wait,
  Check,
  Print,
  Update,
  Stop,
}

@Component({
  selector: 'app-auto-print',
  templateUrl: './auto-print.component.html',
  styleUrls: ['./auto-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoPrintComponent implements OnInit, AfterViewInit {
  @ViewChild('totalPicture') totalPictureRef: ElementRef;

  public progress$: BehaviorSubject<number> = new BehaviorSubject(0);
  public progressText$: BehaviorSubject<string> = new BehaviorSubject('');
  public statusText$: BehaviorSubject<string> = new BehaviorSubject('');
  public log$: BehaviorSubject<AutoPrintLogEntry[]> = new BehaviorSubject([]);
  public isStopping = false;
  public selectedDataSource$: BehaviorSubject<string> = new BehaviorSubject('');

  private ticks$: Observable<number>;
  private stopPolling$: Subject<void> = new Subject();
  private state: TypeState.FiniteStateMachine<AutoPrintStatus>;
  private modalRef: BsModalRef;
  private subscriptions: Subscription[] = [];
  private printList: InitListEntry[] = [];
  private printedList: InitListEntry[] = [];
  private printQueue: InitListEntry[] = [];
  private updateQueue: AutoPrintStatusDTO[] = [];
  private logEntries: AutoPrintLogEntry[] = [];
  private logKey = 'AutoPrintLog';
  private errorOccured = false;

  constructor(
    public settings: UserSettingsService,
    public totalPicturePrinting: TotalPicturePrintingService,
    private modalService: BsModalService,
    private grapeService: GrapeService,
    private renderer: Renderer2,
    private titleService: Title,
    private uiEventService: UIEventService,
    private translate: TranslateService,
    private authService: AuthService,
    private router:Router,
    private authMsalService:MsalService
  ) {
    this.settings.autoPrint.load();
    this.settings.holeDuplication.load();
    this.totalPicturePrinting.renderer = this.renderer;
    this.ticks$ = interval(1000).pipe(takeUntil(this.stopPolling$));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  get isRunning(): boolean {
    return this.currentState !== AutoPrintStatus.Ready;
  }

  get currentState(): AutoPrintStatus {
    if (!this.state) return AutoPrintStatus.Init;
    return this.state.currentState;
  }

  get log(): AutoPrintLogEntry[] {
    return this.settings.autoPrint.showErrorsOnly
      ? this.logEntries.filter((entry) => entry.isError)
      : this.logEntries;
  }

  ngOnInit() {
     this.getUserRole();
    this.setPageTitle();
    this.loadLog();
    this.validateSettings();
    this.initializeState();
    this.initializeTotalPicture();
    this.subscribeToState();
    this.state.go(AutoPrintStatus.Ready);
  }
  getUserRole(){
    let user = this.authMsalService.instance.getAllAccounts()
    console.log(user,"user")
    console.log(user[0]?.idTokenClaims,"user role ")
    if(user[0]?.idTokenClaims.roles[0] === "ExternalUser"){
     console.log("i am in side ")
      this.router.navigateByUrl('/access-denied');
    } 
 
   }
  ngAfterViewInit() {
    setTimeout(() => {
      this.selectedDataSource$.next(
        this.settings.autoPrint.isValid
          ? this.settings.autoPrint.dataSource
          : '-'
      );
    });
  }

  // public async grapeUserRole() {
  //   const grapeAccess = await this.authService.GrapeUserRole();
  //   if (grapeAccess.includes("external")) {
  //     this.router.navigateByUrl('/access-denied');
  //   }
  // }

  public async start(): Promise<void> {
    const log = await this.translate.get('AUTO_PRINT.LOG.STARTED').toPromise();
    this.logEvent(log);
    this.state.go(AutoPrintStatus.Start);
    this.uiEventService.autoPrintStarted(true);
  }

  public stop(): void {
    if (this.totalPicturePrinting.isProcessing) {
      // TODO: Set stop button label to stopping and freeze it
      this.isStopping = true;
      return;
    }

    this.state.go(AutoPrintStatus.Stop);
    this.uiEventService.autoPrintStarted(false);
  }

  private setPageTitle() {
    this.titleService.setTitle('Grape auto-print');
  }

  public showSettings(): void {
    this.modalRef = this.modalService.show(AutoPrintSettingsComponent, {
      animated: true,
      keyboard: false,
      backdrop: 'static',
      class: 'modal-md',
    });

    const component = <AutoPrintSettingsComponent>this.modalRef.content;
    component.settings = this.settings.autoPrint;
    this.modalRef.content.dataSourceChange.subscribe((datasource: any) => {
      this.selectedDataSource$.next(`${datasource}`);
    });
  }

  public clearLog(): void {
    this.logEntries = [];
    this.emitLog();
    this.saveLog();
  }

  public showErrorsOnly(value:any): void {
    this.settings.autoPrint.showErrorsOnly = (value.target.value === 'true');
    this.settings.autoPrint.save();
    this.emitLog();
  }

  private validateSettings(): void {
    if (!this.settings.autoPrint.isValid) {
      this.showSettings();
    }
  }

  private initializeTotalPicture(): void {
    this.totalPicturePrinting.error$
      .pipe(
        filter((error: TotalPicturePrintError) => !!error),
        takeWhile(() => this.totalPicturePrinting.isProcessing)
      )
      .subscribe((error: TotalPicturePrintError) => {
        this.logError(error.message);
      });

    this.subscriptions.push(
      this.totalPicturePrinting.done$
        .pipe(filter((done: boolean) => done))
        .subscribe(() => {
          this.evaluatePrintingStatus();
        })
    );
  }

  private evaluatePrintingStatus(): void {
    if (this.printList.length === this.printedList.length) {
      if (this.updateQueue.length === 0) {
        this.state.go(
          this.isStopping ? AutoPrintStatus.Stop : AutoPrintStatus.Wait
        );
        return;
      }

      this.state.go(AutoPrintStatus.Update);
    } else {
      this.print();
    }
  }

  private initializeState(): void {
    this.state = new TypeState.FiniteStateMachine<AutoPrintStatus>(
      AutoPrintStatus.Init
    );

    this.state.from(AutoPrintStatus.Init).to(AutoPrintStatus.Ready);
    this.state.from(AutoPrintStatus.Ready).to(AutoPrintStatus.Start);
    this.state
      .from(AutoPrintStatus.Start)
      .to(AutoPrintStatus.Check, AutoPrintStatus.Wait);
    this.state.from(AutoPrintStatus.Wait).to(AutoPrintStatus.Check);
    this.state
      .from(AutoPrintStatus.Check)
      .to(AutoPrintStatus.Print, AutoPrintStatus.Wait);
    this.state
      .from(AutoPrintStatus.Print)
      .to(AutoPrintStatus.Update, AutoPrintStatus.Wait);
    this.state.from(AutoPrintStatus.Update).to(AutoPrintStatus.Wait);
    this.state.from(AutoPrintStatus.Stop).to(AutoPrintStatus.Ready);
    this.state.fromAny(AutoPrintStatus).to(AutoPrintStatus.Stop);
  }

  private subscribeToState(): void {
    this.state.on(AutoPrintStatus.Ready, async (_from: AutoPrintStatus) => {
      const status = await this.translate.get('AUTO_PRINT.STATUS.READY').toPromise();
      this.statusText$.next(status);
      this.progress$.next(0);
      this.progressText$.next('--:--');
    });

    this.state.on(AutoPrintStatus.Wait, async (_from: AutoPrintStatus) =>
      await this.waitForData()
    );

    this.state.on(AutoPrintStatus.Start, (_from: AutoPrintStatus) =>
      this.startPolling()
    );

    this.state.on(AutoPrintStatus.Check, async (_from: AutoPrintStatus) => {
      const status = await this.translate.get('AUTO_PRINT.STATUS.CHECKING').toPromise();
      this.statusText$.next(status);
      this.progressText$.next('--:--');
      this.checkForData();
    });

    this.state.on(AutoPrintStatus.Print, async (_from: AutoPrintStatus) => {
      const status = await this.translate.get('AUTO_PRINT.STATUS.PRINTING').toPromise();
      this.statusText$.next(status);
      this.print();
    });

    this.state.on(AutoPrintStatus.Update, async (_from: AutoPrintStatus) => {
      const status = await this.translate.get('AUTO_PRINT.STATUS.UPDATING').toPromise();
      this.statusText$.next(status);
      this.progressText$.next('--:--');
      this.updatePrintFlags();
    });

    this.state.on(AutoPrintStatus.Stop, async (_from: AutoPrintStatus) => {
      const log = await this.translate.get('AUTO_PRINT.LOG.STOPPED').toPromise();
      this.logEvent(log);
      this.stopPolling();
    });
  }

  private startPolling(): void {
    this.state.go(AutoPrintStatus.Check);
  }

  private stopPolling(): void {
    this.stopPolling$.next();
    this.state.go(AutoPrintStatus.Ready);
  }

  private async waitForData(): Promise<void> {
    const status = await this.translate.get('AUTO_PRINT.STATUS.WAITING').toPromise();
    this.statusText$.next(status);

    this.printList = [];
    this.printedList = [];

    this.ticks$.subscribe((seconds: number) => {
      this.calculateTimerProgress(seconds);

      if (seconds === this.settings.autoPrint.interval) {
        this.stopPolling$.next();
        this.state.go(AutoPrintStatus.Check);
      }
    });
  }

  private checkForData(): void {
    this.subscriptions.push(
      this.grapeService
        .getInitList(
          this.settings.autoPrint.dataSource,
          this.settings.autoPrint.workPlaces
        )
        .pipe(
          finalize(() =>
            this.state.go(
              this.printQueue.length !== 0
                ? AutoPrintStatus.Print
                : AutoPrintStatus.Wait
            )
          )
        )
        .subscribe(
          (entries: InitListEntry[]) => {
            this.printList = [...entries];
            this.printQueue.push(...entries);
          },
          (error: HttpErrorResponse) => {
            this.logError(
              this.translate.instant('AUTO_PRINT.EVENT.SIDEMEMBER_LIST_ERROR', { message: error.message })
            );
            this.errorOccured = true;
          }
        )
    );
  }

  private print(): void {
    const entry = this.printQueue.shift();

    this.logProcessEvent(entry);

    if (entry.SideMembers.length === 0) {
      this.logError(
        this.translate.instant('AUTO_PRINT.EVENT.NO_SIDEMEMBER_AVAILABLE', { popid: entry.POPID, wpl: entry.WPL })
      );
    }

    this.printEntry(entry);
  }

  private printEntry(entry: InitListEntry): Promise<void> {
    return new Promise<void>(async () => {
      if (entry.SideMembers.length === 0) {
        const entryIndex = this.printList.indexOf(entry);
        if (entryIndex !== -1) this.printList.splice(entryIndex, 1);
        this.evaluatePrintingStatus();
        return;
      }

      this.printedList.push(entry);

      const totalPictures: TotalPicturePrintId[] = [];

      entry.SideMembers.forEach((sidemember: InitListSidemember) => {
        const picture: TotalPicturePrintId = {
          dataSource: this.settings.autoPrint.dataSource,
          ipoId: sidemember.IPOID,
          version: sidemember.VERSION,
          popId: entry.POPID,
          wpl: entry.WPL,
        };

        this.logPrintEvent(picture);

        totalPictures.push(picture);
      });

      this.progressText$.next(
        `${this.printList.length - this.printQueue.length} of ${
          this.printList.length
        }`
      );

      this.totalPicturePrinting.print(totalPictures, this.totalPictureRef);

      this.updateQueue.push({
        IPOID: entry.IPOID,
        POPID: entry.POPID,
        WPL: entry.WPL,
      });
    });
  }

  private updatePrintFlags(): void {
    this.grapeService
      .setStatusPrinted(this.settings.autoPrint.dataSource, this.updateQueue)
      .pipe(
        finalize(() => {
          this.updateQueue = [];
          this.state.go(AutoPrintStatus.Wait);
        })
      )
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.logError(
            this.translate.instant('AUTO_PRINT.EVENT.DEFAULT_ERROR', { message: error.message })
          );
        }
      );
  }

  private calculateTimerProgress(seconds: number): void {
    const timeLeft = this.settings.autoPrint.interval - seconds;
    const displayMinutes = Math.floor(timeLeft / 60);
    const displaySeconds = timeLeft % 60;

    this.progressText$.next(
      `${displayMinutes
        .toString()
        .padStart(2, '0')}:${displaySeconds.toString().padStart(2, '0')}`
    );

    const progress = Math.round(
      (seconds / this.settings.autoPrint.interval) * 100
    );

    this.progress$.next(progress);
  }

  private logProcessEvent(entry: InitListEntry): void {
    if (!entry) return;
    this.logEvent(
      this.translate.instant('AUTO_PRINT.EVENT.PROCESSING', {
        POPID: entry.IPOID,
        WPL: entry.WPL,
      })
    );
  }

  private logPrintEvent(picture: TotalPicturePrintId): void {
    if (!picture) return;
    this.logEvent(
      this.translate.instant('AUTO_PRINT.EVENT.PRINTING', {
        ipoId: picture.ipoId,
        version: picture.version,
        popId: picture.popId,
        wpl: picture.wpl,
      })
    );
  }

  private logError(message: string): void {
    this.logEvent(message, true);
  }

  private logEvent(message: string, isError?: boolean): void {
    this.logEntries.unshift({
      message,
      isError: isError || false,
      date: new Date(),
    });

    this.emitLog();
    this.saveLog();
  }

  private loadLog(): void {
    this.logEntries = JSON.parse(localStorage.getItem(this.logKey)) || [];
    this.emitLog();
  }

  private saveLog(): void {
    localStorage.setItem(this.logKey, JSON.stringify(this.logEntries));
  }

  private emitLog(): void {
    this.log$.next(this.log);
  }
}
