import {
  Vector3,
  LinesMesh,
  Nullable,
  Scene,
  VertexData,
  _CreationDataStorage,
  Color4,
  Mesh,
  Vector4
} from 'babylonjs';

// tslint:disable:no-parameter-reassignment
export class BabylonExtension {
  public static createDiscWithoutArcEdge(
    name: string,
    options: any,
    scene: Scene
  ): any {
    if (scene === void 0) {
      scene = null;
    }
    const disc = new Mesh(name, scene);
    options.sideOrientation = BabylonExtension.updateSideOrientation(
      options.sideOrientation
    );
    disc._originalBuilderSideOrientation = options.sideOrientation;
    const vertexData = BabylonExtension.createDiscVertexData(options);
    vertexData.applyToMesh(disc, options.updatable);
    return disc;
  }

  public static createDiscVertexData(options: any): any {
    const positions = [];
    const indices = [];
    const normals = [];
    const uvs = [];
    const radius = options.radius || 0.5;
    const tessellation = options.tessellation || 64;
    const arc =
      options.arc && (options.arc <= 0 || options.arc > 1)
        ? 1.0
        : options.arc || 1.0;
    const sideOrientation =
      options.sideOrientation === 0
        ? 0
        : options.sideOrientation || Mesh.DEFAULTSIDE;

    if (options.arc) {
      positions.push(0, 0, 0);
    }

    uvs.push(0.5, 0.5);
    const theta = Math.PI * 2 * arc;
    const step = theta / tessellation;

    for (let a = 0; a < theta; a += step) {
      const x = Math.cos(a);
      const y = Math.sin(a);
      const u = (x + 1) / 2;
      const v = (1 - y) / 2;
      positions.push(radius * x, radius * y, 0);
      uvs.push(u, v);
    }

    if (options.arc && arc === 1) {
      positions.push(positions[3], positions[4], positions[5]);
      uvs.push(uvs[2], uvs[3]);
    }

    const vertexNb = positions.length / 3;
    for (let i = 1; i < vertexNb - 1; i += 1) {
      indices.push(i + 1, 0, i);
    }

    VertexData.ComputeNormals(positions, indices, normals);
    BabylonExtension.computeSides(
      sideOrientation,
      positions,
      indices,
      normals,
      uvs,
      options.frontUVs,
      options.backUVs
    );
    const vertexData = new VertexData();
    vertexData.indices = indices;
    vertexData.positions = positions;
    vertexData.normals = normals;
    vertexData.uvs = uvs;
    return vertexData;
  }

  public static updateSideOrientation(orientation: number): number {
    if (orientation === Mesh.DOUBLESIDE) {
      return Mesh.DOUBLESIDE;
    }
    if (orientation === undefined || orientation === null) {
      return Mesh.FRONTSIDE;
    }
    return orientation;
  }

  public static computeSides(
    sideOrientation,
    positions,
    indices,
    normals,
    uvs,
    frontUVs,
    backUVs
  ) {
    const li = indices.length;
    const ln = normals.length;
    let i;
    let n;

    sideOrientation = sideOrientation || Mesh.DEFAULTSIDE;

    switch (sideOrientation) {
      case Mesh.FRONTSIDE:
        // nothing changed
        break;
      case Mesh.BACKSIDE:
        let tmp;
        // indices
        for (i = 0; i < li; i += 3) {
          tmp = indices[i];
          indices[i] = indices[i + 2];
          indices[i + 2] = tmp;
        }
        // normals
        for (n = 0; n < ln; n += 1) {
          normals[n] = -normals[n];
        }
        break;
      case Mesh.DOUBLESIDE:
        // positions
        const lp = positions.length;
        const l = lp / 3;
        for (let p = 0; p < lp; p += 1) {
          positions[lp + p] = positions[p];
        }
        // indices
        for (i = 0; i < li; i += 3) {
          indices[i + li] = indices[i + 2] + l;
          indices[i + 1 + li] = indices[i + 1] + l;
          indices[i + 2 + li] = indices[i] + l;
        }
        // normals
        for (n = 0; n < ln; n += 1) {
          normals[ln + n] = -normals[n];
        }
        // uvs
        const lu = uvs.length;
        let u = 0;
        for (u = 0; u < lu; u += 1) {
          uvs[u + lu] = uvs[u];
        }
        frontUVs = frontUVs
          ? frontUVs
          : new Vector4(0.0, 0.0, 1.0, 1.0);
        backUVs = backUVs ? backUVs : new Vector4(0.0, 0.0, 1.0, 1.0);
        u = 0;
        for (i = 0; i < lu / 2; i += 1) {
          uvs[u] = frontUVs.x + (frontUVs.z - frontUVs.x) * uvs[u];
          uvs[u + 1] = frontUVs.y + (frontUVs.w - frontUVs.y) * uvs[u + 1];
          uvs[u + lu] = backUVs.x + (backUVs.z - backUVs.x) * uvs[u + lu];
          uvs[u + lu + 1] =
            backUVs.y + (backUVs.w - backUVs.y) * uvs[u + lu + 1];
          u += 2;
        }
        break;
    }
  }

  public static createDashedLinesWithColor(
    name: string,
    options: {
      points: Vector3[];
      dashSize?: number;
      gapSize?: number;
      dashNb?: number;
      updatable?: boolean;
      instance?: LinesMesh;
      color?: Color4;
    },
    scene: Nullable<Scene> = null
  ): LinesMesh {
    const gapSize = options.gapSize || 1;
    const dashSize = options.dashSize || 3;
    const dashedLines = new LinesMesh(name, scene);
    const vertexData = this.getDashedLinesWithColorVertexData(options);

    vertexData.applyToMesh(dashedLines, options.updatable);

    dashedLines._creationDataStorage = new _CreationDataStorage();
    dashedLines._creationDataStorage.dashSize = dashSize;
    dashedLines._creationDataStorage.gapSize = gapSize;

    return dashedLines;
  }

  public static getDashedLinesWithColorVertexData(options: {
    points: Vector3[];
    dashSize?: number;
    gapSize?: number;
    dashNb?: number;
    color?: Color4;
  }): VertexData {
    const dashSize = options.dashSize || 3;
    const gapSize = options.gapSize || 1;
    const dashNb = options.dashNb || 200;
    const points = options.points;
    const color = options.color || new Color4(0, 0, 0);

    const positions = [];
    const indices = [];
    const curvect = Vector3.Zero();
    const vertexColors = [];

    let lg = 0;
    let nb = 0;
    let shft = 0;
    let dashshft = 0;
    let curshft = 0;
    let idx = 0;

    let i = 0;
    for (i = 0; i < points.length - 1; i += 1) {
      points[i + 1].subtractToRef(points[i], curvect);
      lg += curvect.length();
    }

    shft = lg / dashNb;
    dashshft = (dashSize * shft) / (dashSize + gapSize);

    for (i = 0; i < points.length - 1; i += 1) {
      points[i + 1].subtractToRef(points[i], curvect);
      nb = Math.floor(curvect.length() / shft);
      curvect.normalize();

      for (let j = 0; j < nb; j += 1) {
        curshft = shft * j;

        positions.push(
          points[i].x + curshft * curvect.x,
          points[i].y + curshft * curvect.y,
          points[i].z + curshft * curvect.z
        );
        positions.push(
          points[i].x + (curshft + dashshft) * curvect.x,
          points[i].y + (curshft + dashshft) * curvect.y,
          points[i].z + (curshft + dashshft) * curvect.z
        );

        indices.push(idx, idx + 1);
        idx += 2;
      }
    }

    for (i = 0; i < indices.length; i++) {
      vertexColors.push(color.r, color.g, color.b, color.a);
    }

    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.colors = vertexColors;

    return vertexData;
  }
}
