<div class="card card-default fh-panel" [ngClass]="{ fh: !settings.collapsed }">
  <div class="card-header card-header-info no-gutter d-flex flex-row align-items-center" [ngStyle]="{ 'border-bottom': cardColorCue }">
    <div class="col-md-11">
      <h6>{{ 'SIDEMEMBER_INFORMATION.TITLE' | translate }}</h6>
    </div>
    <div class="col-md-1 text-align-right">
      <button mat-icon-button (click)="setCollapsed()" class="expand-mr" id="toggleSidememberInfopane" 
        title="{{ (this.settings.collapsed ? 'EXPAND' : 'COLLAPSE') | translate }}">
        <mat-icon *ngIf="settings.collapsed">expand_less</mat-icon>
        <mat-icon *ngIf="!settings.collapsed">expand_more</mat-icon>
      </button>
    </div>
  </div>

  <div [hidden]="settings.collapsed" class="card-body fh-panel-child">
    <div class="errmessage" *ngIf="(isOpenSidememberError() | async) ||  grpData.Data.loadError?.forSidemember">
      {{ 'SIDEMEMBER_INFORMATION.LOADING_ERROR' | translate }}
    </div>

    <div class="pane-hScroll" (scroll)="onHorizontalScroll()" *ngIf="sidemember$ | async as sidemember" #hScroll>
      <table class="table table-sm table-header">
        <thead>
          <tr>
            <th class="separated w-300">
              {{ 'SIDEMEMBER_INFORMATION.COLUMNS.ATTRIBUTE' | translate }}
            </th>
            <th class="separated w-350">
              {{ 'SIDEMEMBER_INFORMATION.COLUMNS.SIDEMEMBER_INFO' | translate }}              
            </th>
            <th *ngFor="let reinforcement of sidemember?.Reinforcements" class="separated w-350">
              {{ 'SIDEMEMBER_INFORMATION.COLUMNS.REINFORCEMENT_INFO' | translate }}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>

      <div class="pane-vScroll" #vScroll>
        <table class="table table-sm">
          <tbody>
            <tr *ngFor="let key of getKeys(sidemember)">
              <td class="separated horizontalHeader w-300">{{ key }}&nbsp;</td>
              <td class="separated w-350">
                {{ sidemember.HFInfo[key] }}&nbsp;
              </td>
              <td class="separated w-350" *ngFor="let reinforcement of sidemember.Reinforcements">
                {{ reinforcement[key] }}&nbsp;
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>