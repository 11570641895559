// tslint:disable-next-line: import-name
import ResizeObserver from 'resize-observer-polyfill';
import {
  ViewChild,
  Component,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { SidememberRendering } from '../rendering/sidemember-rendering';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
  GrapeDataModel,
} from '../shared/models/grapeservice/grape.data';
import { RenderEngine } from '../rendering/render-engine';
import { filter, debounceTime } from 'rxjs/operators';
import { SidememberSide } from '../shared/models/sidemember-enum';
import { AbstractMesh, Color3, Vector3, PickingInfo } from 'babylonjs';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { PresentationSettings } from '../rendering/core/presentation-settings';
import { Hole, FilteredHole } from '../shared/models/grapeservice/hole';
import { UIEventService } from '../shared/services/ui-event.service';
import { Region } from '../shared/models/rendering/region';
import { SidememberMinimapComponent } from '../sidemember-minimap/sidemember-minimap.component';
import { HoleDrawingOptions } from '../rendering/sidemember-drawing';
import { HoleDistanceOption } from '../shared/models/hole-distance-option';
import { HoleSidememberSegmentService } from '../shared/services/hole-sidemember-segment.service';
import { HoleDistanceService } from '../shared/services/hole-distance.service';
import { HoleShapeEnum } from '../rendering/core/enums/hole-shape-enum';

@Component({
  selector: 'app-sidemember-canvas',
  templateUrl: './sidemember-canvas.component.html',
  styleUrls: ['./sidemember-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidememberCanvasComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasRef: ElementRef;
  @ViewChild('minimap') minimap: SidememberMinimapComponent;

  @Output() drawingExpand = new EventEmitter<boolean>();

  public engineCameraMovement$: BehaviorSubject<Region> = new BehaviorSubject(
    null
  );
  public pointerLoc$: BehaviorSubject<Vector3> = new BehaviorSubject(null);
  public pointerInfo$: BehaviorSubject<PickingInfo> = new BehaviorSubject(null);
  public selectedHole$: BehaviorSubject<Hole> = new BehaviorSubject(null);
  public sidememberInit$: Observable<GrapeData>;
  public selectedHoleOptions: HoleDrawingOptions;

  private subscriptions: Subscription[] = [];
  private engine: RenderEngine;
  private rendering: SidememberRendering;
  private listHoleSelect$: Observable<GrapeData>;
  private drawingHoleSelect$: Observable<GrapeData>;
  private drawingSecondHoleSelect$: Observable<GrapeData>;
  private resetDrawingSecondHoleSelect$: Observable<GrapeData>;
  private toggleHoleFilter$: Observable<GrapeData>;
  private setHoleDuplicates$: Observable<GrapeData>;
  private flipSidemember$: Observable<boolean>;
  private holeDistanceModeChange$: Observable<HoleDistanceOption>;

  private renderSettings$: Observable<boolean>;
  private focusOnSidemember$: Subject<boolean> = new Subject();

  constructor(
    public settings: UserSettingsService,
    private grapeDataService: GrapeDataService,
    private uiEventService: UIEventService,
    private holeDistanceService: HoleDistanceService
  ) {
    this.initializeObservables();

    this.selectedHoleOptions = {
      showFilteredHoles: true,
      showRelatedHoles: true,
      showSelectedHole: true,
      showHoleDuplicates: true,
    };
  }

  ngOnInit() {
    this.subscribeToObservables();
  }

  ngAfterViewInit() {
    this.engine = this.createRenderEngine();
    this.observeResize();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public onMinimapPanning(position: Vector3): void {
    this.engine.moveTo(position);
  }

  public onMinimapZooming(zoomIn: boolean): void {
    this.engine.zoom(zoomIn);
  }

  public redraw(): void {
    this.rendering.redraw();
    this.rendering.hideAllSecondaryHolesInfo();
    this.grapeDataService.grapeData.Data.SecondaryHoles.forEach((hole) => {
      this.rendering.showSecondaryHoleInfo(hole);
    });
    this.rendering.setVisibilityPPTRHoleMarks(
      this.settings.renderSetting.printHoleMarking
    );
    this.rendering.setVisibilityRestrictedAreas(
      this.settings.renderSetting.printRestrictedAreas
    );
    this.minimap.reDrawMinimap();
  }

  private initializeObservables(): void {
    this.sidememberInit$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return this.engine && data.ChangedObject === GrapeObjectType.Sidemember;
      })
    );

    this.flipSidemember$ = this.uiEventService.sidememberFlipStatus.pipe(
      filter((_: boolean) => {
        return this.engine && this.rendering !== undefined;
      })
    );

    this.renderSettings$ = this.uiEventService.settingsChanged.pipe(
      filter((_: boolean) => {
        return this.engine && this.rendering !== undefined;
      })
    );

    this.listHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.SelectedHole
        );
      })
    );

    this.drawingHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.SelectedDrawingHole
        );
      })
    );

    this.drawingSecondHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.SelectedSecondaryDrawingHole
        );
      })
    );

    this.resetDrawingSecondHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.ResetSecondaryDrawingHoles
        );
      })
    );

    this.setHoleDuplicates$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.HoleDuplicates
        );
      })
    );

    this.toggleHoleFilter$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          this.engine &&
          this.rendering &&
          data.ChangedObject === GrapeObjectType.FilteredHoles
        );
      })
    );

    this.holeDistanceModeChange$ = this.uiEventService.holeDistanceModeStatus.pipe(
      filter((value: HoleDistanceOption) => {
        return this.engine && this.rendering !== undefined;
      })
    );
  }

  private subscribeToObservables(): void {
    this.subscriptions.push(
      this.sidememberInit$.subscribe((grapeData: GrapeData) => {
        this.disposeSidemember(this.rendering);
        this.setEngineProperties(grapeData.Data);
        this.renderSidemember(grapeData.Data);
        this.renderHoleDuplicates(grapeData.Data.HoleDuplicates);
        this.renderFilteredHoles(grapeData.Data.FilteredHoles);

        if (this.settings.sidememberPresentation.showUpsideDown) {
          this.flipCamera();
          this.rendering.invertTextNodes();
        }

        this.focusOnSidemember$.next(true);
      }),

      this.focusOnSidemember$.subscribe(() => {
        this.rendering.centerOnSidemember();
      }),

      this.flipSidemember$.subscribe((_) => {
        this.flipCamera();
        this.rendering.invertTextNodes();
        this.grapeDataService.data;
      }),

      this.renderSettings$.subscribe(() => {
        this.setRenderSettings();
      }),

      this.listHoleSelect$.subscribe((grapeData: GrapeData) => {
        this.rendering.resetAllHoles(false);
        this.selectHoleProcedure(
          grapeData.Data.SelectedListHole,
          grapeData.Data.RelatedHoles,
          grapeData.Data.FilteredHoles,
          true
        );
      }),

      this.drawingHoleSelect$.subscribe((grapeData: GrapeData) => {
        this.resetToInitialDrawing();

        this.selectHoleProcedure(
          grapeData.Data.SelectedHole,
          grapeData.Data.RelatedHoles,
          grapeData.Data.FilteredHoles
        );
      }),
      this.drawingSecondHoleSelect$.subscribe((grapeData: GrapeData) => {
        this.arrangeZindexForDistanceMode();

        if (grapeData.Data.selectedSecondaryHole) {
          this.rendering.markSecondaryHole(
            grapeData.Data.selectedSecondaryHole
          );

          this.rendering.showSecondaryHoleInfo(
            grapeData.Data.selectedSecondaryHole
          );

          this.rendering.drawHoleDistanceLine(
            grapeData.Data.SelectedHole,
            grapeData.Data.selectedSecondaryHole
          );
        } else if (grapeData.Data.unSelectedSecondaryHole) {
          this.rendering.unMarkSecondaryHole(
            grapeData.Data.unSelectedSecondaryHole
          );

          if (
            grapeData.Data.RelatedHoles.find(
              (x) => x.HoleId === grapeData.Data.unSelectedSecondaryHole.HoleId
            )
          ) {
            this.rendering.markHole(grapeData.Data.unSelectedSecondaryHole);
          }

          this.rendering.hideSecondaryHoleInfo(
            grapeData.Data.unSelectedSecondaryHole.HoleId
          );

          this.rendering.removeHoleDistance(
            grapeData.Data.unSelectedSecondaryHole.HoleId
          );
        }
      }),

      this.resetDrawingSecondHoleSelect$.subscribe((grapeData: GrapeData) => {
        this.arrangeZindexForDistanceMode();

        this.rendering.hideAllSecondaryHolesInfo();
        this.rendering.hideAllHoleDistances();

        grapeData.Data.SecondaryHoles.forEach((hole) => {
          this.rendering.markSecondaryHole(hole);
          this.rendering.showSecondaryHoleInfo(hole);
          if (grapeData.Data.SelectedHole) {
            this.rendering.drawHoleDistanceLine(
              grapeData.Data.SelectedHole,
              hole
            );
          }
        });
      }),

      this.holeDistanceModeChange$.subscribe((value) => {
        this.setRenderSettings();
        this.grapeDataService.changedHoleDistanceMode(value);
      }),
      this.setHoleDuplicates$.subscribe((grapeData: GrapeData) => {
        this.renderHoleDuplicates(grapeData.Data.HoleDuplicates);
      }),

      this.toggleHoleFilter$.subscribe((grapeData: GrapeData) => {
        this.renderFilteredHoles(grapeData.Data.FilteredHoles);
      })
    );
  }

  resetToInitialDrawing() {
    this.engine.zIndex.reset();
    this.rendering.resetAllHoles();
    this.rendering.hideHoleInfo();
    this.rendering.hideAllHoleDistances();
  }

  arrangeZindexForDistanceMode() {
    this.rendering.resetAllHoles();

    this.selectHoleProcedure(
      this.grapeDataService.grapeData.Data.SelectedHole,
      this.grapeDataService.grapeData.Data.RelatedHoles,
      this.grapeDataService.grapeData.Data.FilteredHoles
    );

    this.grapeDataService.grapeData.Data.SecondaryHoles.forEach((hole) =>
      this.rendering.markSecondaryHole(hole)
    );

    this.rendering.moveToFrontForDistanceMode(
      [
        ...this.grapeDataService.grapeData.Data.SecondaryHoles,
        this.grapeDataService.grapeData.Data.SelectedHole,
      ],
      this.settings.holeDistance.holeDistanceOption
    );
  }

  private renderFilteredHoles(holes: Hole[]): void {
    this.resetToInitialDrawing();

    if (this.settings.holeinfo.searchFilterActive) {
      holes.forEach((hole: FilteredHole) => {
        if (hole.HoleColor && hole.HoleColor.length !== 0) {
          this.rendering.filterHole(hole);
        }
      });

      this.rendering.moveToFront(holes);
    }
  }

  private renderHoleDuplicates(holes: Hole[]) {
    this.rendering.resetHoleDuplicates();
    if (this.settings.holeDuplication.showDuplicates) {
      holes.forEach((hole) => this.rendering.showHoleDuplicate(hole));
    }
  }

  private createRenderEngine(): RenderEngine {
    const engine = new RenderEngine(
      window,
      this.canvasRef.nativeElement,
      true,
      false
    );

    engine.onRender = () => {
      if (this.rendering) {
        this.rendering.onRender();
      }

      this.pointerLoc$.next(this.engine.pointerLoc);
      this.pointerInfo$.next(this.engine.pointerInfo);
    };

    engine.onCameraMovement = (
      leftTop: Vector3,
      leftBottom: Vector3,
      rightTop: Vector3,
      rightBottom: Vector3
    ) => {
      this.engineCameraMovement$.next({
        leftTop,
        leftBottom,
        rightTop,
        rightBottom,
      });
    };

    engine.onSceneClick = () => {
      this.grapeDataService.setSelectedDrawingHole(null);
      this.resetToInitialDrawing();
    };

    engine.onMeshClick = (mesh: AbstractMesh) => {
      const holeId = this.rendering.holeMeshes[mesh.uniqueId];
      const hole = this.grapeDataService.grapeData.Data.Holes.find(
        (h) => h.HoleId === holeId
      );

      this.grapeDataService.setSelectedDrawingHole(hole);
    };

    engine.onMeshRightClick = (mesh: AbstractMesh) => {
      const holeId = this.rendering.holeMeshes[mesh.uniqueId];
      const hole = this.grapeDataService.grapeData.Data.Holes.find(
        (h) => h.HoleId === holeId
      );

      if (this.grapeDataService.grapeData.Data.SelectedHole) {
        this.grapeDataService.setSelectedSecondaryDrawingHole(hole);
      } else {
        this.grapeDataService.setSelectedDrawingHole(hole);
      }
    };

    return engine;
  }

  private setRenderSettings() {
    this.settings.sidememberPresentation.load();
    this.settings.renderSetting.holeColors.duplicateColor = Color3.FromHexString(
      this.settings.sidememberPresentation.duplicateHoleColor
    );
    this.settings.renderSetting.holeColors.initialHoleColor = Color3.FromHexString(
      this.settings.sidememberPresentation.defaultHoleColor
    );
    this.settings.renderSetting.holeInformation = this.settings.sidememberPresentation.holeInformation;
    this.settings.renderSetting.holeDistanceOption = this.settings.holeDistance.holeDistanceOption;
    this.settings.renderSetting.printHoleMarking = this.settings.sidememberPresentation.printHoleMarking;
    this.settings.renderSetting.printRestrictedAreas = this.settings.sidememberPresentation.printRestrictedAreas;

    const newSettings = new PresentationSettings(this.settings.renderSetting);
    this.rendering.setSettings(newSettings);
  }

  private setEngineProperties(data: GrapeDataModel): void {
    if (data.HFInfo.FrameSide === SidememberSide.Right) {
      this.engine.cameraController.inverse();
    }

    this.engine.zIndex.clear();

    this.engine.setZIndexDirection(
      data.HFInfo.FrameSide === SidememberSide.Right ? 1 : -1
    );
  }

  private observeResize(): void {
    const resizeObserver = new ResizeObserver(() => {
      this.engine.redraw();
    });

    resizeObserver.observe(this.canvasRef.nativeElement);
  }

  private renderSidemember(data: GrapeDataModel): void {
    if (this.rendering) this.rendering.dispose();
    this.rendering = this.createSidememberRendering(this.engine, data);
    this.setRenderSettings();
  }

  private disposeSidemember(sidemember: SidememberRendering): void {
    if (!sidemember) {
      return;
    }
    // Reset the selected hole data
    this.selectedHole$.next(null);

    this.engine.scene.blockfreeActiveMeshesAndRenderingGroups = true;
    this.rendering.dispose();
    this.engine.cameraController.reset();
    this.engine.scene.blockfreeActiveMeshesAndRenderingGroups = false;
    this.engine.redraw();
  }

  private createSidememberRendering(
    engine: RenderEngine,
    data: GrapeDataModel
  ): SidememberRendering {
    return new SidememberRendering(
      engine,
      data,
      new PresentationSettings(this.settings.renderSetting),
      this.holeDistanceService,
      new HoleSidememberSegmentService()
    );
  }

  // TODO: refactor the method
  private selectHoleProcedure(
    hole: Hole,
    relatedHoles: Hole[],
    filteredHoles: FilteredHole[],
    highlightHole = false
  ): void {
    if (hole === null){
      this.selectedHole$.next(null);
      return;
    } 
    const filteredRelatedHoles = relatedHoles.filter(
      (x) => !filteredHoles.find((y) => y.HoleId === x.HoleId)
    );
    filteredRelatedHoles.forEach((hole) => this.rendering.markHole(hole));

    this.rendering.moveToFront(
      [...filteredRelatedHoles, ...filteredHoles],
      hole
    );

    this.rendering.selectHole(hole);
    this.rendering.showHoleInfo(hole);

    if (highlightHole) {
      this.rendering.centerOnHole(hole);
      this.rendering.highlightHole(hole);
    }

    this.selectedHole$.next(hole);
  }

  private flipCamera(): void {
    const camera = this.engine.cameraController.getCamera();
    camera.upVector = camera.upVector.multiply(new Vector3(0, -1, 0));
    this.engine.cameraController.refresh();
  }
}
