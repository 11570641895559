<h2 *ngIf="data.title" mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>
    <span [innerHTML]="data.message"></span>
</div>
<hr>
<!-- YesCancel -->
<ng-container *ngIf="data.modalType === modalTypes.YesCancel; else YesNo">
    <div mat-dialog-actions align="end">
        <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.positiveBtnText"
            cdkFocusInitial>{{data.positiveBtnText}}</button>
        <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" type="button"
            [mat-dialog-close]="data.cancelBtnText">{{data.cancelBtnText}}</button>
    </div>
</ng-container>


<!-- YesNo -->
<ng-template #YesNo>
    <ng-container *ngIf="data.modalType === modalTypes.YesNo; else YesNoCancel">
        <div mat-dialog-actions align="end">
            <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.positiveBtnText"
                cdkFocusInitial>{{data.positiveBtnText}}</button>
            <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.negativeBtnText"
                cdkFocusInitial>{{data.negativeBtnText}}</button>
        </div>
    </ng-container>
</ng-template>

<!-- YesNoCancel -->
<ng-template #YesNoCancel>
    <ng-container *ngIf="data.modalType === modalTypes.YesNoCancel; else OK">
        <div mat-dialog-actions align="end">
            <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.positiveBtnText"
                cdkFocusInitial>{{data.positiveBtnText}}</button>
            <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.negativeBtnText"
                cdkFocusInitial>{{data.negativeBtnText}}</button>
            <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" type="button"
                [mat-dialog-close]="data.cancelBtnText">{{data.cancelBtnText}}</button>
        </div>
    </ng-container>
</ng-template>

<!-- OK -->
<ng-template #OK>
    <ng-container *ngIf="data.modalType === modalTypes.Ok; else None">
        <div mat-dialog-actions align="end">
            <button class="sdds-btn sdds-btn-primary sdds-btn-sm" type="button" [mat-dialog-close]="data.positiveBtnText"
                cdkFocusInitial>{{data.positiveBtnText}}</button>
        </div>
    </ng-container>
</ng-template>

<!-- None -->
<ng-template #None></ng-template>