<div class="card card-default fh-panel" [ngClass]="{ fh: !settings.holeDuplication?.collapsed }">
  <div class="card-header card-header-info no-gutter d-flex flex-row align-items-center">
    <div class="col-md-6">
      <h6>{{ 'HOLE_DUPLICATES.TITLE' | translate }}</h6>
    </div>

    <input type="file" accept=".json" (click)="fileInput.value = null" (change)="openFile($event)" [hidden]="true"
      #fileInput />

    <div *ngIf="!editDuplicates" class="col-md-6 text-align-right">
      <button mat-icon-button (click)="openEditDuplicates()"   id="editDuplicates" title="{{ 'EDIT' | translate }}"
        [disabled]="(isOpenSidememberError() | async )">
        <mat-icon>filter_alt</mat-icon>
      </button>

      <button mat-icon-button (click)="showHoleDuplicates()"   [title]="filterToggleTitle | async"
        [disabled]="!isFilterEnabled || (isOpenSidememberError() | async )" id="showDuplicates">
        <mat-icon *ngIf="settings.holeDuplication?.showDuplicates && isFilterEnabled ">check_box</mat-icon>
        <mat-icon *ngIf="!settings.holeDuplication?.showDuplicates || !isFilterEnabled ">check_box_outline_blank
        </mat-icon>
      </button>

      <span class="linkSeparator"></span>

      <button mat-icon-button class="expand-mr" (click)="togglePanel()" id="toggleHoleDuplicates" title="
          {{ (this.settings.holeDuplication.collapsed ? 'EXPAND' : 'COLLAPSE') | translate }}
        ">
        <mat-icon *ngIf="settings.holeDuplication?.collapsed">expand_less</mat-icon>
        <mat-icon *ngIf="!settings.holeDuplication?.collapsed">expand_more</mat-icon>
      </button>
    </div>

    <div *ngIf="editDuplicates" class="col-md-6 text-align-right">
      <button mat-icon-button (click)="validateImportAsync()" [hidden]="true" id="importDuplicates" title="{{ 'IMPORT' | translate }}">
        <mat-icon  svgIcon="import"></mat-icon>
      </button>

      <button   mat-icon-button [disabled]="isListEmpty" [hidden]="true" mat-icon-button (click)="exportHoleDuplicates()"
        id="exportDuplicates" title="{{ 'EXPORT' | translate }}">
        <mat-icon class="export-margin" [ngClass]="{ 'disabled-export-button': isListEmpty}" svgIcon="export"></mat-icon>
      </button>

      <span class="linkSeparator" [hidden]="true"></span>

      <button   [disabled]="isListEmpty" mat-icon-button (click)="clearList()" id="clearDuplicates"
        title="{{ 'CLEAR' | translate }}">
        <mat-icon>delete</mat-icon>
      </button>
      <button   mat-icon-button [disabled]="!hideErrorMessage" (click)="saveHoleDuplicatesAsync()" id="saveDuplicates" title="{{ 'SAVE' | translate }}">
        <mat-icon>save</mat-icon>
      </button>

      <span class="linkSeparator"></span>

      <button class="expand-mr"  mat-icon-button (click)="validateClosingAsync()" id="closeFilterDef" title="{{ 'CLOSE' | translate }}">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div [hidden]="settings.holeDuplication?.collapsed" class="card-body fh-panel-child card-position">
    <div class="loader" *ngIf="isLoading$ | async">
      <div class="dots">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div class="errmessage" [hidden]="hideErrorMessage">
      {{ 'HOLE_DUPLICATES.IMPORT_ERROR' | translate }}
    </div>

    <div class="duplicates-scroll">
      <div class="col-md-6">
        <table class="table table-sm table-header">
          <thead>
            <tr>
              <th>{{ 'HOLE_DUPLICATES.INCLUDED_PARTS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let partNo of holeDuplicates?.includedParts" class="table-bg">
              <td class="part-color">{{ partNo }}</td>
              <td *ngIf="editDuplicates">
                <button mat-icon-button (click)="deleteIncludedPart(partNo)" title="{{ 'DELETE' | translate }}">
                  <mat-icon class="icon-bg">delete</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="editDuplicates" class="table-bg">
              <td>
                <input placeholder="{{ 'HOLE_DUPLICATES.PART_NUMBER' | translate }}" type="number" min="0"
                  (keypress)="checkTypedChar($event)" class="form-control partnumber" id="includedPartInput"
                  [(ngModel)]="newIncludedPartNo" />
              </td>
              <td>
                <button mat-icon-button (click)="addPartToIncludedList()" title="{{ 'ADD' | translate }}">
                  <mat-icon class="icon-bg">add</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-6">
        <table class="table table-sm table-header">
          <thead>
            <tr>
              <th>{{ 'HOLE_DUPLICATES.EXCLUDED_PARTS' | translate }}</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let partNo of holeDuplicates?.excludedParts" class="table-bg">
              <td class="part-color">{{ partNo }}</td>
              <td *ngIf="editDuplicates">
                <button mat-icon-button (click)="deleteExcludedPart(partNo)" title="{{ 'DELETE' | translate }}">
                  <mat-icon class="icon-bg">delete</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="editDuplicates" class="table-bg">
              <td>
                <input placeholder="{{ 'HOLE_DUPLICATES.PART_NUMBER' | translate }}" type="number" min="0"
                  (keypress)="checkTypedChar($event)" class="form-control partnumber" id="excludedPartInput"
                  [(ngModel)]="newExcludedPartNo" />
              </td>
              <td>
                <button mat-icon-button class="table-bg" (click)="addPartToExcludedList()" (keypress)="onEnter()" title="{{ 'ADD' | translate }}">
                  <mat-icon class="icon-bg">add</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>