import { BaseRenderer } from './base-renderer';
import { RenderEngine } from '../render-engine';
import { Hole } from '../../shared/models/grapeservice/hole';
import { HoleFlags } from '../../shared/models/hole-enum';
import {
  Mesh,
  AbstractMesh,
  Vector3,
  MeshBuilder,
  Vector2,
  StandardMaterial,
} from 'babylonjs';
import { SidememberInfo } from '../../shared/models/grapeservice/sidemember-info';
import { WorldTransformation } from '../core/world-transformation';
import { HoleCalculation } from '../core/hole-calculation';
import { SidememberMaterial } from '../../shared/models/rendering/sidemember-material';
import { MeshNode } from '../babylon/mesh-node';
import { BabylonExtension } from '../babylon/babylon.extension';
import { timer, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SidememberSide } from '../../shared/models/sidemember-enum';
import { PresentationSettings } from '../core/presentation-settings';

export class HoleRenderer extends BaseRenderer {
  public holeId: number;

  public isCompareMode = false;

  private isSelected: boolean;
  private isMarked: boolean;
  private isFiltered: boolean;
  private filterMaterial: StandardMaterial;
  private highlightSubscription: Subscription;

  protected holeDuplicateMesh: Mesh;
  protected holeDuplicateSize = 14;

  protected pptrMesh: Mesh;

  constructor(
    protected engine: RenderEngine,
    protected materials: SidememberMaterial,
    protected renderData: Hole,
    protected sidememberData: SidememberInfo,
    protected parent?: AbstractMesh,
    protected settings?: PresentationSettings
  ) {
    super(engine, materials, renderData, sidememberData, parent);

    this.positionMesh();

    if (+this.renderData.SupplOper === 5) {
      this.createPPTRMarking();
    }

    this.createTextNodes();
    this.createHoleDuplicate();
  }

  public setVisibilityPPTRHoleMarks(printHoleMarking: boolean) {
    if (this.pptrMesh) this.pptrMesh.setEnabled(printHoleMarking);
  }

  public setVisibilityRestrictedAreas(printRestrictedAreas: boolean) {
    if (this.mesh) this.mesh.setEnabled(printRestrictedAreas);
  }

  public reset(resetFilteredHoles, compareMode): void {
    this.isCompareMode = compareMode;
    this.isMarked = false;
    this.isSelected = false;
    this.isFiltered = resetFilteredHoles ? false : this.isFiltered;

    if (!resetFilteredHoles && this.isFiltered && !compareMode) {
      this.filterByMaterial(this.filterMaterial);
    } else {
      this.setInitialMaterial();
    }
  }

  public select(): void {
    this.isSelected = true;
    this.setMaterial(this.materials.selected);
  }

  public mark(): void {
    this.isMarked = true;
    this.setMaterial(this.materials.marked);
  }

  public markSecondary(): void {
    this.setMaterial(this.materials.yellow);
  }

  public filterByMaterial(material: StandardMaterial): void {
    this.isFiltered = true;
    this.setMaterial(material);
    this.filterMaterial = material;
  }

  public highlight(): void {
    const highlightRepeats = 7;
    let currentInterval = 0;
    if (this.highlightSubscription) {
      this.highlightSubscription.unsubscribe();
    }
    this.highlightSubscription = timer(0, 200)
      .pipe(
        takeWhile((repeat) => this.isSelected && repeat <= highlightRepeats)
      )
      .subscribe(
        () => {
          currentInterval += 1;
          this.setMaterial(
            currentInterval % 2
              ? this.materials.highlight
              : this.materials.selected
          );
        },
        () => {
          if (this.isSelected) {
            this.setMaterial(this.materials.selected);
          } else if (this.isMarked) {
            this.setMaterial(this.materials.marked);
          } else {
            this.setMaterial(this.materials.default);
          }
        }
      );
  }

  public invertTextNodes(): void {
    if (!this.lowerTextNode || !this.upperTextNode) return;
    this.lowerTextNode.position.y *= -1;
    this.upperTextNode.position.y *= -1;
  }

  protected setInitialMaterial(): void {
    super.setInitialMaterial();

    if (
      this.isCompareMode &&
      this.renderData.HoleFlag === HoleFlags.UniqueInSidemember1
    ) {
      this.setMaterial(this.materials.darkgreen);
    } else if (
      this.isCompareMode &&
      this.renderData.HoleFlag === HoleFlags.UniqueInSidemember2
    ) {
      this.setMaterial(this.materials.orange);
    } else {
      this.setMaterial(this.materials.default);
    }
  }

  protected optimize(): void {
    super.optimize();

    if (!this.mesh) {
      return;
    }

    this.mesh.receiveShadows = false;
    this.mesh.useOctreeForCollisions = false;
    this.mesh.convertToUnIndexedMesh();
    this.mesh.convertToFlatShadedMesh();
  }

  public showHoleDuplicate(): void {
    if (!this.holeDuplicateMesh) return;
    this.holeDuplicateMesh.setEnabled(true);
  }

  public hideHoleDuplicate(): void {
    if (!this.holeDuplicateMesh) return;
    this.holeDuplicateMesh.setEnabled(false);
  }

  protected calculateDuplicateMeshSize(hole: Hole): Vector2 {
    return new Vector2(
      hole.Diameter + this.holeDuplicateSize,
      hole.Diameter + this.holeDuplicateSize
    );
  }

  protected positionMesh(): void {
    if (!this.mesh) {
      return;
    }

    const holeSurface = HoleCalculation.calculateHoleSurface(
      this.renderData,
      this.sidememberData
    );

    const x = this.renderData.Hole_X;
    const y = this.renderData.Hole_Y;
    const z = HoleCalculation.calculateFlangeLocation(
      this.parent.position,
      this.renderData,
      holeSurface
    );

    this.mesh.position = WorldTransformation.worldToLocal(
      new Vector3(x, y, z),
      this.parent.position
    );

    this.mesh.parent = this.parent;
  }

  protected createHoleDuplicate(): void {
    if (!this.mesh) return;

    const size = this.calculateDuplicateMeshSize(this.renderData);

    this.holeDuplicateMesh = MeshBuilder.CreatePlane(
      'HoleDuplicate',
      {
        width: size.x,
        height: size.y,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE,
      },
      this.engine.scene
    );

    this.holeDuplicateMesh.material = this.materials.duplicate;
    this.holeDuplicateMesh.position = new Vector3(
      this.mesh.position.x,
      this.mesh.position.y,
      this.sidememberData.FrameSide === SidememberSide.Left ? -1 : 1
    );

    this.holeDuplicateMesh.parent = this.parent;
    this.holeDuplicateMesh.setEnabled(false);
  }

  private createPPTRMarking(): void {
    const distanceToHole = 11.1;

    this.pptrMesh = BabylonExtension.createDiscWithoutArcEdge(
      'PPTRMarking',
      {
        radius: 2.5,
        tessellation: 18,
        sideOrientation:
          this.sidememberData.FrameSide === SidememberSide.Left
            ? Mesh.FRONTSIDE
            : Mesh.BACKSIDE,
      },
      this.engine.scene
    );

    this.pptrMesh.position = new Vector3(
      this.mesh.position.x,
      this.mesh.position.y - this.renderData.Diameter / 2 - distanceToHole,
      this.sidememberData.FrameSide === SidememberSide.Left ? -1 : 1
    );

    this.pptrMesh.parent = this.parent;
    this.pptrMesh.receiveShadows = false;
    this.pptrMesh.material = this.materials.transparent;
    this.pptrMesh.edgesColor = this.outlineColor;
    this.pptrMesh.edgesWidth = this.outlineWidth;
    this.pptrMesh.enableEdgesRendering();
    this.pptrMesh.setEnabled(this.settings.printHoleMarking);
  }

  protected createTextNodes(): void {
    if (!this.mesh) {
      return;
    }

    const offset = this.mesh._boundingInfo.boundingBox.extendSize.y / 2;

    this.upperTextNode = new MeshNode('UpperTextNode', this.engine.scene);
    this.upperTextNode.parent = this.mesh;
    this.upperTextNode.position.y += offset;

    this.lowerTextNode = new MeshNode('LowerTextNode', this.engine.scene);
    this.lowerTextNode.position.y -= offset;
    this.lowerTextNode.parent = this.mesh;
  }
}
