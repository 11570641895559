<div
  class="card card-default fh-panel"
  [ngClass]="{ fh: !settings.holeinfo.collapsed }"
>
  <div class="card-header card-header-info no-gutter d-flex flex-row align-items-center">
    <div *ngIf="inDefineFilterMode" class="col-md-9">
      <h5>{{ 'HOLE_INFORMATION.SEARCH_TITLE' | translate }}</h5>
    </div>
    <div *ngIf="inDefineFilterMode"  class="col-md-3 headerbuttonsalign panal-mb">
      <button
        mat-icon-button
        (click)="saveFilterDefinitions()"
        id="saveHoleInfoFilterDef"
        title="{{ 'SAVE' | translate }}"
      >
        <mat-icon>save</mat-icon>
      </button>
      <span class="linkSeparator"></span>
      <button
        mat-icon-button
        (click)="validateClosing()"
        id="closeFilterDef"
        class="expand-mr"
        title="{{ 'CLOSE' | translate }}"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>

    <div *ngIf="!inDefineFilterMode" class="col-md-8">
      <h6>{{ 'HOLE_INFORMATION.TITLE' | translate }}</h6>
    </div>
    <div *ngIf="!inDefineFilterMode" class="col-md-4 text-align-right">
      <button
        mat-icon-button
        (click)="printHoleInformation()"
        id="printHoleInfo"
        
        title="{{ 'PRINT' | translate }}"
      >
        <mat-icon>print</mat-icon>
      </button>
      <button
        [disabled]="
          isCompareMode ||
          (isOpenSidememberError() | async) ||
          grpData.Data.loadError.forSidemember
        "
        mat-icon-button
        (click)="showFilterDefinitions()"
        id="holeSearchFilter"
        
        title="{{ 'HOLE_INFORMATION.SELECT_FILTER' | translate }}"
      >
        <mat-icon>filter_alt</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="setFilterState()"
        id="toggleFilterDef"
        
        [title]="filterToggleTitle | async"
        [disabled]="
          isHolesFilterDisabled ||
          (isOpenSidememberError() | async) ||
          grpData.Data.loadError.forSidemember
        "
      >
        <mat-icon *ngIf="searchFilterActive && !isHolesFilterDisabled"
          >check_box</mat-icon
        >
        <mat-icon *ngIf="!searchFilterActive || isHolesFilterDisabled"
          >check_box_outline_blank</mat-icon
        >
      </button>

      <span class="linkSeparator"></span>

      <button
        mat-icon-button
        (click)="setCollapsed()"
        id="toggleHoleInfopane"
        class="expand-mr"
        title="{{
          (this.settings.holeinfo.collapsed ? 'EXPAND' : 'COLLAPSE') | translate
        }}"
      >
        <mat-icon *ngIf="settings.holeinfo.collapsed">expand_less</mat-icon>
        <mat-icon *ngIf="!settings.holeinfo.collapsed">expand_more</mat-icon>
      </button>
    </div>
  </div>

  <div [hidden]="!inDefineFilterMode" class="card-body fh-panel-child">
    <app-hole-search-definition #holeSearchDefs></app-hole-search-definition>
  </div>

  <div
    [hidden]="settings.holeinfo.collapsed || inDefineFilterMode"
    class="card-body fh-panel-child"
  >
    <div
      class="errmessage"
      *ngIf="
        (isOpenSidememberError() | async) ||
        grpData.Data.loadError.forSidemember
      "
    >
      {{ 'HOLE_INFORMATION.LOADING_ERROR' | translate }}
    </div>

    <div class="pane-hScroll pane-hHole" #hScroll>
      <div class="pane-vHole" #vScroll (resized)="onResized($event)">
        <p-table
          #table
          [columns]="holeInfoColumns"
          [value]="holes"
          [virtualScroll]="true"
          [virtualRowHeight]="30"
          [rows]="100"
          [reorderableColumns]="true"
          [resizableColumns]="true"
          columnResizeMode="expand"
          styleClass="p-datatable-gridlines"
          [scrollable]="true"
          scrollHeight="{{ containerHeight | async }}px"
          [style]="{ width: '100%' }"
          sortMode="multiple"
          selectionMode="single"
          stateStorage="local"
          stateKey="HoleInfoTableSettings"
          [totalRecords]="holes.length"
          (onRowSelect)="onRowSelect($event)"
          (onRowUnselect)="onRowUnselect($event)"
        >
          <ng-template pTemplate="colgroup">
            <colgroup>
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
              <col class="w-100-px" />
            </colgroup>
          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                class="th-bg-clr"
                pResizableColumn
                pReorderableColumn
                pSortableColumn="{{ col.field }}"
              >
                <div class="th-col-header">
                  {{ col.header | async }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-hole
            let-columns="columns"
            let-rowIndex="rowIndex"
          >
            <tr
              [pSelectableRow]="hole"
              [attr.id]="rowIndex"
              class="tr-col"
              [ngClass]="{ 'selected-row active': selectedHole === hole }"
              (keydown)="onKeyDown($event)"
            >
              <td *ngFor="let col of columns">
                {{ hole[col.field] }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div
    [hidden]="settings.holeinfo.collapsed || inDefineFilterMode"
    class="card-footer"
  >
    <strong>{{ 'HOLE_INFORMATION.NUMBER_OF_HOLES' | translate }}</strong
    >: {{ holes.length }}
  </div>
</div>
