import { Coordinate } from '../shared/models/rendering/coordinate';
import { GrapeDataModel } from '../shared/models/grapeservice/grape.data';
import { Hole, FilteredHole } from '../shared/models/grapeservice/hole';
import { HoleFlags, HoleShape, HoleSurface } from '../shared/models/hole-enum';
import { Point } from '../shared/models/rendering/point';
import { Rectangle } from '../shared/models/rendering/rectangle';
import { SidememberInfo } from '../shared/models/grapeservice/sidemember-info';
import { SidememberSide } from '../shared/models/sidemember-enum';
import { Vector3 } from 'babylonjs';
import {
  SearchCriteriaColor,
  CriteriaColors,
} from '../shared/models/search-criteria-color';

export interface SidememberDrawingOptions {
  scalingRatio: number;
  lineWidth: number;
  lineColor: string;
  holeOptions: HoleDrawingOptions;
  drawUpsideDown?: boolean;
  drawPPTRMarks?: boolean;
  drawRestrictedAreas?: boolean;
  clipFromBegX?: boolean;
  margins?: Rectangle;
  includeCutouts?: boolean;
  colorRoundHole?: boolean;
}

export interface HoleDrawingOptions {
  showFilteredHoles?: boolean;
  showFilteredHoleNumbers?: boolean;
  showRelatedHoles?: boolean;
  showSelectedHole?: boolean;
  showHoleDuplicates?: boolean;
  enlargeFilteredHoles?: boolean;
}

export interface HoleDrawing {
  context: CanvasRenderingContext2D;
  options: SidememberDrawingOptions;
  hole: Hole;
  position: Point;
  drawEnlarged?: boolean;
}

// TODO: Extract static method logic into separate class
export class SidememberDrawing {
  public static create(
    data: GrapeDataModel,
    options?: SidememberDrawingOptions
  ): SidememberDrawing {
    const drawing = new SidememberDrawing(
      data,
      options || SidememberDrawing.defaultDrawingOptions
    );

    if (options && options.clipFromBegX) {
      SidememberDrawing.clipFromBegX(drawing);
    }

    return drawing;
  }

  public static clipFromBegX(drawing: SidememberDrawing): void {
    const clipBuffer = document.createElement('canvas');

    const safeDiffBegX =
      Math.abs(drawing.data.HFInfo.BD_BlankBeg_X - drawing.viewport.left) *
      drawing.options.scalingRatio;

    const begX =
      drawing.data.HFInfo.BD_BlankBeg_X * drawing.options.scalingRatio;

    clipBuffer.width = drawing.canvas.width - begX + safeDiffBegX;
    clipBuffer.height = drawing.canvas.height;

    let clipBegX = 0;

    if (drawing.data.HFInfo.FrameSide === SidememberSide.Left) {
      clipBegX = drawing.options.drawUpsideDown ? 0 : begX - safeDiffBegX;
    } else if (drawing.data.HFInfo.FrameSide === SidememberSide.Right) {
      clipBegX = drawing.options.drawUpsideDown ? begX - safeDiffBegX : 0;
    }

    const context: CanvasRenderingContext2D = clipBuffer.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.imageSmoothingQuality = 'high';

    context.drawImage(
      drawing.canvas,
      clipBegX - drawing.context.lineWidth,
      -drawing.context.lineWidth,
      clipBuffer.width + drawing.context.lineWidth * 2,
      clipBuffer.height + drawing.context.lineWidth * 2,
      0,
      0,
      clipBuffer.width,
      clipBuffer.height
    );

    drawing.canvas = clipBuffer;
  }

  public static vectorToPoint(
    vector: Vector3,
    drawing: SidememberDrawing
  ): Point {
    // TODO: Clean up, remove magic number 10000
    const topZ = 1000 - drawing.data.FlangeHeight;

    const y =
      (vector.z - topZ - drawing.viewport.top) * drawing.options.scalingRatio;
    const x = (vector.x - drawing.viewport.left) * drawing.options.scalingRatio;

    return { x, y };
  }

  public static pointToVector(
    point: Point,
    drawing: SidememberDrawing
  ): Vector3 {
    const translatedPointY = point.y / drawing.options.scalingRatio;
    const translatedPointX = point.x / drawing.options.scalingRatio;
    const rightSide = drawing.data.HFInfo.FrameSide === SidememberSide.Right;
    const sidememberZ = 1000;

    const sidememberHeight =
      Number(drawing.data.HFInfo.WebHeight) + drawing.data.FlangeHeight * 2;

    const sidememberTop = drawing.options.drawUpsideDown
      ? sidememberZ - drawing.data.FlangeHeight
      : sidememberZ +
      Number(drawing.data.HFInfo.WebHeight) +
      drawing.data.FlangeHeight;

    let y: number;
    let x: number;
    let z: number;

    y = 0;

    if (drawing.options.drawUpsideDown) {
      x = rightSide ? drawing.viewport.left : drawing.viewport.right;
      x -= rightSide ? -translatedPointX : translatedPointX;
      z = sidememberTop + drawing.viewport.top;
      z += translatedPointY;
    } else {
      x = rightSide ? drawing.viewport.right : drawing.viewport.left;
      x += rightSide ? -translatedPointX : translatedPointX;
      z = sidememberTop + (drawing.viewport.bottom - sidememberHeight);
      z -= translatedPointY;
    }

    return new Vector3(x, y, z);
  }

  static translateToSidememberMatrix(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    let scaleX = 1;
    let scaleY = 1;
    let translateX = 0;
    let translateY = 0;

    if (data.HFInfo.FrameSide === SidememberSide.Left) {
      scaleX = options.drawUpsideDown ? -1 : 1;
      scaleY = options.drawUpsideDown ? 1 : -1;
      translateX = options.drawUpsideDown ? -context.canvas.width : 0;
      translateY = options.drawUpsideDown ? 0 : -context.canvas.height;
    } else if (data.HFInfo.FrameSide === SidememberSide.Right) {
      scaleX = options.drawUpsideDown ? 1 : -1;
      scaleY = options.drawUpsideDown ? 1 : -1;
      translateX = options.drawUpsideDown ? 0 : -context.canvas.width;
      translateY = options.drawUpsideDown ? 0 : -context.canvas.height;
    }

    context.scale(scaleX, scaleY);
    context.translate(translateX, translateY);
  }

  static get defaultDrawingOptions(): SidememberDrawingOptions {
    return {
      scalingRatio: 1,
      lineWidth: 1,
      lineColor: '#000000',
      drawUpsideDown: false,
      holeOptions: {
        showFilteredHoles: true,
        showRelatedHoles: true,
        showSelectedHole: true,
        showFilteredHoleNumbers: true,
        showHoleDuplicates: true,
      },
    };
  }

  public canvas: HTMLCanvasElement;
  public viewport: Rectangle; // TODO: Replace with ClientRect
  public context: CanvasRenderingContext2D;
  private searchHoleColors = new SearchCriteriaColor();
  private duplicateHoleSize = 30;

  constructor(
    public data: GrapeDataModel,
    public options: SidememberDrawingOptions
  ) {
    this.viewport = this.calculateViewport(data, options);
    this.canvas = this.initializeCanvas(data, options, this.viewport);
    this.context = this.initializeContext(
      this.canvas,
      data,
      options,
      this.viewport
    );

    this.drawOutline(this.context, data, options);
    this.drawFlanges(this.context, data, options);
    if (!data.compareMode) {
      this.drawReinforcements(this.context, data, options);
    }
    this.drawHoleDuplicates(this.context, data, options);
    this.drawHoles(this.context, data, options);
  }

  private initializeCanvas(
    data: GrapeDataModel,
    options: SidememberDrawingOptions,
    viewport: Rectangle
  ): HTMLCanvasElement {
    const canvas = document.createElement('canvas');

    canvas.width =
      Math.abs(viewport.right - viewport.left) +
      data.HFInfo.BD_BlankBeg_X -
      Math.abs(data.HFInfo.BD_BlankBeg_X - viewport.left);
    canvas.height = Math.abs(viewport.bottom - viewport.top);

    canvas.width *= options.scalingRatio;
    canvas.height *= options.scalingRatio;

    return canvas;
  }

  private initializeContext(
    canvas: HTMLCanvasElement,
    data: GrapeDataModel,
    options: SidememberDrawingOptions,
    viewport: Rectangle
  ): CanvasRenderingContext2D {
    const context = canvas.getContext('2d');

    context.fillStyle = '#ffffff';
    context.strokeStyle = options.lineColor;
    context.lineWidth = options.lineWidth * options.scalingRatio;

    this.setupTransformationMatrix(context, data, options, viewport);

    return context;
  }

  private setupTransformationMatrix(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions,
    viewport: Rectangle
  ): void {
    context.translate(context.lineWidth, context.lineWidth);

    SidememberDrawing.translateToSidememberMatrix(context, data, options);

    context.translate(0, -viewport.top * options.scalingRatio);
    context.translate(0.5, 0.5);
  }

  private calculateViewport(
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): Rectangle {
    const lineOffset = options.lineWidth * 2;
    const width = Math.abs(
      data.HFInfo.BD_BlankEnd_X - data.HFInfo.BD_BlankBeg_X
    );
    const height = data.Height + data.FlangeHeight * 2;

    let top = -lineOffset;
    let bottom = top + height + lineOffset;

    let left = data.HFInfo.BD_BlankBeg_X - lineOffset;
    let right = left + width + lineOffset;

    if (options.includeCutouts) {
      data.Holes.forEach((hole: Hole) => {
        if (
          hole.HoleShape === HoleShape.Rectangular &&
          this.validateHolePosition(hole)
        ) {
          const position = this.calculateHolePosition(hole, data);
          const offsetX = hole.Length / 2 + lineOffset;
          const offsetY = hole.Diameter / 2 + lineOffset;

          top = Math.min(top, position.y - offsetY);
          bottom = Math.max(bottom, position.y + offsetY);
          left = Math.min(left, position.x - offsetX);
          right = Math.max(right, position.x + offsetX);
        }
      });
    }

    if (options.margins) {
      top -= options.margins.top;
      left -= options.margins.left;
      bottom += options.margins.bottom;
      right += options.margins.right;
    }

    return {
      top,
      bottom,
      left,
      right,
    };
  }

  private drawOutline(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    let width =
      Math.abs(data.HFInfo.BD_BlankEnd_X - data.HFInfo.BD_BlankBeg_X) -
      options.lineWidth;
    let height = data.Height - options.lineWidth + data.FlangeHeight * 2;
    let translateX = data.HFInfo.BD_BlankBeg_X;

    width *= options.scalingRatio;
    height *= options.scalingRatio;
    translateX *= options.scalingRatio;

    context.save();
    context.translate(translateX, 0);

    context.setLineDash([]);
    context.beginPath();
    context.rect(0, 0, width, height);
    context.stroke();
    context.closePath();

    context.restore();
  }

  private drawFlanges(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    let upperFlangeY = data.FlangeHeight;
    let lowerFlangeY = data.FlangeHeight + data.Height;
    let dashSpace = options.lineWidth * 4;
    let dashWidth = options.lineWidth * 6;
    let translateX = data.HFInfo.BD_BlankBeg_X;
    let flangeWidth = Math.abs(
      data.HFInfo.BD_BlankEnd_X - data.HFInfo.BD_BlankBeg_X
    );

    upperFlangeY *= options.scalingRatio;
    lowerFlangeY *= options.scalingRatio;
    dashSpace *= options.scalingRatio;
    dashWidth *= options.scalingRatio;
    translateX *= options.scalingRatio;
    flangeWidth *= options.scalingRatio;

    context.save();
    context.setLineDash([dashWidth, dashSpace]);
    context.translate(translateX, 0);

    context.beginPath();
    context.moveTo(0, upperFlangeY);
    context.lineTo(flangeWidth, upperFlangeY);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(0, lowerFlangeY);
    context.lineTo(flangeWidth, lowerFlangeY);
    context.stroke();
    context.closePath();

    context.restore();
  }

  private drawReinforcements(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    let height = data.Height + data.FlangeHeight * 2;
    let dashSpace = options.lineWidth * 2;
    let dashWidth = options.lineWidth * 3;

    height *= options.scalingRatio;
    dashSpace *= options.scalingRatio;
    dashWidth *= options.scalingRatio;

    context.setLineDash([dashWidth, dashSpace]);

    data.Reinforcements.forEach((reinforcement: SidememberInfo) => {
      let begX = reinforcement.BD_BlankBeg_X;
      let endX = reinforcement.BD_BlankEnd_X;

      begX *= options.scalingRatio;
      endX *= options.scalingRatio;

      context.beginPath();
      context.moveTo(begX, 0);
      context.lineTo(begX, height);
      context.stroke();
      context.closePath();

      context.beginPath();
      context.moveTo(endX, 0);
      context.lineTo(endX, height);
      context.stroke();
      context.closePath();
    });
  }

  private drawHoleDuplicates(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    context.setLineDash([]);
    if (data.HoleDuplicates.length > 0) {
      context.save();
      context.fillStyle = CriteriaColors.Gray;
      this.drawHoleDuplicatesRectangle(context, data, options);
      context.restore();
    }
  }

  private drawHoles(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    this.sortHoles(data);

    context.setLineDash([]);
    data.Holes.forEach((hole: Hole) => {
      if (!this.validateHolePosition(hole)) {
        return;
      }

      let position = this.calculateHolePosition(hole, data);

      position = {
        x: position.x * options.scalingRatio,
        y: position.y * options.scalingRatio,
      };

      context.fillStyle = this.getContextColor(data, options, hole);

      const holeIsFiltered = this.holeIsFiltered(hole, data.FilteredHoles);
      const drawEnlarged =
        options.holeOptions.enlargeFilteredHoles && holeIsFiltered;

      const holeDrawing: HoleDrawing = {
        context,
        options,
        hole,
        position,
        drawEnlarged,
      };

      switch (hole.HoleShape) {
        case HoleShape.Round:
          this.drawRoundHole(holeDrawing);

          if (holeIsFiltered && options.holeOptions.showFilteredHoleNumbers) {
            this.drawHoleNumber(holeDrawing, data);
          }

          break;

        case HoleShape.LongShape:
          this.drawLongShapeHole(holeDrawing);
          break;
        case HoleShape.Ellipse:
          this.drawLongShapeHole(holeDrawing);
          break;

        case HoleShape.Rectangular:
          if (options.drawRestrictedAreas) {
            this.drawRectangularHole(holeDrawing);
          }
          break;

        default:
          break;
      }

      if (options.drawPPTRMarks && +hole.SupplOper === 5) {
        this.drawPPTRMark(holeDrawing);
      }
    });
  }

  private holeIsFiltered(hole: Hole, filteredHoles: FilteredHole[]): boolean {
    const filteredHole = filteredHoles.find(
      (filteredHole: FilteredHole) => filteredHole.HoleId === hole.HoleId
    );
    return filteredHole !== null && filteredHole !== undefined;
  }

  private sortHoles(data: GrapeDataModel): void {
    data.RelatedHoles.forEach((hole: Hole) => {
      this.removeItem(hole.HoleId, data.Holes);
      data.Holes.push(hole);
    });

    data.FilteredHoles.forEach((hole: Hole) => {
      this.removeItem(hole.HoleId, data.Holes);
      this.data.Holes.push(hole);
    });

    if (data.SelectedHole !== undefined && data.SelectedHole !== null) {
      this.removeItem(data.SelectedHole.HoleId, data.Holes);
      this.data.Holes.push(data.SelectedHole);
    }

    if (data.SelectedListHole !== undefined && data.SelectedListHole !== null) {
      this.removeItem(data.SelectedListHole.HoleId, data.Holes);
      this.data.Holes.push(data.SelectedListHole);
    }
  }

  private removeItem(holeId: number, allHoles: Hole[]): void {
    const index = allHoles.findIndex((x) => x.HoleId === holeId);
    if (index !== -1) {
      allHoles.splice(index, 1);
    }
  }

  private drawHoleDuplicatesRectangle(
    context: CanvasRenderingContext2D,
    data: GrapeDataModel,
    options: SidememberDrawingOptions
  ): void {
    data.HoleDuplicates.forEach((hole: Hole) => {
      if (!this.validateHolePosition(hole)) {
        return;
      }
      let position = this.calculateHolePosition(hole, data);
      position = {
        x: position.x * options.scalingRatio,
        y: position.y * options.scalingRatio,
      };

      const isEnlarged =
        options.holeOptions.enlargeFilteredHoles &&
        this.holeIsFiltered(hole, data.FilteredHoles);

      const length = isEnlarged
        ? this.enlargeHoleDimension(hole.Length, hole.Diameter)
        : hole.Length;
      const diameter = isEnlarged
        ? this.enlargeHoleDimension(hole.Diameter, hole.Length)
        : hole.Diameter;

      let width = hole.HoleShape === HoleShape.Rectangular ? length : diameter;
      let height = diameter;

      width += this.duplicateHoleSize;
      height += this.duplicateHoleSize;

      width *= options.scalingRatio;
      height *= options.scalingRatio;

      context.beginPath();
      context.fillRect(
        position.x - width / 2,
        position.y - height / 2,
        width,
        height
      );
      context.closePath();
    });
  }

  private getContextColor(
    data: GrapeDataModel,
    options: SidememberDrawingOptions,
    hole: Hole
  ): string {
    if (options.holeOptions.showSelectedHole) {
      if (
        (data.SelectedHole !== undefined &&
          data.SelectedHole !== null &&
          data.SelectedHole.HoleId === hole.HoleId) ||
        (data.SelectedListHole !== undefined &&
          data.SelectedListHole !== null &&
          data.SelectedListHole.HoleId === hole.HoleId)
      ) {
        return CriteriaColors.Pink;
      }
    }

    if (data.SecondaryHoles.findIndex((x) => x.HoleId === hole.HoleId) !== -1) {
      return CriteriaColors.Yellow;
    }

    if (
      this.data.compareMode &&
      hole.HoleFlag === HoleFlags.UniqueInSidemember1
    ) {
      return CriteriaColors.DarkGreen;
    } else if (
      this.data.compareMode &&
      hole.HoleFlag === HoleFlags.UniqueInSidemember2
    ) {
      return CriteriaColors.Orange;
    }

    if (
      options.holeOptions.showFilteredHoles &&
      data.FilteredHoles.length > 0
    ) {
      const filteredHole = data.FilteredHoles.find(
        (x) => x.HoleId === hole.HoleId
      );
      if (filteredHole !== undefined) {
        const foundColor = this.searchHoleColors.colors.find(
          (x) => x.name === filteredHole.HoleColor
        );
        return foundColor ? foundColor.value : '';
      }
    }

    if (options.holeOptions.showRelatedHoles && data.RelatedHoles.length > 0) {
      const relatedHole = data.RelatedHoles.find(
        (x) => x.HoleId === hole.HoleId
      );
      if (relatedHole !== undefined) {
        return CriteriaColors.Green;
      }
    }

    if (hole.HoleShape === HoleShape.Rectangular || !options.colorRoundHole) {
      return CriteriaColors.White;
    }

    return CriteriaColors.White;
  }

  private drawRoundHole(drawing: HoleDrawing): void {
    const context = drawing.context;
    const diameter = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Diameter)
      : drawing.hole.Diameter;

    let radius = diameter / 2;
    radius *= drawing.options.scalingRatio;

    context.beginPath();
    context.arc(drawing.position.x, drawing.position.y, radius, 0, Math.PI * 2);
    context.fill();
    context.stroke();
    context.closePath();
  }

  private drawLongShapeHole(drawing: HoleDrawing): void {
    let diameter = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Diameter, drawing.hole.Length)
      : drawing.hole.Diameter;
    let length = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Length, drawing.hole.Diameter)
      : drawing.hole.Length;

    diameter *= drawing.options.scalingRatio;
    length *= drawing.options.scalingRatio;

    const context = drawing.context;
    const radius = diameter / 2;
    const dx = (length - diameter) / 2;

    const leftTop: Point = {
      x: -dx,
      y: radius,
    };

    const leftBottom: Point = {
      x: -dx,
      y: -radius,
    };

    const leftEdge: Point = {
      x: -dx - radius,
      y: 0,
    };

    const rightTop: Point = {
      x: dx,
      y: radius,
    };

    const rightBottom: Point = {
      x: dx,
      y: -radius,
    };

    const rightEdge: Point = {
      x: dx + radius,
      y: 0,
    };

    context.save();
    context.translate(drawing.position.x, drawing.position.y);
    context.rotate((Math.PI / 180) * drawing.hole.Alpha);
    context.beginPath();
    context.moveTo(leftTop.x, leftTop.y);
    context.lineTo(rightTop.x, rightTop.y);
    context.arcTo(rightEdge.x, rightTop.y, rightEdge.x, rightEdge.y, radius);
    context.arcTo(
      rightEdge.x,
      rightBottom.y,
      rightBottom.x,
      rightBottom.y,
      radius
    );
    context.lineTo(leftBottom.x, leftBottom.y);
    context.arcTo(leftEdge.x, leftBottom.y, leftEdge.x, leftEdge.y, radius);
    context.arcTo(leftEdge.x, leftTop.y, leftTop.x, leftTop.y, radius);
    context.fill();
    context.stroke();
    context.closePath();
    context.restore();
  }

  private drawRectangularHole(drawing: HoleDrawing): void {
    const context = drawing.context;
    const position = drawing.position;

    let width = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Length, drawing.hole.Diameter)
      : drawing.hole.Length;

    let height = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Diameter, drawing.hole.Length)
      : drawing.hole.Diameter;

    width *= drawing.options.scalingRatio;
    height *= drawing.options.scalingRatio;

    context.beginPath();
    context.rect(
      position.x - width / 2,
      position.y - height / 2,
      width,
      height
    );
    context.fill();
    context.stroke();
    context.closePath();
  }

  private drawPPTRMark(drawing: HoleDrawing): void {
    const context = drawing.context;
    const position = drawing.position;

    const MARK_DIAM = 5;
    const MARK_OFFSET = 11.1 * drawing.options.scalingRatio;

    const markDiameter = drawing.drawEnlarged
      ? this.enlargeHoleDimension(MARK_DIAM, drawing.hole.Diameter)
      : MARK_DIAM;

    const holeRadius =
      (drawing.hole.Diameter / 2) * drawing.options.scalingRatio;
    const markRadius = (markDiameter / 2) * drawing.options.scalingRatio;
    const markY = position.y - holeRadius - markRadius - MARK_OFFSET;

    context.beginPath();
    context.arc(position.x, markY, markRadius, 0, Math.PI * 2);
    context.fill();
    context.stroke();
    context.closePath();
  }

  private drawHoleNumber(drawing: HoleDrawing, data: GrapeDataModel): void {
    const context = drawing.context;
    const position = drawing.position;
    const hole = drawing.hole;
    const textOffset = 11.1;
    const diameter = drawing.drawEnlarged
      ? this.enlargeHoleDimension(drawing.hole.Diameter)
      : drawing.hole.Diameter;

    context.save();

    const fontSize = 20 * drawing.options.scalingRatio;
    context.font = `${fontSize}pt sans-serif`;
    context.fillStyle = '#000000';
    context.textAlign = 'center';
    context.textBaseline = 'hanging';

    const textY = diameter + textOffset;

    let scaleX: number;
    let scaleY: number;
    let x = position.x;
    let y = position.y;

    if (data.HFInfo.FrameSide === SidememberSide.Right) {
      scaleX = this.options.drawUpsideDown ? 1 : -1;
      scaleY = this.options.drawUpsideDown ? 1 : -1;
      x = this.options.drawUpsideDown ? x : -x;
      y = this.options.drawUpsideDown ? y : -y;
    } else {
      scaleX = this.options.drawUpsideDown ? -1 : 1;
      scaleY = this.options.drawUpsideDown ? 1 : -1;
      x = this.options.drawUpsideDown ? -x : x;
      y = this.options.drawUpsideDown ? y : -y;
    }

    context.scale(scaleX, scaleY);
    context.translate(x, y);
    context.beginPath();
    context.fillText(hole.HoleObj, 0, textY);
    context.fill();
    context.closePath();
    context.restore();
  }

  private shouldDrawInverted(
    data: GrapeDataModel,
    settings: SidememberDrawingOptions
  ): boolean {
    const rightSidemember = data.HFInfo.FrameSide === SidememberSide.Right;

    const invertDrawing =
      (settings.drawUpsideDown && !rightSidemember) ||
      (!settings.drawUpsideDown && rightSidemember);

    return invertDrawing;
  }

  private getSidememberPosition(data: GrapeDataModel): Coordinate {
    return {
      x: data.HFInfo.BD_BlankBeg_X,
      y: data.HFInfo.FrameSide === SidememberSide.Right ? 385 : -385,
      z: 1000,
    };
  }

  private enlargeHoleDimension(size1: number, size2?: number): number {
    const enlargeOffset = 2.388888889;
    const enlargeK = -0.027777778;
    const max = -enlargeOffset / (2 * enlargeK);

    let s1 = size1;
    let zoom: number;

    if (!size2) {
      if (s1 > max) {
        s1 = max;
      }

      zoom = Math.max(s1 * enlargeK + enlargeOffset, 1);
    } else {
      const quota = size1 / size2;
      let s2 = size2;

      if (s1 > max) {
        s1 = max;
        s2 = s1 / quota;
      }

      if (s2 > max) {
        s2 = max;
        s1 = s2 * quota;
      }

      const zoom1 = Math.max(s1 * enlargeK + enlargeOffset, 1);
      const zoom2 = Math.max(s2 * enlargeK + enlargeOffset, 1);
      zoom = Math.min(zoom1, zoom2);
    }

    const newSize = Math.max(s1 * zoom, size1);

    return newSize;
  }

  private calculateHolePosition(hole: Hole, data: GrapeDataModel): Point {
    const sidememberPos = this.getSidememberPosition(data);
    const surface = this.calculateHoleSurface(hole, data, sidememberPos);

    const sideCorr = data.HFInfo.FrameSide === SidememberSide.Right ? 1 : -1;

    const x = hole.Hole_X;
    let y = hole.Hole_Z - (sidememberPos.z - data.FlangeHeight);

    if (surface === HoleSurface.LowerFlange) {
      y -= (sidememberPos.y - hole.Hole_Y) * sideCorr;
    } else if (surface === HoleSurface.UpperFlange) {
      y += (sidememberPos.y - hole.Hole_Y) * sideCorr;
    }

    return { x, y };
  }

  private calculateHoleSurface(
    hole: Hole,
    data: GrapeDataModel,
    sidememberPosition: Coordinate
  ): HoleSurface {
    const upperFlangeZ = sidememberPosition.z + data.Height;
    const lowerFlangeZ = sidememberPosition.z;

    let isFlangeHole = hole.Hole_Y > sidememberPosition.y;

    if (data.HFInfo.FrameSide === SidememberSide.Right) {
      isFlangeHole = hole.Hole_Y < sidememberPosition.y;
    }

    const isUpperFlangeHole = isFlangeHole && hole.Hole_Z === upperFlangeZ;
    const isLowerFlangeHole = isFlangeHole && hole.Hole_Z === lowerFlangeZ;

    if (isUpperFlangeHole) {
      return HoleSurface.UpperFlange;
    }

    if (isLowerFlangeHole) {
      return HoleSurface.LowerFlange;
    }

    return HoleSurface.Web;
  }

  private validateHolePosition(hole: Hole): boolean {
    const maxAllowedZ = 1270;
    const minAllowedZ = 1000;
    return hole.Hole_Z >= minAllowedZ && hole.Hole_Z <= maxAllowedZ;
  }
}
