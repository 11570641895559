<ng-container *ngIf="variantClasses">
  <div
    class="mb-3"
    *ngFor="
      let filterDefinition of filterDefinitionList.controls;
      let i = index
    "
  >
    <div
      class="form-group form-group-sm m-0 p-0 d-flex"
      [formGroup]="filterDefinition"
      [ngClass]="{ 'has-error': isDuplicateEntry(filterDefinition) }"
    >
      <div class="w-25 m-0 p-0 d-flex align-items-top">
        <select class="form-control flex-grow-1" formControlName="class">
          <option
            *ngFor="let variantClass of variantClasses"
            [ngValue]="variantClass.Name"
            >{{ variantClass.Name }}</option
          >
        </select>
      </div>
      <div
        class="m-0 ml-3 p-0 d-flex flex-column align-items-top"
        [ngClass]="{
          'w-100': !enableVariantClassOption,
          'w-50': enableVariantClassOption
        }"
        *ngIf="enableVariantClassFamily"
      >
        <input
          type="text"
          placeholder="{{ 'VAR_CODE.FAMILY' | translate }}"
          class="form-control"
          formControlName="family"
          maxlength="5"
          [attr.disabled]="
            !canHaveFamily(filterDefinition.get('class').value) || null
          "
          [ngClass]="{
            'has-error': isInvalidFamily(filterDefinition)
          }"
          numbersOnly
        />
        <div class="text-danger mt-2" *ngIf="isInvalidFamily(filterDefinition)">
          * {{ 'VAR_CODE.ENTER_VALID_FAMILY' | translate }}
        </div>
      </div>
      <div
        class="m-0 ml-3 p-0 d-flex flex-column align-items-top"
        [ngClass]="{
          'w-100': !enableVariantClassFamily,
          'w-50': enableVariantClassFamily
        }"
        *ngIf="enableVariantClassOption"
      >
        <input
          type="text"
          placeholder="{{ 'VAR_CODE.OPTION' | translate }}"
          class="form-control"
          formControlName="option"
          maxlength="2"
          [attr.disabled]="
            !canHaveOption(filterDefinition.get('class').value) || null
          "
          [ngClass]="{
            'has-error': isInvalidOption(filterDefinition)
          }"
          toUppercase
        />
        <div class="text-danger mt-2" *ngIf="isInvalidOption(filterDefinition)">
          * {{ 'VAR_CODE.ENTER_VALID_OPTION' | translate }}
        </div>
      </div>
      <div mat-icon-button class="text-right m-0 p-0 d-flex align-items-top flex-grow-1">
        <button
          type="button"
          (click)="deleteDefinition(i)"
          class="btn btn-link p-0 mt-1"
          title="{{ 'DELETE' | translate }}"
        >
          <mat-icon class="btn-mrg">delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="text-danger mt-2" *ngIf="isDuplicateEntry(filterDefinition)">
      * {{ 'VAR_CODE.ALREADY_EXISTS' | translate }}
    </div>
  </div>
  <div class="mb-4">
    <button mat-button type="button" class="btn btn-link p-0" (click)="addDefinition()">
      <mat-icon>add</mat-icon> {{ 'ADD' | translate}}
    </button>
  </div>
</ng-container>
