import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { formatDate } from '@angular/common';
@Pipe({
  name: 'LocaleDateShort',
})
export class LocaleDateShortPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(value: string | Date): string {
    if (!value) {
      return '';
    }

    if (value instanceof Date) {
      return value.toLocaleDateString(this.localeService.locale);
    }

    const date = new Date(value);
    return date.toLocaleDateString(this.localeService.locale);
  }
}
