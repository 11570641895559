import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullString'
})
export class NullStringPipe implements PipeTransform {

  transform(val: any, stringIfNull: string): string {
    if (val !== undefined && val !== null) {
      return val;
    }
    return stringIfNull;
  }

}