import { Settings } from './settings';
import { HoleSearchDef } from '../models/hole-search-def';
import { SearchCriteriaColor } from '../models/search-criteria-color';

export class HoleInfoSettings extends Settings {
  public collapsed = false;
  public searchFilterActive = false;
  public holeSearchDefinitions: HoleSearchDef[] = [];
  public colors: SearchCriteriaColor = new SearchCriteriaColor();

  public load(): void {
    const storedSettings = <HoleInfoSettings>super.load();
    this.collapsed = super.isStored('collapsed', storedSettings) ? storedSettings.collapsed : this.collapsed;
    this.searchFilterActive = super.isStored('searchFilterActive', storedSettings) ? storedSettings.searchFilterActive : this.searchFilterActive;

    this.holeSearchDefinitions = super.isStored('holeSearchDefinitions', storedSettings)
      ? storedSettings.holeSearchDefinitions
      : this.holeSearchDefinitions;

    this.colors = super.isStored('colors', storedSettings)
      ? storedSettings.colors
      : this.colors;

    this.onLoad.emit(this.name);
  }

  public save(): void {
    super.save(this);
    this.onSave.emit(this.name);
  }
}