import { IndexlistEntryDto } from '../dto/indexlist-entry.dto';
import { IndexlistEntry } from '../indexlist-entry';

export class IndexlistMapper {
  public static fromDto(dto: IndexlistEntryDto): IndexlistEntry {
    const indexlistEntry = new IndexlistEntry();
    indexlistEntry.ipoId = dto.IPOId;
    indexlistEntry.version = Number(dto.Version);
    indexlistEntry.ciId = Number(dto.CIId);
    indexlistEntry.hfStatus = dto.HFStatus; // !isNaN(Number(dto.HFStatus)) ? Number(dto.HFStatus) : String(dto.HFStatus);
    indexlistEntry.cusName = dto.CUSName;
    indexlistEntry.assemblyDate = this.mapDateString(dto.AssemblyDate);
    indexlistEntry.supplierName = dto.SupplierName;
    indexlistEntry.chassisNumber = dto.ChassisNumber;
    indexlistEntry.prodIndividual = dto.ProdIndividual;
    indexlistEntry.syncTime = dto.SyncTime;
    return indexlistEntry;
  }

  static mapDateString(assemblyDate: string): Date {
    const dateString: string = assemblyDate.match('[0-9+]+').toString();
    const dateDecimal = parseInt(dateString, 10);
    const date = new Date(dateDecimal);
    return date;
  }
}
