import { Settings } from './settings';
import { DataSource } from '../models/grapeservice/datasource';

export class AutoPrintSettings extends Settings {
  public interval = 15;
  public dataSource: string;
  public workPlaces: string[] = [];
  public showErrorsOnly: boolean;

  get isValid(): boolean {
    return this.dataSource !== undefined && this.workPlaces.length !== 0;
  }

  public load(): void {
    const storedSettings = <AutoPrintSettings>super.load();
    this.interval = super.isStored('interval', storedSettings)
      ? storedSettings.interval
      : this.interval;
    this.dataSource = super.isStored('dataSource', storedSettings)
      ? storedSettings.dataSource
      : this.dataSource;
    this.workPlaces = super.isStored('workPlaces', storedSettings)
      ? storedSettings.workPlaces
      : this.workPlaces;
    this.showErrorsOnly = super.isStored('showErrorsOnly', storedSettings)
      ? storedSettings.showErrorsOnly
      : this.showErrorsOnly;
  }

  public save(): void {
    super.save(this);
  }
}
