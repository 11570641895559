import { Camera } from 'babylonjs';
import { InputEvents } from '../events/input-events';
import { InputObservable } from '../command/input-observable';
import { InputObserver } from '../command/input-observer';
import { InputCommand } from '../command/input-command';
import { PointerCommand } from '../command/pointer-command';
import { CommandType } from '../command/command-type';
import { Keyboard } from '../type/keyboard';
import { KeyboardCommand } from '../command/keyboard-command';

export class InputController<TCamera extends Camera>
  implements InputObservable {
  public camera: TCamera;
  public inputObservers: InputObserver[] = [];

  protected name: string;
  protected preventDefault: boolean;
  protected events: InputEvents;
  protected pressedKeys: number[] = [];

  protected allowedKeys: number[] = [
    Keyboard.UP,
    Keyboard.DOWN,
    Keyboard.W,
    Keyboard.S,
    Keyboard.LEFT,
    Keyboard.RIGHT,
    Keyboard.A,
    Keyboard.D,
    Keyboard.PLUS,
    Keyboard.MINUS
  ];

  private keysUp: number[] = [Keyboard.UP, Keyboard.W];
  private keysDown: number[] = [Keyboard.DOWN, Keyboard.S];
  private keysLeft: number[] = [Keyboard.LEFT, Keyboard.A];
  private keysRight: number[] = [Keyboard.RIGHT, Keyboard.D];
  private keysZoomIn: number[] = [Keyboard.PLUS];
  private keysZoomOut: number[] = [Keyboard.MINUS];

  protected mouseWheelDeltaY = 0;
  protected pointerMove: PointerEvent;
  protected pointerDown: PointerEvent;
  protected pointerUp: PointerEvent;

  constructor(name: string) {
    this.name = name;
    this.initInputHandling();
  }

  get  cameraEngineInputElement(): HTMLElement {
    return this.camera.getEngine()?.getInputElement();
  }
  public getClassName(): string {
    return this.name;
  }
  public getTypeName(): string {
    return this.name;
  }
  public getSimpleName(): string {
    return this.name;
  }

  public checkInputs() {
    if (this.keysPressed(this.keysUp)) {
      this.notifyInputObservers(
        new KeyboardCommand(CommandType.CAMERA_MOVE_UP)
      );
    }

    if (this.keysPressed(this.keysDown)) {
      this.notifyInputObservers(
        new KeyboardCommand(CommandType.CAMERA_MOVE_DOWN)
      );
    }

    if (this.keysPressed(this.keysLeft)) {
      this.notifyInputObservers(
        new KeyboardCommand(CommandType.CAMERA_MOVE_LEFT)
      );
    }

    if (this.keysPressed(this.keysRight)) {
      this.notifyInputObservers(
        new KeyboardCommand(CommandType.CAMERA_MOVE_RIGHT)
      );
    }

    if (this.keysPressed(this.keysZoomIn)) {
      this.notifyInputObservers(new InputCommand(CommandType.CAMERA_ZOOM_IN));
    }

    if (this.keysPressed(this.keysZoomOut)) {
      this.notifyInputObservers(new InputCommand(CommandType.CAMERA_ZOOM_OUT));
    }

    if (this.mouseWheelDeltaY < 0) {
      this.notifyInputObservers(new InputCommand(CommandType.CAMERA_ZOOM_IN));
    }

    if (this.mouseWheelDeltaY > 0) {
      this.notifyInputObservers(new InputCommand(CommandType.CAMERA_ZOOM_OUT));
    }

    this.mouseWheelDeltaY = 0;
  }

  public addInputObserver(observer: InputObserver): void {
    this.inputObservers.push(observer);
  }

  public removeInputObserver(observer: InputObserver): void {
    const index = this.inputObservers.indexOf(observer);
    if (index !== -1) {
      this.inputObservers.splice(index, 1);
    }
  }

  public notifyInputObservers(command: InputCommand): void {
    for (const observer of this.inputObservers) {
      observer.onInput(command);
    }
  }

  public attachControl(noPreventDefault?: boolean): void {
    this.events.attach(this.cameraEngineInputElement);
    this.preventDefault = !noPreventDefault;
  }

  public detachControl(element: HTMLElement): void {
    this.events.detach(element);
  }

  protected initInputHandling(): void {
    this.events = new InputEvents();
    this.events.keyDown = (event: KeyboardEvent) => {
      this.onKeyDown(event);
    };
    this.events.keyUp = (event: KeyboardEvent) => {
      this.onKeyUp(event);
    };
    this.events.mouseWheel = (event: WheelEvent) => {
      this.onMouseWheel(event);
    };
    this.events.pointerDown = (event: PointerEvent) => {
      this.onPointerDown(event);
    };
    this.events.pointerUp = (event: PointerEvent) => {
      this.onPointerUp(event);
    };
    this.events.pointerMove = (event: PointerEvent) => {
      this.onPointerMove(event);
    };
    this.events.focusOut = (event: FocusEvent) => {
      this.onFocusOut();
    };
  }

  protected onKeyDown(event: KeyboardEvent): void {
    if (this.allowedKeys.includes(event.keyCode)) {
      if (!this.pressedKeys.includes(event.keyCode)) {
        this.pressedKeys.push(event.keyCode);
        if (!this.preventDefault) {
          event.preventDefault();
        }
      }
    }
  }

  protected onKeyUp(event: KeyboardEvent): void {
    if (this.allowedKeys.includes(event.keyCode)) {
      const index = this.pressedKeys.indexOf(event.keyCode);
      if (this.pressedKeys.includes(event.keyCode)) {
        this.pressedKeys.splice(index, 1);
      }
      if (this.preventDefault) {
        event.preventDefault();
      }
    }
  }

  protected keysPressed(keys: number[]): boolean {
    for (const keyCode of this.pressedKeys) {
      if (keys.includes(keyCode)) {
        return true;
      }
    }
    return false;
  }

  protected onMouseWheel(event: WheelEvent): void {
    this.mouseWheelDeltaY = event.deltaY;
    event.preventDefault();
  }
  

  protected onPointerDown(event: PointerEvent): void {
    this.pointerDown = event;

    let commandType: CommandType = CommandType.POINTER_LEFT_DOWN;

    switch (event.button) {
      case 0: // LMB
      default:
        commandType = !event.ctrlKey ? CommandType.POINTER_LEFT_DOWN : CommandType.POINTER_CTRL_LEFT;
        break;

      case 1: // Scroll wheel
        commandType = CommandType.SCROLL_WHEEL_PRESSED;
        break;

      case 2: // RMB
        commandType = CommandType.POINTER_RIGHT_DOWN;
        break;
    }

    const command = new PointerCommand(commandType, event);
    this.notifyInputObservers(command);
    event.preventDefault();
  }

  protected onPointerUp(event: PointerEvent): void {
    this.pointerUp = event;

    let commandType: CommandType = CommandType.POINTER_LEFT_UP;

    switch (event.button) {
      case 0: // LMB
      default:
        commandType = CommandType.POINTER_LEFT_UP;
        break;

      case 1: // Scroll wheel
        commandType = CommandType.SCROLL_WHEEL_RELEASED;
        break;

      case 2: // RMB
        commandType = CommandType.POINTER_RIGHT_UP;
        break;
    }

    const command = new PointerCommand(commandType, event);
    this.notifyInputObservers(command);
    event.preventDefault();
  }

  protected onPointerMove(event: PointerEvent): void {
    this.pointerMove = event;
    const command = new PointerCommand(CommandType.POINTER_MOVE, event);
    this.notifyInputObservers(command);
  }

  protected onFocusOut(): void {
    this.pressedKeys = [];
    const command = new InputCommand(CommandType.FOCUS_OUT);
    this.notifyInputObservers(command);
  }
}
