import * as GUI from 'babylonjs-gui';
import { RenderEngine } from '../render-engine';
import { PresentationSettings } from '../core/presentation-settings';

export class HoleDistanceGUI {
  public secondaryHoleId: number;
  protected holeDistanceText: GUI.TextBlock;
  protected holeDistanceRectangle: GUI.Rectangle;
  protected upperTextNode: any;
  private textControlName = 'HoleDistanceText';
  private textBackgroundControlName = 'HoleDistanceRectangle';
  private offsetConstant = 2.35;
  private fontSizeRatio = 0.85;

  constructor(
    private engine: RenderEngine,
    private gui: GUI.AdvancedDynamicTexture,
    private settings: PresentationSettings
  ) {
    this.initialize();
  }

  public show(
    secondaryHoleId: number,
    holeDistance: string,
    upperTextNode: any
  ): void {
    this.secondaryHoleId = secondaryHoleId;
    this.upperTextNode = upperTextNode;

    this.showInfoAboveLine(holeDistance);
  }

  public destroy(): void {
    this.gui.removeControl(this.holeDistanceRectangle);
  }

  private initialize(): void {
    this.settings.wasUpdated.subscribe(() => {
      this.show(this.secondaryHoleId, 'text', this.upperTextNode);
    });

    this.holeDistanceRectangle = new GUI.Rectangle(
      this.textBackgroundControlName
    );

    this.holeDistanceRectangle.background = this.settings.distanceTextBackground.color;
    this.holeDistanceRectangle.thickness = this.settings.distanceTextBackground.borderTickness;
    this.holeDistanceRectangle.alpha = this.settings.distanceTextBackground.opacity;
    this.holeDistanceRectangle.height = this.settings.distanceTextBackground.height;

    this.holeDistanceText = new GUI.TextBlock(this.textControlName, '');
    this.holeDistanceText.fontFamily = this.settings.guiFont.guiFontFamily;
    this.holeDistanceText.fontSize = this.settings.guiFont.guiFontSize;
    this.holeDistanceText.color = this.settings.guiFont.guiFontColor;
    this.holeDistanceText.textWrapping = false;

    this.holeDistanceRectangle.addControl(this.holeDistanceText);

    this.gui.addControl(this.holeDistanceRectangle);
  }

  private showInfoAboveLine(text: string): void {
    if (!this.holeDistanceText && !this.holeDistanceRectangle) return;

    this.holeDistanceText.text = text;
    this.holeDistanceRectangle.onBeforeDrawObservable.add(() => 
      this.resizeDistanceRectangle(text, this.settings.guiFont.guiFontSize)
    );
    this.holeDistanceRectangle.linkWithMesh(this.upperTextNode);

    this.holeDistanceRectangle.linkOffsetY = -this.calculateLinkOffset(text, 1);
  }

  resizeDistanceRectangle(text: string, fontSize: number) {
    this.holeDistanceRectangle.width = this.getTextBackgroundWidth(text, fontSize);
    this.holeDistanceRectangle.height = this.getTextBackgroundHeight(fontSize);
  }

  getTextBackgroundWidth(text: string, fontSize: number): string {
    const canvasWidth = this.engine.canvas.width;
    return (text.length * fontSize * this.fontSizeRatio / canvasWidth) * 100 + '%';
  }

  getTextBackgroundHeight(fontSize: number): string {
    const canvasHeight = this.engine.canvas.height;
    return (fontSize * this.fontSizeRatio - (canvasHeight / 100)) + '%';
  }

  private calculateLinkOffset(text: string, initialOffset?: number): number {
    const numLines = text.indexOf('\n') !== -1 ? text.split('\n').length : 1;
    const textHeight =
      (numLines *
        (this.settings.guiFont.guiFontSize *
          this.settings.guiFont.guiLineHeight)) /
      this.offsetConstant;

    const linkOffsetY = (initialOffset ? initialOffset : 0) + textHeight;

    return linkOffsetY;
  }
}
