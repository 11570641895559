import { Settings } from './settings';

export class SidememberInfoSettings extends Settings {
  public collapsed = true;

  public load(): void {
    const storedSettings = <SidememberInfoSettings>super.load();
    this.collapsed = super.isStored('collapsed', storedSettings) ? storedSettings.collapsed : this.collapsed;
    this.onLoad.emit(this.name);
  }

  public save(): void {
    super.save(this);
    this.onSave.emit(this.name);
  }
}