import { Injectable } from '@angular/core';
import { Hole } from '../models/grapeservice/hole';
import { SidememberInfo } from '../models/grapeservice/sidemember-info';
import { SidememberSegment } from '../models/grapeservice/sidemember-segment';
import { environment } from '../../../environments/environment';

@Injectable()
export class HoleSidememberSegmentService {
  constructor() {}

  public getSidememberSegment(sidemember: SidememberInfo, hole: Hole): SidememberSegment {
    const sidememberStartZ = environment.sidememberConfig.startZAxis;
    // If sidemember.WebHeight is not a valid number consider the default
    const upperFlangeStartZ = sidememberStartZ +
      (parseInt(sidemember.WebHeight) || environment.sidememberConfig.defaultWebHeight);

    if (hole.Hole_Z === sidememberStartZ) {
      return SidememberSegment.lowerFlange;
    }
    if (hole.Hole_Z === upperFlangeStartZ) {
      return SidememberSegment.upperFlange;
    }
    if (hole.Hole_Z < upperFlangeStartZ && hole.Hole_Z > sidememberStartZ) {
      return SidememberSegment.web;
    }
    return SidememberSegment.noSideMemberSegment;
  }

  public areInSameSidememberSegment(sidemember: SidememberInfo, hole1: Hole, hole2: Hole): boolean {
    return this.getSidememberSegment(sidemember, hole1) === this.getSidememberSegment(sidemember, hole2);
  }
}