export class HoleSearchDef {
  constructor(def: HoleSearchDef) {
    if (def) {
      this.attributeName = def.attributeName;
      this.color = def.color;
      this.index = def.index;
      this.isAnd = def.isAnd;
      this.operator = def.operator;
      this.value1 = def.value1;
      this.value2 = def.value2;
    }
  }

  public index: number;
  public attributeName = '';
  public operator = '=';
  public value1 = null;
  public value2 = null;
  public color = '';
  public isAnd = true;

  public searchList: string[] = [];
}

