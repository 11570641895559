import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { DataSource } from '../models/grapeservice/datasource';
import { Indexlist } from '../models/grapeservice/indexlist';
import { Sidemember } from '../models/grapeservice/sidemember';
import { VariantCodes } from '../models/grapeservice/variant-codes';
import { InitListEntry } from '../models/grapeservice/initlist-entry';
import { inflate } from 'pako';
import { AutoPrintStatusDTO } from '../models/grapeservice/auto-print-status-dto';
import { GrapeUserDetails } from '../models/grapeservice/grape-user-details';
import { Router } from '@angular/router';
import { VariantClass } from '../models/variant-class';
import { VariantCodesFilterDefinition } from '../models/selected-variantcodes-def';
@Injectable()
export class GrapeService {
  private variantClasses$ = new BehaviorSubject(null);
  private variantClassesSearch$ = new BehaviorSubject(null);
  get variantClasses(): Observable<VariantClass[]> {
    return this.variantClasses$.asObservable();
  }

  get variantClassesSearch(): Observable<VariantClass[]> {
    return this.variantClassesSearch$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    public router: Router
  ) {
    this.loadVariantClasses();
  }

  public getSideMembersForCompare(
    dataSource: string,
    ipoId: string,
    version: string,
    ipoId2: string,
    version2: string
  ) {
    return forkJoin([
      this.getSidememberData(dataSource, ipoId, version),
      this.getSidememberData(dataSource, ipoId2, version2),
    ]);
  }

  public getDataSources(): Observable<DataSource[]> {
    return this.http.get<DataSource[]>(
      `${this.configService.grapeApi}/GetDataSources`
    );
  }

  public getIndexList(dataSourceName: string): Observable<Indexlist> {
    const queryString = `?dataSourceName=${dataSourceName}`;

    const observable = this.http
      .get<string>(
        `${this.configService.grapeApi}/GetIndexList${queryString}`,
        { responseType: 'text' as 'json' }
      )
      .pipe(
        map((zippedResponse) => {
          const array: number[] = zippedResponse.split(',').map(x => +x);
          const unZippedResponse = JSON.parse(
            inflate(Uint8Array.from(array), { to: 'string' })
          );
          return Indexlist.fromDto(unZippedResponse);
        })
      );

    return observable;
  }

  public getIndexListWithFilter(
    dataSourceName: string,
    searchDefs: VariantCodesFilterDefinition[] = []
  ): Observable<Indexlist> {
    const body = {
      dataSourceName,
      searchOptions: searchDefs.map((def: VariantCodesFilterDefinition) =>
        Object.assign({
          VarClass: def.class,
          VarFam: def.family,
          VarOpt: def.option,
        })
      ),
    };

    const observable = this.http
      .post<string>(
        `${this.configService.grapeApi}/GetIndexListWithVariantCodes`,
        body,
        { responseType: 'text' as 'json' }
      )
      .pipe(
        map((zippedResponse: string) => {
          const array: number[] = zippedResponse.split(',').map(x => +x);
          const unZippedResponse = JSON.parse(
            inflate(Uint8Array.from(array), { to: 'string' })
          );
          return Indexlist.fromDto(unZippedResponse);
        })
      );

    return observable;
  }

  public getSidememberData(
    dataSourceName: string,
    ipoId: string,
    version: string
  ): Observable<Sidemember> {
    return this.http.get<Sidemember>(
      `${this.configService.grapeApi}/GetSideMember?dataSourceName=${dataSourceName}&ipoId=${ipoId}&version=${version}`
    );
  }

  public getTypeText(
    dataSourceName: string,
    ipoId: string
  ): Observable<string> {
    return this.http.get<string>(
      `${this.configService.grapeApi}/GetTypeText?dataSourceName=${dataSourceName}&ipoId=${ipoId}`
    );
  }

  public getVariantCodes(
    dataSourceName: string,
    ipoId: string
  ): Observable<VariantCodes[]> {
    return this.http.get<VariantCodes[]>(
      `${this.configService.grapeApi}/GetVariantCodes?dataSourceName=${dataSourceName}&ipoId=${ipoId}`
    );
  }

  public getInitList(
    dataSourceName: string,
    workplaces: string[]
  ): Observable<InitListEntry[]> {
    const body = {
      dataSourceName,
      workplaces,
    };

    return this.http.post<InitListEntry[]>(
      `${this.configService.grapeApi}/GetGrapeInitList`,
      body
    );
  }

  public setStatusPrinted(
    dataSourceName: string,
    initItems: AutoPrintStatusDTO[]
  ): Observable<any> {
    const body = {
      dataSourceName,
      initItems,
    };

    return this.http.post<any>(
      `${this.configService.grapeApi}/SetStatusPrinted`,
      body
    );
  }

  private loadVariantClasses(): void {
    if (!this.variantClasses$.value) {
      this.getVariantClasses()?.pipe(take(1))?.subscribe();
    }

    if (!this.variantClassesSearch$.value) {
      this.getVariantClassesSearch()?.pipe(take(1))?.subscribe();
    }
  }

  private getVariantClasses(): Observable<VariantClass[]> {
    return this.http
      .get<VariantClass[]>(`${this.configService.grapeApi}/GetVariantClasses`)
      .pipe(
        map((result) => result['GetVariantClassesResult']),
        tap((variantClasses: VariantClass[]) =>
          this.variantClasses$?.next(
            variantClasses?.sort((a, b) => a.Name.localeCompare(b.Name))
          )
        )
      );
  }

  private getVariantClassesSearch(): Observable<VariantClass[]> {
    return this.http
      .get<VariantClass[]>(
        `${this.configService.grapeApi}/GetVariantClassesSearch`
      )
      .pipe(
        map((result) => result['GetVariantClassesSearchResult']),
        tap((variantClasses: VariantClass[]) =>
          this.variantClassesSearch$?.next(
            variantClasses?.sort((a, b) => a.Name.localeCompare(b.Name))
          )
        )
      );
  }
  // public AddUserRole(body) {
  //   return this.http.post<any>(
  //     `${this.configService.grapeApi}/AddUserRole`,
  //     body
  //   );
  // }

  // public DeleteUserRole(body) {
  //   return this.http.post<any>(
  //     `${this.configService.grapeApi}/DeleteUserRole`,
  //     body
  //   );
  // }

  // public GetGrapeUsers(): Observable<GrapeUserDetails> {
  //   return this.http.get<GrapeUserDetails>(
  //     `${this.configService.grapeApi}/GetDataSources`
  //   );
  // }

  // public GetGrapeUsersbyRole(role) {
  //   return this.http.get<GrapeUserDetails>(
  //     `${this.configService.grapeApi}/GetUsersByRole?roleName=${role}`
  //   );
  // }
  // // public GetScaniaUsers(username: string): Observable<any> {
  //   return this.http.get<any>(
  //     `${this.configService.grapeApi}/GetTargetUserByUsername?username=${username}`
  //   );
  // }
  // public GetallRoles(): Observable<any> {
  //   return this.http.get<any>(`${this.configService.grapeApi}/GetAllRoles`);
  // }

  // public checkAccess(users, requiredRoles) {
  //   let granted;

  //   if (users) {
  //     for (const requiredRole of requiredRoles) {
  //       if (users.indexOf(requiredRole.name) > -1) {
  //         granted = true;
  //         break;
  //       } else {
  //         granted = false;
  //       }
  //     }
  //     if (!granted) {
  //       this.router.navigateByUrl('/access-denied');
  //     }
  //   } else {
  //     this.router.navigateByUrl('/access-denied');
  //   }
  //   return granted;
  // }
}
