import { Injectable } from '@angular/core';
import { HoleDistanceSettings } from '../settings/hole-distance-settings';
import { Hole } from '../models/grapeservice/hole';
import { HoleDistanceOption } from '../models/hole-distance-option';
import { HoleSidememberSegmentService } from './hole-sidemember-segment.service';
import { SidememberInfo } from '../models/grapeservice/sidemember-info';
import { environment } from '../../../environments/environment';
import { HoleShape } from '../models/hole-enum';
import { UserSettingsService } from './usersettings.service';

@Injectable()
export class HoleDistanceService {
  private decimalPlaces = environment.sidememberConfig.measureDecimalPlaces;
  private settings: HoleDistanceSettings;

  constructor(
    userSettings: UserSettingsService,
    private sidememberSegmentService: HoleSidememberSegmentService
  ) {
    this.settings = userSettings.holeDistance;
  }

  public calculate(primaryHole: Hole, secondaryHole: Hole): string {
    let holeDistance: number;

    const x = primaryHole.Hole_X - secondaryHole.Hole_X;
    const y = primaryHole.Hole_Y - secondaryHole.Hole_Y;
    const z = primaryHole.Hole_Z - secondaryHole.Hole_Z;

    holeDistance = Math.hypot(x, y, z);
    if (this.settings.holeDistanceOption === HoleDistanceOption.edgeToEdge) {
      holeDistance -= primaryHole.Diameter / 2 + secondaryHole.Diameter / 2;
    }

    return (Math.round(holeDistance * 10 * this.decimalPlaces) / (10 * this.decimalPlaces)).toFixed(this.decimalPlaces);
  }

  public validateHoleSelection(
    sidemember: SidememberInfo,
    primaryHole: Hole,
    secondaryHole: Hole
  ): void {
    const smSegment1 = this.sidememberSegmentService.getSidememberSegment(
      sidemember,
      primaryHole
    );
    const smSegment2 = this.sidememberSegmentService.getSidememberSegment(
      sidemember,
      secondaryHole
    );
    if (smSegment1 !== smSegment2) {
      throw new HoleDistanceCalculationError(
        `Holes are not in the same sidemember segment (${smSegment1} - ${smSegment2}) and cannot be calculated`
      );
    }

    if (this.settings.holeDistanceOption === HoleDistanceOption.edgeToEdge) {
      const notAllowedHole = (hole: Hole) => hole.HoleShape !== HoleShape.Round;

      if (notAllowedHole(primaryHole) || notAllowedHole(secondaryHole)) {
        throw new HoleDistanceCalculationError(
          'Cannot calculate distance from/to a long shape/rectangular hole in Edge-to-edge mode'
        );
      }
    }
  }
}

export class HoleDistanceCalculationError extends Error {
  constructor(message) {
    super(message);
  }
}
