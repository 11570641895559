import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Hole } from '../shared/models/grapeservice/hole';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { KeyValuePair } from '../shared/models/key-value-pair.model';
import { SupplOper } from '../shared/models/supploper';
import { HoleSearchDef } from '../shared/models/hole-search-def';
import { HoleSearchDefinitionComponent } from '../hole-search-definition/hole-search-definition.component';
import { GrapeDataService } from '../shared/services/grape-data.service';
import {
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { Keyboard } from '../rendering/input/type/keyboard';
import { Subscription, Observable, Subject, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { ModalType, ModalResult } from '../shared/models/enums';
import { ResizedEvent } from 'angular-resize-event';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { HoleInformationPrintService } from './hole-information-print.service';

@Component({
  selector: 'app-hole-information',
  templateUrl: './hole-information.component.html',
  styleUrls: ['./hole-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoleInformationComponent implements OnInit, OnDestroy {
  @ViewChild('vScroll') vScroll: ElementRef;
  @ViewChild('holeSearchDefs')
  holeSearchDefs: HoleSearchDefinitionComponent;
  @ViewChild('table') table: Table;
  @Input('holeInformationPrintRef') holeInformationPrintRef: ElementRef;

  //#region properties
  public holeInfoColumns = [
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_NO'), field: 'HoleObj' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_TO'), field: 'HoleToo' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.HOLE_TYPE'), field: 'HSToo' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.PART_NUMBER'), field: 'AObj' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.PART_NAME'), field: 'ASName' },
    { header: of('X'), field: 'Hole_X' },
    { header: of('Y'), field: 'Hole_Y' },
    { header: of('Z'), field: 'Hole_Z' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.DIAM'), field: 'Diameter' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.LENGTH'), field: 'Length' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.SUPPLOPER'), field: 'SupplOper' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.PATTERN_FI'), field: 'HPSName' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.PATTERN_FI_NO'), field: 'HPObj' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.TYPE'), field: 'HoleType' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.GOBJ'), field: 'GObj' },
    { header: this.translate.stream('HOLE_INFORMATION.COLUMNS.GSNAME'), field: 'GSName' },
  ];

  public grpData: GrapeData;
  public supplOper: KeyValuePair[] = new SupplOper().values;
  public holes: Hole[] = [];
  public selectedHole: Hole;
  public inDefineFilterMode = false;
  public searchFilterActive = false;
  public containerHeight = new Subject<number>();
  private subscriptions: Subscription[] = [];
  private allHolesList: Hole[] = [];
  private searchDefinitions: HoleSearchDef[] = [];
  private sidememberInit$: Observable<GrapeData>;
  private drawingHoleSelect$: Observable<GrapeData>;
  private secondaryHoleSelect$: Observable<GrapeData>;
  private toggleHoleFilter$: Observable<GrapeData>;
  //#endregion properties

  //#region ng
  constructor(
    public settings: UserSettingsService,
    private grapeDataService: GrapeDataService,
    private changeDetector: ChangeDetectorRef,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService,
    private holeInfoPrintService: HoleInformationPrintService
  ) {
    this.settings.holeinfo.load();
    this.searchDefinitions = [...this.settings.holeinfo.holeSearchDefinitions];
    this.initializeObservables();
  }

  ngOnInit() {
    this.grpData = this.grapeDataService.grapeData;
    this.searchFilterActive = this.settings.holeinfo.searchFilterActive;
    this.subscribeToObservables();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  //#endregion ng

  //#region data
  private initializeObservables(): void {
    this.sidememberInit$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return data.ChangedObject === GrapeObjectType.Sidemember;
      })
    );

    this.drawingHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return data.ChangedObject === GrapeObjectType.SelectedDrawingHole;
      })
    );

    this.secondaryHoleSelect$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          data.ChangedObject === GrapeObjectType.SelectedSecondaryDrawingHole ||
          data.ChangedObject === GrapeObjectType.ResetSecondaryDrawingHoles
        );
      })
    );

    this.toggleHoleFilter$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return data.ChangedObject === GrapeObjectType.FilteredHoles;
      })
    );
  }

  private subscribeToObservables(): void {
    this.subscriptions.push(
      this.sidememberInit$.subscribe((data: GrapeData) => {
        setTimeout(() => {
          this.table.scrollToVirtualIndex(0);
          this.vScroll.nativeElement.scrollTop = 0;
        });
        if (data.Data.compareMode) {
          this.setDataSource([
            ...data.Data.uniqueSidemember1Holes,
            ...data.Data.uniqueSidemember2Holes,
          ]);
        } else {
          this.setDataSource(
            this.searchFilterActive ? data.Data.FilteredHoles : data.Data.Holes
          );
        }
      }),

      this.drawingHoleSelect$.subscribe((data: GrapeData) => {
        this.toggleFilter(false);
        if (data.Data.SelectedHole) {
          if (data.Data.compareMode) {
            const uniqueHoles = [
              ...data.Data.uniqueSidemember1Holes,
              ...data.Data.uniqueSidemember2Holes,
            ];
            const holesToShow = uniqueHoles.filter(
              (x) => x.HoleId === data.Data.SelectedHole.HoleId
            );
            this.setDataSource(uniqueHoles, holesToShow);
          } else {
            this.selectHole(data.Data.SelectedHole, false);
            this.setDataSource(data.Data.Holes, [
              data.Data.SelectedHole,
              ...data.Data.RelatedHoles,
            ]);
          }
        } else {
          this.selectedHole = null;
          if (data.Data.compareMode) {
            this.setDataSource([
              ...data.Data.uniqueSidemember1Holes,
              ...data.Data.uniqueSidemember2Holes,
            ]);
          } else {
            this.setDataSource(data.Data.Holes);
          }
        }
      }),
      this.secondaryHoleSelect$.subscribe((data: GrapeData) => {
        if (data.Data.SelectedHole && data.Data.SecondaryHoles.length > 0) {
          if (data.Data.compareMode) {
            const uniqueHoles = [
              ...data.Data.uniqueSidemember1Holes,
              ...data.Data.uniqueSidemember2Holes,
            ];
            const holesToShow = uniqueHoles.filter((x) =>
              data.Data.SecondaryHoles.find((y) => y.HoleId === x.HoleId)
            );
            if (uniqueHoles.includes(data.Data.SelectedHole)) {
              holesToShow.push(data.Data.SelectedHole);
            }
            this.setDataSource(uniqueHoles, holesToShow);
          } else {
            this.setDataSource(data.Data.Holes, [
              data.Data.SelectedHole,
              ...data.Data.SecondaryHoles,
            ]);
          }
        } else if (data.Data.SelectedHole) {
          if (data.Data.compareMode) {
            const uniqueHoles = [
              ...data.Data.uniqueSidemember1Holes,
              ...data.Data.uniqueSidemember2Holes,
            ];
            const holesToShow = uniqueHoles.filter(
              (x) => x.HoleId === data.Data.SelectedHole.HoleId
            );
            this.setDataSource(uniqueHoles, holesToShow);
          } else {
            this.setDataSource(data.Data.Holes, [
              data.Data.SelectedHole,
              ...data.Data.RelatedHoles,
            ]);
          }
        } else {
          if (data.Data.compareMode) {
            this.setDataSource([
              ...data.Data.uniqueSidemember1Holes,
              ...data.Data.uniqueSidemember2Holes,
            ]);
          } else {
            this.setDataSource(
              data.Data.Holes, 
              this.searchFilterActive ? data.Data.FilteredHoles : null
            );
          }
        }
      }),

      this.toggleHoleFilter$.subscribe((data: GrapeData) => {
        if (this.searchFilterActive) {
          this.setDataSource(data.Data.Holes, data.Data.FilteredHoles);
        } else {
          this.setDataSource(data.Data.Holes);
        }
      })
    );
  }

  public errText() {
    return this.grpData.Data.loadError.error;
  }

  public isOpenSidememberError() {
    return this.grapeDataService.errorOpenSidemember;
  }

  public isLoading(): boolean {
    return this.grpData === null || this.grpData.Data.loadingSidemember;
  }
  //#endregion data

  //#region getters/setters
  public get allHoles(): Hole[] {
    return this.allHolesList;
  }

  get isCompareMode(): boolean {
    return this.grapeDataService.grapeData.Data.compareMode;
  }

  get isHolesFilterDisabled(): boolean {
    return (
      this.isCompareMode ||
      this.settings.holeinfo.holeSearchDefinitions.length === 0
    );
  }

  get filterToggleTitle(): Observable<string> {
    return this.settings.holeinfo.holeSearchDefinitions.length > 0
      ? this.translate.stream('HOLE_INFORMATION.TOGGLE_FILTER')
      : this.translate.stream('NO_FILTER');
  }

  //#endregion properties

  //#region public methods
  public setCollapsed(): void {
    this.settings.holeinfo.collapsed = !this.settings.holeinfo.collapsed;
    this.settings.holeinfo.save();
  }

  public trackByHoleIndex(index: number) {
    return index;
  }

  public showFilterDefinitions() {
    this.holeSearchDefs.initialize(
      this.settings.holeinfo.holeSearchDefinitions,
      this.settings.holeinfo.colors
    );
    this.settings.holeinfo.collapsed = false;
    this.inDefineFilterMode = true;
  }

  public onRowSelect(event: any) {
    this.selectHole(event.data, true);
  }

  public onRowUnselect(event: any) {
    this.selectHole(event.data, true);
  }

  public onResized(event: ResizedEvent) {
    this.containerHeight.next(event.newHeight - 30);
  }

  public async validateClosing() {
    if (!this.holeSearchDefs.validate(false)) {
      await this.showValidateModalOnClose();
    } else if (this.holeSearchDefs.hasChanged) {
      await this.showSaveChangesModalOnClose();
    }
    if (!this.holeSearchDefs.hasChanged) {
      this.closeFilterDefinitions();
    }
  }

  public async saveFilterDefinitions() {
    if (!this.holeSearchDefs.validate()) return;

    const showPopup =
      !this.settings.holeinfo.searchFilterActive &&
      this.holeSearchDefs.searchDefinitions.length > 0;

    if (showPopup) {
      await this.showActivationFilterModalOnSave();
    }
    if (
      (!this.holeSearchDefs.isSaved && this.holeSearchDefs.hasChanged) ||
      (this.holeSearchDefs.searchDefinitions.length > 0 &&
        !this.holeSearchDefs.hasChanged)
    ) {
      this.closeFilterDefinitionsOnSave();
    }
  }

  private closeFilterDefinitionsOnSave() {
    this.inDefineFilterMode = false;
    this.setFilterHoleDefinition();
    this.closeFilterDefinitions();
  }

  public closeFilterDefinitions() {
    this.searchDefinitions = this.settings.holeinfo.holeSearchDefinitions;
    this.holeSearchDefs.hasChanged = false;
    this.inDefineFilterMode = false;
    this.grapeDataService.setFilteredHoles(this.searchFilterActive);
  }

  //#endregion public methods

  //#region private methods

  //#region Modal
  async showSaveChangesModalOnClose() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('SAVE_CONFIRMATION'),
      ModalType.YesNoCancel
    );
    if (result === ModalResult.Yes) {
      this.closeFilterDefinitionsOnSave();
    } else if (result === ModalResult.No) {
      this.closeFilterDefinitions();
    } else {
      return;
    }
  }

  async showValidateModalOnClose() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_ERROR'),
      ModalType.YesCancel
    );
    if (result === ModalResult.Yes) {
      this.closeFilterDefinitionsOnSave();
    } else {
      return;
    }
  }

  async showActivationFilterModalOnSave() {
    const result: ModalResult = await this.modalDialogService.show(
      this.translate.instant('FILTER_CONFIRMATION'),
      ModalType.YesNo
    );
    if (result === ModalResult.Yes) {
      this.inDefineFilterMode = false;
      this.searchFilterActive = true;
      this.setFilterHoleDefinition();
    } else {
      this.closeFilterDefinitionsOnSave();
    }
  }
  //#endregion Modal

  private setFilterHoleDefinition() {
    if (this.holeSearchDefs.validate(false)) {
      this.searchDefinitions = this.holeSearchDefs.searchDefinitions;
      this.settings.holeinfo.holeSearchDefinitions = this.holeSearchDefs.searchDefinitions;
      this.settings.holeinfo.colors = this.holeSearchDefs.colors;
      this.settings.holeinfo.searchFilterActive = this.searchFilterActive;
    }
    if (this.holeSearchDefs.searchDefinitions.length === 0) {
      this.searchFilterActive = false;
      this.settings.holeinfo.searchFilterActive = this.searchFilterActive;
    }
    this.settings.holeinfo.save();
    this.holeSearchDefs.saved = true;
    this.grapeDataService.setFilteredHoles(this.searchFilterActive);
  }

  private toggleFilter(newState: boolean) {
    this.searchFilterActive = newState;
    this.settings.holeinfo.searchFilterActive = this.searchFilterActive;
    this.settings.holeinfo.save();
  }

  public setFilterState() {
    this.searchFilterActive = !this.searchFilterActive;
    this.settings.holeinfo.searchFilterActive = this.searchFilterActive;
    this.settings.holeinfo.save();
    this.grapeDataService.setFilteredHoles(this.searchFilterActive);
  }

  private setDataSource(fullDataSet: Hole[], filteredDataSet?: Hole[]): void {
    this.containerHeight.next(this.vScroll?.nativeElement?.offsetHeight - 30);
    this.allHolesList = [...fullDataSet];
    this.holes = [...(filteredDataSet ? filteredDataSet : fullDataSet)];
    this.changeDetector.detectChanges();
  }

  public selectHole(hole: Hole, sendSelectedHole: boolean): void {
    this.selectedHole = hole;
    if (sendSelectedHole) {
      this.grapeDataService.setSelectedListHole(hole);
    }
  }

  private ensureVisibilityUpKey(hole: Hole): void {
    const rowIndex = this.holes.indexOf(hole);
    if (rowIndex === -1) {
      return;
    }

    const container = this.table.containerViewChild;
    if (!container) return;

    const rowList: HTMLTableRowElement[] = Array.from(
      container.nativeElement.querySelectorAll('tr')
    );

    const firstVisibleItemIndex = parseInt(rowList[1].id);
    if (firstVisibleItemIndex + 1 >= rowIndex) {
      this.table.scrollToVirtualIndex(rowIndex - 1);
    }
  }

  private selectPreviousHole() {
    const currentIndex = this.holes.indexOf(this.selectedHole);
    if (currentIndex === 0) {
      return;
    }
    const prevHole = this.holes[currentIndex - 1];
    this.selectHole(prevHole, true);
    this.ensureVisibilityUpKey(prevHole);
  }

  private selectNextHole() {
    const currentIndex = this.holes.indexOf(this.selectedHole);
    if (currentIndex === this.holes.length - 1) {
      return;
    }
    const nextHole = this.holes[currentIndex + 1];
    this.selectHole(nextHole, true);
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (this.selectedHole) {
      if (event.keyCode === Keyboard.UP) {
        this.selectPreviousHole();
      } else if (event.keyCode === Keyboard.DOWN) {
        this.selectNextHole();
      }
      event.stopPropagation();
    }
  }

  public printHoleInformation() {
    this.holeInfoPrintService.create(
      this.holeInformationPrintRef, 
      this.grapeDataService.grapeData.Data
    );
  }
}
