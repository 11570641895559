import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  Renderer2,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { Hole } from '../shared/models/grapeservice/hole';
import { HoleSelection } from '../shared/models/rendering/hole-selection';
import { IndexlistEntry } from '../shared/models/indexlist-entry';
import { Observable, of, Subscription } from 'rxjs';
import { SettingsComponent } from '../settings/settings.component';
import { SidememberCanvasComponent } from '../sidemember-canvas/sidemember-canvas.component';
import {
  TotalPicturePrintId,
  TotalPicturePrintingService,
} from '../total-picture/total-picture-printing.service';
import { UIEventService } from '../shared/services/ui-event.service';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { SidememberSelectService } from '../shared/services/sidemember-select.service';
import { HoleDistanceOption } from '../shared/models/hole-distance-option';
import { CriteriaColors } from '../shared/models/search-criteria-color';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidemember-presentation',
  templateUrl: './sidemember-presentation.component.html',
  styleUrls: ['./sidemember-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidememberPresentationComponent implements OnInit {
  @HostBinding('class') class = 'd-flex flex-column flex-grow-1';

  @ViewChild(ModalDirective) modal: ModalDirective;

  @ViewChild('sidememberCanvas')
  sidememberCanvas: SidememberCanvasComponent;

  @ViewChild('totalPicture') totalPictureRef: ElementRef;
  @ViewChild('holeInformationPrint') holeInformationPrintRef: ElementRef;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  @ViewChild('settings')
  settingsComponent: SettingsComponent;

  @ViewChild('smListModal', { static: true })
  public smListModal: TemplateRef<any>;
  public selectedHoles$: Observable<HoleSelection>;
  public selectedHole$: Observable<Hole>;
  public isUpsideDown = false;

  private subscriptions: Subscription[] = [];
  public persistedError = this.grapeDataService.grapeData.Data.loadError.error;

  get totalPictureProgress(): number {
    return (
      (this.totalPicturePrinting.currentlyProcessing /
        this.totalPicturePrinting.pictureCount) *
      100
    );
  }

  get totalPictureProgressPercentage(): string {
    return `${this.totalPictureProgress}%`;
  }

  get totalPictureProgressMessage(): string {
    let totalPictureProgressTranslated: string;
    this.translate
      .get('TOTAL_PICTURE.GENERATING', {
        progress: `${this.totalPictureProgress.toFixed(0)}`,
      })
      .subscribe((res: string) => {
        totalPictureProgressTranslated = res;
      });
    return totalPictureProgressTranslated;
  }

  get isFirstPage(): boolean {
    return this.sidememberSelectService.firstPage;
  }

  public get holeDistanceOption(): typeof HoleDistanceOption {
    return HoleDistanceOption;
  }

  get errorText() {
    if (this.grapeDataService.grapeData.Data.loadError.error) {
      this.persistedError = this.grapeDataService.grapeData.Data.loadError.error;
      this.settings.sidememberPresentation.persistErrors = true;
      this.settings.sidememberPresentation.save();
    }

    return this.settings.sidememberPresentation.persistErrors
      ? this.persistedError
      : this.grapeDataService.grapeData.Data.loadError.error;
  }

  public get isCompareMode(): boolean {
    // Need to identify if compare mode, before subscribing to observables
    // we do that by checking the route params
    return this.route.snapshot.params['compareIpoId'];
  }

  get isloadingPreviewErrorOccured() {
    return this.grapeDataService.errorPreview.value;
  }

  constructor(
    public settings: UserSettingsService,
    public totalPicturePrinting: TotalPicturePrintingService,
    private uiEvent: UIEventService,
    public grapeDataService: GrapeDataService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private sidememberSelectService: SidememberSelectService,
    private translate: TranslateService
  ) {
    this.totalPicturePrinting.renderer = this.renderer;
    this.settings.holeinfo.load();
    this.settings.sidememberPresentation.load();
    this.settings.totalPicture.load();
    this.settings.holeDistance.load();

    this.subscribeToData();
  }

  ngOnInit() {
    this.sidememberSelectService.modalTemplate = this.smListModal;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private subscribeToData() {
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.sidememberSelectService.handleParams(params);
      })
    );
  }

  public canvasHoleSelection(selection: HoleSelection): void {
    this.selectedHoles$ = of(selection);
  }

  public infoHoleSelection(hole: Hole): void {
    this.selectedHole$ = of(hole);
  }

  public dispose(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.sidememberCanvas) {
      return false;
    }
    return true;
  }

  public async printIndexlistTotalPicture(
    data: [IndexlistEntry[], string]
  ): Promise<void> {
    const totalPictures: TotalPicturePrintId[] = [];
    const entries = data[0];
    const dataSource = data[1];

    entries.forEach((entry: IndexlistEntry) => {
      totalPictures.push({
        dataSource,
        ipoId: entry.ipoId,
        version: entry.version.toString(),
      });
    });

    await this.totalPicturePrinting.print(totalPictures, this.totalPictureRef);
  }

  public printSidememberTotalPicture(): void {
    this.totalPicturePrinting.print(
      [
        {
          dataSource: this.settings.indexlist.dataSource,
          ipoId: this.grapeDataService.grapeData.Data.HFInfo.IPOId,
          version: this.grapeDataService.grapeData.Data.HFInfo.Version,
        },
      ],
      this.totalPictureRef
    );
  }

  public saveSettings(dropdown: any): void {
    this.sidememberCanvas.redraw();
    dropdown.hide();
  }

  public getPanelHeading(
    sidememberIndex?: number
  ): {
    chassisNumber: string;
    cuName: string;
    colorHint: string;
    version: string;
  } {
    let hfInfo = this.grapeDataService.grapeData?.Data?.HFInfo;
    let colorHint = undefined;

    if (this.isCompareMode) {
      if (sidememberIndex === 1) {
        hfInfo = this.grapeDataService.grapeData?.Data?.sidemember1?.HFInfo;
        colorHint = CriteriaColors.DarkGreen;
      } else {
        hfInfo = this.grapeDataService.grapeData?.Data?.sidemember2?.HFInfo;
        colorHint = colorHint = CriteriaColors.Orange;
      }
    }
    return {
      chassisNumber: hfInfo?.ChassisNumber,
      cuName: hfInfo?.CUSName,
      colorHint,
      version: hfInfo?.Version,
    };
  }

  public printDisabled() {
    return (
      this.totalPicturePrinting.isProcessing ||
      this.isCompareMode ||
      this.isOpenSidememberError()
    );
  }

  public getSelectedDataSource() {
    if (this.settings) {
      return this.settings.indexlist.dataSource;
    }
  }

  public settingsToggle(open: boolean): void {
    if (!open || !this.settingsComponent) return;
    this.settingsComponent.loadSettings();
  }

  public expandDrawing() {
    this.settings.sidememberPresentation.fullSize = !this.settings
      .sidememberPresentation.fullSize;
    this.settings.sidememberPresentation.save();
    this.uiEvent.sidememberViewExpanded(
      this.settings.sidememberPresentation.fullSize
    );
  }

  public toggleDrawingFlip() {
    this.settings.sidememberPresentation.showUpsideDown = !this.settings
      .sidememberPresentation.showUpsideDown;
    this.settings.sidememberPresentation.save();
    this.uiEvent.sidememberFlip(
      this.settings.sidememberPresentation.showUpsideDown
    );
  }

  public isOpenSidememberError(): boolean {
    if (this.grapeDataService.grapeData === null) {
      return false;
    }
    return this.grapeDataService.grapeData.Data.loadError.forSidemember;
  }

  public isSidememberLoading(): boolean {
    if (this.grapeDataService.grapeData === null) {
      return false;
    }
    return this.grapeDataService.grapeData.Data.loadingSidemember;
  }

  public isIndexListLoading(): boolean {
    return (
      this.grapeDataService.grapeData === null ||
      this.grapeDataService.grapeData.Data.loadingIndexList
    );
  }

  public isOpenButtonDisabled(smSelectionLength: number) {
    return (
      this.isloadingPreviewErrorOccured ||
      !this.isSidememberSelected() ||
      this.isSidememberLoading() ||
      smSelectionLength > 2
    );
  }

  public isSidememberSelected(): boolean {
    if (
      this.sidememberSelectService.sidememberSelectionPreview?.dataSource &&
      this.sidememberSelectService.sidememberSelectionPreview?.entry?.ipoId &&
      this.sidememberSelectService.sidememberSelectionPreview?.entry?.version
    ) {
      return true;
    }
    return false;
  }

  public showSMListModal() {
    this.sidememberSelectService.showModal();
  }

  public cancelSMListModal() {
    this.sidememberSelectService.cancelModal();
  }

  public openSidemember() {
    this.sidememberSelectService.openSidemember();
  }

  public saveSidememberDistanceOption(option: HoleDistanceOption) {
    this.settings.holeDistance.holeDistanceOption = option;
    this.settings.holeDistance.save();

    this.uiEvent.holeDistanceMode(
      this.settings.holeDistance.holeDistanceOption
    );
  }
}
