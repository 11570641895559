import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TotalPictureService } from './total-picture.service';
import {
  TotalPictureDrawingSettings,
  TotalPicturePaperSettings,
} from './total-picture.model';
import {
  GrapeDataModel,
  GrapeData,
  GrapeObjectType,
} from '../shared/models/grapeservice/grape.data';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { GrapeDataService } from '../shared/services/grape-data.service';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-total-picture',
  templateUrl: './total-picture.component.html',
  styleUrls: ['./total-picture.component.scss'],
  providers: [TotalPictureService],
})
export class TotalPictureComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasRef: ElementRef;

  public isLoading = true;

  private canvas: HTMLCanvasElement;
  private sidemember$: Observable<GrapeData>;
  private filteredHoles$: Observable<GrapeData>;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private grapeData: GrapeDataService,
    private totalPicture: TotalPictureService,
    private userSettings: UserSettingsService
  ) {
    this.sidemember$ = this.grapeData.data.pipe(
      filter(
        (data: GrapeData) => data.ChangedObject === GrapeObjectType.Sidemember
      )
    );

    this.filteredHoles$ = this.grapeData.data.pipe(
      filter(
        (data: GrapeData) =>
          data.ChangedObject === GrapeObjectType.FilteredHoles
      )
    );

    this.loadSettings();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.canvas = this.canvasRef.nativeElement;
    this.subscribeToData();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private loadSettings(): void {
    this.userSettings.totalPicture.load();
    this.userSettings.holeinfo.load();
    this.userSettings.holeDuplication.load();
  }

  private subscribeToData(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.loadSidemember(params.dataSource, params.ipoId, params.version);
      }),

      this.sidemember$.subscribe((data: GrapeData) => {
        this.drawTotalPicture(data.Data);
      }),

      this.filteredHoles$.subscribe((data: GrapeData) => {
        this.drawTotalPicture(data.Data);
      })
    );
  }

  private loadSidemember(dataSource: string, ipoId: string, version: string) {
    if (!dataSource || !ipoId || !version) return;

    this.grapeData.setSideMember(dataSource, ipoId, version);
  }

  private drawTotalPicture(data: GrapeDataModel): void {
    const paperSettings: TotalPicturePaperSettings = {
      widthCm: 29.7,
      heightCm: 20.5,
      marginsCm: {
        left: 2.5,
        right: 2.5,
        top: 0.65,
        bottom: 1,
      },
    };

    const settings: TotalPictureDrawingSettings = {
      paperSettings,
      data,
      scalingRatio: 2,
      sectionWidth: 4055,
      sectionOverlap: 100,
      drawUpsideDown: this.userSettings.sidememberPresentation.showUpsideDown,
      drawXm: this.userSettings.totalPicture.printXm,
      drawTickMarks: this.userSettings.totalPicture.printTickMarks,
      enlargeFilteredHoles: this.userSettings.totalPicture.printHoleEnlarged,
      drawFilteredHoleNumbers: this.userSettings.totalPicture.printHoleNumbers,
      drawPPTRMarks: this.userSettings.sidememberPresentation.printHoleMarking,
      drawRestrictedAreas: this.userSettings.sidememberPresentation.printRestrictedAreas,
    };

    const context = this.canvas.getContext('2d');

    this.totalPicture.create(context, settings);

    this.isLoading = false;
  }
}
