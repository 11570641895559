import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import sv from '@angular/common/locales/sv';
import nl from '@angular/common/locales/nl';
import pt from '@angular/common/locales/pt';
import fr from '@angular/common/locales/fr';
import enGB from '@angular/common/locales/en-GB';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  private _locale: string;

  public set locale(value: string) {
    this._locale = value;
  }
  public get locale(): string {
    return this._locale || 'en-GB';
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    this.locale = culture;

    // Register locale data since only the en-US locale data comes with Angular
    switch (culture) {
      case 'sv': {
        registerLocaleData(sv);
        break;
      }
      case 'nl': {
        registerLocaleData(nl);
        break;
      }
      case 'pt': {
        registerLocaleData(pt);
        break;
      }
      case 'fr': {
        registerLocaleData(fr);
        break;
      }
      case 'en': {
        registerLocaleData(enGB);
        break;
      }
    }
  }
}
