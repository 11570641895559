export class SidememberInfo {
  AssemblyDate: string;
  BDAObj: string;
  BDASName: string;
  BDObj: string;
  BDSName: string;
  BD_BlankBeg_X: number;
  BD_BlankEnd_X: number;
  BD_BlankType: string;
  BD_Blank_Thickn: number;
  BD_CADVer: string;
  BatchNumber: string;
  BendingAngle: number;
  BendingPos_X: number;
  BreakdownBy: string;
  CIId: string;
  CUObj: string;
  CUSName: string;
  ChassisNumber: string;
  FlangeWidth: string;
  FrameSide: string;
  HFStatus: number | string;
  IPOId: string;
  Origin: string;
  PopId: string;
  ProdIndividual: string;
  SequenceNumber: string;
  SupplierID: string;
  SupplierName: string;
  SurfaceTreat_ED: string;
  SurfaceTreat_Phos: string;
  SurfaceTreat_Pow: string;
  TypeText: string;
  VINCode_mark: string;
  VINCode_row1: string;
  VINCode_row2: string;
  Version: string;
  WebHeight: string;
}
