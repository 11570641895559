import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  ElementRef,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  SidememberDrawingOptions,
  SidememberDrawing,
} from '../rendering/sidemember-drawing';
import { filter } from 'rxjs/operators';
import {
  GrapeData,
  GrapeObjectType,
  GrapeDataModel,
} from '../shared/models/grapeservice/grape.data';
import { Subscription, Observable } from 'rxjs';
import { UserSettingsService } from '../shared/services/usersettings.service';
import { UIEventService } from '../shared/services/ui-event.service';
import { GrapeDataService } from '../shared/services/grape-data.service';

// tslint:disable-next-line: import-name
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-sidemember-preview',
  templateUrl: './sidemember-preview.component.html',
  styleUrls: ['./sidemember-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidememberPreviewComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'd-flex flex-row';
  @ViewChild('canvas') canvasRef: ElementRef;

  get canvasRect(): ClientRect | DOMRect {
    if (!this.canvas) return;
    return this.canvas.getBoundingClientRect();
  }

  get scale(): number {
    if (!this.canvas || !this.canvasRect) return;
    return this.canvasRect.width / this.canvas.width;
  }

  private drawingOptions: SidememberDrawingOptions = {
    scalingRatio: 0.5,
    lineWidth: 4.5,
    lineColor: '#000000',
    drawUpsideDown: false,
    clipFromBegX: true,
    includeCutouts: true,
    colorRoundHole: true,
    drawPPTRMarks: true,
    drawRestrictedAreas: true,
    holeOptions: {
      showFilteredHoles: true,
      showRelatedHoles: false,
      showSelectedHole: false,
      showHoleDuplicates: true,
    },
  };

  private canvas: HTMLCanvasElement;
  private data: GrapeDataModel;
  private drawing: SidememberDrawing;
  private flipSidemember$: Observable<boolean>;
  private toggleRedraw$: Observable<GrapeData>;
  private subscriptions: Subscription[] = [];

  constructor(
    public settings: UserSettingsService,
    private uiEventService: UIEventService,
    private grapeDataService: GrapeDataService,
    private host: ElementRef
  ) {
    this.settings.sidememberPresentation.load();

    this.flipSidemember$ = this.uiEventService.sidememberFlipStatus.pipe(
      filter(
        (isFlipped: boolean) =>
          this.drawing !== undefined && this.drawing !== null
      )
    );

    this.toggleRedraw$ = this.grapeDataService.data.pipe(
      filter((data: GrapeData) => {
        return (
          data.Data !== undefined &&
          data.Data !== null &&
          (data.ChangedObject === GrapeObjectType.SidememberPreview ||
            data.ChangedObject === GrapeObjectType.FilteredHoles ||
            data.ChangedObject === GrapeObjectType.HoleDuplicates)
        );
      })
    );

    this.subscribeForData();
  }

  ngOnInit() {
    this.drawingOptions.drawUpsideDown = this.settings.sidememberPresentation.showUpsideDown;
  }

  ngAfterViewInit() {
    this.canvas = this.canvasRef.nativeElement;
    this.observeResize();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private subscribeForData() {
    this.subscriptions.push(
      this.toggleRedraw$.subscribe((data: GrapeData) => {
        this.data = JSON.parse(JSON.stringify(data.Data));
        this.createDrawing();
        this.update();
      }),

      this.flipSidemember$.subscribe((flipped: boolean) => {
        this.drawingOptions.drawUpsideDown = flipped;
        this.createDrawing();
        this.update();
      })
    );
  }

  private createDrawing(): void {
    const sidememberWidth = Math.abs(
      this.data.HFInfo.BD_BlankEnd_X - this.data.HFInfo.BD_BlankBeg_X
    );

    const longSidemember = sidememberWidth > 8000;

    // TODO: Calculate real pixels to use as margins instead
    this.drawingOptions.margins = {
      top: longSidemember ? 300 : 150,
      left: 150,
      bottom: longSidemember ? 300 : 150,
      right: 150,
    };

    this.drawingOptions.scalingRatio = longSidemember ? 0.2 : 0.5;
    this.drawingOptions.drawPPTRMarks = this.settings.sidememberPresentation.printHoleMarking;
    this.drawingOptions.drawRestrictedAreas = this.settings.sidememberPresentation.printRestrictedAreas;

    this.drawing = SidememberDrawing.create(this.data, this.drawingOptions);
  }

  private update(): void {
    if (!this.drawing) return;
    if (!this.canvas) return;

    this.clear();

    const context = this.canvas.getContext('2d');

    context.imageSmoothingEnabled = false;
    context.imageSmoothingQuality = 'high';

    this.canvas.width = this.drawing.canvas.width;
    this.canvas.height = this.drawing.canvas.height;

    context.drawImage(
      this.drawing.canvas,
      0,
      0,
      this.drawing.canvas.width,
      this.drawing.canvas.height
    );
  }

  private observeResize(): void {
    const resizeObserver = new ResizeObserver(() => {
      this.update();
    });

    resizeObserver.observe(this.host.nativeElement);
  }

  private clear(): void {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
